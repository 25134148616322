import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { ShareIcon } from "@heroicons/react/20/solid";
import timezones from "@/assets/data/timezones.json";
import Select from "react-select";
import { useMutation, useQuery } from "react-query";
import { fetchPreviewData } from "@/api/project";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { t } from "i18next";
import { postSchedule } from "@/api/schedule";
import { fetchPages, fetchTokenStatus } from "@/api/facebookAuth";
import { toast } from "react-toastify";
import { fetchLinkedinPages, fetchLinkedinTokenStatus } from "@/api/linkedinAuth";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedStory: any;
}

export default function PreviewModal(props: Props) {
  const { open, setOpen, selectedStory } = props;

  const [selectedTimezone, setSelectedTimezone] = useState<any>({});
  const [project] = useRecoilState(projectInfo);
  const [selectedPlatform, setSelectedPlatform] = useState<any>({
    facebook: false,
    instagram: false,
    linkedin: false,
  });
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [pageId, setPageId] = useState<any>(null);
  const [instagramPageId, setInstagramPageId] = useState<any>(null);
  const [linkedinPageUrn, setLinkedinPageUrn] = useState<any>(null);
  const [shareNow, setShareNow] = useState<boolean>(false);

  const [showErrorMessageForAccount, setShowErrorMessageForAccount] = useState<{
    facebook: boolean;
    linkedin: boolean;
  }>({
    facebook: false,
    linkedin: false,
  });

  const { data: facebookPages, isLoading: isLoadingPage } = useQuery(
    "fetchPages",
    () => fetchPages(project.id),
    {
      enabled: !!project.id,
    }
  );

  const { data: linkedinPages } = useQuery(
    "fetchLinkedinPages",
    () => fetchLinkedinPages(project.id),
    {
      enabled: !!project.id,
    }
  );

  const { isLoading, data } = useQuery(
    "fetchPreviewDataforPublish",
    () =>
      fetchPreviewData({
        project_id: project.id,
        project_story_id: selectedStory.project_story_id,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { mutateAsync: post, isLoading: isLoadingPost } = useMutation(postSchedule, {});

  const handlePost = async () => {
    setError(null);
    // is not any selected platform return error
    if (!selectedPlatform.facebook && !selectedPlatform.instagram && !selectedPlatform.linkedin) {
      setError(t("Please select at least one platform"));
      return;
    }
    // is not any selected timezone return error
    if (!shareNow && (!selectedTimezone || !selectedDate)) {
      setError(t("Please fill all the fields"));
      return;
    }

    let date;
    let dateInISO;

    if (selectedDate) date = new Date(selectedDate);
    else date = new Date();

    if (!shareNow) {
      const timezone = selectedTimezone;
      const timezoneOffset = timezones.find(
        (timezoneItem) => timezoneItem.value === timezone
      )?.offset;
      const dateWithTimezoneOffset = new Date(
        date.getTime() - (timezoneOffset ?? 0) * 60 * 60 * 1000
      );
      const dateWithTimezoneOffsetInUTC = new Date(
        dateWithTimezoneOffset.getTime() - dateWithTimezoneOffset.getTimezoneOffset() * 60 * 1000
      );
      dateInISO = dateWithTimezoneOffsetInUTC.toISOString();
    } else {
      dateInISO = date.toISOString();
    }

    if (selectedPlatform.facebook) {
      if (!pageId) {
        setError(t("Please select a page"));
        return;
      } else
        await post({
          event_type: "publish",
          event_resources: [
            {
              id: data[data.length - 1]?.id,
              resource_type: "file",
            },
            {
              published: true,
              resource_type: "published",
            },
            {
              text: (data && data[data.length - 1]?.additional_data?.message) || "",
              resource_type: "message",
            },
          ],
          event_platform: {
            platform: "facebook",
            page_id: pageId,
          },
          status: "pending",
          project_id: project.id,
          event_date: dateInISO,
        });
    }

    if (selectedPlatform.instagram) {
      if (!instagramPageId) {
        setError(t("Please select a page"));
        return;
      }
      await post({
        event_type: "publish",
        event_resources: [
          {
            id: data[data.length - 1]?.id,
            resource_type: "file",
          },
          {
            published: true,
            resource_type: "published",
          },
          {
            text: (data && data[data.length - 1]?.additional_data?.message) || "",
            resource_type: "caption",
          },
        ],
        event_platform: {
          platform: "instagram",
          page_id: instagramPageId,
        },
        status: "pending",
        project_id: project.id,
        event_date: dateInISO,
      });
    }

    if (selectedPlatform.linkedin) {
      if (!linkedinPageUrn) {
        setError(t("Please select a linkedin page"));
        return;
      }
      await post({
        event_type: "publish",
        event_date: dateInISO,
        event_resources: [
          {
            id: data[data.length - 1]?.id,
            resource_type: "file",
          },
          {
            text: (data && data[data.length - 1]?.additional_data?.message) || "",
            resource_type: "commentary",
          },
          {
            text: "image schedule",
            resource_type: "alt_text",
          },
        ],
        event_platform: {
          platform: "linkedin",
          organization_urn: linkedinPageUrn,
        },
        status: "pending",
        project_id: project.id,
      });
    }

    toast.success(t("Your post has been scheduled successfully"));
  };

  const { isError, isLoading: isLoadingStatus } = useQuery(
    "fetchTokenStatus",
    () => fetchTokenStatus(project.id),
    {
      enabled: !!project.id,
      retry: false,
    }
  );

  const { isError: isErrorLinkedin, isLoading: isLoadingLinkedinStatus } = useQuery(
    "fetchLinkedinTokenStatus",
    () => fetchLinkedinTokenStatus(project.id),
    {
      retry: false,
      enabled: !!project.id,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[101]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                {isLoading || isLoadingPage || isLoadingStatus || isLoadingLinkedinStatus ? (
                  <div className="flex justify-center items-center">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div>
                    <div className="my-3 font-semibold text-xl">Edit and share your work!</div>
                    <div className="sm:flex sm:items-start">
                      <div className="flex flex-col md:flex-row gap-4 py-3">
                        {data && data[data.length - 1]?.file_url ? (
                          data[0]?.file_type.includes("image") ? (
                            <img
                              className="w-[250px] h-[250px] object-cover"
                              src={data[data.length - 1]?.file_url}
                              alt="placeholder"
                            />
                          ) : (
                            <div>No image</div>
                          )
                        ) : (
                          <div className="flex justify-center items-center h-40 min-w-[150px] bg-gray-100">
                            <div className="text-gray-400">{t("No image")}</div>
                          </div>
                        )}
                        <div>
                          <div>
                            {data && data[data.length - 1].additional_data?.message ? (
                              <div className="text-gray-800">
                                {data[data.length - 1].additional_data?.message}
                              </div>
                            ) : (
                              <div className="text-gray-400">{t("No message")}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-4 mt-2 w-full">
                      <div className="w-full">
                        <div className="flex gap-5 my-3">
                          <>
                            <div className="flex items-center">
                              <span className="mr-3 text-sm font-medium text-gray-900 ">
                                Share to Facebook
                              </span>
                              <label
                                onClick={() => {
                                  if (isError) {
                                    setShowErrorMessageForAccount({
                                      ...showErrorMessageForAccount,
                                      facebook: true,
                                    });
                                  }
                                }}
                                className="relative inline-flex items-center cursor-pointer">
                                <input
                                  onChange={() => {
                                    setSelectedPlatform({
                                      ...selectedPlatform,
                                      facebook: !selectedPlatform.facebook,
                                    });
                                  }}
                                  disabled={isError}
                                  defaultValue={selectedPlatform.facebook}
                                  type="checkbox"
                                  value=""
                                  className="sr-only peer"
                                />
                                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-green-600"></div>
                              </label>
                            </div>
                            <div className="flex items-center">
                              <>
                                <span className="mr-3 text-sm font-medium text-gray-900 ">
                                  {t("Share to Instagram")}
                                </span>
                                <label
                                  onClick={() => {
                                    if (isError) {
                                      setShowErrorMessageForAccount({
                                        ...showErrorMessageForAccount,
                                        facebook: true,
                                      });
                                    }
                                  }}
                                  className="relative inline-flex items-center cursor-pointer">
                                  <input
                                    onChange={() => {
                                      setSelectedPlatform({
                                        ...selectedPlatform,
                                        instagram: !selectedPlatform.instagram,
                                      });
                                    }}
                                    disabled={isError}
                                    type="checkbox"
                                    value=""
                                    className="sr-only peer"
                                  />
                                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-green-600"></div>
                                </label>
                              </>
                            </div>
                          </>

                          <div className="flex items-center">
                            <span className="mr-3 text-sm font-medium text-gray-900 ">
                              {t("Share to Linkedin")}
                            </span>
                            <label
                              onClick={() => {
                                if (isErrorLinkedin) {
                                  setShowErrorMessageForAccount({
                                    ...showErrorMessageForAccount,
                                    linkedin: true,
                                  });
                                }
                              }}
                              className="relative inline-flex items-center cursor-pointer">
                              <input
                                onChange={() => {
                                  setSelectedPlatform({
                                    ...selectedPlatform,
                                    linkedin: !selectedPlatform.linkedin,
                                  });
                                }}
                                disabled={isErrorLinkedin}
                                type="checkbox"
                                value=""
                                className="sr-only peer"
                              />
                              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-green-600"></div>
                            </label>
                          </div>
                        </div>

                        <div className="w-full">
                          <div className="mt-2 w-full">
                            {selectedPlatform.facebook &&
                              facebookPages &&
                              facebookPages.length > 0 && (
                                <div className="mb-5 mt-2 w-full">
                                  <label htmlFor="page" className="block text-sm text-gray-500">
                                    {t("Select a Facebook page")}
                                  </label>

                                  <select
                                    id="page"
                                    className="w-full md:w-3/4 border outline-none py-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(e) => setPageId(e.target.value)}>
                                    <option value="" hidden selected>
                                      Select a page
                                    </option>
                                    {facebookPages
                                      ?.filter((page: any) => page.can_post)
                                      .map((page: any) => (
                                        <option key={page.id} value={page.id}>
                                          {page.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="w-full">
                          <div className="mt-2 w-full">
                            {selectedPlatform.instagram &&
                              facebookPages &&
                              facebookPages.length > 0 && (
                                <div className="mb-5 mt-2 w-full">
                                  <label htmlFor="page" className="block text-sm text-gray-500">
                                    {t("Select a Instagram page")}
                                  </label>

                                  <select
                                    id="page"
                                    className="w-full md:w-3/4 border outline-none py-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(e) => setInstagramPageId(e.target.value)}>
                                    <option value="" hidden selected>
                                      {t("Select a page")}
                                    </option>
                                    {facebookPages
                                      ?.filter((page: any) => page.can_post)
                                      ?.filter((page: any) => page?.instagram_account !== null)
                                      .map((page: any) => (
                                        <option key={page.id} value={page.id}>
                                          {page.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                              )}
                          </div>
                        </div>

                        <div>
                          <div className="mt-2">
                            {selectedPlatform.linkedin &&
                              linkedinPages &&
                              linkedinPages.length > 0 && (
                                <div className="mb-5 mt-2">
                                  <label htmlFor="lpage" className="block text-sm text-gray-500">
                                    {t("Select a Linkedin page")}
                                  </label>

                                  <select
                                    id="lpage"
                                    className="w-full md:w-3/4 border outline-none py-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    onChange={(e) => setLinkedinPageUrn(e.target.value)}>
                                    <option value="" hidden selected>
                                      Select a page
                                    </option>
                                    {linkedinPages?.map((page: any) => (
                                      <option key={page.id} value={page.organization}>
                                        {page?.name?.localized.en_US || "Unknown Page Name"}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      {/*  <div className="flex items-center">
                      <span className="mr-3 text-sm font-medium text-gray-900 ">
                        Share to LinkedIn
                      </span>
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" />
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-blue-600"></div>
                      </label>
                    </div> */}
                    </div>
                    <div className="flex items-center mt-2">
                      <input
                        className="w-4 h-4 "
                        type="checkbox"
                        id="schedule"
                        name="schedule"
                        onChange={(e) => setShareNow(e.target.checked)}
                      />
                      <label className="ml-2" htmlFor="schedule">
                        {t("Share now")}
                      </label>
                    </div>
                    <div className="text-xs mt-1 ml-7">
                      {t("If you want to schedule your post, please select a date and time")}
                    </div>
                    <div className="flex gap-3 flex-col mt-4">
                      <div className="flex flex-col  text-sm">
                        <label className="text-gray-500" htmlFor="timezone">
                          {t("Select timezone")}
                        </label>
                        <Select
                          isDisabled={shareNow}
                          styles={{
                            placeholder: (provided: any) => ({
                              ...provided,
                              fontSize: "0.875rem",
                            }),
                            control: (provided: any) => ({
                              ...provided,
                              fontSize: "0.875rem",
                              textTransform: "capitalize",
                            }),
                            option: (provided: any) => ({
                              ...provided,
                              fontSize: "0.875rem",
                              textTransform: "capitalize",
                            }),
                          }}
                          id="timezone"
                          options={timezones}
                          className="w-96"
                          onChange={(e: any) => {
                            setSelectedTimezone(e.value);
                          }}
                        />
                      </div>
                      <div className="flex flex-col  text-sm">
                        <label className="text-gray-500" htmlFor="date">
                          {t("Select a date")}
                        </label>
                        <input
                          id="date"
                          disabled={shareNow}
                          onChange={(e) => {
                            setSelectedDate(e.target.value);
                          }}
                          className="w-96 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          type="datetime-local"
                        />
                      </div>
                      {error && <div className="text-red-500 text-sm">{error}</div>}
                    </div>

                    {showErrorMessageForAccount.facebook && isError && (
                      <div className="flex items-center text-sm gap-2 my-2">
                        <div className="text-red-500">
                          {t("Please connect your Facebook account to schedule a post")}
                        </div>
                      </div>
                    )}
                    {showErrorMessageForAccount.linkedin && isErrorLinkedin && (
                      <div className="flex items-center text-sm gap-2 my-2">
                        <div className="text-red-500">
                          {t("Please connect your Linkedin account to schedule a post")}
                        </div>
                      </div>
                    )}

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <DefaultButton
                        className="flex items-center w-fit"
                        type="button"
                        isLoading={isLoadingPost}
                        onClick={() => handlePost()}>
                        <ShareIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                        <span>{t("share")}</span>
                      </DefaultButton>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/* 
{(selectedPlatform.facebook || selectedPlatform.instagram) && (
  <div>
    <div className="mt-2">
      {facebookPages && facebookPages.length > 0 ? (
        <div className="mb-5 mt-2">
          <label htmlFor="page" className="block text-sm text-gray-500">
            {t("Select a Facebook page")}
          </label>

          <select
            id="page"
            className="w-full border outline-none py-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={(e) => setPageId(e.target.value)}>
            <option value="" hidden selected>
              Select a page
            </option>
            {facebookPages
              ?.filter((page: any) => page.can_post)
              .map((page: any) => (
                <option key={page.id} value={page.id}>
                  {page.name}
                </option>
              ))}
          </select>
        </div>
      ) : (
        <div className="mb-5 mt-2 text-sm text-red-500">
          {t("Please add a page to your account to share")}
        </div>
      )}
    </div>
  </div>
)}

{selectedPlatform.linkedin && (
  <div>
    <div className="mt-2">
      {linkedinPages && linkedinPages.length > 0 ? (
        <div className="mb-5 mt-2">
          <label htmlFor="lpage" className="block text-sm text-gray-500">
            {t("Select a Linkedin page")}
          </label>

          <select
            id="lpage"
            className="w-full border outline-none py-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            onChange={(e) => setLinkedinPageUrn(e.target.value)}>
            <option value="" hidden selected>
              Select a page
            </option>
            {linkedinPages?.map((page: any) => (
              <option key={page.id} value={page.organization}>
                {page?.name?.localized.en_US || "Unknown Page Name"}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="mb-5 mt-2 text-sm text-red-500">
          {t("Please add a page to your account to share")}
        </div>
      )}
    </div>
  </div>
)} */
