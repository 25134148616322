import { atom } from "recoil";

export const unreadMessageCount = atom<{
  items: any[];
}>({
  key: "unreadMessageCount",
  default: {
    items: [],
  },
});
