import moment from "moment";
import client from "./googleAdsBase";

export const loginGoogleAds = async () => {
  const token = localStorage.getItem("authToken");
  if (token === null) {
    return;
  }
  const accountId = localStorage.getItem("accountId");
  if (accountId === null) {
    return;
  }

  const googleAdsTokenHolderStr = localStorage.getItem("googleAdsTokenHolder");
  if (googleAdsTokenHolderStr) {
    const googleAdsTokenHolder = JSON.parse(googleAdsTokenHolderStr);
    if (accountId === googleAdsTokenHolder.accountId) {
      const validThroughUtc = moment(googleAdsTokenHolder.validThroughUtc);
      if (moment().utc() <= validThroughUtc) {
        return googleAdsTokenHolder;
      }
    }
  }

  await client.post("/Auth/login", {
    username: "Earnado",
    password: "Za45oxnctF7dG?4Jt1awDr",
    bearerToken: token,
  }).then((response) => {
    localStorage.setItem("googleAdsTokenHolder", JSON.stringify(response.data));
    localStorage.setItem("googleAdsLogin", response.data.tokenString);
    return response.data;
  })
};

export const fetchProjectSnapshot = async (projectId: string) => {
  const response = await client.get(`/Project/snapshot/${projectId}`);

  return response.data;
};

export const fetchGoogleAdsCampaignReport = async (campaignId: number | undefined) => {
  const response = await client.get(`/ea/campaign/report/${campaignId}`);

  return response.data;
};

export const fetchGoogleAdsCampaignStats = async (projectId: number) => {
  const response = await client.get(`/ea/project/campaign-statistics/${projectId}`);

  return response.data;
};

export const fetchGoogleAdsErrorStats = async (projectId: number) => {
  const response = await client.get(`/ea/project/error-statistics/${projectId}`);

  return response.data;
};

export const fetchGoogleStatistics = async (projectId: number) => {
  const response = await client.get(`/ea/project/statistics/${projectId}`);

  return response.data;
};
