import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import WhiteButton from "@/components/buttons/WhiteButton";
import { useMutation } from "react-query";
import { postProjectFile } from "@/api/project";
import { toast } from "react-toastify";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";
import { t } from "i18next";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedStory: any;
  refetch: () => void;
  defaultImage: any;
  defaultImageName: any;
  defaultText: any;
};

export default function UploadYourContentModal(props: Props) {
  const { open, setOpen, selectedStory, refetch, defaultImage, defaultText, defaultImageName } =
    props;
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedCaption, setSelectedCaption] = useState<any>(null);
  const [project] = useRecoilState(projectInfo);

  const { mutateAsync: createPost, isLoading: isLoadingPost } = useMutation(postProjectFile);

  const handleCreatePost = () => {
    createPost({
      project_id: project.id,
      file_type: selectedImage?.type || "image/png",
      file_in: selectedImage,
      file_url: selectedImage ? null : defaultImage,
      additional_data: selectedCaption
        ? `{"message":"${selectedCaption}","project_story_id":${selectedStory.project_story_id}}`
        : `{"message":${defaultText === undefined ? null : `'${defaultText}'`},"project_story_id":${selectedStory.project_story_id
        }}`,
    }).then(() => {
      refetch();
      toast.success(t("Post created successfully, you can preview"));
      setOpen(false);
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[101]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[101] overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none  "
                    onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div>
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {t("Upload your content")}
                  </Dialog.Title>

                  <label
                    className="block mb-2 text-sm font-medium text-gray-400 dark:text-white mt-4"
                    htmlFor="file_input">
                    {t("Upload your image")}
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    aria-describedby="file_input_help"
                    id="file_input"
                    type="file"
                    onChange={(e: any) => setSelectedImage(e.target.files[0])}
                  />
                  <div className="text-sm text-gray-600 dark:text-gray-300 mt-2">
                    {defaultImageName && (
                      <div>
                        {t("Selected image")}: {defaultImageName}
                      </div>
                    )}
                  </div>

                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-400 dark:text-white mt-3">
                    {t("Your message")}
                  </label>
                  <textarea
                    defaultValue={defaultText}
                    id="message"
                    rows={4}
                    onChange={(e) => setSelectedCaption(e.target.value)}
                    className="outline-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={t("Write your thoughts here...")}></textarea>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
                  <DefaultButton
                    isLoading={isLoadingPost}
                    onClick={() => handleCreatePost()}
                    className="w-fit">
                    {t("upload")}
                  </DefaultButton>
                  <WhiteButton className="w-fit" onClick={() => setOpen(false)}>
                    {t("cancel")}
                  </WhiteButton>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
