import mixpanel from "mixpanel-browser";

export const signUpEvent = (data: {
  userId: string;
  signUpDone: boolean;
  sourceOutput: string;
  email: string;
  fromGoogle: boolean;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Register", {
        userId: data.userId,
        email: data.email,
        signUpDone: data.signUpDone,
        sourceOutput: data.sourceOutput,
        fromGoogle: data.fromGoogle,
      })
    : console.log("development mode");

export const signUpFailureEvent = (data: { signUpDone: boolean }): void =>
  mixpanel.track("Sign Up Failure", {
    signUpDone: data.signUpDone,
  });

export const signInEvent = (data: {
  userId: string;
  signInDone: boolean;
  sourceOutput: string;
  email?: string;
  fromGoogle: boolean;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Sign In", {
        userId: data.userId,
        signInDone: data.signInDone,
        sourceOutput: data.sourceOutput,
        email: data.email,
        fromGoogle: data.fromGoogle,
      })
    : console.log("development mode");

export const signInFailureEvent = (data: { signInDone: boolean; email?: string }): void =>
  mixpanel.track("Sign In Failure", {
    signInDone: data.signInDone,
    email: data.email,
  });

export const storyViewEvent = (data: {
  userId: string;
  storyId: number;
  storyName: string;
  storyViewDone: boolean;
  sourceOutput: string;
  email: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Story View", {
        userId: data.userId,
        storyId: data.storyId,
        storyName: data.storyName,
        storyViewDone: data.storyViewDone,
        sourceOutput: data.sourceOutput,
        email: data.email,
      })
    : console.log("development mode");

export const storyCompleteEvent = (data: {
  userId: string;
  storyId: number;
  storyCompleteDone: boolean;
  email: string;
  storyName: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Story Complete", {
        userId: data.userId,
        storyId: data.storyId,
        storyCompleteDone: data.storyCompleteDone,
        email: data.email,
        storyName: data.storyName,
      })
    : console.log("development mode");

export const storyExitEvent = (data: {
  userId: string;
  storyId: number;
  storyExitDone: boolean;
  sourceOutput: string;
  email: string;
  storyName: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Story Exit", {
        userId: data.userId,
        storyId: data.storyId,
        storyExitDone: data.storyExitDone,
        sourceOutput: data.sourceOutput,
        email: data.email,
        storyName: data.storyName,
      })
    : console.log("development mode");

export const taskCompleteEvent = (data: {
  userId: string;
  taskId: number;
  taskName: string;
  taskCompleteDone: boolean;
  assignTask: boolean;
  email: string;
  previousPage: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Task Complete", {
        userId: data.userId,
        taskId: data.taskId,
        taskName: data.taskName,
        taskCompleteDone: data.taskCompleteDone,
        assignTask: data.assignTask,
        email: data.email,
        sourceInput: data.previousPage,
      })
    : console.log("development mode");

export const integrationSuccess = (data: {
  userId: string;
  email: string;
  integrationName: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Integration Success", {
        userId: data.userId,
        integrationName: data.integrationName,
        email: data.email,
      })
    : console.log("development mode");

export const storiesPageViewEvent = (data: {
  userId: string;
  email: string;
  previousPage: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Stories Page View", {
        userId: data.userId,
        email: data.email,
        previousPage: data.previousPage,
      })
    : console.log("development mode");

export const backToStoriesEvent = (data: {
  userId: string;
  email: string;
  previousPage: string;
  storyName: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Back to Stories", {
        userId: data.userId,
        email: data.email,
        previousPage: data.previousPage,
        storyName: data.storyName,
      })
    : console.log("development mode");

export const dashboardPageViewEvent = (data: {
  userId: string;
  email: string;
  previousPage: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Dashboard Page View", {
        userId: data.userId,
        email: data.email,
        previousPage: data.previousPage,
      })
    : console.log("development mode");

export const pageViewEvent = (data: {
  pageName: string;
  userId?: string;
  email?: string;
  previousPage?: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track(`${data.pageName} page`, {
        userId: data.userId,
        email: data.email,
        previousPage: data.previousPage,
        pageName: data.pageName,
      })
    : console.log("development mode");

export const reportsDetailEvent = (data: {
  reportName: string;
  reportId: string;
  userId: string;
  email: string;
  previousPage: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Report Detail View", {
        userId: data.userId,
        email: data.email,
        previousPage: data.previousPage,
        reportName: data.reportName,
        reportId: data.reportId,
      })
    : console.log("development mode");

export const contentCalendarPageViewEvent = (data: {
  userId: string;
  email: string;
  previousPage: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Content Calendar Page View", {
        userId: data.userId,
        email: data.email,
        previousPage: data.previousPage,
      })
    : console.log("development mode");

export const adsManagementPageViewEvent = (data: {
  userId: string;
  email: string;
  previousPage: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Ads Management Page View", {
        userId: data.userId,
        email: data.email,
        previousPage: data.previousPage,
      })
    : console.log("development mode");

export const saveOnboardingEvent = (data: { userId: string; email: string }): void => {
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Save Onboarding", {
        userId: data.userId,
        email: data.email,
      })
    : console.log("development mode");
};

export const roadmapDetailEvent = (data: {
  userId: string;
  email: string;
  roadmapName: string;
  roadmapId: string;
}): void => {
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Roadmap Detail Page View", {
        userId: data.userId,
        email: data.email,
        roadmapName: data.roadmapName,
        roadmapId: data.roadmapId,
      })
    : console.log("development mode");
};

export const creatingTaskEvent = (data: {
  userId: string;
  email: string;
  taskName: string;
}): void => {
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Creating Task", {
        userId: data.userId,
        email: data.email,
        taskName: data.taskName,
      })
    : console.log("development mode");
};

export const taskViewEvent = (data: {
  userId: string;
  taskId: number;
  taskName: string;
  taskViewDone: boolean;
  email: string;
  previousPage: string;
  // sourceOutput: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Task View", {
        userId: data.userId,
        taskId: data.taskId,
        taskName: data.taskName,
        taskViewDone: data.taskViewDone,
        email: data.email,
        sourceInput: data.previousPage,
      })
    : console.log("development mode");

export const startingRoadmapEvent = (data: {
  email: string;
  userId: string;
  roadmapName: string;
  roadmapId: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Starting Roadmap", {
        email: data.email,
        userId: data.userId,
        roadmapName: data.roadmapName,
      })
    : console.log("development mode");

export const createIntegrationEvent = (data: {
  connectionStatus: string;
  integrationName: string;
  userId: string;
  email: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Create Integration", {
        connectionStatus: data.connectionStatus,
        integrationName: data.integrationName,
        userId: data.userId,
        email: data.email,
      })
    : console.log("development mode");

export const buyingPriceEvent = (data: {
  userId: string;
  email: string;
  pricingPlanId: number;
  status: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Buying a Plan", {
        userId: data.userId,
        email: data.email,
        pricingPlanId: data.pricingPlanId,
        status: data.status,
      })
    : console.log("development mode");

export const listenButtonClickedEvent = (data: {
  userId: string;
  email: string;
  buttonName: string;
  skip?: boolean;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track(`${data.buttonName} Button Clicked`, {
        userId: data.userId,
        email: data.email,
        buttonName: data.buttonName,
        skip: data.skip,
      })
    : console.log("development mode");

export const selectedGoalEvent = (data: {
  userId: string;
  email: string;
  goalName: string;
  selected: boolean;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Selected Goal", {
        userId: data.userId,
        email: data.email,
        goalName: data.goalName,
        selected: data.selected,
      })
    : console.log("development mode");

export const workHasBeenSeen = (data: {
  userId: number | string;
  email: string;
  partnerTaskId: number;
  partnerId: number;
  partnerFullName: string;
  messageId: number;
  partnerRole: string;
  projectStoryId: string;
  storyName: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Work Has Been Seen", {
        userId: data.userId,
        email: data.email,
        partnerTaskId: data.partnerTaskId,
        partnerId: data.partnerId,
        partnerFullName: data.partnerFullName,
        messageId: data.messageId,
        partnerRole: data.partnerRole,
        projectStoryId: data.projectStoryId,
        storyName: data.storyName,
      })
    : console.log("development mode");

export const taskViewModalEvent = (data: {
  userId: string;
  email: string;
  taskId: number;
  taskName: string;
  tabName: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("Task Modal" + data.tabName + "View", {
        userId: data.userId,
        email: data.email,
        taskId: data.taskId,
        taskName: data.taskName,
        tabName: data.tabName,
      })
    : console.log("development mode");

export const mvpOnbardingStepClickedEvent = (data: {
  userId?: number;
  email?: string;
  strategyUrl?: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("MVP Onboarding Step 1 Clicked", {
        userId: data.userId,
        email: data.email,
        strategyUrl: data?.strategyUrl,
      })
    : console.log("development mode");

export const mvpOnbardingStepCreateNewTaskEvent = (data: {
  userId?: number;
  email?: string;
}): void =>
  process.env.NODE_ENV === "production"
    ? mixpanel.track("MVP Onboarding Step 3 Clicked", {
        userId: data.userId,
        email: data.email,
      })
    : console.log("development mode");
