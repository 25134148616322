import client from "./client";
import { loginGoogleAds } from "./googleAds";

export const fetchGoogleAdsAccounts = async (projectId: number) => {
  const response = await client.get(`/ea/get_accounts/${projectId}`);

  return response.data;
};

export const postGoogleAdsAccounts = async (data: {
  project_id: number;
  google_ads_account_id: number | null;
}) => {
  const response = await client.post(
    `/ea/post_refresh_token`, data
  );

  return response.data;
};

export const fetchEaStatus = async (data: { project_id: number }) => {
  const response = await client.get(`/ea/account_enabled/${data.project_id}`);

  return response.data;
};

export const fetchAutoPilotRules = async (data: { project_id: number }) => {
  await loginGoogleAds();
  const response = await client.get(`/ea/autopilot/rules/${data.project_id}`);

  return response;
};

export const getExecutionHistory = async (data: { customer_client_id: string, rule_identifier: string }) => {
  await loginGoogleAds();
  const response = await client.get(`/ea/autopilot/executions_by_customerclient_ruleidentifier/${data.customer_client_id}/${data.rule_identifier}`);

  return response.data;
}

export const stopAutoPilot = async (data: { auto_pilot_item_id: number }) => {
  await loginGoogleAds();
  const response = await client.delete(`/ea/autopilot/${data.auto_pilot_item_id}`);

  return response;
}

export const getAutoPilotAssets = async (data: { customerClientId: number, ruleIdentifier: string }) => {
  await loginGoogleAds();
  const response = await client.get(`/ea/autopilot/assets/${data.customerClientId}/${data.ruleIdentifier}`);

  return response;
}

export const postAutoPilot = async (data: {
  action: number,
  rule_identifier: string,
  customer_client_id: number,
  exception_asset_ids?: number[],
}) => {
  await loginGoogleAds();
  const response = await client.post(`/ea/autopilot`, data);

  return response;
}