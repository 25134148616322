import { t } from "i18next";
import GeneralTable from "@/features/report/components/charts/GeneralTable";
import AdsContentCard from "@/features/report/components/cards/AdsContentCard";

type Props = {
  reportDetail: any;
  isSample?: boolean;
};

const AdsOverview = (props: Props) => {
  const { reportDetail, isSample } = props;

  return (
    <div className="w-full lg:pr-0 relative ">
      <AdsContentCard
        reportDetail={reportDetail}
        size="w-full"
        date={reportDetail?.date}
        altTitle={t("adsSnapshotFor")}
        message={reportDetail?.message}
        title={reportDetail?.customerClientName}
        description={reportDetail?.metric_description || ""}>
        {isSample && (
          <div className="absolute font-medium text-sm text-orange-500 mt-1 bg-orange-50 w-fit rounded-lg py-.5 px-2.5 right-1 top-1">
            {t("sample")}
          </div>
        )}
        <div className="flex flex-col w-full ">
          <GeneralTable data={reportDetail} />
        </div>
      </AdsContentCard>
    </div>
  );
};

export default AdsOverview;
