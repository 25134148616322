import { BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";

interface Props {
  setFilterSettings: any;
  filterSettings: any;
}

export default function FilterInput(props: Props) {
  const { setFilterSettings, filterSettings } = props;

  return (
    <div>
      <div className="mt-1 flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            type="email"
            name="email"
            id="email"
            className="block w-full outline-none border rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder={t("search")}
            onChange={(e) => {
              setFilterSettings({ ...filterSettings, search: e.target.value });
            }}
          />
        </div>
        <button
          type="button"
          onClick={() => setFilterSettings({ ...filterSettings, sort: !filterSettings.sort })}
          className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
          {filterSettings.sort ? (
            <BarsArrowUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          ) : (
            <BarsArrowDownIcon className="h-5 w-5 text-gray-400 transform" aria-hidden="true" />
          )}
          <span>{t("sort")}</span>
        </button>
      </div>
    </div>
  );
}
