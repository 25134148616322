import client from "./client";

export const assignTasks = async (project_id: number) => {
  const response = await client.post(`/partner/tasks`, { project_story_id: project_id });
  return response.data;
};

export const assignMultiTasks = async (data: { project_story_id: number; tasks: number[] }) => {
  const response = await client.post(`/partner/tasks`, data);
  return response.data;
};

export const fetchPartnerTaskTimeline = async (partner_task_id: number) => {
  const response = await client.get(`/partner/tasks/${partner_task_id}/timeline`);
  return response.data;
};

export const postPartnerTaskTimeline = async (data: {
  partner_task_id: number;
  message: string;
  files: File[];
}) => {
  const form_data = new FormData();

  if (data.files && data.files.length > 0) {
    for (let i = 0; i < data.files.length; i++) {
      // add each file to form data
      form_data.append(`files`, data.files[i]);
    }
  }

  data.message && form_data.append("message", data.message);

  const response = await client.post(`/partner/tasks/${data.partner_task_id}/timeline`, form_data);
  return response.data;
};

export const postPartnerFeedback = async (data: {
  partner_task_id: number;
  comment: string;
  rate: number;
}) => {
  const response = await client.post(`/partner/feedback`, data);
  return response.data;
};

export const confirmPartnerJob = async (partner_task_id: number) => {
  const response = await client.put(`/partner/tasks/${partner_task_id}/status`, {
    status: "finished",
  });

  return response.data;
};
