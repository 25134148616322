import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import { useMutation } from "react-query";
import { verifyEmail } from "@/api/verification";

const PasswordRecoveryPage = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const { id } = useParams();
  const idParams = id?.split("&");

  const { mutateAsync, isLoading } = useMutation(verifyEmail);

  const handleNewPasswordChange = (e: any) => {
    e.preventDefault();
    if (newPassword.length < 8) {
      alert("Password must be at least 8 characters long");
    } else if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
    } else {
      if (idParams) {
        mutateAsync({
          user_id: idParams[0] ? parseInt(idParams[0]) : 0,
          uuid_str: idParams[1] ? idParams[1] : "",
          new_password: newPassword,
        })
          .then(() => {
            navigate("/");
          })
          .catch((e) => {
            alert(
              e?.response?.data?.detail ||
                "There was an error verifying your email, please try again."
            );
          });
      } else {
        alert("There was an error verifying your email, please try again.");
      }
    }
  };

  return (
    <div className="bg-gray-50">
      <section className="bg-gray-50 ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
          <div className="w-full p-6 bg-white rounded-lg shadow  md:mt-0 sm:max-w-md sm:p-8">
            <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              {t("changePassword")}
            </h2>
            <form
              onSubmit={handleNewPasswordChange}
              className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
              action="#">
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">
                  {t("newPassword")}
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                  required
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor="confirm-password"
                  className="block mb-2 text-sm font-medium text-gray-900 ">
                  {t("confirmPassword")}
                </label>
                <input
                  type="password"
                  name="confirm-password"
                  id="confirm-password"
                  placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  "
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <DefaultButton isLoading={isLoading} type="submit">
                {t("changePassword")}
              </DefaultButton>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PasswordRecoveryPage;
