import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Component
import PrivateRoute from "@/components/Routes/PrivateRoute";
import "react-phone-number-input/style.css";

import { Translator } from "@/utils/translate/index";
import { useTranslation } from "react-i18next";

// Views
import Login from "@/views/Auth/LoginPage";
import Register from "@/views/Auth/RegisterPage";

import DashboardPage from "@/views/Dashboard/DashboardPage";
import IntegrationsPage from "@/views/Dashboard/IntegrationsPage";
import ProtectedRoute from "@/components/Routes/ProtectedRoute";

import ContentCalendar from "@/views/Dashboard/ContentCalendar";

import ReportsPage from "@/views/Dashboard/ReportsPage";

import RulesPage from "@/views/Dashboard/RulesPage";

import AdsCallbackPage from "@/views/Integrations/AdsCallbackPage";
import AnalyticsCallbackPage from "@/views/Integrations/AnalyticsCallbackPage";
import AnalyticsPropertiesPage from "@/views/Integrations/AnalyticsPropertiesPage";
import Privacy from "@/views/Auth/TermsAndPrivacy/Privacy";
import Terms from "@/views/Auth/TermsAndPrivacy/Terms";
import VerifyPage from "@/views/Auth/VerifyPage";
import PasswordRecoveryPage from "@/views/Auth/PasswordRecoveryPage";
import AutoPilotPage from "@/views/Dashboard/AutoPilotPage";

import PersonalInformationPage from "@/views/Dashboard/ProfilePage/PersonalInformationPage";
import ProjectsPage from "@/views/Dashboard/ProfilePage/ProjectsPage";
import SubscriptionPlansPage from "@/views/Dashboard/ProfilePage/SubscriptionPlansPage";
import BillingPage from "@/views/Dashboard/ProfilePage/BillingPage";

import InProgressPage from "@/views/Dashboard/TasksPage/InProgressPage";
import AssignedPage from "@/views/Dashboard/TasksPage/AssignedPage";
import FinishedPage from "@/views/Dashboard/TasksPage/FinishedPage";

import MessagesPage from "@/views/Dashboard/MessagesPage";
import PaymentPage from "@/views/Payment/PaymentPage";
import IyzicoCallback from "@/views/Payment/IyzicoCallback";
import PaymentSuccessfullPage from "@/views/Payment/PaymentSuccessfulPage";
import PaymentErrorPage from "@/views/Payment/PaymentErrorPage";

import OnboardinPage from "@/views/Onboarding/OnboardingPage";
import ReportsDetailsPage from "@/views/Dashboard/ReportsPage/ReportsDetailsPage";

import HelpCenterPage from "@/views/Dashboard/HelpCenter";
import FAQPage from "@/views/Dashboard/HelpCenter/FAQ";
import RoadmapPage from "@/views/Dashboard/RoadmapPage";
import LinkedinCallbackPage from "@/views/Dashboard/LinkedinPage/Callback";
import AdsPropertiesPage from "@/views/Integrations/AdsPropertiesPage";

import TaskCardLayout from "@/features/tasks/components/TaskCardLayout";
import HowTo from "./views/Dashboard/HelpCenter/HowTo";
import EearndoPlansPage from "./views/Dashboard/ProfilePage/EarnadoPlansPage";
import MVPRouteLocked from "./components/Routes/MVPRouteLocked";
import { userAtom } from "./store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import MvpOnboardingPage from "./views/Onboarding/MvpOnboardingPage";
import UnsubscribeMail from "./views/Auth/UnsubscribeMail";

import "react-datepicker/dist/react-datepicker.css";
import ExecutionDetails from "./views/Dashboard/AutoPilotPage/execution/execution-details";
import AssetsDetail from "./views/Dashboard/AutoPilotPage/assets-detail/assets-detail";
import ExecutionHistory from "./views/Dashboard/AutoPilotPage/execution/execution-history";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function App() {
  const { i18n } = useTranslation();
  const [user] = useRecoilState(userAtom);
  // example procider
  const cacheProvider = {
    get: (language: any, key: any) =>
      ((JSON.parse(localStorage.getItem("translations") as any) || {})[key] || {})[language],
    set: (language: any, key: any, value: any) => {
      const existing = JSON.parse(localStorage.getItem("translations") as any) || {
        [key]: {},
      };
      existing[key] = { ...existing[key], [language]: value };
      localStorage.setItem("translations", JSON.stringify(existing));
    },
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcY5Y8pAAAAAIQD0K9xSjc-f8m3_FIlVsrzP433">
      <Translator
        cacheProvider={cacheProvider}
        from={i18n.language === "tr" ? "en" : "tr"}
        to={i18n.language}
        googleApiKey={process.env.REACT_APP_GOOGLE_TRANSLATION_API_KEY as string}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
              <Route index element={<DashboardPage />} />

              <Route path="roadmaps">
                <Route index element={<RoadmapPage />} />
              </Route>

              <Route path="tasks" element={<TaskCardLayout />}>
                <Route index element={<InProgressPage />} />
                <Route path="assigned" element={<AssignedPage />} />
                <Route path="finished" element={<FinishedPage />} />
              </Route>

              <Route path="oauth2callback" element={<AnalyticsCallbackPage />} />
              <Route path="iyzico/callback" element={<IyzicoCallback />} />
              <Route path="payment-success" element={<PaymentSuccessfullPage />} />
              <Route path="payment-error" element={<PaymentErrorPage />} />

              <Route path="profile">
                <Route index element={<PersonalInformationPage />} />
                <Route path="projects" element={<ProjectsPage />} />
                <Route path="plans" element={<SubscriptionPlansPage />} />
                <Route path="earnado-plans" element={<EearndoPlansPage />} />
                <Route path="billing" element={<BillingPage />} />
              </Route>

              <Route element={<MVPRouteLocked />}>
                <Route path="integrations" element={<IntegrationsPage />} />

                <Route path="rules">
                  <Route index element={<RulesPage />} />
                </Route>

                <Route path="reports">
                  <Route index element={<ReportsPage />} />
                  <Route path=":application/:id" element={<ReportsDetailsPage />} />
                </Route>

                <Route path="content-calendar">
                  <Route index element={<ContentCalendar />} />
                </Route>
                <Route path="Auto-Pilot">
                  <Route index element={<AutoPilotPage />} />
                </Route>
                <Route path="/execution-details" element={<ExecutionDetails />} />
                <Route path="auto-pilot/execution-history" element={<ExecutionHistory />} />
                <Route path="/assets-detail" element={<AssetsDetail />} />

                <Route path="messages">
                  <Route index element={<MessagesPage />} />
                </Route>
              </Route>

              <Route path="help-center">
                <Route index element={<HelpCenterPage />} />
                <Route path="faq" element={<FAQPage />} />
                <Route path="how-to" element={<HowTo />} />
              </Route>

              <Route path="onboarding">
                {user?.role === "mvp1" || user?.role === "mvp2" ? (
                  <>
                    <Route index element={<MvpOnboardingPage />} />
                  </>
                ) : (
                  <>
                    <Route index element={<OnboardinPage />} />
                  </>
                )}
              </Route>

              <Route path="linkedin">
                <Route path="callback" element={<LinkedinCallbackPage />} />
              </Route>
              <Route path="ea">
                <Route path="callback" element={<AdsCallbackPage />} />
              </Route>
              <Route path="google-analytics">
                <Route path="properties" element={<AnalyticsPropertiesPage />} />
              </Route>
              <Route path="google-ads">
                <Route path="properties" element={<AdsPropertiesPage />} />
              </Route>
            </Route>

            <Route element={<ProtectedRoute />}>
              <Route path="login" element={<Login />}></Route>
              <Route path="register" element={<Register />}></Route>
            </Route>
            <Route path="payment" element={<PaymentPage />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="terms-of-service" element={<Terms />} />
            <Route path="verify/:id" element={<VerifyPage />} />
            <Route path="password-recovery/:id" element={<PasswordRecoveryPage />} />
            <Route path="unsubscribe" element={<UnsubscribeMail />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </Translator>
    </GoogleReCaptchaProvider>
  );
}

export default App;
