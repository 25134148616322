import { useQuery } from "react-query";
import { fetchProjectMetrics } from "@/api/metric";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { t } from "i18next";
import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";
import { SunIcon } from "@heroicons/react/20/solid";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface Props {
  projectId: string;
}

export default function TopSection(props: Props) {
  const { projectId } = props;

  const { data: dataFirst, isLoading: isLoadingMetrics } = useQuery(
    "fetchProjectMetrics12",
    () => fetchProjectMetrics({ projectId: projectId, metricId: "12" }),
    {
      retry: false,
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataSecond, isLoading: isLoadingSecondMetrics } = useQuery(
    "fetchProjectMetrics13",
    () => fetchProjectMetrics({ projectId: projectId, metricId: "13" }),
    {
      retry: false,
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div>
      {isLoadingMetrics || isLoadingSecondMetrics ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <div className="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-3">
            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
              {dataFirst.sample && (
                <div className="absolute font-medium text-sm text-orange-500 mt-1 bg-orange-50 w-fit rounded-lg py-.5 px-2.5 right-1 top-1">
                  {t("sample")}
                </div>
              )}
              <dt>
                <div className="absolute rounded-md bg-[#5952AD] p-3">
                  <SunIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">
                  {dataFirst?.metric_name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  {dataFirst?.data[0]?.data?.map((value: any) => (
                    <span>{value?.scope === "customer" && value?.authority_score} </span>
                  ))}
                </p>
                <p className={classNames("mb-2 flex items-baseline text-sm font-semibold")}></p>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
              {dataFirst.sample && (
                <div className="absolute font-medium text-sm text-orange-500 mt-1 bg-orange-50 w-fit rounded-lg py-.5 px-2.5 right-1 top-1">
                  {t("sample")}
                </div>
              )}
              <dt>
                <div className="absolute rounded-md bg-[#5952AD] p-3">
                  <ComputerDesktopIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500 capitalize">
                  {t("desktop")} {dataSecond?.metric_name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  {dataSecond?.data[0]?.data?.map((value: any) => (
                    <span>
                      {value?.scope === "desktop_page_speed" && value?.page_load_time + "s"}{" "}
                    </span>
                  ))}
                </p>
                <p className={classNames("mb-2 flex items-baseline text-sm font-semibold")}></p>
              </dd>
            </div>
            <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
              {dataFirst.sample && (
                <div className="absolute font-medium text-sm text-orange-500 mt-1 bg-orange-50 w-fit rounded-lg py-.5 px-2.5 right-1 top-1">
                  {t("sample")}
                </div>
              )}
              <dt>
                <div className="absolute rounded-md bg-[#5952AD] p-3">
                  <DevicePhoneMobileIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500 capitalize ">
                  {t("mobile")} {dataSecond?.metric_name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                  {dataSecond?.data[0]?.data?.map((value: any) => (
                    <span>
                      {value?.scope === "mobile_page_speed" && value?.page_load_time + "s"}{" "}
                    </span>
                  ))}
                </p>
                <p className={classNames("mb-2 flex items-baseline text-sm font-semibold")}></p>
              </dd>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
