import { useEffect, useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { fetchExecutionRules } from "@/api/execution";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import useGoogleAdsLogin from "@/hooks/use-google-login";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

interface TableRowData {
  id: number;
  date: string;
  severity: string;
  campaignName: string;
  adGroupName: string;
  adGroupKeywordText: string;
  action: string;
}

const ExecutionDetails = () => {
  const [tableData, setTableData] = useState<TableRowData[]>([]);

  const { isLoadingGoogleAdsLogin } = useGoogleAdsLogin();
  const { isLoading: isLoadingProjectId } = useFetchingProjectIds();

  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    fetchExecutionRules()
      .then((response) => {
        if (response && response.items) {
          const apiData = response.items.map((item: any) => ({
            id: item.id,
            date: item.execution_date,
            severity: item.rule_severity_text || "N/A",
            campaignName: item.campaign_name || "N/A",
            adGroupName: item.ad_group_name || "N/A",
            adGroupKeywordText: item.ad_group_keyword_text || "N/A",
            action: item.action,
          }));
          setTableData(apiData);
          setIsLoadingData(false);

        } else {
          console.error("API Response Error: 'items' property is missing or empty.");
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  }, []);

  return (
    <div data-testid="/dashboard/auto-pilot-page">
      <AssessmentLayout>
        <div className="text-sm text-[#707070] mt-8 mb-6">
          <div className="text-sm text-[#707070] flex gap-2 flex-row items-center w-fit pb-1">
            <div className="flex items-center gap-2">
              <Link className="flex items-center gap-2" to="/AutoPilotPage">
                <HomeIcon className="w-4 h-4" />
              </Link>
              <div>
                <Link className="flex items-center gap-2" to="/views/Dashboard/auto-pilot-page">
                  <ChevronRightIcon className="w-4 h-4 width: 1.25rem; height: 1.25rem;" />
                  {t("Ad Compliance and Enhancement Control Table")}
                  {">Execution Details"}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    colSpan={7}
                    style={{ fontWeight: "bold", color: "black", borderBottom: "2px solid black" }}>
                    RULE NAME
                    <p>
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi a ullam animi
                      doloribus expedita aliquid. Optio, deleniti eos saepe ut praesentium in minima
                      quod labore delectus officia eum tempore facilis.
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>DATE</TableCell>
                  <TableCell>CAMPAIGN NAME</TableCell>
                  <TableCell>AD GROUP NAME</TableCell>
                  <TableCell>AD GROUP KEYWORD TEXT</TableCell>
                  <TableCell>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingData || isLoadingProjectId || isLoadingGoogleAdsLogin ? (
                  <TableRow>
                    <LoadingSpinner size="w-6 h-6" />
                    <TableCell colSpan={7}>Veriler Yükleniyor...</TableCell>

                  </TableRow>
                ) : tableData && tableData.length > 0 ? (
                  tableData.map((row: TableRowData) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>{row.campaignName}</TableCell>
                      <TableCell>{row.adGroupName}</TableCell>
                      <TableCell>{row.adGroupKeywordText}</TableCell>
                      <TableCell>{row.action}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>Veri bulunamadı.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </AssessmentLayout>
    </div>
  );
};

export default ExecutionDetails;
