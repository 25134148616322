const getToken = () => {
  return localStorage.getItem("authToken");
};

const setToken = (token: string) => {
  localStorage.setItem("authToken", token);
};

const removeToken = () => {
  localStorage.removeItem("authToken");
};

export { getToken, setToken, removeToken };
