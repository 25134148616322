import { PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { t } from "i18next";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export default class SimpleAreaChart extends PureComponent<{
  data: any;
  color?: string;
  height?: any;
  width?: any;
}> {
  // get random color code

  state: any = {
    colorCodes: ["#0E9CFF"],

    myData: this.props.data[0]?.data[2]?.data?.reduce((acc: any, item: any) => {
      acc.push({
        name:
          this.props.data[0]?.data[2].scope === "month"
            ? months[new Date(item.date).getMonth()]
            : this.props.data[0]?.data[2].scope === "day"
              ? days[new Date(item.date).getDate()]
              : new Date(item.date) || "",
        value: item.value,
        pv: 2400,
        amt: 2400,
      });
      return acc;
    }, []),
    active: "monthly",
  };

  getRandomColorCode = () => {
    const { colorCodes } = this.state;
    const index = Math.floor(Math.random() * colorCodes.length);
    return colorCodes[index];
  };

  changeActiveStatus = (status: string) => {
    this.setState({ active: status });
    if (status === "daily") {
      this.setState({
        myData: this.props.data[0]?.data[0].data.reduce((acc: any, item: any) => {
          acc.push({
            name: new Date(item.date).getHours() + ":00" || "",
            value: item.value,
            pv: 2400,
            amt: 2400,
          });
          return acc;
        }, []),
      });
    } else if (status === "weekly") {
      this.setState({
        myData: this.props.data[0]?.data[1].data.reduce((acc: any, item: any) => {
          acc.push({
            name: days[(new Date(item.date).getDate() % 7) - 2] || "",
            value: item.value,
            pv: 2400,
            amt: 2400,
          });
          return acc;
        }, []),
      });
    } else if (status === "monthly") {
      this.setState({
        myData: this.props.data[0]?.data[2].data.reduce((acc: any, item: any) => {
          acc.push({
            name: months[new Date(item.date).getMonth()] || "",
            value: item.value,
            pv: 2400,
            amt: 2400,
          });
          return acc;
        }, []),
      });
    }
  };

  render() {
    console.log("this.props.data", this.props);

    return (
      <div>
        <div className="flex justify-end gap-2 mr-6 text-sm text-gray-500">
          <div
            onClick={() => this.changeActiveStatus("daily")}
            className={`p-2 rounded cursor-pointer capitalize text-sm  ${this.state.active === "daily" && "bg-gray-100 font-medium text-gray-700"
              }`}>
            {t("daily")}
          </div>
          <div
            onClick={() => this.changeActiveStatus("weekly")}
            className={`p-2 rounded cursor-pointer capitalize ${this.state.active === "weekly" && "bg-gray-100 font-medium text-gray-700"
              }`}>
            {t("weekly")}
          </div>
          <div
            onClick={() => this.changeActiveStatus("monthly")}
            className={`p-2 rounded cursor-pointer capitalize ${this.state.active === "monthly" && "bg-gray-100 font-medium text-gray-700"
              }`}>
            {t("monthly")}
          </div>
        </div>
        <ResponsiveContainer width={this.props.width || "100%"} height={this.props.height || 270}>
          <AreaChart
            width={500}
            height={400}
            data={this.state.myData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}>
            <XAxis fontSize={12} dataKey="name" />
            <YAxis fontSize={14} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stroke={this.props.color || "#0E9CFF"}
              fill={this.props.color || "#0E9CFF"}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
