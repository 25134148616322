// import React from "react";
import { useQuery } from "react-query";
import { fetchProjectData } from "@/api/project"; //, fetchProjectRoadmap, fetchRoadmapCategories
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
// import { CheckIcon, RectangleStackIcon, UsersIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom"; //, Link } from "react-router-dom";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";
import UpgradeSection from "@/components/UpgradeSection";
// import CreateStoryPopup from "@/features/create-task-getting-started/components/Popup";
// import ConnectAccountPopup from "@/features/getting-started/components/ConnectAccountPopup";
// import { listenButtonClickedEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { t } from "i18next";
// import { CreditCardIcon } from "@heroicons/react/24/outline";


const GettingStartedPage = () => {
  const { projectId, isLoading: isLoadingProjectId } = useFetchingProjectIds();
  const [project] = useRecoilState(projectInfo);
  const navigate = useNavigate();
  // const [openCreateTaskModal, setOpenCreateTaskModal] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  const [user] = useRecoilState(userAtom);

  const { isLoading: isLoadingProjectData, data } = useQuery(
    "fetchProjectGettingStartedDataForDashboardd",
    () => fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      cacheTime: 0,
    }
  );

  // Normally use this query to fetch if there is any roadmap appointed to project 
  // const { data: roadmap, isLoading } = useQuery(
  //   "fetchRoadmapPreview",
  //   () => fetchProjectRoadmap(projectId),
  //   {
  //     retry: false,
  //     enabled: !!projectId,
  //   }
  // );

  // Normally use this query to fetch categories in Line 60
  // const { data: categories, isLoading: isLoadingCategories } = useQuery<any>(
  //   "fetchCategories",
  //   () => fetchRoadmapCategories(),
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  return (
    <>
      {isLoadingProjectId || isLoadingProjectData ? (
        <div className="flex h-[calc(100vh-5rem)] justify-center items-center ">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <>
          {/* {!(
            data?.data?.value?.roadmap &&
            data.data?.value?.assigned &&
            data.data?.value?.connectAccount
          ) && (
              <div className="text-lg font-medium text-gray-600 mb-5 mt-2">
                {t("letsRockDigitalMarketingSuccessWithPrecision")}
              </div>
            )} */}
          {/* <> {+project.totalCredit - +project.totalUsedCredit <= 10 && <UpgradeSection />} </> */}
          {/* { {!( }
            // data?.data?.value?.roadmap &&
            // data.data?.value?.assigned &&
            // data.data?.value?.connectAccount
          ) && ( }*/(
              <>
                <div className="text-lg font-medium text-gray-600 mb-5 mt-2">
                  {t("letsRockDigitalMarketingSuccessWithPrecision")}
                </div>
                <UpgradeSection />
                <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 mt-5">
                  <div
                    onClick={() => {
                      // if (!data?.data?.value?.roadmap) {
                      navigate("/integrations");
                      //   localStorage.setItem("autoStart", "true");
                      //   localStorage.setItem("autoStartStep", "true");
                      // }
                    }}>
                    <div
                      // className={`    ${data?.data?.value?.roadmap ? "bg-gray-100" : "bg-white hover:shadow-md "}
                      className={`bg-white hover:shadow-md p-4 rounded shadow cursor-pointer transition-all flex flex-col`}>
                      <div className="flex items-center gap-3">
                        <div
                          className={`rounded-full text-indigo-500 border-2 border-blue-400 w-8 h-8 flex justify-center items-center bg-white`}>
                          {/* ${data?.data?.value?.roadmap ? "bg-indigo-600" : "bg-white"} */}

                          {/* {data?.data?.value?.roadmap ? (
                            <CheckIcon className="w-5 h-5 text-white" />
                          ) : ( */}
                          1
                          {/* )} */}
                        </div>
                        <div className="  text-blue-600 text-xl font-medium capitalize">
                          {t("connectYourAccounts")}
                        </div>
                      </div>
                      <div className="mt-4 flex-1">
                        <div className="font-medium">{t("letsSyncForInsights")}</div>
                        <div className="text-sm text-gray-500">
                          {t(
                            "dataFuelsAccuracyConnectGoogleAdsAndAnalyticsForPreciseInsightsIntoThreatsAndOpportunities"
                          )}
                        </div>
                      </div>
                      <div className="mt-4  text-blue-600 rounded text-sm cursor-pointer">
                        {t("connectAccounts")} →
                      </div>
                    </div>
                  </div>

                  <div
                    onClick={() => {
                      // if (data?.data?.value?.roadmap && !data?.data?.value?.assigned) {
                      navigate("/reports");
                      // listenButtonClickedEvent({
                      //   buttonName: "Create a new task(Getting Started)",
                      //   email: user?.email || "",
                      //   userId: user?.id.toString() || "",
                      // });
                      // }
                    }}
                    // className={`${data?.data?.value?.assigned ? "bg-gray-100" : "bg-white hover:shadow-md "}
                    className={`bg-white hover:shadow-md p-4 rounded shadow cursor-pointer transition-all flex flex-col`}>
                    <div className="flex items-center gap-3">
                      <div
                        className={`rounded-full text-indigo-500 border-2 border-blue-400 w-8 h-8 flex justify-center items-center bg-white`}>
                        {/* ${data?.data?.value?.assigned ? "bg-indigo-600" : "bg-white"} */}

                        {/* {data?.data?.value?.assigned ? (
                          <CheckIcon className="w-5 h-5 text-white" />
                        ) : ( */}
                        2
                        {/* )} */}
                      </div>
                      <div className=" text-blue-600 text-xl font-medium capitalize">
                        {t("getYourFirstReport")}
                      </div>
                    </div>
                    <div className="mt-4 flex-1">
                      <div className="font-medium">{t("unlockYourMetrics")}</div>
                      <div className="text-sm text-gray-500">
                        {t("accessClicksImpressionsCTRConversionsAndCostsForInDepthAdAnalysisAcrossAllCampaignsAndAdGroups")}
                      </div>
                    </div>
                    <div className="mt-4  text-blue-600 rounded text-sm cursor-pointer ">
                      <a href="/reports">{t("getAllInsights")} →</a>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      // if (
                      //   data?.data?.value?.roadmap &&
                      //   data?.data?.value?.assigned &&
                      //   !data?.data?.value?.connectAccount
                      // ) {
                      navigate("/auto-pilot");
                      // setOpen(true);
                      // localStorage.setItem("firstConnection", "true");
                      // }
                    }}
                    // className={`${data?.data?.value?.connectAccount ? "bg-gray-100" : "bg-white hover:shadow-md " }
                    className={` bg-white hover:shadow-md p-4 rounded shadow  cursor-pointer transition-all flex flex-col`}>
                    <div className="flex items-center gap-3">
                      <div
                        className={`rounded-full text-indigo-500 border-2 border-blue-400 w-8 h-8 flex justify-center items-center bg-white`}>
                        {/* ${data?.data?.value?.connectAccount ? "bg-indigo-600" : "bg-white"} */}

                        {/* {data?.data?.value?.connectAccount ? (
                          <CheckIcon className="w-5 h-5 text-white" />
                        ) : ( */}
                        3
                        {/* )} */}
                      </div>
                      <div className=" text-blue-600 text-xl font-medium capitalize">
                        {t("setUpAutoPilot")}
                      </div>
                    </div>
                    <div className="mt-4 flex-1">
                      <div className="font-medium">{t("elevateYourAds")}</div>
                      <div className="text-sm text-gray-500">
                        {t(
                          "refineCampaignsEffortlesslyClickSetUpToTailorRulesEnsuringCustomizedOptimizationForYourUniqueAdStrategy"
                        )}
                      </div>
                    </div>
                    <div className="mt-4  text-blue-600 rounded text-sm cursor-pointer">
                      <a href="/auto-pilot">{t("setUpNow")} →</a>
                    </div>
                  </div>
                </div>
              </>
            )}
          {/* {openCreateTaskModal && (
            <CreateStoryPopup
              modalState={openCreateTaskModal}
              setModalState={setOpenCreateTaskModal}
            />
          )}
          <ConnectAccountPopup setOpen={setOpen} open={open} />
          {data?.data?.value?.roadmap && (
            <>
              <div>
                {roadmap?.filter((item: any) => !item.is_selected).length > 0 && (
                  <>
                    <div className="text-2xl font-bold mt-10">{t("Strategies For You")}</div>
                    <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 mt-5">
                      {roadmap
                        ?.filter((item: any) => !item.is_selected)
                        .slice(0, 3)
                        .map((item: any, index: any) => (
                          <Link
                            to={`/roadmaps`}
                            key={index}
                            className="p-6 bg-white rounded cursor-pointer relative shadow transition-all">
                            <div className="text-base font-medium text-indigo-600 ">
                              {item.name}
                            </div>
                            <div className="mt-3 space-y-2">
                              <div className="flex gap-2 items-center">
                                <UsersIcon className="w-5 h-5 text-gray-400" />
                                <div className="text-sm text-gray-500">
                                  {t("createdBy")} {item.created_by}
                                </div>
                              </div>
                              <div className="flex gap-2 items-center">
                                <RectangleStackIcon className="w-5 h-5 text-gray-400" />
                                <div className="text-sm text-gray-500">
                                  {
                                    categories.find(
                                      (element: any) => element.id === item.category_id
                                    )?.title
                                  }
                                </div>
                              </div>
                              <div className="flex gap-2 items-center">
                                <CreditCardIcon className="w-5 h-5 text-gray-400" />
                                <div className="text-sm text-gray-500">
                                  {t("totalCredit")}: {item.total_credit}
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                  </>
                )}

                <div className="flex justify-end">
                  <Link
                    to={`/roadmaps`}
                    className="text-blue-500 text-sm font-medium mt-5 cursor-pointer">
                    {t("View All")} →
                  </Link>
                </div>
              </div>
            </>
          )} */}
        </>
      )}
    </>
  );
};

export default GettingStartedPage;
