import React, { useEffect } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { t } from "i18next";
import { fetchProjectData, fetchProjectRoadmap, fetchRoadmapCategories } from "@/api/project";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { pageViewEvent } from "@/analytics/mixpanel";
import RoadmapDetailPopup from "@/features/roadmap/components/RoadmapDetailPopup";
import ConfirmPopup from "@/features/roadmap/components/Popup";
import { RectangleStackIcon, UsersIcon } from "@heroicons/react/20/solid";
import SelectFilter from "@/features/roadmap/components/SelectFilter";
import { CreditCardIcon } from "@heroicons/react/24/outline";

const DefaultPage = () => {
  const { projectId, isLoading: isLoadingProjectId } = useFetchingProjectIds();
  const [user] = useRecoilState(userAtom);
  const [filterText, setFilterText] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState<string | number>("");
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<null | number>(null);
  const [openTaskModal, setOpenModal] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState<any>(null);
  const [categoryLabel, setCategoryLabel] = React.useState("");

  const { isLoading: isLoadingProjectData } = useQuery(
    "fetchProjectGettingStartedData",
    () => fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "tasks",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  const {
    data: roadmap,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery("fetchRoadmap", () => fetchProjectRoadmap(projectId), {
    retry: false,
    enabled: !!projectId,
  });

  const { data: categories, isLoading: isLoadingCategories } = useQuery<any>(
    "fetchCategories",
    () => fetchRoadmapCategories(),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const autoStart = localStorage.getItem("autoStart");

    if (autoStart === "true" && isSuccess) {
      setSelectedId(roadmap[0].id);
      setOpen(true);
      localStorage.removeItem("autoStart");
    }
  }, [isSuccess]);

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("strategies")} />
        {isLoadingProjectId || isLoadingProjectData || isLoadingCategories ? (
          <div className="flex h-[calc(100vh-5rem)] justify-center items-center ">
            <LoadingSpinner size="w-8 h-8" />
          </div>
        ) : (
          <>
            <div className="flex justify-start sm:justify-center w-full mt-5">
              <SelectFilter
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            </div>

            <div className="flex flex-col md:flex-row gap-10">
              <div className="flex flex-col sm:flex-row gap-10 w-full">
                <div className="w-full">
                  <div className="flex flex-col gap-2 md:gap-0 md:flex-row justify-between items-end md:items-center w-full mt-6">
                    <input
                      type="search"
                      placeholder={t("search")}
                      className="py-1.5 px-4 outline-none rounded border w-full text-sm"
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </div>
                  {isLoading ? (
                    <LoadingSpinner size="w-8 h-8" />
                  ) : (
                    <div className="flex gap-7 mt-6 flex-wrap mb-5">
                      {roadmap
                        ?.filter(
                          (r: any) =>
                            r.name.toLowerCase().includes(filterText.toLowerCase()) ||
                            r.description.toLowerCase().includes(filterText.toLowerCase())
                        )
                        .filter(
                          (r: any) => +r.category_id === +selectedCategory || !selectedCategory
                        )
                        .map((card: any, index: any) => (
                          <div
                            onClick={() => {
                              setSelectedCard(card);
                              setSelectedId(card.id);
                              setCategoryLabel(
                                categories.find((element: any) => element.id === card.category_id)
                                  ?.title
                              );
                              setOpen(true);
                            }}
                            key={index}
                            className="p-6 bg-white w-full xl:w-96  cursor-pointer relative hover:shadow transition-all rounded shadow">
                            <div className="text-lg font-medium mt-10 ">{card.name}</div>
                            <div
                              /* style={{
                                WebkitLineClamp: 4,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                display: "-webkit-box",
                              }} */
                              className="mt-3 space-y-2">
                              <div className="flex gap-2 items-center">
                                <UsersIcon className="w-5 h-5 text-gray-400" />
                                <div className="text-sm text-gray-500">
                                  {t("createdBy")} {card.created_by}
                                </div>
                              </div>
                              <div className="flex gap-2 items-center">
                                <RectangleStackIcon className="w-5 h-5 text-gray-400" />
                                <div className="text-sm text-gray-500">
                                  {
                                    categories.find(
                                      (element: any) => element.id === card.category_id
                                    )?.title
                                  }
                                </div>
                              </div>
                              <div className="flex gap-2 items-center">
                                <CreditCardIcon className="w-5 h-5 text-gray-400" />
                                <div className="text-sm text-gray-500">
                                  {t("totalCredit")}: {card.total_credit}
                                </div>
                              </div>
                            </div>
                            <span
                              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                              aria-hidden="true">
                              {card.is_selected ? (
                                <>
                                  <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ">
                                    {t("active")}
                                  </span>
                                </>
                              ) : (
                                <span
                                  className={`${card?.new
                                      ? "bg-rose-100 text-rose-700"
                                      : "bg-blue-100 text-blue-700"
                                    }  inline-flex items-center rounded-full  px-2 py-1 text-xs font-medium `}>
                                  {card?.new ? t("new") : t("startNow")}
                                </span>
                              )}
                            </span>
                          </div>
                        ))}
                      <RoadmapDetailPopup
                        selectedCard={selectedCard}
                        open={open}
                        setOpen={setOpen}
                        roadmapId={selectedId}
                        setOpenModal={setOpenModal}
                        refetch={refetch}
                        label={categoryLabel}
                      />
                      <ConfirmPopup open={openTaskModal} setOpen={setOpenModal} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </AssessmentLayout>
    </div>
  );
};

export default DefaultPage;
