import client from "./client";

export const fetchPaymentForm = async (data: {
  projectId: string;
  amount: number;
  currency: "USD" | "TRY";
}) => {
  const form_data = new FormData();
  form_data.append("project_id", data.projectId);
  form_data.append("amount", data.amount.toString());

  const response = await client.post(`/credit/payment/form`, form_data, {
    params: {
      currency: data.currency,
    },
  });

  return response.data;
};

export const fetchPaymentCallback = async (data: { token: string }) => {
  const response = await client.get(`/credit/payment/callback`, {
    params: {
      token: data.token,
    },
  });

  return response.data;
};

export const fetchCreditInfo = async (currency: string) => {
  const response = await client.get(`/credit/info`, {
    params: {
      currency: currency,
    },
  });

  return response.data;
};
