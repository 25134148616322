import React, { useEffect } from "react";
import { CheckIcon } from "@heroicons/react/24/solid";
import i18n, { t } from "i18next";

type Props = {
  steps: any;
  setSteps: any;
  setCurrentStep: (step: number) => void;
  currentStep: number;
};

const Steps = (props: Props) => {
  const { steps, currentStep, setCurrentStep, setSteps } = props;

  const handleStep = (step: any) => {
    if (+step.id <= +currentStep) {
      if (step.id === "01") {
        setSteps([
          { id: "01", name: t("goals"), status: "current" },
          { id: "02", name: t("assets"), status: "upcoming" },
          { id: "03", name: t("marketingTools"), status: "upcoming" },
          { id: "04", name: t("target"), status: "upcoming" },
        ]);

        setCurrentStep(1);
      } else if (step.id === "02") {
        setSteps([
          { id: "01", name: t("goals"), status: "complete" },
          { id: "02", name: t("assets"), status: "current" },
          { id: "03", name: t("marketingTools"), status: "upcoming" },
          { id: "04", name: t("target"), status: "upcoming" },
        ]);

        setCurrentStep(2);
      } else if (step.id === "03") {
        setSteps([
          { id: "01", name: t("goals"), status: "complete" },
          { id: "02", name: t("assets"), status: "complete" },
          { id: "03", name: t("marketingTools"), status: "current" },
          { id: "04", name: t("target"), status: "upcoming" },
        ]);

        setCurrentStep(3);
      } else if (step.id === "04") {
        setSteps([
          { id: "01", name: t("goals"), status: "complete" },
          { id: "02", name: t("assets"), status: "complete" },
          { id: "03", name: t("marketingTools"), status: "complete" },
          { id: "04", name: t("target"), status: "current" },
        ]);

        setCurrentStep(4);
      }
    }
  };

  useEffect(() => {
    // localStorage.setItem("onboardingItems", JSON.stringify([...steps]));
    // console.log("steps", steps);
  }, [steps, i18n.language]);

  useEffect(() => {
    const index = localStorage.getItem("onboardingCurrentStep");
    if (index) {
      console.log("index", index);
      handleStep(steps[+index - 1]);
    } else {
      handleStep(steps[0]);
    }
  }, [i18n.language]);

  return (
    <div aria-label="Progress">
      <ul className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 ">
        {steps.map((step: any, index: any) => (
          <li
            key={index}
            className="relative md:flex md:flex-1 bg-white "
            onClick={() => handleStep(step)}>
            {step.status === "complete" ? (
              <div className="group flex w-full items-center cursor-pointer">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-primaryColor ">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </div>
            ) : step.status === "current" ? (
              <div
                className="flex items-center px-6 py-4 text-sm font-medium  cursor-pointer"
                aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-primaryColor">
                  <span className="text-primaryColor">{step.id}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-primaryColor">{step.name}</span>
              </div>
            ) : (
              <div className="group flex items-center  cursor-pointer">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 ">
                    <span className="text-gray-500">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500 capitalize">
                    {step.name}
                  </span>
                </span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Steps;
