import { Combobox, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { fetchAllCountries } from "@/api/country";
import { useRecoilState } from "recoil";
import { stepperState } from "@/store/atoms/brief.atoms";
import { t } from "i18next";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import BlurInput from "@/components/BlurInput";

interface Props {
  countryData: object | null;
  selectedCountry?: any;
  disabled?: boolean;
}

function CountryCombobox(props: Props) {
  const { countryData, selectedCountry, disabled } = props;

  const [stepper, setStepper] = useRecoilState(stepperState);

  /*********** */

  const {
    status,
    isError,
    data: sectors,
    error,
  }: UseQueryResult<any, Error> = useQuery<any, Error>("sectors", fetchAllCountries, {
    retry: false,
  });

  /*********** */

  const [selected, setSelected] = useState<any>();
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (countryData) {
      if (stepper.country.id !== 0) {
        setSelected(stepper.country);
      } else setSelected(countryData);
    } else {
      if (stepper.country.id !== 0) {
        setSelected(stepper.country);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      const country = sectors?.find((item: any) => item?.id === selectedCountry);
      setSelected(country);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected) {
      setStepper({ ...stepper, country: selected });
      localStorage.setItem("stepper", JSON.stringify({ ...stepper, country: selected }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const filteredPeople =
    query === ""
      ? sectors
      : sectors.filter((field: any) =>
        field.name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
      );

  if (status === "idle" || status === "loading") {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>{error.message}</div>;
  }

  return (
    <div className="w-full z-40 ">
      <Combobox disabled={disabled || false} value={selected} onChange={setSelected}>
        <div className="relative mt-1 ">
          <BlurInput>
            {({ onblur, setOnblur }: any) => (
              <div
                className={`${onblur ? "border-primaryColor" : "border-borderStroke"
                  } relative py-[2px] w-full text-left  bg-white cursor-default focus:outline-none focus-visible:ring-2  rounded border-[1px] focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-teal-300 focus-visible:ring-offset-2 sm:text-sm overflow-hidden`}>
                <Combobox.Input
                  onBlur={() => {
                    setOnblur(true);
                  }}
                  className="focus:border-primaryColor w-full border-none focus:outline-none focus:ring-0 py-1.5 pl-5 pr-10  leading-5 text-gray-900 text-sm"
                  displayValue={(person: any) => person?.name}
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder={t("selectCountry")}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2 ">
                  {/* <SelectorIcon className="w-5 h-5 text-black" aria-hidden="true" /> */}
                  <ChevronDownIcon className="w-5 h-5 text-black" aria-hidden="true" />
                </Combobox.Button>
              </div>
            )}
          </BlurInput>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}>
            <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== "" ? (
                <div className="cursor-default select-none relative py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((field: any) => (
                  <Combobox.Option
                    key={field.id}
                    className={({ active }) =>
                      `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? "text-black bg-[#E1E1E1]" : "text-gray-900"
                      }`
                    }
                    value={field}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
                          {field.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-black" : "text-teal-600"
                              }`}>
                            {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
                            <></>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}

export default CountryCombobox;
