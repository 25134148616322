import React from "react";
import { fetchSuggestion } from "@/api/suggestion";
import { useMutation, useQuery } from "react-query";
import { t } from "i18next";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { postProjectFile } from "@/api/project";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import WhiteButton from "@/components/buttons/WhiteButton";

interface Props {
  selectedStory: any;
  handleAssignTask: any;
  isAssigning: boolean;
}
const OurSuggestionContent = (props: Props) => {
  const { selectedStory, handleAssignTask, isAssigning } = props;
  const [selectedImage, setSelectedImage] = React.useState<any>(null);
  const [selectedCaption, setSelectedCaption] = React.useState<any>(null);
  const [error, setError] = React.useState<any>(null);
  const [project] = useRecoilState(projectInfo);

  const {
    data,
    isError,
    isLoading,
    error: errorFetchSuggestion,
  } = useQuery<any, any>("fetchSuggestion", () => fetchSuggestion(selectedStory.project_story_id), {
    enabled: !!selectedStory.project_story_id,
    cacheTime: 0,
    retry: false,
  });

  const { mutateAsync: createPost, isLoading: isLoadingPost } = useMutation(postProjectFile);

  if (isError)
    return (
      <div className="mt-3 text-red-500">
        {errorFetchSuggestion?.response?.data?.detail || t("somethingWentWrong")}
      </div>
    );

  const handleCreatePost = () => {
    setError(null);
    if (!selectedImage || !selectedCaption) {
      setError(t("Please select image or caption"));
      return;
    }

    createPost({
      project_id: project.id,
      file_type: "image/png",
      file_url: selectedImage,
      additional_data: `{"message":"${selectedCaption}","project_story_id":${selectedStory.project_story_id}}`,
    }).then(() => {
      toast.success(t("Post created successfully, you can preview"));
    });
  };

  return (
    <div className="w-full h-[calc(100%-100px)]  overflow-auto relative mb-8">
      {isLoading ? (
        <div>
          <LoadingSpinner size="w-5 h-5" />
        </div>
      ) : (
        <div className="space-y-2 mb-12">
          <div>
            <div className="text-[#6B7280] text-sm mt-5">{t("about")}</div>
            <div>You can select image and text from our suggestion</div>
          </div>
          <div>
            <div className="text-[#6B7280] text-sm mt-5">{t("images")}</div>
            <div className="mt-3">
              <div className="flex gap-2 flex-col md:flex-row">
                {data?.suggested_data?.images.map((img: any) => (
                  <div className="w-full md:w-96">
                    <img
                      className={`transition-all border-4 cursor-pointer ${selectedImage === img ? " border-blue-500" : "border-2 border-white"
                        }`}
                      onClick={() => setSelectedImage(img)}
                      src={img}
                      alt="img"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div>
            <div className="text-[#6B7280] text-sm mt-5">{t("captions")}</div>
            <div className="flex flex-col gap-3 mt-2">
              {data?.suggested_data?.captions.map((c: any, index: any) => (
                <div className="flex justify-start content-start gap-2 cursor-pointer">
                  <input
                    onClick={() => setSelectedCaption(c)}
                    type="radio"
                    id={index}
                    name="fav_language"
                    value={c}
                  />
                  <label htmlFor={index}>{c}</label>
                </div>
              ))}
            </div>
          </div>

          {error && <div className="text-red-500 text-sm mt-2">{error}</div>}

          <div className="fixed bottom-0 right-0 flex justify-end gap-3 py-4 pr-6  bg-white w-full z-10">
            {selectedStory.partner_role !== null &&
              selectedStory.project_story_status === "inprogress" &&
              !selectedStory.is_assigned &&
              !isAssigning && (
                <>
                  {Boolean(selectedStory.partner_task_id) ? (
                    <> </>
                  ) : (
                    <WhiteButton onClick={() => handleAssignTask()} className=" w-fit">
                      {t("assignToMikrolod")}
                    </WhiteButton>
                  )}
                </>
              )}

            {selectedStory.project_story_status !== "finished" && (
              <DefaultButton onClick={handleCreatePost} isLoading={isLoadingPost} className="w-fit">
                {t("Create post now")}
              </DefaultButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default OurSuggestionContent;
