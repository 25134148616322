import { userAtom } from "@/store/atoms/user.atoms";
import React from "react";
import { useRecoilState } from "recoil";
import ErrorBoundary from "../ErrorBoundary";
import { Navigate, Outlet } from "react-router-dom";
import { USER_TYPE, USER_TYPE_MVP2 } from "@/models/userType";

const MVPRouteLocked = () => {
  const [user] = useRecoilState(userAtom);

  return (
    <>
      <ErrorBoundary>
        {/* if user type is not xxx, 
          cancel the route and redirect to dashboard */}
        {user?.role === USER_TYPE || user?.role === USER_TYPE_MVP2 ? (
          <Navigate to="/" />
        ) : (
          <Outlet />
        )}
      </ErrorBoundary>
    </>
  );
};

export default MVPRouteLocked;
