import { useMutation, useQuery } from "react-query";
import { fetchProjectData, fetchRoadmapCategories, postProjectData } from "@/api/project";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { Link, useNavigate } from "react-router-dom";
import { t } from "i18next";
import {
  RectangleStackIcon,
  UsersIcon,
  PlayCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import { ProjectDataType } from "@/models/projectDataType";
import { fetchMvpRoadmaps, fetchRoadmapUrl } from "@/api/mvp";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import MvpRoadmapDetailPopup from "@/features/roadmap/components/MvpRoadmapDetailPopup";
import { useEffect, useState } from "react";
import {
  mvpOnbardingStepClickedEvent,
  mvpOnbardingStepCreateNewTaskEvent,
} from "@/analytics/mixpanel";
import VideoModal from "@/components/VideoModal";
import CreateStoryPopup from "@/features/mvp-getting-started/components/mvp-create-task";
import PricingModal from "@/features/mvp-getting-started/components/pricingModal";
import { USER_TYPE } from "@/models/userType";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import toast, { Toaster } from "react-hot-toast";

const MvpGettingStartedPage = () => {
  const { projectId, isLoading: isLoadingProjectId } = useFetchingProjectIds();
  const [user] = useRecoilState(userAtom);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [openCreateStory, setOpenCreateStory] = useState(false);
  const [project] = useRecoilState(projectInfo);
  const navigate = useNavigate();
  const [openPricing, setOpenPricing] = useState(false);

  const {
    isLoading: isLoadingProjectData,
    data,
    refetch,
  } = useQuery(
    "fetchProjectGettingStartedDataForDashboardMVP_GETTING_STARTED",
    () => fetchProjectData({ projectId: projectId, name: "MVP_GETTING_STARTED" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      cacheTime: 0,
    }
  );

  const { data: roadmap, isLoading } = useQuery("fetchRoadmap", () => fetchMvpRoadmaps(), {
    retry: false,
  });

  const { data: categories, isLoading: isLoadingCategories } = useQuery<any>(
    "fetchCategories",
    () => fetchRoadmapCategories(),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { mutateAsync } = useMutation("postProjectDomainData", postProjectData, {
    retry: false,
  });

  const handleMVPGettingStarted = (key: string) => {
    fetchProjectData({ projectId: projectId, name: "MVP_GETTING_STARTED" })
      .then((res) => {
        const data = res.data.value;

        mutateAsync({
          projectId,
          project: {
            datatype_name: ProjectDataType.MVP_GETTING_STARTED,
            data: {
              value: {
                ...data,
                [key]: true,
              },
            },
          },
        });
      })
      .catch(() => {
        mutateAsync({
          projectId,
          project: {
            datatype_name: ProjectDataType.MVP_GETTING_STARTED,
            data: {
              value: {
                [key]: true,
              },
            },
          },
        });
      })
      .finally(() => {
        setTimeout(() => {
          refetch();
        }, 300);
      });
  };

  const { data: strategyUrl, isLoading: isLoadingStrategyUrl } = useQuery(
    "mvproadmap_url",
    () => fetchRoadmapUrl(projectId),
    {}
  );

  useEffect(() => {
    const getInfoFromLocalStorage = JSON.parse(
      localStorage.getItem("notificationInfoForPayment") as any
    );

    if (getInfoFromLocalStorage && getInfoFromLocalStorage.isShow) {
      toast.success(getInfoFromLocalStorage.message, {});
      localStorage.removeItem("notificationInfoForPayment");
    }
  }, []);

  return (
    <>
      <Toaster position="bottom-right" />

      {isLoadingProjectId ||
        isLoadingProjectData ||
        isLoading ||
        isLoadingCategories ||
        isLoadingStrategyUrl ? (
        <div className="flex h-[calc(100vh-5rem)] justify-center items-center ">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <div className="flex justify-center pb-10 ">
          <div className="max-w-3xl">
            <div className="relative">
              <div>
                {data?.data?.value?.roadmap &&
                  data.data?.value?.assigned &&
                  data?.data?.value?.connectAccount &&
                  data?.data?.value?.newStrategy && (
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-500 inline-block
                        absolute right-5 top-5 z-10"
                    />
                  )}
              </div>
              <div className="w-full shadow rounded relative bg-white p-6 space-y-3">
                <div className="font-bold text-3xl">
                  {data?.data?.value?.roadmap &&
                    data.data?.value?.assigned &&
                    data?.data?.value?.connectAccount &&
                    data?.data?.value?.newStrategy
                    ? "Welcome again "
                    : "Hi "}
                  {user?.first_name}
                </div>
                <div className="font-medium text-2xl text-gray-700">
                  {data?.data?.value?.roadmap && data.data?.value?.assigned
                    ? "What’s new on this week?"
                    : "Let’s get started!"}
                </div>
                <div className="text-gray-600">
                  Here are the tasks you create to achieve your marketing goals. To complete the
                  task, please accept the latest work from the experts.
                </div>
                <div className="flex justify-center py-2">
                  <div
                    onClick={() => setOpenVideo(true)}
                    className="max-w-lg w-full h-56 bg-gray-200 rounded-lg shadow-md bg-cover bg-center flex justify-center items-center cursor-pointer
                      relative border border-gray-200 overflow-hidden
                    ">
                    <img
                      src="/images/how-to.png"
                      className="w-full h-full object-cover"
                      alt="how"
                    />
                    <PlayCircleIcon
                      className="w-12 h-12 text-black absolute
                      top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    />
                  </div>
                  <VideoModal
                    src={"https://www.youtube.com/embed/WcVoJgtDRjE"}
                    onClose={() => setOpenVideo(false)}
                    open={openVideo}
                    title={"Mikrolo/ Getting Strategies - Digital Marketing"}
                  />
                </div>
                <div className="text-gray-500">
                  Get started with digital marketing with our customized guidance.{" "}
                </div>
                <div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5 ">
                    <div
                      className="bg-green-500 h-2.5 rounded-full"
                      style={{
                        width: data?.data?.value?.newStrategy
                          ? "100%"
                          : data?.data?.value?.connectAccount
                            ? "75%"
                            : data?.data?.value?.assigned
                              ? "50%"
                              : data?.data?.value?.roadmap
                                ? "25%"
                                : "0%",
                      }}></div>
                  </div>
                </div>
                <div className="font-medium">
                  {data?.data?.value?.newStrategy
                    ? 4
                    : data?.data?.value?.connectAccount
                      ? 3
                      : data?.data?.value?.assigned
                        ? 2
                        : data?.data?.value?.roadmap
                          ? 1
                          : 0}{" "}
                  of 4 completed
                </div>
              </div>
            </div>

            <>
              <div className="mt-5 flex flex-col  gap-6 ">
                <div
                  className="relative"
                  onClick={() => {
                    if (!data?.data?.value?.roadmap) {
                      handleMVPGettingStarted("roadmap");
                      if (strategyUrl) window.open(strategyUrl, "_blank");
                    } else {
                      if (strategyUrl) window.open(strategyUrl, "_blank");
                    }
                    mvpOnbardingStepClickedEvent({
                      email: user?.email,
                      userId: user?.id,
                      strategyUrl: strategyUrl,
                    });
                  }}>
                  <div
                    className={`${data?.data?.value?.roadmap ? "bg-green-50" : "bg-white hover:shadow-md"
                      }  p-4 rounded shadow cursor-pointer transition-all flex flex-col `}>
                    <div className=" flex-1 flex flex-col gap-1">
                      <div
                        className={`rounded-full text-gray-500 text-sm border-2 border-gray-400 w-8 h-8 flex justify-center items-center
                          bg-white}
                  `}>
                        1
                      </div>
                      <div className="font-medium text-lg">A defined strategy for you!</div>
                      <div className="text-sm text-gray-500">
                        The strategy is developed and ready to be shared or used to help you achieve
                        success or your goals more effectively.
                      </div>
                    </div>
                    <div className="mt-4  text-blue-600 rounded text-sm cursor-pointer">
                      {t("connectAccounts")} →
                    </div>
                  </div>
                  {data?.data?.value?.roadmap && (
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-500 inline-block
                        absolute right-5 top-5 z-10"
                    />
                  )}
                </div>

                <div
                  onClick={() => {
                    if (data?.data?.value?.roadmap && !data?.data?.value?.assigned) {
                      handleMVPGettingStarted("assigned");
                      window.open("https://calendly.com/burak-yilmaz-earnado/15min", "_blank");
                    } else if (data?.data?.value?.roadmap && data?.data?.value?.assigned) {
                      window.open("https://calendly.com/burak-yilmaz-earnado/15min", "_blank");
                    }
                  }}
                  className={`${data?.data?.value?.assigned ? "bg-green-50" : "bg-white hover:shadow-md"
                    } bg-white hover:shadow-md  p-4 rounded shadow cursor-pointer transition-all flex flex-col relative `}>
                  <div className=" flex-1 flex flex-col gap-1">
                    <div
                      className={`rounded-full text-gray-500 text-sm border-2 border-gray-400 w-8 h-8 flex justify-center items-center
                          bg-white}
                  `}>
                      2
                    </div>
                    <div className="font-medium text-lg">Need extra help?</div>
                    <div className="text-sm text-gray-500">
                      If there is anything else you would like assistance with, we would be
                      delighted to provide further guidance and support, including the option to
                      book a demo.
                    </div>
                  </div>
                  <div className="mt-4  text-blue-600 rounded text-sm cursor-pointer ">
                    Book a demo →
                  </div>
                  {data?.data?.value?.assigned && (
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-500 inline-block
                        absolute right-5 top-5 z-10"
                    />
                  )}
                </div>

                <div
                  onClick={() => {
                    if (
                      data?.data?.value?.roadmap &&
                      data?.data?.value?.assigned &&
                      !data?.data?.value?.connectAccount
                    ) {
                      // handleMVPGettingStarted("connectAccount");
                      if (project.pricingPlan.id === 1) {
                        setOpenPricing(true);
                      } else {
                        setOpenCreateStory(true);
                      }
                      mvpOnbardingStepCreateNewTaskEvent({
                        email: user?.email,
                        userId: user?.id,
                      });
                    }
                  }}
                  className={`${data?.data?.value?.connectAccount ? "bg-green-50" : "bg-white hover:shadow-md"
                    } bg-white hover:shadow-md  p-4 rounded shadow cursor-pointer transition-all flex flex-col relative `}>
                  <div className=" flex-1 flex flex-col gap-1">
                    <div
                      className={`rounded-full text-gray-500 text-sm border-2 border-gray-400 w-8 h-8 flex justify-center items-center
                          bg-white}
                  `}>
                      3
                    </div>
                    <div className="font-medium text-lg">Create a new task!</div>
                    <div className="text-sm text-gray-500">
                      Whatever you need right now, you can create this task and assign it to the
                      required expert. Let's complete a task with you.
                    </div>
                  </div>
                  <div className="mt-4  text-blue-600 rounded text-sm cursor-pointer ">
                    Create new task →
                  </div>
                  {data?.data?.value?.connectAccount && (
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-500 inline-block
                        absolute right-5 top-5 z-10"
                    />
                  )}
                </div>

                <div
                  onClick={() => {
                    if (
                      data?.data?.value?.roadmap &&
                      data?.data?.value?.assigned &&
                      data?.data?.value?.connectAccount &&
                      !data?.data?.value?.newStrategy
                    ) {
                      handleMVPGettingStarted("newStrategy");
                      navigate("/roadmaps");
                    }
                  }}
                  className={`${data?.data?.value?.newStrategy ? "bg-green-50" : "bg-white hover:shadow-md"
                    } bg-white hover:shadow-md  p-4 rounded shadow cursor-pointer transition-all flex flex-col relative `}>
                  <div className=" flex-1 flex flex-col gap-1">
                    <div
                      className={`rounded-full text-gray-500 text-sm border-2 border-gray-400 w-8 h-8 flex justify-center items-center
                          bg-white}
                  `}>
                      4
                    </div>
                    <div className="font-medium text-lg">New strategies!</div>
                    <div className="text-sm text-gray-500">
                      Have you seen new roadmaps that will help you grow your business in digital
                      marketing?
                    </div>
                  </div>
                  <div className="mt-4  text-blue-600 rounded text-sm cursor-pointer ">
                    See roadmaps →
                  </div>
                  {data?.data?.value?.newStrategy && (
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-500 inline-block
                        absolute right-5 top-5 z-10"
                    />
                  )}
                </div>
              </div>
            </>

            <>
              {data?.data?.value?.roadmap && (
                <div>
                  {roadmap?.filter((item: any) => !item.is_selected).length > 0 && (
                    <>
                      <div className="text-2xl font-bold mt-10 flex justify-between">
                        <div>{t("Strategies For You")}</div>

                        <div>
                          <Link
                            to={`/roadmaps`}
                            className="text-blue-500 text-base font-medium cursor-pointer">
                            {t("View All")} →
                          </Link>
                        </div>
                      </div>
                      <div className="flex flex-col gap-6 mt-5">
                        {roadmap
                          ?.filter((item: any) => !item.is_selected)
                          .slice(0, 3)
                          .map((item: any, index: any) => (
                            <div
                              onClick={() => {
                                setSelectedCard(item);
                                setOpen(true);
                              }}
                              key={index}
                              className="p-6 bg-white rounded cursor-pointer relative shadow transition-all">
                              <div className="text-base font-medium text-indigo-600 ">
                                {item.name}
                              </div>
                              <div className="mt-3 space-y-2">
                                <div className="flex gap-2 items-center">
                                  <UsersIcon className="w-5 h-5 text-gray-400" />
                                  <div className="text-sm text-gray-500">
                                    {t("createdBy")} {item.created_by}
                                  </div>
                                </div>
                                <div className="flex gap-2 items-center">
                                  <RectangleStackIcon className="w-5 h-5 text-gray-400" />
                                  <div className="text-sm text-gray-500">
                                    {
                                      categories.find(
                                        (element: any) => element.id === item.category_id
                                      )?.title
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          </div>
        </div>
      )}

      <MvpRoadmapDetailPopup
        selectedCard={selectedCard}
        open={open}
        setOpen={setOpen}
        label={"Suggested"}
      />

      <CreateStoryPopup
        handleGettingStart={handleMVPGettingStarted}
        modalState={openCreateStory}
        setModalState={setOpenCreateStory}
      />

      {user?.role === USER_TYPE && openPricing && (
        <PricingModal open={openPricing} setOpen={setOpenPricing} />
      )}
    </>
  );
};

export default MvpGettingStartedPage;
