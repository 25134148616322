import client from "./client";

export const fetchReports = async (projectId: number) => {
  const response = await client.get(`/report`, { params: { project_id: projectId } });
  return response.data;
};

export const fetchProjectReports = async (data: { projectId: number; reportId: number }) => {
  const response = await client.get(`/report/get-projects-report/${data.reportId}`, {
    params: { project_id: data.projectId },
  });
  return response.data;
};

export const fetchProjectMetrics = async (data: {
  projectId: number;
  startDate?: string;
  endDate?: string;
}) => {
  const today = new Date();
  let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  // one month ago
  let oneMonthAgo = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  if (data.startDate) {
    oneMonthAgo =
      new Date(data.startDate).getFullYear() +
      "-" +
      (new Date(data.startDate).getMonth() + 1) +
      "-" +
      new Date(data.startDate).getDate();
  }
  if (data.endDate) {
    date =
      new Date(data.endDate).getFullYear() +
      "-" +
      (new Date(data.endDate).getMonth() + 1) +
      "-" +
      new Date(data.endDate).getDate();
  }

  const response = await client.get("/ea/metrics_overview", {
    params: {
      project_id: data.projectId,
      start_date: oneMonthAgo,
      end_date: date,

      // language_code: "en",
    },
  });
  return response.data;
};

export const fetchAdsReport = async (projectId: number) => {
  const response = await client.get(`/ea/get_ads_report/${projectId}`, {});
  return response.data;
};
