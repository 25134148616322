import { RegisterFormDataType } from "../models/user.interface";
import client from "./client";

interface ILogin {
  email: string;
  password: string;
}

export const loginUser = async (loginInfo: ILogin) => {
  const form_data = new FormData();

  form_data.append("username", loginInfo.email);
  form_data.append("password", loginInfo.password);

  const user = await client.post("/auth/login", form_data);
  return user;
};

/* export const loginWithGoogle = async (code: string) => {
  const user = await client.get(
    `/auth/google?code=${code}&redirectUrl=http://localhost:8080/login`
  );
  return user;
}; */

export const checkRecaptcha = async (token: string) => {
  const response = await client.post("/auth/check-recaptcha?token=" + token);
  return response;
};

export const registerUser = async (registerData: RegisterFormDataType) => {
  const tempData = { ...registerData };

  const user = await client.post("/auth/signup", tempData);
  return user;
};

export const authMe = async () => {
  const data = await client.get("/auth/me");
  localStorage.setItem("accountId", data.data.account_id);
  return data.data;
};

export const puthAuthMe = async (data: {
  userId: number;
  email?: string;
  phone_number?: string;
  first_name?: string;
  last_name?: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  country: number;
  tax_identification_number: string;
  tax_office: string;
  imageFile?: File | null;
  invoice_title: string;
  invoice_type: string;
}) => {
  // create multipart form data
  const form_data: any = new FormData();

  if (data.email) form_data.append("email", data.email);
  if (data.phone_number) form_data.append("phone_number", data.phone_number);
  if (data.first_name) form_data.append("first_name", data.first_name);
  if (data.last_name) form_data.append("last_name", data.last_name);
  if (data.address) form_data.append("address", data.address);
  if (data.city) form_data.append("city", data.city);
  if (data.state) form_data.append("state", data.state);
  if (data.zip_code) form_data.append("zip_code", data.zip_code);
  if (data.country) form_data.append("country", data.country);
  if (data.tax_identification_number)
    form_data.append("tax_identification_number", data.tax_identification_number);
  if (data.tax_office) form_data.append("tax_office", data.tax_office);
  if (data.invoice_title) form_data.append("invoice_title", data.invoice_title);
  if (data.invoice_type) form_data.append("invoice_type", data.invoice_type);

  if (data.tax_identification_number)
    form_data.append("tax_identification_number", data.tax_identification_number);
  if (data.tax_office) form_data.append("tax_office", data.tax_office);

  if (data.imageFile) form_data.append("avatar", data.imageFile);

  const res = await client.put(`/auth/me/${data.userId}`, form_data);
  return res;
};

export const createGoogleAuthUrl = async (redirectUrl: string) => {
  const res = await client.get(`/auth/signup-with-google?redirect_uri=${redirectUrl}`);
  return res.data;
};

export const loginWithGoogleAuth = async (data: { code: string; role: string | null }) => {
  const res = await client.post(
    `/auth/google-callback`,
    {},
    {
      params: {
        code: data.code,
        role: data.role,
        redirect_uri: `${window.location.origin}/login`,
      },
    }
  );
  return res.data;
};
