import client from "./client";

export const createGAUrl = async (platform: "google_analytics" | "google_ads") => {
  const response = await client.get(`/credential/oauth2url?platform=${platform}&redirect_uri=${window.location.origin}  `);

  return response.data;
};

export const googleAnalyticsCallback = async (data: {
  project_id: number;
  code: string;
  platform: string;
  base_url: string;
}) => {
  const response = await client.post(`/credential/callback`, data);
  return response.data;
};

export const fetchPlatformStatus = async (data: {
  project_id: number;
  platform: "facebook" | "google_ads" | "google_analytics";
}) => {
  const response = await client.get(`/credential/${data.project_id}/${data.platform}`);

  return response.data;
};
