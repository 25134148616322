import { useEffect } from "react";
import { useQuery } from "react-query";
import { fetchProjectData } from "@/api/project";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import MainPage from "./MainPage";
import AssessmentLayout from "@/components/Layout/Assessment/index";
import { t } from "i18next";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { pageViewEvent } from "@/analytics/mixpanel";
import GettingStartedPage from "./GettingStartedPage";

import mixpanel from "mixpanel-browser";
import { handleLogout } from "@/utils/logout";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { USER_TYPE, USER_TYPE_MVP2 } from "@/models/userType";
import MvpGettingStartedPage from "./MvpGettingStartedPage";
import MvpGettingStartedPage2 from "./MvpGettingStartedPage2";

const DashboardPage = () => {
  const { projectId, isLoading: isLoadingProjectId } = useFetchingProjectIds();

  const [user] = useRecoilState(userAtom);

  const { isSuccess: isSuccessOnboarding, data: dataOnboarding } = useQuery(
    "fetchProjectOnboardingData",
    () => fetchProjectData({ projectId: projectId, name: "ONBOARDING" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
    }
  );

  useEffect(() => {
    if (isSuccessOnboarding) {
      if (dataOnboarding?.data?.value) {
        const getPaths = JSON.parse(localStorage.getItem("paths") as any);

        pageViewEvent({
          pageName: "dashboard",
          userId: user?.id.toString() || "",
          email: user?.email || "",
          previousPage: getPaths?.currentPath || "",
        });
      }
    }
  }, [isSuccessOnboarding]);

  useEffect(() => {
    if (isSuccessOnboarding) {
      if (dataOnboarding?.data?.value) {
        if (user?.id) mixpanel.identify(user.id.toString());
        mixpanel.people.set({
          $name: user?.first_name + " " + user?.last_name,
          $email: user?.email,
        });
      }
    }
  }, []);

  return (
    <AssessmentLayout>
      <div className="flex justify-between items-center z-[99] pt-2 pb-1">
        {user?.role !== USER_TYPE ? (
          <h2 className="text-3xl leading-6 text-gray-900 font-bold  ">
            {user?.first_name}, {t("welcomeToMikrolo")}
          </h2>
        ) : (
          <div></div>
        )}

        <div className="hidden lg:block">
          <button
            onClick={handleLogout}
            className="bg-white py-2 px-3 rounded-xl flex text-base gap-2 items-center">
            <ArrowRightOnRectangleIcon className="h-5 w-5 text-gray-400" />
            {t("logout")}
          </button>
        </div>
      </div>

      {isLoadingProjectId ? (
        <div className="flex h-[calc(100vh-5rem)] justify-center items-center ">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <div>
          {user?.role === USER_TYPE ? (
            <>
              <MvpGettingStartedPage />
            </>
          ) : user?.role === USER_TYPE_MVP2 ? (
            <>
              <MvpGettingStartedPage2 />
            </>
          ) : (
            <>
              <GettingStartedPage />
              <MainPage />
            </>
          )}
        </div>
      )}
    </AssessmentLayout>
  );
};

export default DashboardPage;
