import { useEffect } from "react";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { useMutation, useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { t } from "i18next";
import { pageViewEvent, saveOnboardingEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { fetchMvpQuestions } from "@/api/mvp";
import { USER_TYPE } from "@/models/userType";
import Select from "react-select";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { postProjectData } from "@/api/project";
import { ProjectDataType } from "@/models/projectDataType";
import { useNavigate } from "react-router-dom";
import { mvpOnboarding } from "@/store/atoms/mvpOnboarding";

type Props = {
  setCurrentStep: (step: number) => void;
  setSteps: any;
  projectId: number;
};

const TargetAreas = (props: Props) => {
  const { setCurrentStep, projectId } = props;
  const [user] = useRecoilState(userAtom);
  const navigate = useNavigate();
  const [mvponboarding, setMvpOnboarding] = useRecoilState(mvpOnboarding);

  const handleBusinessInfoSubmit = (e: any) => {
    e.preventDefault();

    setCurrentStep(4);
  };

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "onboarding(target)",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  const { data: goals, isLoading } = useQuery(
    "goals4",
    () =>
      fetchMvpQuestions({
        form: USER_TYPE,
      }),
    {}
  );

  const { mutateAsync, isLoading: isLoadingPost } = useMutation(
    "postProjectDomainData",
    postProjectData,
    {
      retry: false,
    }
  );

  const handleFinish = async () => {
    await mutateAsync({
      projectId,
      project: {
        datatype_name: ProjectDataType.MVP1_ONBOARDING,
        data: {
          1: mvponboarding.needs,
          2: mvponboarding.digitalAssets,
          3: mvponboarding.digitalTools,
          4: mvponboarding.focusArea,
        },
      },
    });

    await mutateAsync({
      projectId,
      project: {
        datatype_name: ProjectDataType.ONBOARDING,
        data: {
          value: true,
        },
      },
    }).then(() => {
      saveOnboardingEvent({
        userId: user?.id.toString() || "",
        email: user?.email || "",
      });

      navigate("/");
    });
  };

  return (
    <div className="flex-1 h-full w-full flex md:items-center md:justify-center flex-col  ">
      {isLoading ? (
        <LoadingSpinner size="w-6 h-6" />
      ) : (
        <>
          <div className="flex justify-center flex-col items-center pt-4 max-w-3xl text-center">
            {/* <div className="text-indigo-600 uppercase font-medium">{t("businessInfo")}</div> */}
            <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">{goals[3]?.text}</h1>
            <div className="text-xl text-gray-500">{goals[3]?.description}</div>
          </div>
          <form className="w-full" onSubmit={handleBusinessInfoSubmit}>
            <div className="flex justify-center">
              <Select
                className="w-full md:w-1/2 mt-10 mb-20"
                placeholder="Select..."
                options={goals[3]?.answers?.map((goal: any) => ({
                  value: goal.id,
                  label: goal.text,
                }))}
                onChange={(e: any) => {
                  setMvpOnboarding({
                    ...mvponboarding,
                    focusArea: e.value,
                  });
                }}
                // default value with label and value
                defaultValue={{
                  value: mvponboarding.focusArea,
                  label: goals[3]?.answers?.find((goal: any) => goal.id === mvponboarding.focusArea)
                    ?.text,
                }}
              />
            </div>

            <div className="flex justify-end my-5 md:mb-0 pr-5 md:absolute bottom-10 right-10 items-center">
              <DefaultButton
                onClick={() => {
                  handleFinish();
                }}
                isLoading={isLoadingPost}
                isDisabled={mvponboarding.focusArea === null}
                className="w-fit px-6 mr-2 md:mr-0">
                {t("finish")}
              </DefaultButton>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default TargetAreas;
