import { TableCellProps } from "@/common/TableCellProps";
import { TableRowProps } from "@/common/TableRowProps";
import TableCell from "./TableCell";

export default function TableRow(props: TableRowProps) {
    return <tr key={props.akey} className="even:bg-gray-50">
        {
            props.cells.map((tableCellProps: TableCellProps) => (
                <TableCell {...tableCellProps}></TableCell>
            ))
        }
    </tr>
}