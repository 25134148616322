import client from "./client";

export const fetchProjectIds = async (account_id: number) => {
  const response = await client.get(`/account/${account_id}/projects`);
  return response.data;
};

export const putAccountUnsubscribe = async (data: { user_id: number; email: string }) => {
  const response = await client.put(
    `/account/unsubscribe`,
    {},
    {
      params: {
        user_id: data.user_id,
        email: data.email,
      },
    }
  );
  return response.data;
};
