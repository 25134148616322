import { XMarkIcon } from "@heroicons/react/20/solid";

type ModelType = {
  closeAction: React.MouseEventHandler;
  title?: string;
  children: React.ReactChild;
  isPopup: boolean;
  width?: string;
};

const PopupModal = ({ closeAction, title, children, isPopup, width }: ModelType) => {
  return isPopup ? (
    <>
      <div className="flex justify-center items-center fixed inset-0 z-[999]">
        <button
          className="fixed inset-0 bg-black opacity-25 z-20"
          onClick={closeAction}
          type="button"></button>
        <div
          className={`flex flex-col gap-6 bg-white p-6 rounded-lg shadow-xl transform transition-all sm:w-full relative z-30 overflow-auto max-h-[90%] ${width ? width : "max-w-3xl"
            }  `}>
          <div className="flex justify-end items-start -mb-6">
            <button onClick={closeAction}>
              <XMarkIcon className="h-6 w-6 text-gray-400" />
            </button>
          </div>
          {title && <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>}
          {children}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default PopupModal;
