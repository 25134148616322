// Package
import axios from "axios";
// Util

const api = axios.create({
  baseURL: process.env.REACT_APP_GOOGLE_ADS_ANALYTICS_URL,
});

api.interceptors.request.use((request: any) => {
  const googleAdsTokenHolderStr = localStorage.getItem("googleAdsTokenHolder");

  if (googleAdsTokenHolderStr) {
    try {
      const googleAdsTokenHolder = JSON.parse(googleAdsTokenHolderStr);
      if (googleAdsTokenHolder && googleAdsTokenHolder.tokenString) {
        request.headers.common.Authorization = `Bearer ${googleAdsTokenHolder.tokenString}`;
      }
    } catch (error) {
      console.error("Error parsing googleAdsTokenHolder", error);
    }
  }
  return request;
});

api.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem("authToken");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;
