import client from "./client";

export const fetchTaskByProjectId = async (data: { projectId: number; is_done?: boolean }) => {
  const response = await client.get(`/project/${data.projectId}/tasks`, {
    params: {
      is_done: data.is_done,
    },
  });
  return response.data;
};

export const fetchDailyTasks = async (data: { projectId: number; is_done: boolean; date: any }) => {
  // due date after 1 day
  const dueEnd = new Date(data.date);
  dueEnd.setDate(dueEnd.getDate() + 1);

  const response = await client.get(`/project/${data.projectId}/tasks`, {
    params: {
      is_done: data.is_done,
      due_dtstart: new Date().toISOString(),
      due_dtend: dueEnd.toISOString(),
    },
  });
  return response.data;
};

export const fetchWeeklyTasks = async (data: { projectId: number; is_done: boolean }) => {
  const dueEnd = new Date();
  dueEnd.setDate(dueEnd.getDate() + 7);

  const response = await client.get(`/project/${data.projectId}/main-tasks`, {
    params: {
      is_done: data.is_done,
      limit: 5,
      /* due_dtstart: dueStart.toISOString(),
      due_dtend: dueEnd.toISOString(), */
    },
  });
  return response.data;
};

// fetch project stories

export const fetchProjectMainTasks = async (data: {
  project_id: number;
  limit?: number;
  status?: string;
}) => {
  const response = await client.get(`/project/${data.project_id}/main-tasks`, {
    params: {
      limit: data?.limit,
      status: data?.status,
    },
  });
  return response.data;
};
