import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import { fetchCreditInfo } from "@/api/credit";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleExtraCredit?: () => void;
  setAmount: (amount: number) => void;
  amount: number;
  currenctFromCountry: any;
}

export default function ExtraCreditModal(props: Props) {
  const { isOpen, setIsOpen, setAmount, amount, currenctFromCountry } = props;

  const { data: creditInfo, isLoading } = useQuery("creditInfo", () =>
    fetchCreditInfo(currenctFromCountry)
  );

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {isLoading ? (
                  <LoadingSpinner size="w-5 h-5" />
                ) : (
                  <>
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => setIsOpen(false)}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex justify-center items-center">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-semibold leading-6 text-gray-900 ">
                          Buy extra credit
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">How many credits do you need?</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <input
                        type="number"
                        defaultValue={amount}
                        min={creditInfo?.min_value ?? 10}
                        step={creditInfo?.step ?? 10}
                        max={10000}
                        className="w-full border border-gray-300 rounded-md p-2 mt-4 outline-none"
                        //  remove dot using replace javascript in input type number?
                        onKeyDown={(e) => {
                          if (e.key === "." || e.key === "," || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => setAmount(Number(e.target.value))}
                      />
                    </div>
                    <div className="mt-5 sm:mt-4 grid grid-cols-2 gap-3">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setIsOpen(false)}>
                        {t("cancel")}
                      </button>
                      <DefaultButton onClick={props.handleExtraCredit}>
                        {t("buy")} {amount * creditInfo?.credit}{" "}
                        {currenctFromCountry === "USD" ? "$" : "₺"}
                      </DefaultButton>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
