import React, { useEffect, useRef, useState } from "react";

type Props = {
  items: any;
  value: any;
  changeValue: any;
  data: any;
};

const SelectBox = (props: Props) => {
  const [active, setActive] = useState(false);
  const { items, value, changeValue, data } = props;
  const selectRef = useRef<any>(null);

  useEffect(() => {
    if (value.isActive) {
      setActive(true);
    }
  }, [value.isActive]);

  const handleOnclick = (event: any) => {
    // control select selectRef click, if it is clicked, do not close the select box
    if (selectRef.current.contains(event.target)) {
      return;
    }
    setActive(!active);

    if (value.value === "clicks") {
      changeValue({
        value: value.value,
        isActive: !active,
        valueType: data[value.value + "ValueType"] || 0,
      });
    }
    if (value.value === "impressions") {
      changeValue({
        value: value.value,
        isActive: !active,
        valueType: data[value.value + "ValueType"] || 0,
      });
    }
    if (value.value === "ctr") {
      changeValue({
        value: value.value,
        isActive: !active,
        valueType: data[value.value + "ValueType"] || 0,
      });
    }
    if (value.value === "cost") {
      changeValue({
        value: value.value,
        isActive: !active,
        valueType: data[value.value + "ValueType"] || 0,
      });
    }
    if (value.value === "conversions") {
      changeValue({
        value: value.value,
        isActive: !active,
        valueType: data[value.value + "ValueType"] || 0,
      });
    }
    if (value.value === "averageCost") {
      changeValue({
        value: value.value,
        isActive: !active,
        valueType: data[value.value + "ValueType"] || 0,
      });
    }
    if (value.value === "averageCpc") {
      changeValue({
        value: value.value,
        isActive: !active,
        valueType: data[value.value + "ValueType"] || 0,
      });
    }
    if (value.value === "averageCpm") {
      changeValue({
        value: value.value,
        isActive: !active,
        valueType: data[value.value + "ValueType"] || 0,
      });
    }
  };

  const handleChange = (e: any) => {
    if (e.target.value === "clicks") {
      changeValue({
        value: e.target.value,
        isActive: true,
        valueType: data[e.target.value + "ValueType"] || 0,
      });
    }
    if (e.target.value === "impressions") {
      changeValue({
        value: e.target.value,
        isActive: true,
        valueType: data[e.target.value + "ValueType"] || 0,
      });
    }
    if (e.target.value === "ctr") {
      changeValue({
        value: e.target.value,
        isActive: true,
        valueType: data[e.target.value + "ValueType"] || 0,
      });
    }
    if (e.target.value === "cost") {
      changeValue({
        value: e.target.value,
        isActive: true,
        valueType: data[e.target.value + "ValueType"] || 0,
      });
    }
    if (e.target.value === "conversions") {
      changeValue({
        value: e.target.value,
        isActive: true,
        valueType: data[e.target.value + "ValueType"] || 0,
      });
    }
    if (e.target.value === "averageCost") {
      changeValue({
        value: e.target.value,
        isActive: true,
        valueType: data[e.target.value + "ValueType"] || 0,
      });
    }
    if (e.target.value === "averageCpc") {
      changeValue({
        value: e.target.value,
        isActive: true,
        valueType: data[e.target.value + "ValueType"] || 0,
      });
    }
    if (e.target.value === "averageCpm") {
      changeValue({
        value: e.target.value,
        isActive: true,
        valueType: data[e.target.value + "ValueType"] || 0,
      });
    }
    setActive(true);
  };

  return (
    <div
      className={`w-44 h-16 px-3 pt-3 rounded-lg border  ${active
          ? `${items.find((item: any) => item.name === value.value).color} border-b-4`
          : "border-gray-300"
        } `}
      onClick={(e) => handleOnclick(e)}>
      <select
        ref={selectRef}
        onChange={handleChange}
        className="text-base bg-transparent placeholder-gray-600 border rounded-lg focus:shadow-outline outline-none border-none ">
        {items.map((item: any) => (
          <option value={item.name} selected={item.name === value.value}>
            {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectBox;
