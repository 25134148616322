import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { googleAnalyticsCallback } from "@/api/credential";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";

const AnalyticsCallbackPage = () => {
  // get code from url
  const { projectId } = useFetchingProjectIds();
  const [error, setError] = React.useState(false);

  const code = new URLSearchParams(window.location.search).get("code");
  const platform = new URLSearchParams(window.location.search).get("platform");
  const errorTag = new URLSearchParams(window.location.search).get("error");
  const baseUrl = window.location.origin;

  useEffect(() => {
    if (errorTag) {
      window.location.href = "/integrations";
    }
  }, [errorTag]);

  const { mutate, isSuccess, isError } = useMutation(
    "googleAnalyticsCallback",
    googleAnalyticsCallback
  );

  if (isSuccess) {
    if (platform === "google_analytics") {
      window.location.href = "/google-analytics/properties";
    }
    if (platform === "google_ads") {
      window.location.href = "/google-ads/properties";
    }
  }

  if (isError) {
    console.log("---Error----", JSON.stringify(error));
    setError(true);

    // window.location.href = "/integrations";
  }

  useEffect(() => {
    if (code && projectId && platform) {
      mutate({
        code: code,
        platform: platform,
        project_id: projectId,
        base_url: baseUrl,
      });
    }
  }, [code, projectId, platform, baseUrl]);

  return (
    <>
      <div>You are being redirected to the relevant page, please wait...</div>
      {isError && (
        <div>
          Something went wrong, please try again
          <a href="/">Go to dashboard</a>
        </div>
      )}
      {/* <PropertiesModal isPopup={isPopup} setIsPopup={setIsPopup} /> */}
    </>
  );
};

export default AnalyticsCallbackPage;
