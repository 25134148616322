import React from "react";
import Sidebar from "./Sidebar";

type Props = {
  children?: React.ReactNode;
};

const AssessmentLayout = (props: Props) => {
  return (
    <div>
      <Sidebar>{props.children}</Sidebar>
    </div>
  );
};

export default AssessmentLayout;
