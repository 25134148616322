export enum ProjectDataType {
  HAS_FACEBOOK_ACCOUNT = "HAS_FACEBOOK_ACCOUNT",
  COMPANY_NAME = "COMPANY_NAME",
  DOMAIN_NAME = "DOMAIN_NAME",
  SECTOR = "SECTOR",
  TARGETS = "TARGETS",
  COMPETITOR_DOMAIN_NAMES = "COMPETITOR_DOMAIN_NAMES",
  COUNTRIES = "COUNTRIES",
  SPECIAL_DATES = "SPECIAL_DATES",
  REPETITIVE_JOBS = "REPETITIVE_JOBS",
  OBJECTIVE = "OBJECTIVE",
  KEYWORDS = "KEYWORDS",
  SOCIAL_MEDIA_ACCOUNTS = "SOCIAL_MEDIA",
  BUSINESS_DATA = "BUSINESS_DATA",
  ONBOARDING = "ONBOARDING",
  GETTING_STARTED = "GETTING_STARTED",
  AUTO_ASSIGN_PARTNER = "AUTO_ASSIGN_PARTNER",
  MVP1_ONBOARDING = "MVP1_ONBOARDING",
  MVP_GETTING_STARTED = "MVP_GETTING_STARTED",
  MVP2_GETTING_STARTED = "MVP2_GETTING_STARTED",
}
