import moment from "moment";
import client from "./client";

export const loginGoogleAnalytics = async () => {
  const token = localStorage.getItem("authToken");
  if (token === null) {
    return;
  }

  const googleAnalyticsTokenHolderStr = localStorage.getItem("googleAnalyticsTokenHolder");
  if (googleAnalyticsTokenHolderStr) {
    const googleAnalyticsTokenHolder = JSON.parse(googleAnalyticsTokenHolderStr);
    const validThroughUtc = moment(googleAnalyticsTokenHolder.validThroughUtc);
    if (moment().utc() <= validThroughUtc) {
      return googleAnalyticsTokenHolder;
    }
  }

  await client.get("/eanl/authenticate").then((response) => {
    localStorage.setItem("googleAnalyticsTokenHolder", JSON.stringify(response.data));
    return response.data;
  })
};
