import { putAccountUnsubscribe } from "@/api/account";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import React from "react";
import { useMutation } from "react-query";

const UnsubscribeMail = () => {
  // get query
  const query = new URLSearchParams(window.location.search);
  const email = query.get("email");
  const user_id = query.get("user_id");

  const { mutate: unsubscribe, isLoading } = useMutation(putAccountUnsubscribe, {
    onSuccess: () => {
      alert("You have been unsubscribed from our mailing list.");
    },
    onError: () => {
      alert("Something went wrong. Please try again.");
    },
  });

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
      <div className="w-full p-6 bg-white rounded-lg border shadow  md:mt-0 sm:max-w-md sm:p-8">
        <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
          Unsubscribe
        </h2>
        <div className="text-sm space-y-2 my-3">
          <p className=" text-gray-500">You have been unsubscribed from our mailing list.</p>
          <p className=" text-gray-500">
            Your email address{" "}
            <span className="font-medium text-gray-900 dark:text-gray-100">{email}</span>
          </p>
        </div>

        <div className="mt-5 flex justify-end">
          <DefaultButton
            onClick={() => unsubscribe({ user_id: Number(user_id), email: email as string })}
            isLoading={isLoading}
            type="button"
            isDisabled={!email || !user_id}
            className="w-fit">
            Unsubscribe
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeMail;
