import { removeToken } from "@/utils/token";
import React from "react";
import mixpanel from "mixpanel-browser";

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    console.log(error)
    return { hasError: true };
  }

  handleLogout = () => {
    mixpanel.reset();
    removeToken();
    localStorage.clear();
    window.location.href = "/login";
  };

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="
          flex flex-col items-center justify-center
          h-screen
          text-center
        ">
          <div
            className="
            flex flex-col items-center justify-center
            w-96
            p-8
            bg-white
            rounded-lg
            border border-gray-200
          ">
            <h1
              className="
              text-2xl
              font-bold
              text-gray-800 mb-1
            ">
              Something went wrong.
            </h1>
            <div className="text-gray-600 mb-4 text-lg">Please contact support.</div>
            <div className="flex gap-3">
              <div className=" text-blue-600">
                <a href="/">Go to Home</a>
              </div>
              |
              <div className=" text-blue-600">
                <button onClick={this.handleLogout}>Logout</button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
