import React, { useEffect } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import Tabs from "@/features/profile/components/Tabs";
import { t } from "i18next";

import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { pageViewEvent } from "@/analytics/mixpanel";
import EarnadoPlans from "@/features/profile/components/EearnadoPlans";

const EarnadoPlansPage = () => {
  const [selectedTab, setSelectedTab] = React.useState("earnado");
  const [user] = useRecoilState(userAtom);

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "earnado plans",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  return (
    <AssessmentLayout>
      <ContentBar title={t("earnadoPlans")} />
      <Tabs setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
      <EarnadoPlans />
    </AssessmentLayout>
  );
};

export default EarnadoPlansPage;
