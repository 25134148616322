import React, { useEffect, useState } from "react";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { useRecoilState } from "recoil";
import { t } from "i18next";
import { pageViewEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { fetchMvpQuestions } from "@/api/mvp";
import { USER_TYPE } from "@/models/userType";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import Goal from "./Goal";

type Props = {
  setCurrentStep: (step: number) => void;
  setSteps: any;
  projectId: number;
};

const SelectRoadmap = (props: Props) => {
  const { setCurrentStep, setSteps } = props;
  const [user] = useRecoilState(userAtom);
  const [selectedGoal, setSelectedGoal] = useState(null);

  const handleBusinessInfoSubmit = (e: any) => {
    e.preventDefault();

    setSteps([
      { id: "01", name: t("goals"), status: "complete" },
      { id: "02", name: t("assets"), status: "current" },
      { id: "03", name: t("marketingTools"), status: "upcoming" },
      { id: "04", name: t("target"), status: "upcoming" },
    ]);

    setCurrentStep(2);
  };

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "onboarding(goals)",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  const { data: goals, isLoading } = useQuery(
    "goals",
    () =>
      fetchMvpQuestions({
        form: USER_TYPE,
      }),
    {}
  );

  return (
    <div className="h-full w-full flex  flex-col justify-center items-center px-8 md:px-16 ">
      {isLoading ? (
        <LoadingSpinner size="w-6 h-6" />
      ) : (
        <>
          <div className="flex justify-center flex-col items-center bg-gray-50 py-5">
            <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">{goals[0]?.text}</h1>
            <div className="text-xl text-gray-500">{goals[0]?.description}</div>
          </div>

          <form onSubmit={handleBusinessInfoSubmit}>
            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 flex-wrap mt-10 mb-20">
              {goals[0]?.answers?.map((goal: any, key: number) => (
                <Goal
                  selectedGoal={selectedGoal}
                  setSelectedGoal={setSelectedGoal}
                  key={key}
                  {...goal}
                />
              ))}
            </div>

            <div className="flex justify-end my-5 md:mb-0 md:pr-5 md:absolute bottom-10 right-10 ">
              <DefaultButton isDisabled={!selectedGoal} className="w-fit px-6 mr-2 md:mr-0">
                {t("next")}
              </DefaultButton>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default SelectRoadmap;
