import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import mixpanel from "mixpanel-browser";
import * as yup from "yup";

import { registerUser, createGoogleAuthUrl, loginWithGoogleAuth } from "@/api/auth";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";

// Integration
import { RegisterFormDataType } from "@/models/user.interface";
// Hook
import { setToken } from "@/utils/token";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  pageViewEvent,
  signInEvent,
  signInFailureEvent,
  signUpEvent,
  signUpFailureEvent,
} from "@/analytics/mixpanel";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { USER_TYPE_MVP2 } from "@/models/userType";

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

// import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const Register = () => {
  const { t } = useTranslation();

  const schema = yup
    .object({
      first_name: yup.string().required(t("firstname_is_required")),
      last_name: yup.string().required(t("lastname_is_required")),
      email: yup.string().email().required(t("email_is_required")),
      // password contains at least one letter
      password: yup
        .string()
        .required(t("password_is_required"))
        .min(6, t("password_must_be_at_least_6_characters"))
        .max(20, t("password_must_be_less_than_20_characters"))
        .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])/, t("password_must_contain")),
    })
    .required();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<RegisterFormDataType>({
    resolver: yupResolver(schema),
  });

  const [error, setError] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string>("");

  const navigate = useNavigate();

  const { mutate, isLoading: isLoadingPost } = useMutation(registerUser, {
    onSuccess: (data) => {
      const tempData = {
        userId: data.data.user_id,
        signUpDone: true,
        sourceOutput: "/",
        email: email,
        fromGoogle: false,
      };
      signUpEvent(tempData);
      mixpanel.identify(data.data.user_id);
      mixpanel.people.set({
        $email: email,
        $name: getValues("first_name") + " " + getValues("last_name"),
      });

      setToken(data.data.access_token);

      localStorage.removeItem("dropdown1");

      navigate("/");
    },
    onError: (error: any) => {
      setError(error.response.data.detail);
      const tempData = {
        signUpDone: false,
      };
      signUpFailureEvent(tempData);
    },
    retry: false,
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data: any) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    try {
      const token = await executeRecaptcha('register');

      // const tokenCheck = await checkRecaptcha(token);
      // if (!tokenCheck.data) {
      //   setError(t("recaptchaFailed"));
      //   return;
      // }

      const role = window.location.hostname.includes("prod") ? USER_TYPE_MVP2 : null;
      mutate({
        ...data,
        role: role,
        recaptchaToken: token
      });
    } catch (error) {
      console.error('Recaptcha Error:', error);
    }
  };

  const { data: googleAuthUrl, isLoading: isLoadingGoogleUrl } = useQuery("googleAuthUrl", () =>
    createGoogleAuthUrl(window.location.origin + "/login")
  );

  const { isLoading: isLoadingGoogleLogin } = useMutation(
    loginWithGoogleAuth,
    {
      onSuccess: (data) => {
        if (!data.is_register) {
          const tempData = {
            userId: data.user_id,
            signInDone: true,
            sourceOutput: "/",
            email: data.email,
            fromGoogle: true,
          };
          signInEvent(tempData);
        } else {
          const tempData = {
            userId: data.user_id,
            signUpDone: true,
            sourceOutput: "/",
            email: data.email,
            fromGoogle: true,
          };
          signUpEvent(tempData);
        }

        mixpanel.identify(data.user_id);
        mixpanel.people.set({ $email: data.email, $name: data?.first_name + " " + data.last_name });

        setToken(data.access_token);
        localStorage.removeItem("dropdown1");
        navigate("/");
      },
      onError: (error: any) => {
        setError(error.response.data.detail);
        const tempData = {
          signInDone: false,
          email: email,
        };
        signInFailureEvent(tempData);
      },
      retry: false,
    }
  );

  useEffect(() => {
    pageViewEvent({
      pageName: "Register",
    });
  }, []);

  return (
    <div>
      <div className="flex min-h-screen">
        <div className="flex flex-1 justify-center flex-col py-6 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 relative">
          {/*    <div className="absolute right-5 top-3 z-[99]">
          </div> */}
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-12 w-auto" src="/assets/icons/logoPrivate.svg" alt="Logo" />
              <h2 className="mt-5 text-3xl font-bold tracking-tight text-gray-900">
                {t("getStartedForFree")}
              </h2>
              <div className="text-base mt-2 text-gray-500">*{t("freeNoCreditCard")}</div>
            </div>

            <div className="mt-8 ">
              <div className="mt-6">
                <form method="POST" className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <div className="w-full">
                      {isLoadingGoogleUrl || isLoadingGoogleLogin ? (
                        <LoadingSpinner size="w-5 h-5" />
                      ) : (
                        <a
                          href={googleAuthUrl?.authorization_url}
                          className=" flex items-center justify-center space-x-2 bg-white border border-gray-300 px-4 py-2 rounded-md shadow-sm text-sm font-medium text-gray-500 hover:bg-gray-50 w-full">
                          <img src="/assets/icons/google.svg" alt="google" />
                          <span>{t("registerWithGoogle")}</span>
                        </a>
                      )}
                    </div>
                    <div className="w-full border-t border-gray-300 mt-8">
                      <div className="flex justify-center items-center -mt-3">
                        <span className="bg-white px-4 text-gray-500 text-sm ">{t("or")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        {t("firstname")}
                      </label>
                      <div className="mt-1">
                        <input
                          {...register("first_name")}
                          type="text"
                          placeholder={t("enteryourfirstname")}
                          required
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      {errors.first_name?.message && (
                        <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                          {errors.first_name?.message}
                        </span>
                      )}
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        {t("lastname")}
                      </label>
                      <div className="mt-1">
                        <input
                          {...register("last_name")}
                          type="text"
                          placeholder={t("enteryourlastname")}
                          required
                          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      {errors.last_name?.message && (
                        <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                          {errors.last_name?.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      {t("email")}
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("email")}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        placeholder={t("enterYourEmail")}
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    {errors.email?.message && (
                      <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                        {errors.email?.message}
                      </span>
                    )}
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      {t("password")}
                    </label>
                    <div className="mt-1">
                      <input
                        type="password"
                        {...register("password")}
                        placeholder={t("enterYourPassword")}
                        required
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    {errors.password?.message && (
                      <span className="text-dangerButtonPrimary text-sm -mt-2 ml-2">
                        {errors.password?.message}
                      </span>
                    )}
                  </div>

                  <div className="flex  justify-between gap-3">
                    <input
                      type="checkbox"
                      name="user_contract"
                      id="user_contract"
                      required={true}
                    />
                    <label htmlFor="user_contract" className="text-sm text-textColor">
                      {t("bySigningUpYouAgreeToOur")}{" "}
                      <Link
                        className="text-sky"
                        to="/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("termsOfUse")}
                      </Link>{" "}
                      {t("and")}{" "}
                      <Link
                        className="text-sky"
                        to="/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer">
                        {t("privacyPolicy")}
                      </Link>
                      .
                    </label>
                  </div>

                  {error && (
                    <div className="text-dangerButtonPrimary text-sm -my-2 first-letter:capitalize">
                      {error}
                    </div>
                  )}
                  <div className="flex flex-col gap-4">
                    <DefaultButton isLoading={isLoadingPost}>{t("signUp")}</DefaultButton>
                  </div>

                  <div className="flex justify-center flex-col space-y-2">
                    <span className="text-center text-sm text-textColor">
                      {t("aldreadyHaveAnAccount")}{" "}
                      <Link to="/login" className="text-sky">
                        {t("logIn")}
                      </Link>
                      .
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="relative hidden w-0 h-screen flex-1 lg:block border-l">
          <img
            className="absolute h-full w-full object-cover"
            src="/assets/images/login-bg-mvp.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
