import DefaultPage from "./DefaultPage";

const RoadmapPage = () => {
  return (
    <div>
      {/*  {user?.role === USER_TYPE || user?.role === USER_TYPE_MVP2 ? (
        <>
          <MvpPage />
        </>
      ) : (
        <>
          <DefaultPage />
        </>
      )} */}
      <DefaultPage />
    </div>
  );
};

export default RoadmapPage;
