import client from "./client";

export const postFacebookAccessToken = async (data: {
  project_id: string;
  short_lived_user_access_token: string;
}) => {
  const response = await client.post(
    "/social_media/facebook/auth/long_lived_user_access_token",
    {},
    {
      params: {
        project_id: data.project_id,
        short_lived_user_access_token: data.short_lived_user_access_token,
      },
    }
  );

  return response.data;
};

export const fetchTokenStatus = async (projectId: number) => {
  const response = await client.get("/social_media/facebook/auth/token_status", {
    params: { project_id: projectId },
  });
  return response.data;
};

export const postFacebookNewPost = async (data: {
  file_in?: File;
  message?: string;
  page_id: string;
  project_id: number;
}) => {
  const response_form = new FormData();
  response_form.append("project_id", data.project_id as any);
  response_form.append("page_id", data.page_id);
  if (data.file_in) {
    response_form.append("file_in", data.file_in);
  }
  if (data.message) {
    response_form.append("message", data.message);
  }

  const response = await client.post("/social_media/facebook/post/new_post", response_form);
  return response.data;
};

export const fetchPages = async (projectId: number) => {
  const response = await client.get(`/social_media/facebook/auth/pages`, {
    params: { project_id: projectId },
  });
  return response.data;
};

export const removeFacebookConnection = async (projectId: number) => {
  const response = await client.delete(`/social_media/facebook/auth/${projectId}`);

  return response.data;
};
