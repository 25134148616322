import { CheckIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { fetchIyzicoPlans, postIyzicoCheckoutForm } from "@/api/iyzico";
import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import { authMe } from "@/api/auth";
import { fetchAddressFromIP } from "@/utils/fetchAddressFromIP";

import { t } from "i18next";
import { fetchPaymentForm } from "@/api/credit";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import WhiteButton from "@/components/buttons/WhiteButton";
import ExtraCreditModal from "../ExtraCreditModal";
// eslint-disable-next-line import/namespace
import UpdateProfileInformationModal from "../UpdateProfileInformationModal";

export default function PricingTable() {
  const [currenctFromCountry, setCurrencyFromCountry] = useState<any>(null);
  const [isLoadingIp, setIsLoadingIp] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isProfileModal, setProfileModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const { mutateAsync, isLoading: isLoadingCheckout } = useMutation(postIyzicoCheckoutForm, {});
  const { data: authData, isLoading: isLoadingAuth } = useQuery("authmeProfile", authMe);
  const [amount, setAmount] = useState<number>(10);

  const [user] = useRecoilState(userAtom);
  const { projectId, isLoading: isLoadingProjectId, pricingPlan } = useFetchingProjectIds();

  const { data, isLoading } = useQuery(
    "iyzicoPlans",
    () =>
      fetchIyzicoPlans({
        currency: currenctFromCountry,
        projectId,
      }),
    {
      retry: false,
      enabled: !!currenctFromCountry && !!projectId,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    setIsLoadingIp(true);
    fetchAddressFromIP().then((res) => {
      if (res.country_name === "Turkey") setCurrencyFromCountry("TRY");
      else setCurrencyFromCountry("USD");
      setIsLoadingIp(false);
    });
  }, []);

  const handleClick = (tier: any) => {
    if (!authData?.invoice_type || !authData?.address?.country) {
      setProfileModal(true);
      return;
    }

    mutateAsync({
      locale: "tr",
      conversation_id: projectId.toString(),
      project_id: projectId,
      callback_url: `${process.env.REACT_APP_BASE_URL}/iyzico/callback/${projectId}`,
      pricing_plan_reference_code: tier.iyzico_pricing_plan_reference_code,
      subscription_initial_status: "ACTIVE",
      customer: {
        name: user?.first_name,
        surname: user?.last_name,
        email: user?.email,
        gsm_number: "+905555555555",
        identity_number: "11111111111",
        billing_address: {
          contact_name: user?.first_name,
          city: "İstanbul",
          country: "Turkey",
          address:
            "Maslak, Teknokent ARI, Reşitpaşa Mah. Katar Cad, 3 Binası No:4 D:B208, 34467 Sarıyer/İstanbul",
        },
      },
    }).then((res) => {
      if (res.status === "failure") {
        console.log(res);
        alert(res.error_message);
      } else {
        // open new tab
        navigate("/payment", {
          state: {
            checkoutFormContent: res.checkout_form_content,
          },
        });
        window.location.reload();
      }
    });
  };

  const { mutateAsync: mutateAsyncAuthCustomForm, isLoading: isLoadingForm } =
    useMutation(fetchPaymentForm);

  const handleCustomClick = () => {
    mutateAsyncAuthCustomForm({
      projectId,
      amount: amount,
      currency: currenctFromCountry,
      // callback_url: `${process.env.REACT_APP_BASE_URL}/iyzico/callback/${projectId}`,
    }).then((res) => {
      if (res.status === "failure") {
        alert(res.error_message);
      } else {
        navigate("/payment", {
          state: {
            checkoutFormContent: res.checkoutFormContent,
          },
        });
        window.location.reload();
      }
    });
  };

  return (
    <div className="bg-white ">
      {isLoading || isLoadingProjectId || isLoadingAuth || isLoadingIp ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <div>
          {(isLoadingCheckout || isLoadingForm) && (
            <div className="fixed flex justify-center items-center top-0 left-0 h-screen w-screen z-[999]">
              <div className="bg-gray-500 opacity-10 h-screen w-screen"></div>
              <div className="absolute">
                <LoadingSpinner size="w-12 h-12" />
              </div>
            </div>
          )}
          <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8 relative">
            <div className="sm:align-center sm:flex sm:flex-col">
              <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-center">
                {t("Pricing Plans")}
              </h1>
              <p className="mt-5 text-xl text-gray-500 sm:text-center">
                {t(
                  "Start building for free, then add a site plan to go live. Account plans unlock additional features."
                )}
              </p>
            </div>
            <div className="mt-12 space-y-4 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
              {data?.mikrolo
                // ?.sort((a: any, b: any) => a.id - b.id)
                .map((tier: any) => (
                  <div
                    key={tier.name}
                    className={`divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm ${pricingPlan?.id === tier.id ? "bg-indigo-50 border-indigo-200" : "bg-white"
                      } `}>
                    <div className="p-6">
                      <h2 className="text-lg font-medium leading-6 text-gray-900">{tier.name}</h2>
                      <p className="mt-4 text-sm text-gray-500"></p>
                      <p className="mt-8">
                        <span className="text-4xl font-bold tracking-tight text-gray-900">
                          {tier.iyzico_pricing_plan_currency === "TRY" && "₺"}
                          {tier.iyzico_pricing_plan_currency === "USD" && "$"}
                          {tier.iyzico_pricing_plan_price ? tier.iyzico_pricing_plan_price : "-"}
                        </span>{" "}
                        <span className="text-base font-medium text-gray-500">/mo</span>
                      </p>
                      {tier.id === 1 ? (
                        <>
                          {pricingPlan?.id === tier.id && (
                            <div className=" text-white">
                              <button className="mt-8 block w-full rounded-md border border-green-600 bg-green-500 py-2 text-center text-sm font-semibold text-white">
                                {t("Your Plan")}
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {pricingPlan?.id === tier.id ? (
                            <div className=" text-white">
                              <button className="mt-8 block w-full rounded-md border border-green-600 bg-green-500 py-2 text-center text-sm font-semibold text-white">
                                {t("Your Plan")}
                              </button>
                            </div>
                          ) : (
                            <DefaultButton
                              onClick={() => handleClick(tier)}
                              className="mt-8 block w-full">
                              {t("buy_now")} {tier.name}
                            </DefaultButton>
                          )}
                        </>
                      )}
                    </div>
                    <div className="px-6 pt-6 pb-8  min-h-[300px]">
                      <h3 className="text-sm font-medium text-gray-900">{t("What's included")}</h3>
                      <ul className="mt-6 space-y-4">
                        <li className="flex items-start">
                          <CheckIcon
                            className="h-5 w-5 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <p className="ml-3 text-sm text-gray-500">
                            {/*    {tier.assignable_task_limit > 1
                        ? includedFeatures["assignable_task_limit"]
                        : "10 tasks"} */}
                            {tier?.credit} {t("credit")}
                          </p>
                        </li>
                        {tier.has_integratons && (
                          <li className="flex items-start">
                            <CheckIcon
                              className="h-5 w-5 flex-shrink-0 text-green-500"
                              aria-hidden="true"
                            />
                            <p className="ml-3 text-sm text-gray-500">{t("integrations_true")}</p>
                          </li>
                        )}

                        <li className="flex items-start">
                          <CheckIcon
                            className="h-5 w-5 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <p className="ml-3 text-sm text-gray-500">
                            {tier.has_premium_reports
                              ? t("has_premium_rules_true")
                              : t("has_premium_rules_false")}
                          </p>
                        </li>
                        <li className="flex items-start">
                          <CheckIcon
                            className="h-5 w-5 flex-shrink-0 text-green-500"
                            aria-hidden="true"
                          />
                          <p className="ml-3 text-sm text-gray-500">
                            {tier.has_premium_rules
                              ? t("premium_rules_true")
                              : t("premium_rules_false")}
                          </p>
                        </li>
                        {/*   {tier.has_premium_support && (
                          <li className="flex items-start">
                            <CheckIcon
                              className="h-5 w-5 flex-shrink-0 text-green-500"
                              aria-hidden="true"
                            />
                            <p className="ml-3 text-sm text-gray-500">{t("supports_true")}</p>
                          </li>
                        )} */}
                      </ul>
                    </div>
                  </div>
                ))}
              <div className="divide-y divide-gray-200 rounded-lg border border-gray-200 shadow-sm">
                <div className="p-6">
                  <h2 className="text-lg font-medium leading-6 text-gray-900">Extra Credit</h2>
                  <p className="mt-4 text-sm text-gray-500"></p>
                  <p className="mt-8">
                    <span className="text-4xl font-bold tracking-tight text-gray-900"></span>{" "}
                    <span className="text-base font-medium text-gray-500">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">
                        {currenctFromCountry === "TRY" ? "₺1500" : "$30"}
                      </span>
                      / 10 {t("credit")}
                    </span>
                  </p>

                  <>
                    <WhiteButton
                      isDisabled={pricingPlan?.id === 1}
                      onClick={() => {
                        if (!authData?.invoice_type || !authData?.address?.country) {
                          setProfileModal(true);
                          return;
                        }
                        setIsModalOpen(true);
                      }}
                      className="mt-8 block w-full ">
                      {t("Buy Extra Credit")}
                    </WhiteButton>
                    {pricingPlan?.id === 1 && (
                      <div className="mt-2 text-xs text-gray-500">
                        *You can't buy extra credit with free plan
                      </div>
                    )}
                  </>
                </div>
                <div className="px-6 pt-6 pb-8">
                  <h3 className="text-sm font-medium text-gray-900">{t("What's included")}</h3>
                  <ul className="mt-6 space-y-4">
                    <li className="flex items-start">
                      <CheckIcon
                        className="h-5 w-5 flex-shrink-0 text-green-500"
                        aria-hidden="true"
                      />
                      <p className="ml-3 text-sm text-gray-500">
                        {/*    {tier.assignable_task_limit > 1
                        ? includedFeatures["assignable_task_limit"]
                        : "10 tasks"} */}
                        {t("credit")}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <ExtraCreditModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          handleExtraCredit={handleCustomClick}
          setAmount={setAmount}
          amount={amount}
          currenctFromCountry={currenctFromCountry}
        />
      )}

      {isProfileModal && (
        <UpdateProfileInformationModal open={isProfileModal} setOpen={setProfileModal} />
      )}
    </div>
  );
}
