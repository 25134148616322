import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { t } from "i18next";

import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import ChatScreen from "@/features/messages/components/ChatScreen";
import UserList from "@/features/messages/components/UserList";
import { fetchProjectPartnerTasks } from "@/api/project";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { pageViewEvent } from "@/analytics/mixpanel";

const MessagePage = () => {
  const [selectUser, setSelectUser] = useState(false);
  const [currentPage, setCurrentPage] = React.useState<number>(
    (localStorage.getItem("messageId") as any) ? 0 : 1
  );

  const { projectId, isLoading } = useFetchingProjectIds();
  const [user] = useRecoilState(userAtom);

  const {
    data: userList,
    isLoading: isLoadingUser,
    refetch,
    isSuccess,
  } = useQuery(
    ["fetchProjectPartnerTasks", currentPage],
    () => fetchProjectPartnerTasks({ project_id: projectId, page: currentPage }),
    {
      enabled: !!projectId,
      keepPreviousData: false,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      localStorage.setItem("userList.items", JSON.stringify(userList?.items));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (localStorage.getItem("messageId") as any) {
      console.log("location.state?.id", localStorage.getItem("messageId") as any);
      userList?.items?.map((item: any) => {
        if (item.id === +(localStorage.getItem("messageId") as any)) {
          setSelectUser(item);
          localStorage.removeItem("messageId");
        }
      });
    }
  }, [userList]);

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "messages",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("messages")} />
        {isLoading || isLoadingUser ? (
          <div className="flex h-[calc(100vh-5rem)] justify-center items-center ">
            <LoadingSpinner size="w-8 h-8" />
          </div>
        ) : (
          <>
            <div className="mt-8 lg:grid lg:grid-cols-9 gap-2 max-w-[1460px] h-full ">
              <div className="lg:col-span-3 mb-2 sm:mb-0">
                <UserList
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  selectUser={selectUser}
                  setSelectUser={setSelectUser}
                  userList={userList}
                  refetch={refetch}
                />
                {/*  <div className="inline-flex p-4">
                  <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l">
                    Prev
                  </button>
                  <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r">
                    Next
                  </button>
                </div> */}
              </div>

              <div className="lg:col-span-6 h-full ">
                <ChatScreen selectUser={selectUser} refetchUserList={refetch} />
              </div>
            </div>
          </>
        )}
      </AssessmentLayout>
    </div>
  );
};

export default MessagePage;
