import React from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { t } from "i18next";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const faqs = [
  {
    question: {
      en: "What is the connection between rules and tasks?",
      tr: "Kurallar ve görevler arasında nasıl bir bağlantı vardır?",
    },
    answer: {
      en: "The rules create a number of tasks for you by detecting errors and anomalies in your website and advertising campaigns. By completing these tasks, you can improve your digital marketing performance.",
      tr: "Kurallar, websitenizdeki ve reklam kampanyalarınızdaki hataların ve anomalilerin tespit edilmesini sağlayarak sizin için birtakım görevler oluşturmaktadır. Bu görevleri gerçekleştirerek dijital pazarlama performansınızı geliştirebilirsiniz.",
    },
  },
  {
    question: {
      en: "Why should I integrate my accounts?",
      tr: "Neden entegrasyon yapmalıyım?",
    },
    answer: {
      en: "You should integrate your accounts to create tasks that fit your needs by analyzing the previous data of your business and to see reports containing your data. Thus, you can use Mikrolo more effectively.",
      tr: "İşletmenizin daha önceki verilerini analiz ederek ihtiyaçlarınıza uygun görevler oluşturulması için ve verilerinizi içeren raporlar görebilmeniz için entegrasyon yapmalısınız. Böylelikle Mikrolo’yu daha efektif kullanabilirsiniz.",
    },
  },
  {
    question: {
      en: "How are tasks assigned to me?",
      tr: "Görevler bana neye göre atanıyor?",
    },
    answer: {
      en: "Assigning the tasks that form the basis of your digital marketing journey to you is based on the needs of your business. Tasks are divided into types such as assigned by our experts, created by the rules, or created by yourself.",
      tr: "Dijital pazarlama yolculuğunuzun temelini oluşturan görevlerin size atanması işletmenizin ihtiyaçlarına göre gerçekleşmektedir. Görevler uzmanlarımızın atadıkları, kuralların tespit ettikleri, kendi oluşturduklarınız gibi çeşitlere ayrılır.",
    },
  },
  {
    question: {
      en: "I want to upgrade my subscription plan. How can I do that?",
      tr: "Abonelik planımı yükseltmek istiyorum. Bunu nasıl yapabilirim?",
    },
    answer: {
      en: "You can upgrade your subscription plan by choosing the most suitable plan from the “Subscription Plans” section on your profile screen.",
      tr: "Profil ekranınızdaki “Abonelik Planları” bölümünden size en uygun paketi seçerek aboneliğinizi yükseltebilirsiniz.",
    },
  },
  {
    question: {
      en: "I don't want to do the tasks myself. What can I do?",
      tr: "Görevleri kendim yapmak istemiyorum. Ne yapabilirim?",
    },
    answer: {
      en: "When you click on the tasks on the “Tasks” page, you can have our experts do your tasks by clicking the 'Assign to Mikrolo' button in the section that opens on the right side of the screen. Note that you cannot assign every task to Mikrolo, for now. :)",
      tr: "“Görevler” sayfasındaki görevlere tıkladığınızda ekranın sağ tarafında açılan bölümde “Mikrolo’ya ata” butonuna tıklayarak görevlerinizi uzmanlarımızın yapmasını sağlayabilirsiniz. Her görevi Mikrolo’ya atayamacağınızı unutmamalısınız, şimdilik. :)",
    },
  },
  {
    question: {
      en: "I want to create a task for a partner to do. How can I do that?",
      tr: "Bir partnerin yapması için bir görev oluşturmak istiyorum. Bunu nasıl yapabilirim?",
    },
    answer: {
      en: "On the “Tasks” screen, you can create a new task by clicking the “+” button at the top right of the tasks list and selecting a template.",
      tr: "Görevler ekranında, görev listesinin sağ üstünde bulunan “+” butonuna tıklayıp bir şablon seçerek yeni bir görev oluşturabilirsiniz.",
    },
  },
  {
    question: {
      en: "I assigned a task to Mikrolo. How can I contact the partner?",
      tr: "Bir görevi Mikrolo’ya atadım. Partner ile nasıl iletişime geçebilirim?",
    },
    answer: {
      en: "You can use the Messages section on the left-hand navigation bar to communicate with the partner about the task you have assigned.",
      tr: "Atadığınız görevle ilgili partner ile iletişime geçmek için sol taraftaki navigasyon barda bulunan Mesajlar bölümünü kullanabilirsiniz.",
    },
  },
  {
    question: {
      en: "What is the connection between threats and opportunities and tasks?",
      tr: "Tehdit ve fırsatlar ile görevler arasında nasıl bir ilişki vardır?",
    },
    answer: {
      en: "Mikrolo creates tasks by identifying the threats and opportunities your business is facing. Thus, by completing tasks, you can both avoid threats and seize opportunities.",
      tr: "Mikrolo, işletmenizin karşılaştığı tehditleri ve fırsatları tespit ederek görevler oluşturur. Böylelikle görevleri gerçekleştirerek hem tehditlerden kaçınabilir hem de fırsatları değerlendirebilirsiniz.",
    },
  },
  {
    question: {
      en: "Where can I see my previous months' invoices?",
      tr: "Önceki aylara ait faturalarımı nereden görebilirim?",
    },
    answer: {
      en: "You can view your invoices for the previous months in the “Invoice” section on your profile screen.",
      tr: "Profil ekranınızdaki “Fatura” bölümünden önceki aylara ait faturalarınızı görüntüleyebilirsiniz.",
    },
  },
];

const FAQPage = () => {
  const { i18n } = useTranslation();
  //   const [selectedFAQ, setSelectedFAQ] = React.useState<any>([]);

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title="FAQs" />
        <div className="text-sm text-[#707070] mt-8 mb-6">
          <div className="text-sm text-[#707070] flex gap-2 flex-row items-center w-fit pb-1">
            <div>
              <HomeIcon className="w-4 h-4" />
            </div>
            <div>
              <Link className="flex items-center gap-2" to="/help-center">
                <ChevronRightIcon className="w-4 h-4" />
                {t("helpCenter")}
              </Link>
            </div>
            <div className="flex items-center gap-2">
              <ChevronRightIcon className="w-4 h-4" /> {t("FAQs")}
            </div>
          </div>
        </div>

        <div>
          <div className="bg-white">
            <div className="mx-auto w-full py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
              <div className="mx-auto  divide-y-2 divide-gray-200">
                <h2 className="text-left text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  {t("frequentlyAskedQuestions")}
                </h2>
                <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                  {faqs.map((faq: any) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <>
                          <dt className="text-lg">
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                              <span className="font-medium text-gray-900">
                                {faq.question[i18n.language]}
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                <ChevronDownIcon
                                  className={classNames(
                                    open ? "-rotate-180" : "rotate-0",
                                    "h-6 w-6 transform"
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base text-gray-500">{faq.answer[i18n.language]}</p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default FAQPage;
