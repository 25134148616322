type Props = {
    metricName: string;
    value: number;
    difference: string;
    color: string;
};

const badgeClasses = `inline-block px-3 py-1 text-sm font-semibold text-black rounded-full shadow-sm`;

const SmallCardItem = (props: Props) => {
    return (
        <div className="flex flex-col items-center bg-white rounded shadow-md">
            <dt>
                <p
                    className="truncate text-sm font-medium text-gray-500 capitalize"
                    title={props.metricName}>
                    {props.metricName}
                </p>
            </dt>
            <dd className="flex flex-col items-center pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">
                    {props.value}
                </p>
                <span
                    style={{ backgroundColor: props.color }}
                    className={`${badgeClasses}`}
                >
                    {props.difference}
                </span>
            </dd>
        </div>
    );
};

export default SmallCardItem;
