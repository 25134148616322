import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { t } from "i18next";
import { useMutation, useQuery } from "react-query";
import { fetchStoryAssignableTasks, fetchStoryTemplates } from "@/api/stories";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import Select from "react-select";
import Input from "@/components/UI/Input";
import TextArea from "@/components/UI/TextArea";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { postProjectWorkDemands } from "@/api/project";
import { creatingTaskEvent } from "@/analytics/mixpanel";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { CheckIcon } from "@heroicons/react/20/solid";
// import { dashboardOnboarding } from "@/store/atoms/dashboardOnboarding.atoms";
// import { taskNavbar } from "@/store/atoms/taskNavbar.atoms";

const identifiers = [
  {
    id: "advertising",
    name: "Advertising",
    icon: (
      <img src="assets/images/templates/googleads.png" alt="advertising" className="w-16 h-16" />
    ),
    description: "All your advertising needs&development",
  },
  {
    id: "social_media",
    name: "Social Media",
    icon: (
      <img src="assets/images/templates/hashtag.png" alt="social media" className="w-16 h-16" />
    ),
    description: "All your marketing needs&development",
  },
  {
    id: "seo",
    name: "SEO",
    icon: (
      <img src="assets/images/templates/websiteanalytics.png" alt="seo" className="w-16 h-16" />
    ),
    description: "All your website needs&development",
  },
  {
    id: "content_marketing",
    name: "Content Marketing",
    icon: <img src="assets/images/templates/content.png" alt="seo" className="w-16 h-16" />,
    description: "All your content needs&development",
  },
  {
    id: "other",
    name: "Others",
    icon: <img src="assets/images/templates/viewmore.png" alt="seo" className="w-16 h-16" />,
    description: "All your other needs",
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}
interface Props {
  modalState: boolean;
  setModalState: (state: boolean) => void;
  handleGettingStart: any;
}

export default function CreateStoryPopup(props: Props) {
  const {
    projectId,
    isLoading: isLoadingProjectId,
    assignedTaskCount,
    pricingPlan,
  } = useFetchingProjectIds();
  const [user] = useRecoilState(userAtom);
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [selectFilter, setSelectFilter] = useState("");

  const steps = [
    {
      name: "Step 1",
      href: "#",
      status: step === 0 ? "current" : step > 0 ? "complete" : "upcoming",
    },
    {
      name: "Step 2",
      href: "#",
      status: step === 1 ? "current" : step > 1 ? "complete" : "upcoming",
    },
    {
      name: "Step 3",
      href: "#",
      status: step === 2 ? "current" : step > 2 ? "complete" : "upcoming",
    },
  ];

  const today = new Date();
  today.setDate(today.getDate() + 1);

  // const [navbarFetch, setNavbarFetch] = useRecoilState<any>(taskNavbar);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);
  const [selectedTasks, setSelectedTasks] = useState<any>([]);
  const [storyName, setStoryName] = useState("");
  const [dueDate, setDueDate] = useState(today.toISOString());
  const [brief, setBrief] = useState("");
  // const [, setDashboard] = useRecoilState(dashboardOnboarding);

  const { modalState, setModalState, handleGettingStart } = props;
  const [isOpen, setIsOpen] = useState(false);

  const [error, setError] = useState("");

  const { data, isLoading } = useQuery("fetchTemplate", () => fetchStoryTemplates(), {
    retry: false,
    cacheTime: 0,
    refetchOnWindowFocus: true,
  });

  const { data: tasks, isFetching } = useQuery(
    ["fetchStoryAssignableTasksToSelect", selectedTemplateId],
    () => fetchStoryAssignableTasks(selectedTemplateId),
    {
      retry: false,
      cacheTime: 0,
      enabled: selectedTemplateId !== 0,
    }
  );

  function closeModal() {
    setIsOpen(false);
    setModalState(false);
  }

  useEffect(() => {
    setIsOpen(modalState);
  }, [modalState]);

  useEffect(() => {
    setSelectedTasks([]);
  }, [selectedTemplateId]);

  const { mutateAsync, isLoading: isLoadingWorkDemand } = useMutation(
    "postTemplate",
    postProjectWorkDemands
  );

  const handleSubmit = async () => {
    if (!storyName || !dueDate || !brief || !selectedTemplateId || !selectedTasks.length) {
      setError(t("Please fill all fields"));
      return;
    } else {
      const finalOutgoingTasks = selectedTasks.map((task: any) => {
        return {
          task_id: task.id,
          amount: 1,
        };
      });

      setError("");
      mutateAsync({
        content: brief,
        due_date: dueDate,
        tasks: finalOutgoingTasks,
        title: storyName,
        project_id: projectId,
        story_id: selectedTemplateId,
      })
        .then(() => {
          closeModal();
          creatingTaskEvent({
            userId: user?.id.toString() || "",
            email: user?.email || "",
            taskName: storyName,
          });

          handleGettingStart("connectAccount");
          navigate("/tasks");
          // localStorage.setItem("showMessageForGettingStarted", "true");
        })
        .catch((err) => {
          setError(err?.response?.data?.message || t("somethingWentWrong"));
        });
    }
  };

  return (
    <div>
      {modalState && (
        <div className="fixed top-0 left-0 bg-gray-300 h-screen w-screen z-5 transition-all opacity-50 z-[99]"></div>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[99999] overflow-y-auto z-6 text-center "
          onClose={closeModal}>
          <div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-2xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 flex justify-between">
                  <div className="flex justify-end w-full">
                    <button
                      type="button"
                      className=" inline-flex self-start justify-center px-2  font-medium text-blue-900  border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 transition-all"
                      onClick={closeModal}>
                      {"x"}
                    </button>
                  </div>
                </Dialog.Title>
                {isLoading || isLoadingProjectId ? (
                  <LoadingSpinner size="w-6 h-6" />
                ) : (
                  <div className="space-y-5 divide-y divide-gray-200">
                    <div className="bg-gray-50 p-4">
                      <div className="text-lg font-medium">{t("Create a new task")}</div>
                      <div className="text-sm text-gray-500">
                        {t(
                          "Get started by filling in the information below to create your new task."
                        )}
                      </div>
                    </div>

                    <div className="flex justify-center pt-4">
                      <nav aria-label="Progress">
                        <ol className="flex items-center">
                          {steps.map((step, stepIdx) => (
                            <li
                              key={step.name}
                              className={classNames(
                                stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                                "relative"
                              )}>
                              {step.status === "complete" ? (
                                <>
                                  <div
                                    className="absolute inset-0 flex items-center"
                                    aria-hidden="true">
                                    <div className="h-0.5 w-full bg-indigo-600" />
                                  </div>
                                  <div
                                    onClick={() => {
                                      setStep(stepIdx);
                                    }}
                                    className="cursor-pointer relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                    <span className="sr-only">{step.name}</span>
                                  </div>
                                </>
                              ) : step.status === "current" ? (
                                <>
                                  <div
                                    className="absolute inset-0 flex items-center"
                                    aria-hidden="true">
                                    <div className="h-0.5 w-full bg-gray-200" />
                                  </div>
                                  <div
                                    className="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white"
                                    aria-current="step">
                                    <span
                                      className="h-2.5 w-2.5 rounded-full bg-indigo-600"
                                      aria-hidden="true"
                                    />
                                    <span className="sr-only">{step.name}</span>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className="absolute inset-0 flex items-center"
                                    aria-hidden="true">
                                    <div className="h-0.5 w-full bg-gray-200" />
                                  </div>
                                  <div className="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                                    <span
                                      className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                                      aria-hidden="true"
                                    />
                                    <span className="sr-only">{step.name}</span>
                                  </div>
                                </>
                              )}
                            </li>
                          ))}
                        </ol>
                      </nav>
                    </div>

                    {step === 0 && (
                      <>
                        <div className="pt-3">
                          <div className="flex flex-wrap gap-y-4 ">
                            {identifiers.map((identifier) => (
                              <div
                                onClick={() => {
                                  setSelectFilter(identifier.id);
                                }}
                                className={`flex flex-col items-center justify-center w-1/3 text-center
                                ${selectFilter === identifier.id ? "bg-gray-50 shadow" : "bg-white"}
                                rounded-md p-4 cursor-pointer transition-all
                                
                                `}
                                key={identifier.id}>
                                <div>{identifier.icon}</div>
                                <div>{identifier.name}</div>
                                <div className="text-sm text-gray-400">
                                  {identifier.description}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="grid grid-cols-8 items-center pt-5">
                          <div className="text-textColor text-sm mb-2 col-span-2">
                            {t("selectTemplate")}
                          </div>
                          <div className="col-span-6">
                            <Select
                              isDisabled={selectFilter === ""}
                              styles={{
                                placeholder: (provided: any) => ({
                                  ...provided,
                                  fontSize: "0.875rem",
                                }),
                                control: (provided: any) => ({
                                  ...provided,
                                  fontSize: "0.875rem",
                                }),
                              }}
                              options={data
                                ?.filter((item: any) => item.identifier === selectFilter)
                                ?.map((item: any) => ({
                                  value: item.id,
                                  label: item.name,
                                }))}
                              onChange={(e: any) => setSelectedTemplateId(e.value)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {step === 1 && (
                      <>
                        <div className="grid grid-cols-8 items-center pt-3">
                          <div className="text-sm text-gray-500 block col-span-2">
                            {t("taskName")}
                          </div>
                          <div className="col-span-6">
                            <Input
                              name=""
                              className={{
                                rounded: "rounded",
                              }}
                              placeholder={t("writeYourTaskName")}
                              type="text"
                              defaultChecked
                              onChange={(e) => {
                                setStoryName(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-8 items-center pt-5">
                          <label htmlFor="date" className="text-textColor text-sm col-span-2">
                            {t("dueDate")}
                          </label>
                          <div className="col-span-6">
                            <input
                              className="w-full text-sm appearance-none py-2 px-5 text-black leading-5 outline-none rounded border-[1px] border-borderStroke bg-white focus:border-focusStroke"
                              name="date"
                              id="task-date"
                              type="date"
                              onKeyDown={(e) => e.preventDefault()}
                              defaultValue={today.toISOString().split("T")[0]}
                              min={today.toISOString().split("T")[0]}
                              onChange={(e) => {
                                setDueDate(new Date(e.target.value).toISOString());
                              }}
                            />
                          </div>
                        </div>

                        <div className="grid grid-cols-8 pt-5">
                          <div className="text-textColor text-sm col-span-2 pt-1">{t("brief")}</div>
                          <div className="col-span-6">
                            <TextArea
                              name=""
                              placeholder={t("writeYourBrief")}
                              className="h-5"
                              onChange={(e) => {
                                setBrief(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        {" "}
                        <div className="grid grid-cols-8 items-center pt-5">
                          <div className="text-textColor text-sm col-span-2">
                            {t("selectExperts")}
                          </div>
                          <div className="col-span-6">
                            {isFetching ? (
                              <div className="flex justify-start">
                                <LoadingSpinner size="w-4 h-4" />
                              </div>
                            ) : (
                              <div className="flex justify-start gap-2 flex-col">
                                {tasks?.map((item: any) => {
                                  return (
                                    <label className="inline-flex justify-start items-center text-sm font-medium">
                                      <input
                                        type="checkbox"
                                        className="form-checkbox h-3.5 w-3.5 text-indigo-600 transition duration-150 ease-in-out "
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setSelectedTasks((prev: any) => [
                                              ...prev,
                                              {
                                                id: item.id,
                                                credit: +item.credit || 1,
                                                amount: item.default_value || 1,
                                                label: item.partner_role,
                                              },
                                            ]);
                                          } else {
                                            setSelectedTasks((prev: any) =>
                                              prev.filter((task: any) => task.id !== item.id)
                                            );
                                          }
                                        }}
                                      />
                                      <span className="ml-2 first-letter:uppercase">
                                        {item.partner_role.replace("_", " ")}
                                      </span>
                                    </label>
                                  );
                                })}
                              </div>
                            )}

                            {selectedTemplateId === 0 && (
                              <div className="text-xs text-gray-500">
                                {t("Please select a template to see the list of experts!")}
                              </div>
                            )}
                          </div>
                        </div>
                        {/* if any item step is bigger than 0 show div and selected task control */}
                        {tasks?.find((item: any) => item.step !== 0) && selectedTasks.length > 0 && (
                          <div className="pt-4">
                            {tasks?.map((item: any) => {
                              return (
                                <label className="text-sm font-medium">
                                  {item.step !== 0 &&
                                    !!selectedTasks.find((task: any) => task.id === item.id) && (
                                      <div className="flex gap-3 items-center mb-3 relative">
                                        <div className="first-letter:uppercase w-44 truncate">
                                          {item.partner_role.replace("_", " ")} ({item.title})
                                        </div>
                                        <select
                                          onChange={(e) => {
                                            setSelectedTasks((prev: any) =>
                                              prev.map((task: any) => {
                                                if (task.id === item.id) {
                                                  return {
                                                    ...task,
                                                    amount: +e.target.value,
                                                  };
                                                }
                                                return task;
                                              })
                                            );
                                          }}
                                          className="border border-gray-300 rounded w-full ml-2 outline-none pl-1 py-2">
                                          {Array.from(
                                            {
                                              length:
                                                Math.floor(
                                                  (item.max_value - item.min_value) / item.step
                                                ) + 1,
                                            },
                                            (_, i) => item.min_value + i * item.step
                                          ).map((item: any) => (
                                            <option value={item}>{item}</option>
                                          ))}
                                        </select>
                                        {/*calculate selected  credit */}
                                        <div className="text-gray-500 flex gap-1 items-center absolute right-6 text-xs">
                                          <div>
                                            {selectedTasks
                                              .filter((task: any) => task.id === item.id)
                                              .map((task: any) => {
                                                if (task.credit && task.amount) {
                                                  return task.credit * task.amount;
                                                }
                                              })}
                                          </div>

                                          <div>{t("credit")}</div>
                                        </div>
                                      </div>
                                    )}
                                </label>
                              );
                            })}
                          </div>
                        )}
                        <div className="grid grid-cols-8 pt-5 items-center">
                          <div className="text-textColor text-sm col-span-2 pt-1 flex items-center gap-2">
                            <BanknotesIcon className="w-6 h-6 text-green-500" />
                            <div className="text-textColor text-sm">{t("Total Credit")}:</div>
                          </div>
                          <div className="col-span-6">
                            {selectedTasks.reduce((acc: any, item: any) => {
                              if (item.credit && item.amount)
                                return acc + +item.credit * +item.amount;
                            }, 0)}
                          </div>
                        </div>
                        {error && (
                          <div
                            className="
                       border-red-400 text-red-700 pt-4 text-sm
                    ">
                            {error}
                          </div>
                        )}
                      </>
                    )}

                    {step === 0 && (
                      <div className="flex justify-end mt-2 pt-4">
                        <DefaultButton
                          isDisabled={selectedTemplateId ? false : true}
                          onClick={() => {
                            setStep((prev) => prev + 1);
                          }}
                          className="w-fit">
                          <div className="font-normal">{t("next")}</div>
                        </DefaultButton>
                      </div>
                    )}

                    {step === 1 && (
                      <div className="flex justify-end mt-2 pt-4">
                        <DefaultButton
                          isDisabled={storyName === "" || dueDate === "" || brief === ""}
                          onClick={() => {
                            setStep((prev) => prev + 1);
                          }}
                          className="w-fit">
                          <div className="font-normal">{t("next")}</div>
                        </DefaultButton>
                      </div>
                    )}

                    {step === 2 && (
                      <>
                        <div className="flex justify-end mt-2 pt-4">
                          <DefaultButton
                            onClick={handleSubmit}
                            isLoading={isLoadingWorkDemand}
                            isDisabled={
                              pricingPlan?.assignable_task_limit - assignedTaskCount <= 0 ||
                              selectedTasks.length >
                              pricingPlan?.assignable_task_limit - assignedTaskCount
                            }
                            className="w-fit ">
                            <div className="font-normal">{t("create")}</div>
                          </DefaultButton>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
