import { CalendarIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";

import MiddleSection from "@/features/dashboard/components/MiddleSection";
import TopSection from "@/features/dashboard/components/TopSection";

import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";

import { fetchWeeklyTasks } from "@/api/task";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { fetchProjectData } from "@/api/project";
import moment from "moment";

const MainPage = () => {
  const { projectId, isLoading: isLoadingProjectId, pricingPlan } = useFetchingProjectIds();

  const { isLoading: isLoadingProjectData, data } = useQuery(
    "fetchProjectGettingStartedDataForDashboardd",
    () => fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      cacheTime: 0,
    }
  );

  const { data: tasks, isLoading: isLoadingTasks } = useQuery<any>(
    "fetchWeeklyTasksForPreview",
    () => fetchWeeklyTasks({ projectId: projectId, is_done: false }),
    {
      retry: false,
      enabled: !!projectId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div>
      {isLoadingProjectId || isLoadingProjectData ? (
        <div className="flex h-[calc(100vh-5rem)] justify-center items-center ">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <div className="flex flex-col lg:flex-row lg:gap-6">
          {data?.data?.value?.roadmap &&
          data?.data?.value?.assigned &&
          data?.data?.value?.connectAccount ? (
            <div className="space-y-7 mb-10 flex-1 ">
              <TopSection projectId={projectId} />
              <MiddleSection projectId={projectId} pricingPlan={pricingPlan} />
            </div>
          ) : (
            <>
              {data?.data?.value?.roadmap && (
                <>
                  {isLoadingTasks ? (
                    <LoadingSpinner />
                  ) : (
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 w-full">
                      <div className="overflow-hidden rounded-lg bg-white px-4 py-4 shadow sm:p-5 mb-10 f">
                        <div className="mt-1 text-xl font-medium tracking-tight text-gray-900 mb-3 first-letter:uppercase">
                          {t("Task List")}
                        </div>
                        <div className="h-48 overflow-y-hidden bg-white  sm:rounded-md">
                          <ul className="divide-y divide-gray-200">
                            {tasks?.items.slice(0, 3).map((position: any) => {
                              return (
                                <li key={position.id}>
                                  <Link to={`/tasks`} className="block hover:bg-gray-50">
                                    <div className="flex items-center px-4 py-2 sm:px-6">
                                      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                        <div className="truncate">
                                          <div className="flex text-sm">
                                            <p className="truncate font-medium text-indigo-600">
                                              {position.story_name}
                                            </p>
                                          </div>
                                          <div className="mt-2 flex">
                                            <div className="flex items-center text-sm text-gray-500">
                                              <CalendarIcon
                                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                aria-hidden="true"
                                              />
                                              <p className="capitalize">
                                                {t("dueDate")}
                                                {": "}
                                                {position.due_date
                                                  ? moment(position.due_date).format("DD MMM YYYY")
                                                  : "-"}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                          <div className="flex -space-x-1 overflow-hidden"></div>
                                        </div>
                                      </div>
                                      <div className="ml-5 flex-shrink-0">
                                        <ChevronRightIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>

                        <div className="flex justify-end">
                          <Link
                            to={`/tasks`}
                            className="text-blue-500 text-sm font-medium mt-5 cursor-pointer">
                            {t("View All")} →
                          </Link>
                        </div>
                      </div>
                    </dl>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MainPage;
