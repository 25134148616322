import { useQuery } from "react-query";
import { fetchProjectMetrics } from "@/api/metric";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import AreaChart from "@/features/report/components/charts/AreaChart";
import { t } from "i18next";

type Props = {
  projectId: any;
};

const Graph1 = (props: Props) => {
  const { projectId } = props;

  const { data: dataFirst, isLoading: isLoadingMetrics } = useQuery(
    "fetchProjectMetrics18",
    () => fetchProjectMetrics({ projectId: projectId, metricId: "18" }),
    {
      retry: false,
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <div>
      {isLoadingMetrics ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <div className="overflow-hidden rounded-lg py-2 custom-scroll bg-white shadow overflow-x-auto relative ">
            {dataFirst?.sample && (
              <div className="absolute font-medium text-sm text-orange-500 mt-1 bg-orange-50 w-fit rounded-lg py-.5 px-2.5 right-1 top-2">
                {t("sample")}
              </div>
            )}
            <div className="pl-4 pt-4 font-medium text-xl">{dataFirst?.metric_name}</div>

            {dataFirst?.data && <AreaChart data={dataFirst?.data} height={183} />}
          </div>
        </>
      )}
    </div>
  );
};

export default Graph1;
