
interface Props {
  data: any;
}

const RuleCard = (props: Props) => {
  const { data } = props;

  return (
    <div
      className="group w-full sm:w-[344px] min-h-[210px] max-h-64  p-6 rounded-lg bg-white  transition-all cursor-pointer relative"
      key={data.id}>
      <div className="mb-2 flex flex-col gap-2 items-start w-full relative">
        {data.is_premium && (
          <div className="absolute bg-secondaryColor py-1 px-2 font-medium text-xs rounded-full right-0 text-purple-800">
            Premium
          </div>
        )}
        <div className="p-2 bg-secondaryColor rounded-md mb-2 flex justify-center items-center">
          {data.application === "google_ads" && (
            <img src="/assets/icons/google_ads.svg" alt="gas" width={24} className="mb-2" />
          )}
          {data.application === "google_analytics" && (
            <img src="/assets/icons/google_analytics.svg" alt="gas" width={24} className="mb-2" />
          )}
          {data.application === "facebook_ads" && (
            <img src="/assets/icons/facebook_ads.svg" alt="gas" width={24} className="mb-2" />
          )}
          {data.identifier === "content_suggestion" && (
            <img src="/assets/icons/robot.svg" alt="gas" width={24} className="mb-2" />
          )}
        </div>
        <div className="font-medium text-lg capitalize">{data.name}</div>
      </div>

      <div
        className="text-gray-500 text-sm first-letter:capitalize overflow-hidden "
        style={{
          WebkitLineClamp: 4,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          display: "-webkit-box",
        }}
        title={data.description}>
        <div className="peer ">{data.description}</div>
        <div className="text-gray-500 text-sm  absolute h-full pt-5 bottom-0 hover:bg-gray-50 left-0 right-0  z-20 rounded-lg p-2    hidden group-hover:block">
          {data.description}
        </div>
      </div>

      {/*   {data.is_coming_soon && (
        <div className="absolute right-4 bottom-3" title={t("comingSoon")}>
          <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      )} */}
    </div>
  );
};

export default RuleCard;
