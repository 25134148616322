
const Terms = () => {
  return (
    <div className="my-5 lg:mx-24 mx-10">
      <p>
        <span className="font-medium text-xl">Terms and Conditions</span>
        <br />
        <br />
        Please read these mikrolo terms of service (“agreement”) carefully before using the services
        offered by Mikrolo. By clicking the “agree”, “start my free trial” or “submit” (or similar)
        button on an online order form or other online purchase form on the services (an “order
        form”) or by using the services in any manner, you (“customer”) agree that you have read and
        agree to be bound by and a party to these terms of service to the exclusion of all other
        terms. You represent and warrant that you have the authority to enter into this agreement;
        if you are entering into this agreement on behalf of an organization or entity, references
        to “customer” and “you” in this agreement, except this sentence, refer to that organization
        or entity. If a separate signed written agreement with respect to the services exists
        between customer and mikrolo, the terms of that signed written agreement (excluding the
        pre-printed terms of any purchase order, confirmation or similar document, which will have
        no effect and will not be considered agreed to by mikrolo) shall take precedence over these
        terms of service, and you acknowledge that customer is bound by the terms of that signed
        written services agreement. If the terms of this agreement are considered an offer,
        acceptance is expressly limited to such terms.
        <div>1.Order Forms; Access to the Service</div>
        <div>
          Upon submission by Customer, each Order Form shall be incorporated into and form a part of
          the Agreement. Subject to Customer’s compliance with the terms and conditions of this
          Agreement (including any limitations and restrictions set forth on the applicable Order
          Form) Mikrolo grants Customer the right and license to access and use the
          software-as-a-service platform and products specified in each Order Form (collectively,
          the “Service,” or “Services”) during the applicable Order Form Term (as defined below) for
          the internal business purposes of Customer, only as provided herein.
        </div>
        <div>2.Support; Service Levels</div>
        <div>
          Mikrolo will undertake commercially reasonable efforts to make the Services available
          twenty-four (24) hours a day, seven (7) days a week. Notwithstanding the foregoing,
          Mikrolo reserves the right to suspend Customer’s access to the Services: (i) for scheduled
          or emergency maintenance, or (ii) in the event Customer is in breach of this Agreement,
          including failure to pay any amounts due to Mikrolo. Subject to the terms hereof, Mikrolo
          will use commercially reasonable efforts to provide the support services applicable to the
          Services tier purchased by Customer, from Monday through Friday during Mikrolo’s normal
          business hours. Customer acknowledges and agrees that the Services operates on or with or
          using application programming interfaces (APIs) and/or other services operated or provided
          by third parties (“Third Party Services”). Mikrolo is not responsible for the operation of
          any Third Party Services nor the availability or operation of the Services to the extent
          such availability and operation is dependent upon Third Party Services. Customer is solely
          responsible for procuring any and all rights necessary for it to access Third Party
          Services and for complying with any applicable terms or conditions thereof. Mikrolo does
          not make any representations or warranties with respect to Third Party Services or any
          third party providers. Any exchange of data or other interaction between Customer and a
          third party provider is solely between Customer and such third party provider and is
          governed by such third party’s terms and conditions.
        </div>
        <div>3. Service Updates</div>
        <div>
          The Services are subject to modification (including, without limitation, to provide new
          features, implement new protocols, maintain compatibility with emerging standards or
          comply with regulatory requirements) from time to time at Mikrolo’s sole discretion,
          provided that such modifications do not result in a material degradation of the Services.
          For clarity, from time to time, Mikrolo may provide upgrades, patches, enhancements, or
          fixes for the Services to its customers with or without additional charge (“Updates”), and
          such Updates will become part of the Services and subject to this Agreement; provided that
          Mikrolo shall have no obligation under this Agreement or otherwise to provide any such
          Updates.
        </div>
        <div>4.Ownership; Feedback</div>
        <div>
          As between the parties, Mikrolo retains all right, title, and interest in and to the
          Services, and all software, products, works, and other intellectual property and moral
          rights related thereto or created, used, or provided by Mikrolo for the purposes of this
          Agreement, including any copies and derivative works of the foregoing. No rights or
          licenses are granted except as expressly and unambiguously set forth in this Agreement.
          Customer may from time to time provide suggestions, comments or other feedback to Mikrolo
          with respect to the Service (“Feedback”). Feedback, even if designated as confidential by
          Customer, shall not create any confidentiality obligation for Mikrolo notwithstanding
          anything else. Customer shall, and hereby does, grant to Mikrolo a nonexclusive,
          worldwide, perpetual, irrevocable, transferable, sublicensable, royalty-free, fully paid
          up license to use and exploit the Feedback for any purpose. Nothing in this Agreement will
          impair Mikrolo’s right to develop, acquire, license, market, promote or distribute
          products, software or technologies that perform the same or similar functions as, or
          otherwise compete with any products, software or technologies that Customer may develop,
          produce, market, or distribute.
        </div>
        <div>5. Fees & Payment</div>
        <div>
          Paid Services Certain Mikrolo Services may be subject to payments now or in the future
          (the “Paid Services”). Please see Mikrolo’s pricing page for a description of the current
          Paid Services. Any payment terms presented to Customer in the process of using or signing
          up for a Paid Service are deemed part of this Agreement. Billing Mikrolo uses third-party
          payment processors (each a, “Payment Processor”) to bill Customer through a payment
          account linked to Customer’s account on the Services (the “Billing Account”) for use of
          the Paid Services. The processing of payments will be subject to the terms, conditions and
          privacy policies of the Payment Processor in addition to this Agreement. Mikrolo is not
          responsible for error by the Payment Processor. By choosing to use Paid Services, Customer
          agrees to pay Mikrolo, through the Payment Processor, all charges at the prices then in
          effect for any use of such Paid Services in accordance with the applicable payment terms
          and Customer authorizes Mikrolo, through the Payment Processor, to charge Customer’s
          chosen payment provider (the “Payment Method”). Customer agrees to make payment using that
          selected Payment Method. Mikrolo reserves the right to correct any errors or mistakes that
          the Payment Processor makes even if it has already requested or received payment.
          Notwithstanding anything to the contrary, Mikrolo, in its sole discretion, may make
          available to Customer alternative billing and payment methods (including, without
          limitation, invoices through iyzico.com or another service provider and the payment
          options made available therewith, wire transfers, etc.), and if Customer selects such
          option, then Customer agrees to comply with any additional terms, conditions and privacy
          policies of such alternative billing and payment methods in addition to this Agreement and
          to make payment of all due Fees using such payment options. Payment Method The terms of
          Customer’s payment will be based on its Payment Method and may be determined by agreements
          between Customer and the financial institution, credit card issuer or other provider of
          its chosen Payment Method. If Mikrolo, through the Payment Processor, does not receive
          payment from Customer, Customer agrees to pay all amounts due on its Billing Account upon
          demand. Auto-Renewal for Paid Services Any Paid Services Customer has signed up for will
          be automatically extended for successive renewal periods of the same duration as the
          subscription term originally selected, at the then-current non-promotional rate. If
          Customer terminates a Paid Service, Customer may use its subscription until the end of its
          then-current term and its subscription will not be renewed after its then-current term
          expires. However, Customer will not be eligible for a prorated refund of any portion of
          the subscription fee paid for the then-current subscription period. If Customer does not
          want to continue to be charged on a recurring monthly basis, Customer must cancel the
          applicable Paid Service through its account settings or terminate Customer’s Mikrolo
          account before the end of the recurring term. Current Information Required Customer must
          provide current, complete and accurate information for its billing account. Customer must
          promptly update all information to keep its billing account current, complete and accurate
          (such as a change in billing address, credit card number, or credit card expiration date),
          and customer must promptly notify mikrolo or our payment processor if customer’s payment
          method is canceled (e.G., for loss or theft) or if customer becomes aware of a potential
          breach of security, such as the unauthorized disclosure or use of customer’s user name or
          password. Changes to such information can be made at account settings. If customer fails
          to provide any of the foregoing information, customer agrees that mikrolo may continue
          charging customer for any use of paid services under its billing account unless it has
          terminated its paid services as set forth above. Change in Amount Authorized Mikrolo
          reserves the right to change the amount it charges for the Services. If the amount to be
          charged to Customer’s Billing Account varies from the amount Customer preauthorized (other
          than due to the imposition or change in the amount of state sales taxes), Customer has the
          right to receive, and Mikrolo shall provide, notice of the amount to be charged and the
          date of the charge before the scheduled date of the transaction. Any agreement Customer
          has with its payment provider will govern Customer’s use of its Payment Method. Customer
          agrees that Mikrolo may accumulate charges incurred and submit them as one or more
          aggregate charges during or at the end of each billing cycle. Reaffirmation of
          Authorization Customer’s non-termination or continued use of a Paid Service reaffirms that
          Mikrolo is authorized to charge the Payment Method for that Paid Service. Mikrolo may
          submit those charges for payment and Customer will be responsible for such charges. This
          does not waive Mikrolo’s right to seek payment directly from Customer. Customer’s charges
          may be payable in advance, in arrears, per usage, or as otherwise described when Customer
          initially selected to use the Paid Service.
        </div>
        <div>
          Free Trials and Other Promotions. Any free trial or other promotion that provides access
          to a Paid Service must be used within the specified time of the trial. Customer must stop
          using a Paid Service before the end of the trial period in order to avoid being charged
          for that Paid Service. If Customer cancels prior to the end of the trial period and are
          inadvertently charged for a Paid Service, please contact Mikrolo at support@/mikrolo.com.
        </div>
        <div>6.Restrictions</div>
        <div>
          Except as expressly set forth in this Agreement, Customer shall not (and shall not permit
          any third party to), directly or indirectly: (i) reverse engineer, decompile, disassemble,
          or otherwise attempt to discover the source code, object code, or underlying structure,
          ideas, or algorithms of the Service (except to the extent applicable laws specifically
          prohibit such restriction); (ii) modify, translate, or create derivative works based on
          the Service; (iii) copy, rent, lease, distribute, pledge, assign, or otherwise transfer or
          encumber rights to the Service; (iv) use the Service for the benefit of a third party; (v)
          remove or otherwise alter any proprietary notices or labels from the Service or any
          portion thereof; (vi) use the Service to build an application or product that is
          competitive with any Mikrolo product or service; (vii) interfere or attempt to interfere
          with the proper working of the Service or any activities conducted on the Service; or
          (viii) bypass any measures Mikrolo may use to prevent or restrict access to the Service
          (or other accounts, computer systems or networks connected to the Service). Customer is
          responsible for all of Customer’s activity in connection with the Service, including but
          not limited to uploading Customer Data (as defined below) onto the Service. Customer (i)
          shall use the Service in compliance with all applicable local, state, national and foreign
          laws, treaties and regulations in connection with Customer’s use of the Service (including
          those related to data privacy, international communications, export laws and the
          transmission of technical or personal data laws), and (ii) shall not use the Service in a
          manner that violates any third party intellectual property, contractual or other
          proprietary rights. Notwithstanding anything to the contrary, if Mikrolo reasonably
          believes that Customer is responsible for violating a third party’s copyright on the
          Services, Mikrolo may delete or disable such Customer content.
        </div>
        <div>7.Confidentiality; Customer Data; Publicity</div>
        <div>
          Each party (the “Receiving Party”) understands that the other party (the “Disclosing
          Party”) has disclosed or may disclose information relating to the Disclosing Party’s
          technology or business (hereinafter referred to as “Proprietary Information” of the
          Disclosing Party).
        </div>
        <div>
          The Receiving Party agrees: (i) not to divulge to any third person any such Proprietary
          Information, (ii) to give access to such Proprietary Information solely to those employees
          with a need to have access thereto for purposes of this Agreement, and (iii) to take the
          same security precautions to protect against disclosure or unauthorized use of such
          Proprietary Information that the party takes with its own proprietary information, but in
          no event will a party apply less than reasonable precautions to protect such Proprietary
          Information. The Disclosing Party agrees that the foregoing will not apply with respect to
          any information that the Receiving Party can document (a) is or becomes generally
          available to the public without any action by, or involvement of, the Receiving Party, or
          (b) was in its possession or known by it prior to receipt from the Disclosing Party, or
          (c) was rightfully disclosed to it without restriction by a third party, or (d) was
          independently developed without use of any Proprietary Information of the Disclosing
          Party. Nothing in this Agreement will prevent the Receiving Party from disclosing the
          Proprietary Information pursuant to any judicial or governmental order, provided that the
          Receiving Party gives the Disclosing Party reasonable prior notice of such disclosure to
          contest such order.
        </div>
        <div>
          For purposes of this Agreement, “Customer Data” shall mean any data, information or other
          material provided, uploaded, or submitted by Customer to the Service in the course of
          using the Service. Customer shall retain all right, title and interest in and to the
          Customer Data, including all intellectual property rights therein. Customer, not Mikrolo,
          shall have sole responsibility for the accuracy, quality, integrity, legality,
          reliability, appropriateness, and intellectual property ownership or right to use of all
          Customer Data. Mikrolo shall use commercially reasonable efforts to maintain the security
          and integrity of the Service and the Customer Data. Mikrolo is not responsible to Customer
          for unauthorized access to Customer Data or the unauthorized use of the Service unless
          such access is due to Mikrolo’s gross negligence or willful misconduct. Customer is
          responsible for the use of the Service by any person to whom Customer has given access to
          the Service, even if Customer did not authorize such use. Customer agrees and acknowledges
          that Customer Data may be irretrievably deleted if Customer’s account is ninety (90) days
          or more delinquent. For clarity, Mikrolo has no obligation to delete any Customer Data
          unless Customer requests such deletion, and if Customer requests deletion, then Mikrolo
          will use commercially reasonable efforts to destroy Customer Data then in its control.
          Notwithstanding anything to the contrary, Customer acknowledges and agrees that Mikrolo
          may (i) internally use and modify (but not disclose) Customer Data for the purposes of (A)
          providing the Services and any support or consultation services to Customer and (B)
          generating Aggregated De-Identified Data (as defined below), and (ii) freely use and make
          available Aggregated De-Identified Data for Mikrolo’s business purposes (including without
          limitation, for purposes of improving, testing, operating, promoting and marketing
          Mikrolo’s products and services). “Aggregated De-Identified Data” means data submitted to,
          collected by, or generated by Mikrolo in connection with Customer’s use of the Service,
          but only in aggregate, de-identified form which is not linked specifically to Customer or
          any individual. Notwithstanding anything else, Mikrolo is permitted to disclose (including
          through display of Customer’s logo) that Customer is one of its customers (including in
          its publicity and marketing materials).
        </div>
        <div>8. Term; Termination</div>
        <div>
          This Agreement shall commence upon the effective date set forth in the first Order Form,
          and, unless earlier terminated in accordance herewith, shall last until the expiration of
          all Order Form Terms. For each Order Form, the “Order Form Term” shall begin as of the
          effective date set forth on such Order Form, and unless earlier terminated as set forth
          herein, (x) shall continue for the initial term specified on the Order Form (the “Initial
          Order Form Term”), and (y) following the Initial Order Form Term, shall automatically
          renew for additional successive periods of thirty (30) days (each, a “Renewal Order Form
          Term”) unless either party notifies the other party of such party’s intention not to renew
          no later than thirty (30) days prior to the expiration of the Initial Order Form Term or
          then-current Renewal Order Form Term, as applicable. In the event of a material breach of
          this Agreement by either party, the non-breaching party may terminate this Agreement by
          providing written notice to the breaching party, provided that the breaching party does
          not materially cure such breach within thirty (30) days of receipt of such notice. Without
          limiting the foregoing, Mikrolo may suspend or limit Customer’s access to or use of the
          Service if (i) Customer’s account is past due, or (ii) Customer’s use of the Service
          results in (or is reasonably likely to result in) damage to or material degradation of the
          Service which interferes with Mikrolo’s ability to provide access to the Service to other
          customers; provided that in the case of subsection (ii): (a) Mikrolo shall use reasonable
          good faith efforts to work with Customer to resolve or mitigate the damage or degradation
          in order to resolve the issue without resorting to suspension or limitation; (b) prior to
          any such suspension or limitation, Mikrolo shall use commercially reasonable efforts to
          provide notice to Customer describing the nature of the damage or degradation; and (c)
          Mikrolo shall reinstate Customer’s use of or access to the Service, as applicable, if
          Customer remediates the issue within thirty (30) days of receipt of such notice. All
          provisions of this Agreement which by their nature should survive termination shall
          survive termination, including, without limitation, accrued payment obligations, ownership
          provisions, warranty disclaimers, indemnity and limitations of liability.
        </div>
        <div>9. Indemnification</div>
        <div>
          Customer will indemnify and hold harmless Mikrolo, its affiliates and each of its and its
          affiliates’ employees, contractors, directors, suppliers and representatives from all
          liabilities, claims, and expenses from a third party claim arising from or relating to
          Customer’s access to the Services, Customer’s use of the Services, Customer’s violation of
          this Agreement, or the infringement by Customer or any third party using Customer’s
          account of any intellectual property or other right of any third party.
        </div>
        <div>10. Disclaimer</div>
        <div>
          Except as expressly set forth herein, the service is provided “as is” and “as available”
          and is without warranty of any kind, express or implied, including, but not limited to,
          the implied warranties of title, non-infringement, merchantability and fitness for a
          particular purpose, and any warranties implied by any course of performance, usage of
          trade, or course of dealing, all of which are expressly disclaimed.
        </div>
        <div>11. Limitation of Liability</div>
        <div>
          In no event shall mikrolo, nor its directors, employees, agents, partners, suppliers or
          content providers, be liable under contract, tort, strict liability, negligence or any
          other legal or equitable theory with respect to the subject matter of this agreement (I)
          for any lost profits, data loss, cost of procurement of substitute goods or services, or
          special, indirect, incidental, punitive, or consequential damages of any kind whatsoever,
          (ii) for any bugs, viruses, trojan horses, or the like (regardless of the source of
          origination), or (iii) for any direct damages in excess of (in the aggregate) the fees
          paid (or payable) by customer to mikrolo hereunder in the twelve (12) months prior to the
          event giving rise to a claim hereunder.
        </div>
        <div>12. Miscellaneous</div>
        <div>
          This Agreement represents the entire agreement between Customer and Mikrolo with respect
          to the subject matter hereof, and supersedes all prior or contemporaneous communications
          and proposals (whether oral, written or electronic) between Customer and Mikrolo with
          respect thereto. All notices under this Agreement shall be in writing and shall be deemed
          to have been duly given when received, if sent by email to Customer’s registered email
          account (when Mikrolo is providing notice) or privacy@/mikrolo.com (when Customer is
          providing notice). The parties may also provide notice by certified or registered mail,
          return receipt requested to the address most recently submitted to the Services by
          Customer (when Mikrolo is providing notice) or to Mikrolo’s address at Reşitpaşa, Katar
          Cad. No:4, İTÜ Arı Teknokent Sarıyer/İstanbul. Notices to Mikrolo must be addressed to
          “Attn: Legal Notice”. Except as otherwise provided herein, this Agreement may be amended
          only by a writing executed by both parties (including, without limitation, Customer’s
          submission of an additional Order Form via the Services). Except for payment obligations,
          neither party shall be liable for any failure to perform its obligations hereunder where
          such failure results from any cause beyond such party’s reasonable control, including,
          without limitation, the elements; fire; flood; severe weather; earthquake; vandalism;
          accidents; sabotage; power failure; denial of service attacks or similar attacks; Internet
          failure; acts of God and the public enemy; acts of war; acts of terrorism; riots; civil or
          public disturbances; strikes, lock-outs or labor disruptions; any laws, orders, rules,
          regulations, acts or restraints of any government or governmental body or authority, civil
          or military, including the orders and judgments of courts. Neither party may assign any of
          its rights or obligations hereunder without the other party’s consent; provided that (i)
          either party may assign all of its rights and obligations hereunder without such consent
          to a successor-in-interest in connection with a sale of substantially all of such party’s
          business or assets relating to this Agreement, and (ii) Mikrolo may utilize subcontractors
          in the performance of its obligations hereunder. No agency, partnership, joint venture, or
          employment relationship is created as a result of this Agreement and neither party has any
          authority of any kind to bind the other in any respect. In any action or proceeding to
          enforce rights under this Agreement, the prevailing party shall be entitled to recover
          costs and attorneys’ fees. If any provision of this Agreement is held to be unenforceable
          for any reason, such provision shall be reformed only to the extent necessary to make it
          enforceable. The failure of either party to act with respect to a breach of this Agreement
          by the other party shall not constitute a waiver and shall not limit such party’s rights
          with respect to such breach or any subsequent breaches.
        </div>
      </p>
    </div>
  );
};

export default Terms;
