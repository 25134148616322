import { getAnalyticsReport } from "@/api/eAnl";
import { useEffect, useState } from "react";
import SimpleLineChart from "../../components/charts/SimpleLineChart";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import ContentCard from "../../components/cards/ContentCard";
import { BarChartColors, LineGraphColors, PieChartColors } from "@/common/Colors";
import CustomActiveShapePieChart from "../../components/charts/CustomActiveShapePieChart";
import ReportTable from "../../components/cards/ReportTable";
import { TableRowProps } from "@/common/TableRowProps";
import SimpleBarChart from "../../components/charts/SimpleBarChart";
import DatePicker from "react-datepicker";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import SmallCardChart from "../../components/charts/SmallCardChart";

type Props = {
    reportId: any;
};

const AnalyticsReport = (props: Props) => {
    const [dataFromApi, setData] = useState<any>(null);
    const [isLoading, setLoading] = useState<boolean>(true);
    const projectIdString = useFetchingProjectIds().projectId;
    const today = new Date();
    const lastweek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    const [dateRange, setDateRange] = useState([lastweek, today]);
    const [startDate, endDate] = dateRange;

    const [refreshCount, setRefreshCount] = useState(0);

    const handleRefresh = () => {
        setRefreshCount(prevCount => prevCount + 1);  // Trigger a change in refreshCount, which will re-run the useEffect
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                if (projectIdString === null) {
                    return <LoadingSpinner />;
                }
                const result = await getAnalyticsReport(
                    parseInt(projectIdString), props.reportId, startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10));
                setData(result?.data);
                setLoading(false);
            } catch (error: any) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [refreshCount]);

    const CreateRows = (data: any) => {
        const rowProps: TableRowProps[] = data.Metrics.map((metric: any) => ({
            className: "even:bg-gray-50",
            akey: data.ReportIdentifier,
            cells: data.Holders.map((holder: any) => ({
                className: holder.DataType === 1 || holder.DataType === 2 ? "whitespace-nowrap p-2 text-sm text-gray-500 text-right" : "whitespace-nowrap p-2 text-sm text-gray-500",
                text: metric[holder.Key],
            })),
        }));

        return rowProps;
    };

    return isLoading ? <LoadingSpinner /> : (
        <div>
            <div className="text-right pb-4">
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    onChange={(update: [Date, Date]) => {
                        setDateRange(update);
                        update[1] !== null && handleRefresh();
                    }}
                    isClearable={true}
                />
            </div>
            <div key="ilkDiv" className="w-full space-y-4 pb-4">
                {dataFromApi.Report.Metrics.length > 0 &&
                    dataFromApi.Report.Metrics.map((data: any) => (
                        data.MetricType === "LINE_GRAPH" && data.Metrics.length > 0 ? (
                            <div className="w-full" key={data.Title + "LineGraph"}>
                                <ContentCard
                                    reportDetail='{"sample":false}'
                                    title={data.Title}
                                    description={data.Description || ""}
                                    size="w-full">
                                    <div className="w-full" key={data.ReportIdentifier + "SimpleLineChart"}>
                                        <SimpleLineChart
                                            languageCode={navigator?.languages?.[0]}
                                            key={data.ReportIdentifier}
                                            data={data.Metrics}
                                            dataKey="Label"
                                            width="100%"
                                            height={300}
                                            lineProps={null !== data.Holders ? data.Holders.map((holder: any, index: number) => (
                                                {
                                                    "dataKey": holder.Key,
                                                    "color": LineGraphColors[index],
                                                    "name": holder.Value
                                                }
                                            )) : null} />
                                    </div>
                                </ContentCard>
                            </div>
                        ) : data.MetricType === "PIE_CHART" && data.Metrics.length > 0 ? (
                            <div className="w-full" key={data.Title + "PieChart"}>
                                <ContentCard
                                    height="max-h-screen"
                                    reportDetail='{"sample":false}'
                                    title={data.Title}
                                    description={data.Description || ""}
                                    size="w-full">
                                    <div className="w-full" key={data.ReportIdentifier + "CustomActiveShapePieChart"}>
                                        <CustomActiveShapePieChart
                                            key={data.ReportIdentifier}
                                            data={data.Metrics.map((metric: any, index: number) => (
                                                {
                                                    "name": metric.Label,
                                                    "value": metric.Value,
                                                    "fill": PieChartColors[index]
                                                }
                                            ))
                                            }
                                            dataKey="value"
                                            width={900}
                                            height={500}
                                            cy={200}
                                            innerRadius={100}
                                            outerRadius={140}
                                        />
                                    </div>
                                </ContentCard>
                            </div>
                        ) : data.MetricType === "BAR_CHART" && data.Metrics.length > 0 ? (
                            <ContentCard
                                height="max-h-screen"
                                reportDetail='{"sample":false}'
                                title={data.Title}
                                description={data.Description || ""}
                                size="w-full">
                                <SimpleBarChart
                                    data={data.Metrics}
                                    dataKey={
                                        data.Holders.find((holder: any) => holder.IsKey === true).Key
                                    }
                                    barProps={null !== data.Holders ? data.Holders.map((holder: any, index: number) => (
                                        {
                                            "isKey": holder.IsKey,
                                            "dataKey": holder.Key,
                                            "color": BarChartColors[index],
                                            "name": holder.Value
                                        }
                                    )) : null}
                                />
                            </ContentCard>
                            // ) : data.MetricType === "SINGLE_VALUE" ? (
                            //     <div>
                            //         SINGLE_VALUE
                            //     </div>
                        ) : data.MetricType === "TABLE" && data.Metrics.length > 0 ? (
                            <ReportTable
                                title={data.Title.toUpperCase()}
                                description={data.Description}
                                tableHeader={data.Holders.map((holder: any) => (
                                    {
                                        "text": holder.Value,
                                        "align": holder.DataType === 1 || holder.DataType === 2 ? "text-right" : "text-left",
                                    }
                                ))}
                                akey={data.ReportIdentifier}
                                rows={CreateRows(data)}
                                maxHeight="max-h-[450px]"
                            ></ReportTable>
                        ) : data.MetricType === "SINGLE_VALUE" && data.Metrics.length > 0 ? (
                            <div>{
                                <SmallCardChart
                                    metrics={data.Metrics}
                                />
                            }</div>
                        ) : <div>
                            <p>No metric found for {data.Title}</p>
                        </div>
                    ))}
            </div>
        </div>
    )
};

export default AnalyticsReport;
