import React, { useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import { t } from "i18next";
import ContentBar from "@/components/ContentBar";
import { Calendar } from "react-calendar";
import { fetchContentCalendar } from "@/api/schedule";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { AiFillFacebook, AiFillLinkedin, AiOutlineInstagram } from "react-icons/ai";
import ContentCalendarPreviewModal from "@/features/content-calendar/components/PreviewModal";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import ToastNotify from "@/components/UI/ToastNotify";
import { Link } from "react-router-dom";

// type Props = {};

// colors as platforms
const platforms: any = {
  facebook: { color: "#dbeafe", icon: <AiFillFacebook size={25} color="#0f5ad1" /> },
  instagram: { color: "#ffe4e6", icon: <AiOutlineInstagram size={25} color="#f81c3d" /> },
  linkedin: { color: "#dbeafe", icon: <AiFillLinkedin size={25} color="#1169f7" /> },
  google_analytics: { color: "#fbbf24" },
  google_ads: { color: "#fbbf24" },
  facebook_ads: { color: "#fbbf24" },
  twitter: { color: "#fbbf24" },
  wordpress: { color: "#fbbf24" },
};

const ContentCalendar = () => {
  const [value, onChange] = useState(new Date());
  const [project] = useRecoilState(projectInfo);
  const [selectedStory, setSelectedStory] = useState<any>(null);
  const [open, setOpen] = useState(false);

  const {
    data: contentCalendar,
    isLoading,
    isError,
  } = useQuery("contentCalendar", () => fetchContentCalendar(project.id), {
    refetchOnWindowFocus: false,
  });

  return (
    <AssessmentLayout>
      <ContentBar title={t("contentCalendar")} />
      {isLoading ? (
        <div className="flex h-[calc(100vh-5rem)] justify-center items-center ">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <>
          <div className="mt-5 flex flex-col sm:flex-row gap-5">
            <div>
              <Calendar onChange={onChange} value={value} />
            </div>
            {isError ? (
              <div className="flex items-center w-full h-full">
                <p className="text-gray-500 text-sm text-center bg-white rounded p-4">
                  <div>
                    {t(
                      "There is no event for this project. If you want to add an event, please click the button below and go to the task page. You can schedule your tasks from there."
                    )}
                  </div>
                  <div className="mt-2">
                    <Link
                      className="text-blue-500 text-base underline underline-offset-2 hover:text-blue-600"
                      to="/tasks">
                      {t("Add Event")}
                    </Link>
                  </div>
                </p>
              </div>
            ) : (
              <>
                <div className="w-full">
                  <div className="px-3 pb-3 border-b-2 mb-2">
                    <div className="text-lg font-medium">{t("Todays Files")}</div>
                    <div className="text-sm text-gray-500">
                      {value.getDate()} {value.toLocaleString("default", { month: "long" })}
                    </div>
                  </div>

                  <div className="divide-y divide-gray-200 w-full flex gap-3 flex-col max-h-48 overflow-auto">
                    {contentCalendar
                      ?.filter(
                        (c: any) =>
                          new Date(c.event_date).toDateString() ===
                          new Date(
                            value.getFullYear(),
                            value.getMonth(),
                            value.getDate()
                          ).toDateString()
                      )
                      ?.map((p: any, index: any) => (
                        <li
                          onClick={() => {
                            setSelectedStory(p);
                            setOpen(true);
                          }}
                          key={index}
                          className="flex bg-white w-full rounded-lg border-dashed border hover:shadow cursor-pointer">
                          <div
                            style={{
                              backgroundColor: platforms[p.event_platform]?.color || "#9ca3af",
                            }}
                            className="w-12 flex items-center justify-center">
                            {platforms[p.event_platform]?.icon}
                          </div>
                          <div className="mx-3 py-4 flex justify-between w-full">
                            <div>
                              <p className="text-sm font-medium text-gray-900">{p.title}</p>
                              <p className="text-sm text-gray-500">{p.email}</p>
                            </div>
                            <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                          </div>
                        </li>
                      ))}

                    {contentCalendar?.filter(
                      (c: any) =>
                        new Date(c.event_date).toDateString() ===
                        new Date(
                          value.getFullYear(),
                          value.getMonth(),
                          value.getDate()
                        ).toDateString()
                    ).length === 0 && (
                        <div className="flex justify-center items-center w-full h-full">
                          <p className="text-gray-500 text-sm">{t("No content found")}</p>
                        </div>
                      )}
                  </div>
                </div>
              </>
            )}
          </div>

          {isError ? null : (
            <div className="mt-5">
              <div className="px-3 pb-3 border-b-2 mb-2">
                <div className="text-lg font-medium first-letter:uppercase">{t("This Month")}</div>
                <div className="text-sm text-gray-500">
                  {value.toLocaleString("default", { month: "long" })}
                </div>
              </div>
              <div className="divide-y divide-gray-200 w-full flex gap-3 flex-col max-h-[500px] overflow-auto mb-5">
                {contentCalendar
                  ?.filter(
                    (c: any) =>
                      new Date(c.event_date).getMonth() === value.getMonth() &&
                      new Date(c.event_date).getFullYear() === value.getFullYear()
                  )
                  ?.map((p: any, index: any) => (
                    <li
                      onClick={() => {
                        setSelectedStory(p);
                        setOpen(true);
                      }}
                      key={index}
                      className="flex bg-white w-full rounded-lg border-dashed border hover:shadow cursor-pointer">
                      <div
                        style={{
                          backgroundColor: platforms[p.event_platform]?.color || "#9ca3af",
                        }}
                        className="w-12 flex items-center justify-center">
                        {platforms[p.event_platform]?.icon}
                      </div>
                      <div className="mx-3 py-4 flex justify-between w-full">
                        <div>
                          <p className="text-sm font-medium text-gray-900">{p.title}</p>
                          <p className="text-sm text-gray-500">{p.email}</p>
                        </div>
                        <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                      </div>
                    </li>
                  ))}

                {contentCalendar?.filter(
                  (c: any) =>
                    new Date(c.event_date).getMonth() === value.getMonth() &&
                    new Date(c.event_date).getFullYear() === value.getFullYear()
                ).length === 0 && (
                    <div className="flex justify-center items-center w-full h-full">
                      <p className="text-gray-500 text-sm">{t("No content found")}</p>
                    </div>
                  )}
              </div>
            </div>
          )}

          {selectedStory && open && (
            <ContentCalendarPreviewModal
              open={open}
              setOpen={setOpen}
              selectedStory={selectedStory}
            />
          )}
          <ToastNotify />
        </>
      )}
    </AssessmentLayout>
  );
};

export default ContentCalendar;
