import mixpanel from "mixpanel-browser";
// import { removeToken } from "./token";

export const handleLogout = () => {
  mixpanel.reset();
  // removeToken();
  // localStorage.removeItem("stepper");
  // localStorage.removeItem("stepper.objectives");
  // localStorage.removeItem("contentCalendar");
  localStorage.clear();

  window.location.href = "/login";
};

