import { useQuery } from "react-query";
import { authMe } from "@/api/auth";
import { getToken, removeToken } from "@/utils/token";

import LoadingSpinner from "@/components/UI/LoadingSpinner";

import { userAtom } from "@/store/atoms/user.atoms";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchProjectData } from "@/api/project";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import ErrorBoundary from "../ErrorBoundary";

const PrivateRoute = () => {
  const location = useLocation();
  const token = getToken();
  const [, setUser] = useRecoilState(userAtom);
  const [, setProjectInfo] = useRecoilState(projectInfo);
  const navigate = useNavigate();
  // console.log("Burası Çalışıyor")
  const { isLoading, data, isError, status } = useQuery<any, Error>("authMe", authMe, {
    retry: false,
    refetchOnWindowFocus: false,
  });

  const {
    projectId,
    pricingPlan,
    isLoading: isLoadingProjectId,
    isSuccess,
    hasAssignTask,
    totalCredit,
    totalUsedCredit,
  } = useFetchingProjectIds();

  const {
    isLoading: isLoadingProjectData,
    isError: isErrorOnboarding,
    isFetched,
  } = useQuery(
    "fetchProjectOnboardingData",
    () => fetchProjectData({ projectId: projectId, name: "ONBOARDING" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
    }
  );

  if (isError) {
    removeToken();
    window.location.href = "/login";
  }

  useEffect(() => {
    const pathMap = new Map();
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    if (getPaths) {
      pathMap.set("prevPath", getPaths.currentPath || location.pathname);
      pathMap.set("currentPath", location.pathname);

      const paths = Array.from(pathMap).reduce((obj: any, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

      localStorage.setItem("paths", JSON.stringify(paths));
    } else {
      pathMap.set("prevPath", location.pathname);
      pathMap.set("currentPath", location.pathname);

      const paths = Array.from(pathMap).reduce((obj: any, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

      localStorage.setItem("paths", JSON.stringify(paths));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isSuccess) {
      setProjectInfo({
        id: projectId,
        pricingPlan: pricingPlan,
        hasAssignTask: hasAssignTask,
        totalCredit,
        totalUsedCredit,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (status === "success") {
      if (data && data.account_id) {
        setUser(data);
      } else {
        removeToken();
        window.location.href = "/login";
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (!token) {
    window.location.href = "/login";
  }

  useEffect(() => {
    if (isFetched) {
      if (
        isErrorOnboarding &&
        location.pathname !== "/onboarding" &&
        location.pathname !== "/oauth2callback" &&
        location.pathname !== "/google-ads/properties" &&
        location.pathname !== "/google-analytics/properties"
      ) {
        navigate("/onboarding", { replace: true });
      }
    }
  }, [isFetched]);

  return isLoading || isLoadingProjectId || isLoadingProjectData || !isFetched ? (
    <div className="bg-white h-screen flex justify-center items-center">
      <LoadingSpinner />
    </div>
  ) : (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
};

export default PrivateRoute;
