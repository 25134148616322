import React, { useCallback, useState } from "react";
import { PieChart, Pie, Sector, Legend, ResponsiveContainer } from "recharts";

type CustomActiveShapePieChartProps = {
    data: any;
    dataKey: string;
    width?: number;
    height?: number;
    cx?: number;
    cy?: number;
    innerRadius?: number;
    outerRadius?: number;
}

const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
            >{`${payload.name}: ${value}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
            >
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

export default function CustomActiveShapePieChart(props: CustomActiveShapePieChartProps) {
    const [activeIndex, setActiveIndex] = useState(0);
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    return (
        <ResponsiveContainer width={"100%"} height={props.height || 300}>
            <PieChart width={props.width} height={props.height}>
                <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={props.data}
                    cy={props.cy ? props.cy : 200} // Center Y coordinate
                    innerRadius={props.innerRadius}
                    outerRadius={props.outerRadius}
                    fill="#8884d8"
                    dataKey={props.dataKey}
                    onMouseEnter={onPieEnter}
                />
                <Legend
                    wrapperStyle={{ color: '#333333', backgroundColor: '#f5f5f5', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px' }}
                    formatter={(value) => <span className="text-black">{value}</span>}
                />
            </PieChart>
        </ResponsiveContainer>
    );
}
