import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { buyingPriceEvent } from "@/analytics/mixpanel";

const PaymentErrorPage = () => {
  const [user] = useRecoilState(userAtom);
  const { isLoading: isLoadingProjectId, pricingPlan, isSuccess } = useFetchingProjectIds();

  useEffect(() => {
    if (isSuccess) {
      buyingPriceEvent({
        email: user?.email || "",
        userId: user?.id.toString() || "",
        pricingPlanId: pricingPlan?.id || "",
        status: "failed",
      });
    }
  }, [isSuccess]);

  return (
    <>
      {isLoadingProjectId ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <div className=" h-screen flex justify-center items-center text-lg flex-col gap-2">
          <div className="p-3 bg-red-200 rounded-full">
            <ExclamationTriangleIcon
              fill="transparent"
              className="h-5 w-5 flex-shrink-0 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="text-lg font-medium mt-2">Payment failed</div>
          <div className="text-gray-500 text-sm max-w-sm text-center ">
            Your payment has been failed. Please try again.
          </div>
          <DefaultButton className="w-fit">
            <Link to="/profile/plans">Go to plans</Link>
          </DefaultButton>
        </div>
      )}
    </>
  );
};

export default PaymentErrorPage;
