import {
  CheckCircleIcon,
  ClipboardDocumentCheckIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { t } from "i18next";
import React, { useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useQueries } from "react-query";
import { fetchProjectMainTasks } from "@/api/task";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import ToastNotify from "@/components/UI/ToastNotify";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { taskNavbar } from "@/store/atoms/taskNavbar.atoms";

const TaskCardLayout = () => {
  const [navbarFetch] = useRecoilState(taskNavbar);
  const [project] = useRecoilState(projectInfo);
  const location = useLocation();
  const [activeCard, setActiveCard] = React.useState("inProgress");

  useEffect(() => {
    if (location.pathname === "/tasks") {
      setActiveCard("inProgress");
    } else if (location.pathname.includes("assigned")) {
      setActiveCard("assigned");
    } else if (location.pathname.includes("finished")) {
      setActiveCard("finished");
    }
  }, [location.pathname]);

  const data = useQueries([
    {
      queryKey: ["fetchInProgressTasks"],
      queryFn: () =>
        fetchProjectMainTasks({
          project_id: project.id,
          status: "inprogress",
        }),
      retry: false,
      enabled: !!project.id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    },
    {
      queryKey: ["fetchFinishedTasks"],
      queryFn: () =>
        fetchProjectMainTasks({
          project_id: project.id,
          status: "finished",
        }),
      retry: false,
      enabled: !!project.id,
      cacheTime: 0,
    },
  ]);

  useEffect(() => {
    data[0].refetch();
    data[1].refetch();
  }, [navbarFetch]);

  return (
    <AssessmentLayout>
      {data[0].isLoading || data[1].isLoading ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <ContentBar title={t("tasks")} />

          <div className="flex mb-8 my-4 gap-6 flex-col md:flex-row">
            <Link
              to="/tasks"
              className={`w-full rounded-lg overflow-hidden cursor-pointer ${
                activeCard === "inProgress" ? "bg-yellow-50  shadow-lg" : "bg-white shadow"
              } `}>
              <div className="flex items-center gap-5 p-4">
                <div className="bg-[#FCD34D] p-2 rounded-lg w-fit">
                  <CheckCircleIcon className="w-6 h-6 text-white mx-auto" />
                </div>
                <div>
                  <div className="text-sm text-[#6B7280]">{t("inProgress")}</div>
                  <div className="text-xl font-semibold">
                    {data[0]?.data?.total_count} {t("tasks_plural")}
                  </div>
                </div>
              </div>
              <div
                className={`${
                  activeCard === "inProgress" ? "bg-yellow-50" : "bg-white "
                }  py-2 px-4`}>
                <div className="text-[#4F46E5] text-sm cursor-pointer">{t("viewAll")}</div>
              </div>
            </Link>
            <Link
              to="/tasks/assigned"
              className={`w-full rounded-lg overflow-hidden cursor-pointer ${
                activeCard === "assigned" ? "bg-rose-50  shadow-lg" : "bg-white shadow"
              } `}>
              <div className="flex items-center gap-5 p-4">
                <div className="bg-rose-400 p-2 rounded-lg w-fit">
                  <UserGroupIcon className="w-6 h-6 text-white mx-auto" />
                </div>
                <div>
                  <div className="text-sm text-[#6B7280]">{t("assigned")}</div>
                  <div className="text-xl font-semibold">
                    {
                      data[0]?.data?.items?.filter((item: any) =>
                        item.tasks.find((task: any) => task.partner_first_name)
                      ).length
                    }{" "}
                    {t("tasks_plural")}
                  </div>
                </div>
              </div>
              <div
                className={`${activeCard === "assigned" ? "bg-rose-50" : "bg-white "}  py-2 px-4`}>
                <div className="text-[#4F46E5] text-sm cursor-pointer">{t("viewAll")}</div>
              </div>
            </Link>
            <Link
              to="/tasks/finished"
              className={`w-full rounded-lg overflow-hidden cursor-pointer ${
                activeCard === "finished" ? "bg-green-50  shadow-lg" : "bg-white shadow"
              } `}>
              <div className="flex items-center gap-5 p-4">
                <div className="bg-[#34D399] p-2 rounded-lg w-fit">
                  <ClipboardDocumentCheckIcon className="w-6 h-6 text-white mx-auto" />
                </div>
                <div>
                  <div className="text-sm text-[#6B7280]">{t("finished")}</div>
                  <div className="text-xl font-semibold">
                    {data[1]?.data?.total_count}
                    {` `}
                    {t("tasks_plural")}
                  </div>
                </div>
              </div>
              <div
                className={`${activeCard === "finished" ? "bg-green-50" : "bg-white "}  py-2 px-4`}>
                <div className="text-[#4F46E5] text-sm cursor-pointer">{t("viewAll")}</div>
              </div>
            </Link>
          </div>

          <Outlet />

          <ToastNotify />
        </>
      )}
    </AssessmentLayout>
  );
};

export default TaskCardLayout;
