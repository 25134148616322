import IdenticalTranslator from './identical-translator';
import CacheTranslator from './cache-translator';
import GoogleTranslator from './google-translator';

export default class TranslatorFactory {
  static create(options, cacheProvider) {

    if (this.isToLanguageIdenticalWithFrom(options.to, options.from)) {
      return new IdenticalTranslator(options);
    }

    if (this.isCachable(cacheProvider)) {
      return new CacheTranslator(options, cacheProvider);
    }

    return new GoogleTranslator(options);
  }

  static isToLanguageIdenticalWithFrom = (to, from) => {
    return to === from;
  };

  static isCachable = cacheProvider => {
    return !!cacheProvider;
  };
}
