import i18next, { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";

import { useMutation, useQuery } from "react-query";
import { fetchPartnerTaskTimeline, postPartnerTaskTimeline } from "@/api/partner";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

import { putStoryTask } from "@/api/stories";

import { taskCompleteEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";

import { Gallery, Item } from "react-photoswipe-gallery";
import "photoswipe/dist/photoswipe.css";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";

type Props = {
  selectUser: any;
  refetchUserList: any;
};

const ChatScreen = (props: Props) => {
  const [user] = useRecoilState(userAtom);

  const { selectUser } = props;
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const [files, setFiles] = useState<any>();
  const [comment, setComment] = useState("");
  const inputRef = React.useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const {
    data: chat,
    isLoading,
    refetch,
  } = useQuery("fetchPartnerTaskTimeline", () => fetchPartnerTaskTimeline(selectUser.id), {
    enabled: !!selectUser.id,
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: 5000,
    cacheTime: 0,
  });

  useEffect(() => {
    if (selectUser.id) {
      setLoading(true);
      refetch()
        .then(() => { })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectUser.id]);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const { mutateAsync: mutateAsyncMessage, isLoading: isLoadingPostTimeline } = useMutation(
    "postPartnerTaskTimeline",
    postPartnerTaskTimeline,
    {
      retry: false,
    }
  );

  const handleSendCommment = async () => {
    if (!comment) {
      if (!files || files.length === 0) {
        return;
      } else {
        setComment("");
        mutateAsyncMessage({ partner_task_id: selectUser.id, message: comment, files: files })
          .then(() => {
            setFiles([]);
            refetch();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setComment("");
      mutateAsyncMessage({ partner_task_id: selectUser.id, message: comment, files: files })
        .then(() => {
          setComment("");
          setFiles([]);
          refetch();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const { isLoading: isLoadingFinish, mutateAsync } = useMutation(putStoryTask, {
    retry: false,
    onSuccess: () => { },
  });

  const handleFinishTask = async () => {
    mutateAsync({
      taskId: selectUser.task_id,
      projectStoryId: selectUser.project_story_id,
    })
      .then(() => {
        const items = JSON.parse(localStorage.getItem("userList.items") as any);

        const item = items.find((item: any) => item.id === selectUser?.id);
        item.status = "finished";

        localStorage.setItem("userList.items", JSON.stringify(items));
      })
      .then(() => {
        const getPaths = JSON.parse(localStorage.getItem("paths") as any);
        taskCompleteEvent({
          taskId: selectUser.task_id,
          taskName: selectUser.story_name + " " + selectUser.task_name || "",
          previousPage: getPaths?.prevPath || "",
          assignTask: true,
          email: user?.email || "",
          userId: user?.id.toString() || "",
          taskCompleteDone: true,
        });
      })
      .finally(() => {
        refetch();
      });
  };

  const handleImage = (e: any) => {
    const files = e.target.files;
    const filesArr = Array.prototype.slice.call(files);
    // add set files and keep the previous files
    setFiles([...filesArr]);
  };

  const handleRemoveImage = (f: any) => {
    const newFiles = files.filter((file: any) => file !== f);
    setFiles(newFiles);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && event.shiftKey === false) {
        event.preventDefault();
        handleSendCommment();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment, files]);

  const [lastWorkMessage, setLastWorkMessage] = useState<any>();

  useEffect(() => {
    if (chat?.length > 0) {
      const lastWorkMessage = chat?.find((item: any) => item?.content?.type === "work");
      setLastWorkMessage(lastWorkMessage);
    }
  }, [chat]);

  return (
    <div
      className="flex flex-col  rounded-lg p-4  h-full bg-white"
    /*  style={{ minHeight: "calc(100vh - 140px)" }} */
    >
      {selectUser ? (
        <>
          {isLoading || loading ? (
            <LoadingSpinner size="w-5 h-5" />
          ) : (
            <div className="h-full ">
              <div className="flex items-center border-b pb-2 justify-between ">
                <div className="flex items-center">
                  <img
                    className="h-12 w-12 rounded-full"
                    src={selectUser?.partner_avatar ?? "/assets/icons/placeholder.png"}
                    alt=""
                  />
                  <div className="flex flex-col">
                    <span className="ml-2 font-medium text-gray-900">{selectUser.first_name}</span>
                    <span className="ml-2 text-gray-600 text-sm">{selectUser.story_name}</span>
                    <span className="ml-2 text-gray-600 text-xs">{selectUser.task_name}</span>
                  </div>
                </div>

                {/*   <div>
                  {selectUser.status === "finished" ||
                  JSON.parse(localStorage.getItem("userList.items") as any).find(
                    (item: any) => item.id === selectUser?.id
                  ).status === "finished" ? (
                    <button className="bg-gray-200 text-gray-600 text-sm font-semibold px-4 py-2 rounded-lg mr-2">
                      {t("finished")}
                    </button>
                  ) : (
                    <WhiteButton
                      className="w-fit"
                      onClick={() => handleFinishTask()}
                      isLoading={isLoadingFinish}>
                      {t("finishTask")}
                    </WhiteButton>
                  )}
                </div> */}
              </div>

              <div
                className="flex-1 py-3 overflow-auto mb-2 flex flex-col-reverse"
                style={{
                  height: "calc(100vh - 300px)",
                }}>
                {chat.map((item: any, index: any) => {
                  return (
                    <div
                      key={index}
                      className={`flex gap-2 mr-2 text-gray-600 text-sm ${item.content.type.toLowerCase() === "system" ||
                        item.content.type.toLowerCase() === "notification"
                        ? "justify-center italic  "
                        : item.content.type.toLowerCase() === "revised"
                          ? "justify-end"
                          : "justify-start"
                        }`}>
                      <div
                        className={`max-w-[75%] ${item.content.type.toLowerCase() === "revised" ? "" : ""
                          }`}>
                        <div
                          className={`flex text-sm items-center gap-2  ${item.content.type.toLowerCase() === "system" ||
                            item.content.type.toLowerCase() === "notification"
                            ? "hidden"
                            : item.content.type.toLowerCase() === "revised"
                              ? "justify-end"
                              : "justify-start"
                            }  `}></div>
                        <div
                          className={`py-2 px-4 rounded-md mt-4   ${item.content.type.toLowerCase() === "system" ||
                            item.content.type.toLowerCase() === "notification"
                            ? "bg-white   shadow font-medium text-base"
                            : item.content.type.toLowerCase() === "revised"
                              ? "bg-gray-100"
                              : "bg-green-100 "
                            }`}>
                          <div>
                            {item.content.type.toLowerCase() === "notification" ||
                              item.content.type.toLowerCase() === "auto_message" ? (
                              i18next.language === "tr" ? (
                                <>
                                  {item.content.user_message
                                    ? item.content.user_message
                                    : item.content.message}
                                </>
                              ) : (
                                <>
                                  {item.content.user_message
                                    ? item.content.user_message
                                    : item.content.message}
                                </>
                              )
                            ) : (
                              <>
                                {item.content.user_message
                                  ? item.content.user_message
                                  : item.content.message}
                              </>
                            )}
                          </div>
                          <div className="flex w-full justify-end text-[10px] mt-1">
                            {new Date(item.create_date.slice(0, -1)).toLocaleTimeString("tr-TR", {
                              hour: "2-digit",
                              minute: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                              year: "numeric",
                            })}
                          </div>
                        </div>
                        {item.content.files?.map((file: any, index: any) => {
                          if (
                            file.file_type === "image/png" ||
                            file.file_type === "image/jpeg" ||
                            file.file_type === "image/jpg"
                          ) {
                            return (
                              <div key={index} className="text-blue-500 flex gap-2 p-1 ">
                                <Gallery withDownloadButton>
                                  <Item
                                    original={`${file?.file_url || ""}`}
                                    thumbnail={`${file?.file_url || ""}`}
                                    width="1600"
                                    height="1066">
                                    {({ ref, open }: any) => (
                                      <img
                                        width={120}
                                        ref={ref}
                                        onClick={open}
                                        src={`${file?.file_url || ""}`}
                                        alt=""
                                      />
                                    )}
                                  </Item>
                                </Gallery>
                              </div>
                            );
                          } else if (file.file_type === "application/pdf") {
                            return (
                              <div key={index} className="text-blue-500 flex gap-2 p-1">
                                <a
                                  href={`${file.file_url}`}
                                  target="_blank"
                                  className="flex justify-center items-center">
                                  <img src="/assets/icons/pdf-repo.svg" alt="pdf" width={40} />
                                  {file.file_name}
                                </a>
                              </div>
                            );
                          } else
                            return (
                              <div key={index} className="text-blue-500 flex gap-2">
                                <a
                                  href={`${file.file_url}`}
                                  target="_blank"
                                  className="flex justify-center items-center">
                                  <img src="/assets/icons/file-extension.png" alt="" width={40} />
                                  {file.file_name}
                                </a>
                              </div>
                            );
                        })}
                        <div
                          className={`flex text-xs mr-2 mt-0.5  ${item.content.type.toLowerCase() === "system" ||
                            item.content.type.toLowerCase() === "notification"
                            ? "hidden"
                            : item.content.type.toLowerCase() === "revised"
                              ? "justify-end"
                              : "justify-start"
                            } `}></div>
                      </div>
                      {/*
                      Find the last content.type === "work" and show 
                      */}
                      {lastWorkMessage?.id === item.id &&
                        item.content.type.toLowerCase() === "work" && (
                          <div className="flex justify-center items-center ">
                            <div className="flex flex-wrap items-center gap-2 border-y py-5">
                              <div>
                                <div className="font-medium text-gray-900">
                                  {t("Did you like the work?")}
                                </div>
                                <div className="text-gray-500 text-sm mt-1">
                                  {t("If you like the work, please click the 'Accept' button.")}
                                </div>
                              </div>

                              <div>
                                {selectUser.status === "finished" ||
                                  JSON.parse(localStorage.getItem("userList.items") as any).find(
                                    (item: any) => item.id === selectUser?.id
                                  ).status === "finished" ? (
                                  <button className="bg-gray-200 text-gray-600 text-sm font-semibold px-4 py-2 rounded-lg mr-2">
                                    {t("accepted")}
                                  </button>
                                ) : (
                                  <DefaultButton
                                    isLoading={isLoadingFinish}
                                    onClick={() => handleFinishTask()}
                                    className="w-fit">
                                    {t("accept")}
                                  </DefaultButton>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  );
                })}
              </div>
              <div ref={messagesEndRef}></div>

              <div>
                <div className="w-full border-t pb-4">
                  <div className="flex w-full border ">
                    <textarea
                      disabled={
                        selectUser.status === "finished" ||
                        JSON.parse(localStorage.getItem("userList.items") as any).find(
                          (item: any) => item.id === selectUser?.id
                        ).status === "finished"
                      }
                      rows={1}
                      ref={inputRef}
                      value={comment}
                      id="website-admin"
                      className="resize-none  outline-none active:border-secondBackground rounded-lg   text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                      placeholder={t("writeComment")}
                      onChange={(e) => setComment(e.target.value)}
                    />

                    <label className="custom-file-upload mt-1">
                      <input
                        disabled={
                          selectUser.status === "finished" ||
                          JSON.parse(localStorage.getItem("userList.items") as any).find(
                            (item: any) => item.id === selectUser?.id
                          ).status === "finished"
                        }
                        type="file"
                        multiple
                        onChange={(e) => handleImage(e)}
                      />
                      <PaperClipIcon className="w-5 h-5" />
                    </label>

                    {isLoadingPostTimeline ? (
                      <div className="flex justify-end bg-[#f7f7f7]">
                        <div className="py-1 px-5">
                          <LoadingSpinner size="w-4" />
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-end ">
                        {selectUser.status !== "finished" &&
                          JSON.parse(localStorage.getItem("userList.items") as any).find(
                            (item: any) => item.id === selectUser?.id
                          ).status !== "finished" ? (
                          <>
                            <button
                              className="w-fit rounded-[0] bg-[#f7f7f7] border-t border-r hover:bg-[#e9e9e9] py-2 px-6"
                              onClick={() => handleSendCommment()}>
                              <img src="/assets/icons/send.svg" alt="" width={20} />
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              disabled={true}
                              className="w-fit text-gray-500 border bg-gray-100 px-4 py-2  text-sm font-medium cursor-not-allowed">
                              <img src="/assets/icons/send.svg" alt="" width={20} />
                            </button>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {files &&
                  files.length > 0 &&
                  files.map((file: any, index: any) => {
                    return (
                      <div key={index} className="flex gap-2 p-1">
                        <div className="flex gap-2">
                          <div className="flex justify-center items-center">
                            <div
                              className="bg-secondaryColor p-1 rounded-lg text-sm cursor-pointer"
                              onClick={() => handleRemoveImage(file)}>
                              {file.name}
                              {" x"}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col items-center gap-1 justify-center text-lg font-medium  h-full">
          <DocumentTextIcon className="w-8 h-8 mr-2" />
          <div className="">{t("noMessageSelected")}</div>
          <div className="text-gray-400 text-sm">{t("pleaseSelectOneMessageToRead")}</div>
        </div>
      )}
    </div>
  );
};

export default ChatScreen;
