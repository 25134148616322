import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  selectedCard: any;
  label?: string;
}

export default function MvpRoadmapDetailPopup(props: Props) {
  const { open, setOpen, selectedCard, label } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-400 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow transition-all sm:my-8 sm:w-full sm:max-w-4xl px-6 pt-8 pb-16">
                <div className="h-96 overflow-auto">
                  <div className="absolute top-3 right-3   sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400  "
                      onClick={() => setOpen(false)}>
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-2 w-full">
                    <>
                      <div className="px-6 pt-6  bg-white  rounded cursor-pointer  w-full min-h-[250px]">
                        <div className="text-lg font-bold">{selectedCard?.name}</div>

                        <div className="mt-3">
                          <div className="border p-5 text-sm space-y-5">
                            <div className="grid grid-cols-2 gap-5 ">
                              <div>
                                <div className="text-gray-500">Strategy Name</div>
                                <div>{selectedCard?.name}</div>
                              </div>
                              <div>
                                <div className="text-gray-500">Strategy Label</div>
                                <div>{label}</div>
                              </div>
                              <div>
                                <div className="text-gray-500">Operating time</div>
                                <div>{selectedCard?.duration} day</div>
                              </div>
                              <div></div>
                            </div>
                            <div>
                              <div>
                                <div className="text-gray-500">Description</div>
                                <div>{selectedCard?.description}</div>
                              </div>
                            </div>
                            <div>
                              <div>
                                <div className="text-gray-500">Tasks</div>
                              </div>
                              <div className="border  rounded divide-y mt-2">
                                {selectedCard?.stories?.map((task: any, index: any) => {
                                  return (
                                    <div className="flex p-2 justify-between">
                                      <div>
                                        {index + 1} - {task.name}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="absolute bottom-5 right-10 ">
                          <>
                            <a href={selectedCard?.doc_url} target="_blank">
                              <DefaultButton className="mt-5 w-fit ">
                                {t("Go to roadmap")}
                              </DefaultButton>
                            </a>
                          </>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
