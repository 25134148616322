// src/MyForm.js
import { postAutoPilot } from '@/api/eA';
import DefaultButton from '@/components/buttons/DefaultButton/DefaultButton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { MultiValue, ActionMeta } from 'react-select'

interface FormProps {
    options: any[]
    placeholder: string
    buttonText: string
    ruleIdentifier: string
    customerClientId: number
    onRefresh: () => void;
}

type OptionType = { value: string; label: string };

function SetForm(props: FormProps) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<{ selectedAssetIds: any[] }>({
        selectedAssetIds: []
    });
    const [isSuccess, setIsSuccess] = useState(false);

    const [actionData, setActionData] = useState<string | null>(null);

    const handleSelectChange = (selectedOptions: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        const selectedIds = selectedOptions.map(option => option.value);
        setFormData(prev => ({ ...prev, selectedAssetIds: selectedIds }));
        console.log("actionMeta", actionMeta.option)
    };

    const handleActionChange = (selectedOption: OptionType | null, actionMeta: ActionMeta<OptionType>) => {
        if (selectedOption) {
            setActionData(selectedOption.value);
        } else {
            setActionData(null);
        }
        console.log("actionMeta", actionMeta)
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = {
            action: actionData === "0" ? 0 : actionData === "1" ? 1 : -1,
            rule_identifier: props.ruleIdentifier,
            customer_client_id: props.customerClientId,
            exception_asset_ids: formData.selectedAssetIds
        }

        try {
            console.log(data)
            const result = await postAutoPilot(data);
            if (result.status === 201) {
                setIsSuccess(true);
                props.onRefresh();
            }
        } catch (error) {
            console.error("Error stopping the auto-pilot:", error);
        }
    };

    return (
        <>
            {
                isSuccess ?
                    <div className="flex">
                        <div className='w-full'>
                            <div className='w-full'>
                                <div className="text-lg font-medium divide-y divide-gray-200">
                                    {t("autoPilotItemCreated")}
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <div className="flex">
                        <div className='w-full'>
                            <form onSubmit={handleSubmit}>
                                {props.options.length === 0 ? <div className="text-sm text-gray-500">{t("noAssetsFoundForSettingException")}<br /></div> :
                                    <div className='w-full'>
                                        <div className="text-lg font-medium divide-y divide-gray-200">
                                            {t("keepThisRuleOutFor")}
                                        </div>
                                        <Select
                                            defaultValue={[]}
                                            isMulti={true}
                                            name="assetsMultiSelect"
                                            id="selectAssets"
                                            options={props.options}
                                            placeholder={props.placeholder}
                                            onChange={handleSelectChange}
                                            styles={{
                                                placeholder: (provided: any) => ({
                                                    ...provided,
                                                    fontSize: "0.875rem",
                                                }),
                                                control: (provided: any) => ({
                                                    ...provided,
                                                    fontSize: "0.875rem",
                                                }),
                                            }}
                                            className='w-full'
                                        />
                                    </div>
                                }
                                <div className='w-full'>
                                    <div className="text-lg font-medium divide-y divide-gray-200">
                                        {t("actionIkiNokta")}
                                    </div>
                                    <Select
                                        defaultValue={[]}
                                        isMulti={false}
                                        name="actionSelect"
                                        id="selectAction"
                                        required
                                        options={
                                            [
                                                { value: '0', label: t('pause') || "Pause" },
                                                { value: '1', label: t('remove') || "Remove" }
                                            ]
                                        }
                                        placeholder={props.placeholder}
                                        onChange={handleActionChange}
                                        styles={{
                                            placeholder: (provided: any) => ({
                                                ...provided,
                                                fontSize: "0.875rem",
                                            }),
                                            control: (provided: any) => ({
                                                ...provided,
                                                fontSize: "0.875rem",
                                            }),
                                        }}
                                        className='w-full' />
                                </div>
                                <div className="flex justify-end w-full">
                                    <DefaultButton type="submit" className="w-1/2 mt-4 mb-4">
                                        {props.buttonText}
                                    </DefaultButton>
                                </div>
                            </form>
                        </div>
                    </div>
            }
        </>
    );
}

export default SetForm;