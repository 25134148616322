import { TableCellProps } from "@/common/TableCellProps";
import Button from "../buttons/Button";

export default function TableCell(props: TableCellProps) {
    switch (props.type) {
        case "text":
        default:
            return <td className={props.className}>{props.text}</td>;
        case "link":
            return <td className={props.className}>
                <a href={props.link}>{props.text}</a>
            </td>;
        case "button":
            const handleButtonClick = props.buttonProps?.onClick || (() => { });
            return <td className={props.className}>
                <Button
                    onClick={handleButtonClick}
                    text={props.buttonProps?.text || "Submit"}
                    buttonType={props.buttonProps?.buttonType || "primary"}
                ></Button>
            </td >;
        case "textwithdesc":
            return <td className={props.className}><div>{props.text}</div><div className="text-xs">{props.desc}</div></td>
    }
}