import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

interface Props {
  children: React.ReactNode;
  title?: string;
  date?: string;
  modalState: boolean;
  setModalState: (state: boolean) => void;
}

export default function Modal(props: Props) {
  const { children, title, date, modalState, setModalState } = props;

  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
    setModalState(false);
  }

  useEffect(() => {
    setIsOpen(modalState);
  }, [modalState]);

  return (
    <div className="">
      {modalState && (
        <div className="fixed top-0 left-0 bg-gray-300 h-screen w-screen z-5 transition-all opacity-50"></div>
      )}
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto z-6 text-center "
          onClose={closeModal}>
          <div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                <Dialog.Title className="text-lg font-medium leading-6 text-gray-900 flex justify-between">
                  <div className="flex flex-col">
                    <span>{title} </span>
                    <span className="text-sm text-gray-400 mt-1">{date}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className=" inline-flex self-start justify-center px-2 py-1 font-medium text-blue-900  border border-transparent rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 transition-all"
                      onClick={closeModal}>
                      {"x"}
                    </button>
                  </div>
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-black">{children}</p>
                </div>

                <div className="mt-4"></div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
