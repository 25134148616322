import { useEffect, useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Chip from '@mui/material/Chip';

interface Execution {
  id: number;
  campaign_name: string;
  execution_date: string;
  action: string;
}

const AssetsDetail = () => {
  const [executions, setExecutions] = useState<Execution[]>([]);
  const ruleIdentifier = "GA_ADGROUPKEYWORD_TOO_MANY_CLICKS";


  useEffect(() => {
    // API isteği yapmak için bir fonksiyon tanımlayabilirsiniz
    const fetchAssetsRules = async () => {
      try {
        const response = await fetch(
          `https://dev.api.mikrolo.com/ea/autopilot/executions_by_rule_identifier/${ruleIdentifier}?language_code=tr&current_page=1&records_per_page=4&order_by=2&sort_order=1`
        );

        if (response.ok) {
          const data = await response.json();
          setExecutions(data.items);
        } else {
          console.error("API isteği başarısız oldu.");
        }
      } catch (error) {
        console.error("API isteği sırasında bir hata oluştu:", error);
      }
    };

    fetchAssetsRules();
  }, [ruleIdentifier]);

  return (
    <div data-testid="/dashboard/auto-pilot-page">
      <AssessmentLayout>
        <div className="text-sm text-[#707070] mt-8 mb-6">
          {/* ... */}
        </div>

        <Paper sx={{ width: "100%" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    colSpan={7}
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      borderBottom: "2px solid black"
                    }}
                  >
                    RULE NAME
                    <div>
                      <Chip label="Low severity" color="warning" />
                    </div>
                    <p>Rule operator: less then</p>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>CAMPAIGN NAME</TableCell>
                  <TableCell>DATE</TableCell>
                  <TableCell>CURRENT STATUS</TableCell>
                  <TableCell>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* API'den gelen verileri kullanarak tabloyu doldurun */}
                {executions.map((execution) => (
                  <TableRow key={execution.id}>
                    <TableCell>{execution.campaign_name}</TableCell>
                    <TableCell>{execution.execution_date}</TableCell>
                    <TableCell>{execution.execution_date}</TableCell>
                    <TableCell>{execution.action}</TableCell>
                    <TableCell>Detaylar</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </AssessmentLayout>
    </div>
  );
};

export default AssetsDetail;