export enum Severity {
  Low = 0,
  Moderate = 1,
  High = 2,
  Critical = 3,
}

export enum ScopeType {
  Account = 0,
  Campaign = 1,
  AdGroup = 2,
  Ad = 3,
}
