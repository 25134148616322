import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { createGAUrl } from "@/api/credential";
import { fetchEaStatus } from "@/api/eA";
import PopupModal from "@/components/PopupModal/PopupModal";
import { useState } from "react";
import WhiteButton from "@/components/buttons/WhiteButton";
import { t } from "i18next";
import useGoogleAdsLogin from "@/hooks/use-google-login";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";

interface Props {
  title: string;
  description: string;
  imgSrc?: string;
}

const GoogleAdsCard = (props: Props) => {
  const [project] = useRecoilState(projectInfo);
  const [openModal, setOpenModal] = useState(false);

  const { title, description } = props;

  const { isSuccess: isSuccessGoogle, isLoadingGoogleAdsLogin } = useGoogleAdsLogin();

  const { data: status, isLoading: isLoadingStatus } = useQuery(
    "fetchPlatformStatus",
    () =>
      fetchEaStatus({
        project_id: project.id,
      }),
    {
      retry: false,
      enabled: !!project.id && isSuccessGoogle,
      cacheTime: 0,
    }
  );

  const { data, isLoading } = useQuery("getGoogleAdsUrl", () => createGAUrl("google_ads"));

  const handleDisconnect = () => { };

  return (
    <div className="bg-bgCardColor max-w-7xl rounded-lg shadow p-6 ">
      {isLoadingStatus || isLoading || isLoadingGoogleAdsLogin ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <PopupModal
            closeAction={() => setOpenModal(false)}
            title=""
            isPopup={openModal}
            width="max-w-md">
            <>
              <div>{t("Are you sure you want to disconnect Google Ads?")}</div>
              <div className="flex flex-col gap-2">
                <DefaultButton onClick={() => handleDisconnect()}>{t("remove")}</DefaultButton>
                <WhiteButton onClick={() => setOpenModal(false)}>{t("cancel")}</WhiteButton>
              </div>
            </>
          </PopupModal>
          <img src="/assets/icons/google_ads.svg" alt="gas" width={34} className="mb-2" />
          <div className="text-lg font-semibold">{title}</div>
          <div className="my-3 text-base ">{description}</div>
          <div className="flex justify-end pt-3">
            {/* <div>
              <button
                className="font-medium  border  flex items-center
                  hover:shadow hover:shadow-blue-400 transition-all  border-[#4285f4] 
                "
                onClick={() => setOpenModal(true)}>
                <img
                  className="px-3 min-h-[18px] min-w-[18px] py-3"
                  src="/assets/icons/google.svg"
                  alt="gas"
                />
                <span className="bg-[#4285f4] text-white text-sm py-3 pl-4 pr-3 ">
                  Connect with Google
                </span>
              </button>
            </div> */}
            <div>
              {status ? (
                <a href={data?.authorization_url} rel="noreferrer">
                  <button
                    className=" text-green-700 bg-green-100 font-medium py-2 px-4 rounded-lg border border-green-400
                    hover:bg-green-200 hover:border-green-500 transition-all text-sm">
                    {t("connected")}
                  </button>
                </a>
              ) : (
                <a href={data?.authorization_url} rel="noreferrer">
                  <DefaultButton className="w-fit">{t("connect")}</DefaultButton>
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GoogleAdsCard;
