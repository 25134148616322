import React from "react";
import { t } from "i18next";
import AdsContentCard from "@/features/report/components/cards/AdsContentCard";
import SimpleLineChart from "@/features/report/components/charts/LineChart";

type Props = {
  reportDetail: any;
};
const GeneralLineGraph = (props: Props) => {
  const { reportDetail } = props;

  return (
    <div className="w-full lg:pr-0 relative ">
      {reportDetail?.sample && (
        <div className="absolute font-medium text-sm text-orange-500 mt-1 bg-orange-50 w-fit rounded-lg py-.5 px-2.5 right-1 top-1">
          {t("sample")}
        </div>
      )}

      <AdsContentCard
        reportDetail={reportDetail}
        size="w-full"
        altTitle={t("adsMetricsFor")}
        customTitle={reportDetail?.customerClientName || ""}
        date={reportDetail?.date}
        title={reportDetail?.metric_name}
        description={reportDetail?.metric_description || ""}>
        <div className="flex flex-col w-full ">
          <SimpleLineChart data={reportDetail} />
        </div>
      </AdsContentCard>
    </div>
  );
};

export default GeneralLineGraph;
