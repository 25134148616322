export default class Translator {
  to;
  from;
  apiKey;
  cacheProvider;

  constructor(options, cacheProvider) {
    this.from = options.from;
    this.to = options.to;
    this.apiKey = options.apiKey;
    this.cacheProvider = cacheProvider;
  }

  translate(value) {
    throw new Error('You must extend the base `translate()` method!' + value);
  }
}
