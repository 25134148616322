import { useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { t } from "i18next";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

import VideoContainer from "@/features/help-center/components/VideoContainer";

/* const VideoModal = (props: { src: string }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
      }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}>
        <iframe
          title={props.src}
          allowFullScreen
          frameBorder="0"
          height="415px"
          src={props.src}
          width="660px"
        />
      </div>
    </div>
  );
}; */

// function classNames(...classes: any) {
//   return classes.filter(Boolean).join(" ");
// }

const videos = [
  {
    id: 1,
    title: "How can I assign my task to a talent?",
    image: "/assets/help-center/Frame-1.png",
    video: "https://www.youtube.com/embed/9s9k2uusj9o",
    description: "You can view the video below to learn how to assign a task to a talent.",
  },
  {
    id: 2,
    title: "Start strategy",
    image: "/assets/help-center/Frame-2.png",
    video: "https://www.youtube.com/embed/ZUcBPI_AqpI",
    description:
      "You can view the video below to learn how to start a strategy and use the strategy builder.",
  },
  {
    id: 3,
    title: "Create a new task",
    image: "/assets/help-center/Frame-3.png",
    video: "https://www.youtube.com/embed/uqUQoAZA2G4",
    description: "You can view the video below to learn how to create a new task.",
  },
];

const HowTo = () => {
  const [text, setText] = useState("");

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("howTo")} />
        <div className="text-sm text-[#707070] mt-8 mb-6">
          <div className="text-sm text-[#707070] flex gap-2 flex-row items-center w-fit pb-1">
            <div>
              <HomeIcon className="w-4 h-4" />
            </div>
            <div>
              <Link className="flex items-center gap-2" to="/help-center">
                <ChevronRightIcon className="w-4 h-4" />
                {t("helpCenter")}
              </Link>
            </div>
            <div className="flex items-center gap-2">
              <ChevronRightIcon className="w-4 h-4" /> {t("howTo")}
            </div>
          </div>
        </div>

        <div className="bg-white p-4 sm:p-16">
          <div>
            <div className="text-2xl font-bold mb-4">{t("howTo")}...?</div>
          </div>
          <div>
            <input
              type="search"
              onChange={(e) => setText(e.target.value)}
              placeholder={t("search")}
              className="py-1.5 px-4 outline-none rounded border w-full md:w-1/2 text-sm"
            />
          </div>
          <div className="mb-10 space-y-5 bg-gray-50 p-16 mt-5 text-gray-500">
            <div className="text-center pb-5">
              {t(
                "Everything you need to know about the product. Can’t find the answer you’re looking for?"
              )}
            </div>
            <div className="flex flex-wrap gap-6">
              {videos
                .filter((video) => video.title.toLowerCase().includes(text.toLowerCase()))
                .map((video) => (
                  <VideoContainer video={video} />
                ))}
            </div>
          </div>
        </div>
      </AssessmentLayout>
    </div>
  );
};

export default HowTo;
