import { atom } from "recoil";
import { BriefInterface } from "../../models/brief.interface";
import { FormInterface } from "../../models/briefFormValidation.interface";

const getLocalStorage = (key: string): BriefInterface => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return {
    domainName: "",
    targetCountry: 0,
    keywords: [],
    competitors: [],
    country: {
      id: 0,
      name: "",
      country_code: "",
    },
    businessName: "",
    businessWebsite: "",
    businessSector: 0,
    http: "",
  };
};

const initialState = getLocalStorage("stepper");

export const stepperState = atom<BriefInterface>({
  key: "stepperState",
  default: initialState,
});

export const stepperFormValidation = atom<FormInterface>({
  key: "stepperFormValidation",
  default: {
    targetCountry: 0,
    domainName: "",
    objectives: "",
  },
});
