import React, { useEffect, useState } from "react";
import Steps from "@/features/mvpOnboarding/components/Steps";
import SelectGoals from "@/features/mvpOnboarding/components/SelectGoals";

import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useQuery } from "react-query";
import { fetchProjectData } from "@/api/project";
import { useNavigate } from "react-router-dom";
import { removeToken } from "@/utils/token";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";

import { t } from "i18next";
import { useRecoilState } from "recoil";
import DigitalAssets from "@/features/mvpOnboarding/components/DigitalAssets";
import DigitalCompanies from "@/features/mvpOnboarding/components/DigitalCompanies";
import TargetAreas from "@/features/mvpOnboarding/components/TargetAreas";

const MvpOnboardingPage = () => {
  const [project] = useRecoilState(projectInfo);
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);

  const [steps, setSteps] = useState([
    { id: "01", name: t("goals"), status: "current" },
    { id: "02", name: t("assets"), status: "upcoming" },
    { id: "03", name: t("marketingTools"), status: "upcoming" },
    { id: "04", name: t("target"), status: "upcoming" },
  ]);

  const {
    isLoading: isLoadingProjectData,
    isFetched,
    data,
  } = useQuery(
    "fetchProjectOnboardingDataForControl",
    () => fetchProjectData({ projectId: project.id, name: "ONBOARDING" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!project.id,
    }
  );

  useEffect(() => {
    if (isFetched) {
      if (data) {
        navigate("/", { replace: true });
      }
    }
  }, [isFetched]);

  const handleLogout = () => {
    removeToken();
    // localStorage.removeItem("stepper");
    // localStorage.removeItem("stepper.objectives");
    // localStorage.removeItem("contentCalendar");

    // localStorage.removeItem("onboardingItems");
    // localStorage.removeItem("onboardingCurrentStep");
    localStorage.clear();

    window.location.href = "/login";
  };

  return (
    <div>
      {isLoadingProjectData || !isFetched ? (
        <div
          style={{
            height: "calc(100vh - 74px)",
          }}>
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <div className="min-h-screen flex flex-col bg-gray-50 relative">
          <Steps
            steps={steps}
            setSteps={setSteps}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          />
          <div className="flex justify-end mx-8">
            <div className="font-medium cursor-pointer mt-1" onClick={handleLogout}>
              {t("logout")}
            </div>
          </div>
          <div>
            {currentStep === 1 && (
              <div>
                <SelectGoals
                  projectId={project.id}
                  setCurrentStep={setCurrentStep}
                  setSteps={setSteps}
                />
              </div>
            )}
            {currentStep === 2 && (
              <DigitalAssets
                projectId={project.id}
                setCurrentStep={setCurrentStep}
                setSteps={setSteps}
              />
            )}

            {currentStep === 3 && (
              <DigitalCompanies
                projectId={project.id}
                setCurrentStep={setCurrentStep}
                setSteps={setSteps}
              />
            )}

            {currentStep === 4 && (
              <TargetAreas
                projectId={project.id}
                setCurrentStep={setCurrentStep}
                setSteps={setSteps}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MvpOnboardingPage;
