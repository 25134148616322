import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import SelectBox from "@/components/UI/SelectBox";

const incomingData = [
  { name: "clicks", color: "border-red-500", colorCode: "#F56565" },
  { name: "impressions", color: "border-blue-500", colorCode: "#4299E1" },
  { name: "ctr", color: "border-green-500", colorCode: "#48BB78" },
  { name: "cost", color: "border-amber-500", colorCode: "#ED8936" },
  { name: "conversions", color: "border-cyan-500", colorCode: "#38B2AC" },
  { name: "averageCost", color: "border-fuchsia-500", colorCode: "#9F7AEA" },
  { name: "averageCpc", color: "border-lime-500", colorCode: "#68D391" },
  { name: "averageCpm", color: "border-rose-500", colorCode: "#E53E3E" },
];

export default class SimpleLineChart extends PureComponent<{
  data: any;
  color?: string;
}> {
  state: any = {
    clicks: this.props.data.clicksByDate,
    impressions: this.props.data.impressionsByDate,
    ctr: this.props.data.ctrByDate,
    cost: this.props.data.costByDate,
    conversions: this.props.data.conversionsByDate,
    averageCost: this.props.data.averageCostByDate,
    averageCpc: this.props.data.averageCpcByDate,
    averageCpm: this.props.data.averageCpmByDate,

    select1Value: { value: "clicks", isActive: true, valueType: 0 },
    select2Value: { value: "impressions", isActive: true, valueType: 0 },
    select3Value: { value: "ctr", isActive: false, valueType: 0 },
    select4Value: { value: "cost", isActive: false, valueType: 0 },

    changeSelect1Value: (value: any) => {
      this.setState({ select1Value: value });
    },
    changeSelect2Value: (value: any) => {
      this.setState({ select2Value: value });
    },
    changeSelect3Value: (value: any) => {
      this.setState({ select3Value: value });
    },
    changeSelect4Value: (value: any) => {
      this.setState({ select4Value: value });
    },
  };

  render() {
    // all state data combine as date in one array
    const newData: any = [];

    this.state.clicks.map((item: any, index: number) => {
      newData.push({
        name: item.date,
        clicks: item.value,
        impressions: this.state.impressions[index]?.value,
        ctr: this.state.ctr[index]?.value,
        cost: this.state.cost[index]?.value,
        conversions: this.state.conversions[index]?.value,
        averageCost: this.state.averageCost[index]?.value,
        averageCpc: this.state.averageCpc[index]?.value,
        averageCpm: this.state.averageCpm[index]?.value,
      });
    });

    return (
      <div>
        <div className="gap-1 mb-5 flex sm:flex-row flex-col ">
          <SelectBox
            value={this.state.select1Value}
            data={this.props.data}
            changeValue={this.state.changeSelect1Value}
            items={incomingData}
          />
          <SelectBox
            value={this.state.select2Value}
            data={this.props.data}
            changeValue={this.state.changeSelect2Value}
            items={incomingData}
          />
          <SelectBox
            value={this.state.select3Value}
            data={this.props.data}
            changeValue={this.state.changeSelect3Value}
            items={incomingData}
          />
          <SelectBox
            value={this.state.select4Value}
            data={this.props.data}
            changeValue={this.state.changeSelect4Value}
            items={incomingData}
          />
        </div>
        <ResponsiveContainer minWidth={400} width="100%" height={230}>
          <LineChart
            width={500}
            height={500}
            data={newData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" />
            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat("en-US", {
                  notation: "compact",
                  compactDisplay: "short",
                }).format(value)
              }
            />
            <YAxis
              tickFormatter={(value) =>
                new Intl.NumberFormat("en-US", {
                  notation: "compact",
                  compactDisplay: "short",
                }).format(value)
              }
              yAxisId="right"
              orientation="right"
              unit="%"
            />

            <Tooltip />

            {this.state.select1Value.isActive && (
              <>
                {this.state.select1Value.valueType === 0 ? (
                  <Line
                    type="monotone"
                    dataKey={this.state.select1Value.value}
                    stroke={
                      incomingData.find((o) => o.name === this.state.select1Value.value)
                        ?.colorCode || "#8B5CF6"
                    }
                    activeDot={{ r: 8 }}
                    unit={
                      " " + this.props.data[this.state.select1Value.value + "Unit"] || undefined
                    }
                  />
                ) : (
                  <Line
                    type="monotone"
                    dataKey={this.state.select1Value.value}
                    stroke={
                      incomingData.find((o) => o.name === this.state.select1Value.value)
                        ?.colorCode || "#8B5CF6"
                    }
                    activeDot={{ r: 8 }}
                    yAxisId="right"
                    unit={
                      " " + this.props.data[this.state.select1Value.value + "Unit"] || undefined
                    }
                  />
                )}
              </>
            )}
            {this.state.select2Value.isActive && (
              <>
                {this.state.select2Value.valueType === 0 ? (
                  <Line
                    type="monotone"
                    dataKey={this.state.select2Value.value}
                    stroke={
                      incomingData.find((o) => o.name === this.state.select2Value.value)
                        ?.colorCode || "#8B5CF6"
                    }
                    activeDot={{ r: 8 }}
                    unit={
                      " " + this.props.data[this.state.select2Value.value + "Unit"] || undefined
                    }
                  />
                ) : (
                  <Line
                    type="monotone"
                    dataKey={this.state.select2Value.value}
                    stroke={
                      incomingData.find((o) => o.name === this.state.select2Value.value)
                        ?.colorCode || "#8B5CF6"
                    }
                    activeDot={{ r: 8 }}
                    yAxisId="right"
                    unit={
                      " " + this.props.data[this.state.select2Value.value + "Unit"] || undefined
                    }
                  />
                )}
              </>
            )}
            {this.state.select3Value.isActive && (
              <>
                {this.state.select3Value.valueType === 0 ? (
                  <Line
                    type="monotone"
                    dataKey={this.state.select3Value.value}
                    stroke={
                      incomingData.find((o) => o.name === this.state.select3Value.value)
                        ?.colorCode || "#8B5CF6"
                    }
                    activeDot={{ r: 8 }}
                    unit={
                      " " + this.props.data[this.state.select3Value.value + "Unit"] || undefined
                    }
                  />
                ) : (
                  <Line
                    type="monotone"
                    dataKey={this.state.select3Value.value}
                    stroke={
                      incomingData.find((o) => o.name === this.state.select3Value.value)
                        ?.colorCode || "#8B5CF6"
                    }
                    activeDot={{ r: 8 }}
                    yAxisId="right"
                    unit={
                      " " + this.props.data[this.state.select3Value.value + "Unit"] || undefined
                    }
                  />
                )}
              </>
            )}
            {this.state.select4Value.isActive && (
              <>
                {this.state.select4Value.valueType === 0 ? (
                  <Line
                    type="monotone"
                    dataKey={this.state.select4Value.value}
                    stroke={
                      incomingData.find((o) => o.name === this.state.select4Value.value)
                        ?.colorCode || "#8B5CF6"
                    }
                    activeDot={{ r: 8 }}
                    unit={
                      " " + this.props.data[this.state.select4Value.value + "Unit"] || undefined
                    }
                  />
                ) : (
                  <Line
                    type="monotone"
                    dataKey={this.state.select4Value.value}
                    stroke={
                      incomingData.find((o) => o.name === this.state.select4Value.value)
                        ?.colorCode || "#8B5CF6"
                    }
                    activeDot={{ r: 8 }}
                    yAxisId="right"
                    unit={
                      " " + this.props.data[this.state.select4Value.value + "Unit"] || undefined
                    }
                  />
                )}
              </>
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
