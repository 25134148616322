import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useMutation } from "react-query";
import { verifyEmail } from "@/api/verification";

const VerifyPage = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const idParams = id?.split("&");

  const { mutateAsync, isLoading } = useMutation(verifyEmail);

  const handleVerifyEmail = () => {
    if (idParams) {
      mutateAsync({
        user_id: idParams[0] ? parseInt(idParams[0]) : 0,
        uuid_str: idParams[1] ? idParams[1] : "",
        new_password: null,
      })
        .then(() => {
          navigate("/");
        })
        .catch(() => {
          alert("There was an error verifying your email, please try again.");
        });
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center min-h-screen p-5 bg-secondaryColor min-w-screen">
        <div className="max-w-xl p-8 text-center text-gray-800 bg-white shadow-md lg:max-w-3xl rounded-lg lg:p-12">
          <h3 className="text-2xl">Thanks for signing up for Mikrolo!</h3>
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-24 h-24 text-green-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
              />
            </svg>
          </div>

          <p>We're happy you're here. Let's get your email address verified:</p>
          <div className="mt-4">
            <button
              disabled={isLoading}
              onClick={() => handleVerifyEmail()}
              className="px-3 py-2 text-white bg-blue-600 rounded shadow disabled:bg-gray-400">
              Click to Verify Email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
