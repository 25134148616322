import client from "./client";

export const fetchAllCountries = async () => {
  const response = await client.get("/country", {});

  return response.data;
};

export const fetchCountryById = async (id: number) => {
  const response = await client.get(`/country/${id}`);
  return response.data;
};
