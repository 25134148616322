import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BanknotesIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useMutation } from "react-query";
import { assignMultiTasks } from "@/api/partner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import UpgradeSection from "@/components/UpgradeSection";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { userAtom } from "@/store/atoms/user.atoms";
import { USER_TYPE } from "@/models/userType";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedStory: any;
  refetch: any;
  setIsAssigning: (isAssigning: boolean) => void;
  setSelectedStory: (story: any) => void;
}

export default function AssignTalentModal(props: Props) {
  const { open, setOpen, selectedStory, refetch, setIsAssigning, setSelectedStory } = props;
  const [selectedTasks, setSelectedTasks] = useState<any>([]);
  const { mutateAsync: mutateAssign, isLoading } = useMutation(assignMultiTasks);
  const [error, setError] = useState<string | null>(null);
  const [project] = useRecoilState(projectInfo);
  const [user] = useRecoilState(userAtom);

  const handleAssign = () => {
    if (selectedTasks.length === 0) {
      setError(t("Please select at least one task"));
    } else {
      // add amount to selected tasks
      const finalOutgoingTasks = selectedTasks.map((task: any) => {
        return {
          task_id: task.id,
          amount: task?.amount || 1,
        };
      });

      mutateAssign({
        project_story_id: selectedStory.project_story_id,
        tasks: finalOutgoingTasks,
      })
        .then(() => {
          setSelectedStory({
            ...selectedStory,
            is_assigned: true,
          });
          setIsAssigning(true);
          setOpen(false);
        })
        .catch((err) => {
          setError(err?.response?.data?.detail || t("There was an error assigning the tasks"));
        })
        .finally(() => {
          refetch();
        });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-20 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full divide-y">
                    <div className="mt-2">
                      <div className="flex justify-center text-xl font-semibold">
                        {t("What you need?")}
                      </div>
                      <div className="text-gray-500 text-center">
                        {t("You can select any expert to do your task. Select what you need!")}
                      </div>

                      <>
                        {user?.role !== USER_TYPE && (
                          <>
                            {+project.totalCredit - +project.totalUsedCredit <= 10 && (
                              <UpgradeSection />
                            )}
                          </>
                        )}
                      </>

                      <div className="border-b first-letter:uppercase mt-4 text-lg font-medium">
                        {t("experts")}
                      </div>
                      <div className="mt-3 mb-8">
                        <div className="flex justify-start gap-2 flex-col">
                          {selectedStory?.tasks?.map((item: any) => {
                            return (
                              <label className="inline-flex justify-start items-center text-sm font-medium">
                                <input
                                  type="checkbox"
                                  className="form-checkbox h-3 w-3 text-indigo-600 transition duration-150 ease-in-out "
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedTasks((prev: any) => [
                                        ...prev,
                                        {
                                          id: item.task_id,
                                          credit: +item.credit || 1,
                                          amount: item.step || 1,
                                          label: item.partner_role,
                                        },
                                      ]);
                                    } else {
                                      setSelectedTasks((prev: any) =>
                                        prev.filter((task: any) => task.id !== item.task_id)
                                      );
                                    }
                                  }}
                                />
                                <span className="ml-2 first-letter:uppercase">
                                  {item.partner_role.replace("_", " ")}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {
                      /*control step field in tasks */
                      selectedStory?.tasks?.find((item: any) => item?.step) &&
                      selectedStory?.tasks?.find((item: any) => item?.step !== 0) &&
                      selectedTasks.length > 0 && (
                        <div className="pt-4">
                          {selectedStory?.tasks?.map((item: any) => {
                            return (
                              <label className="text-sm font-medium">
                                {item.step !== 0 &&
                                  !!selectedTasks.find(
                                    (task: any) => task.id === item.task_id
                                  ) && (
                                    <div className="flex gap-3 items-center mb-3">
                                      <div className="first-letter:uppercase w-44 truncate">
                                        {item.partner_role.replace("_", " ")} ({item.title})
                                      </div>
                                      <select
                                        onChange={(e) => {
                                          setSelectedTasks((prev: any) =>
                                            prev.map((task: any) => {
                                              if (task.id === item.task_id) {
                                                return {
                                                  ...task,
                                                  amount: +e.target.value,
                                                };
                                              }
                                              return task;
                                            })
                                          );
                                        }}
                                        className="border border-gray-300 rounded w-full ml-2 outline-none pl-1 py-2 ">
                                        {Array.from(
                                          {
                                            length:
                                              Math.floor(
                                                (item.max_value - item.min_value) / item.step
                                              ) + 1,
                                          },
                                          (_, i) => item.min_value + i * item.step
                                        ).map((item: any) => (
                                          <option value={item}>{item}</option>
                                        ))}
                                      </select>
                                      <div className="text-gray-500 flex gap-1 items-center absolute right-6 text-xs">
                                        <div>
                                          {selectedTasks
                                            .filter((task: any) => task.id === item.id)
                                            .map((task: any) => {
                                              if (task.credit && task.amount) {
                                                return task.credit * task.amount;
                                              }
                                            })}
                                        </div>

                                        <div className="mr-5">{t("credit")}</div>
                                      </div>
                                    </div>
                                  )}
                              </label>
                            );
                          })}
                        </div>
                      )
                    }

                    <div className="grid grid-cols-8 pt-3 items-center">
                      <div className="text-textColor text-sm col-span-2 pt-1  gap-2 flex items-center">
                        <BanknotesIcon className="w-6 h-6 text-green-500" />
                        <div className="text-textColor text-sm">{t("Total Credit")}:</div>
                      </div>
                      <div className="col-span-6">
                        {selectedTasks.reduce((acc: any, item: any) => {
                          if (item.credit && item.amount) return acc + +item.credit * +item.amount;
                        }, 0)}
                      </div>
                    </div>

                    {error && (
                      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative my-3">
                        {error}
                      </div>
                    )}

                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse w-full gap-2">
                      <DefaultButton isLoading={isLoading} type="button" onClick={handleAssign}>
                        {t("assign")}
                      </DefaultButton>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:text-sm"
                        onClick={() => setOpen(false)}>
                        {t("cancel")}
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
