import React, { useEffect, useState } from "react";
import Steps from "@/features/onboarding/components/Steps";
import KeywordsTask from "@/features/onboarding/components/KeywordsTask";
import CompetitorsTask from "@/features/onboarding/components/CompetitorsTask";
import SelectGoals from "@/features/onboarding/components/SelectGoals";
import ConnectAccounts from "@/features/onboarding/components/ConnectAccounts";
import GeneralInfo from "@/features/onboarding/components/GeneralInfo";

import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useQuery } from "react-query";
import { fetchProjectData } from "@/api/project";
import { useNavigate } from "react-router-dom";
import { removeToken } from "@/utils/token";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";

import { t } from "i18next";
import { useRecoilState } from "recoil";

const OnboardinPage = () => {
  const [project] = useRecoilState(projectInfo);
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(
    parseInt(localStorage.getItem("onboardingCurrentStep") as any) || 1
  );

  const [steps, setSteps] = useState(
    JSON.parse(localStorage.getItem("onboardingItems") as any) || [
      // { id: "01", name: t("goals"), status: "current" },
      // { id: "02", name: t("target"), status: "upcoming" },
      // { id: "03", name: t("keywords"), status: "upcoming" },
      // { id: "04", name: t("competitors"), status: "upcoming" },
      { id: "01", name: t("accounts"), status: "current" },
    ]
  );

  const {
    isLoading: isLoadingProjectData,
    isFetched,
    data,
  } = useQuery(
    "fetchProjectOnboardingDataForControl",
    () => fetchProjectData({ projectId: project.id, name: "ONBOARDING" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!project.id,
    }
  );

  useEffect(() => {
    if (isFetched) {
      if (data) {
        navigate("/", { replace: true });
      }
    }
  }, [isFetched]);

  const handleLogout = () => {
    removeToken();
    // localStorage.removeItem("stepper");
    // localStorage.removeItem("stepper.objectives");
    // localStorage.removeItem("contentCalendar");

    // localStorage.removeItem("onboardingItems");
    // localStorage.removeItem("onboardingCurrentStep");
    localStorage.clear();

    window.location.href = "/login";
  };

  return (
    <div>
      {isLoadingProjectData || !isFetched ? (
        <div
          style={{
            height: "calc(100vh - 74px)",
          }}>
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <div className="min-h-screen flex flex-col bg-gray-50 relative">
          {/* <Steps
            steps={steps}
            setSteps={setSteps}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
          /> */}
          <div className="flex justify-end mx-8">
            <div className="font-medium cursor-pointer mt-1" onClick={handleLogout}>
              {t("logout")}
            </div>
          </div>
          <div>
            {/* {currentStep === 1 && (
              <div>
                <SelectGoals
                  projectId={project.id}
                  setCurrentStep={setCurrentStep}
                  setSteps={setSteps}
                />
              </div>
            )}
            {currentStep === 2 && (
              <GeneralInfo
                projectId={project.id}
                setCurrentStep={setCurrentStep}
                setSteps={setSteps}
              />
            )}

            {currentStep === 3 && (
              <KeywordsTask
                projectId={project.id}
                setCurrentStep={setCurrentStep}
                setSteps={setSteps}
              />
            )}

            {currentStep === 4 && (
              <CompetitorsTask
                projectId={project.id}
                setCurrentStep={setCurrentStep}
                setSteps={setSteps}
              />
            )} */}


            <ConnectAccounts
              setCurrentStep={setCurrentStep}
              setSteps={setSteps}
              projectId={project.id}
            />

          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardinPage;
