import { t } from "i18next";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { handleLogout } from "@/utils/logout";

type Props = {
  title: string;
};

const ContentBar = (props: Props) => {
  return (
    <div className="flex justify-between items-center z-[99] pt-2">
      <h2 className="text-2xl leading-6 text-gray-900 font-bold ">{props.title}</h2>
      <div className="hidden lg:block">
        <button
          onClick={handleLogout}
          className="bg-white py-2 px-3 rounded-xl flex text-base gap-2 items-center">
          <ArrowRightOnRectangleIcon className="h-5 w-5 text-gray-400" />
          {t("logout")}
        </button>
      </div>
    </div>
  );
};

export default ContentBar;
