function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  categories: any;
  setSelectedCategory: any;
  selectedCategory: any;
};

export default function SelectFilter(props: Props) {
  const { categories, setSelectedCategory, selectedCategory } = props;

  const handleFilter = (filterId: string) => {
    setSelectedCategory(filterId);
  };

  return (
    <div className="w-full">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 py-2 outline-none"
          onChange={(e) => handleFilter(e.target.value)}>
          {categories.map((tab: any) => (
            <option value={tab.id} key={tab.title}>
              {tab?.title}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:flex   justify-center">
        <nav className="flex space-x-4" aria-label="Tabs">
          <div
            className={classNames(
              selectedCategory === ""
                ? "bg-white text-gray-800"
                : "text-gray-600 hover:text-gray-800",
              "rounded-md px-3 py-2 text-sm font-medium border cursor-pointer"
            )}
            onClick={() => handleFilter("")}>
            All
          </div>
          {categories.map((tab: any) => (
            <div
              key={tab.name}
              onClick={() => handleFilter(tab.id)}
              className={classNames(
                selectedCategory === tab.id
                  ? "bg-white text-gray-800"
                  : "text-gray-600 hover:text-gray-800",
                "rounded-md px-3 py-2 text-sm font-medium border cursor-pointer"
              )}
              aria-current={tab.current ? "page" : undefined}>
              {tab.title}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
}
