import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { fetchMultiTimeline, postMessageMultiTimeline } from "@/api/stories";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { ArrowDownTrayIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid";
import i18n, { t } from "i18next";
import humanizeDuration from "humanize-duration";
import moment from "moment";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import { workHasBeenSeen } from "@/analytics/mixpanel";
import { toast } from "react-toastify";
import ConfirmModal from "./ConfirmModal";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";

interface Props {
  selectedStory: any;
  refetch: any;
  handlePartnerTaskStatus: any;
}

const MessageTabContent = (props: Props) => {
  const { selectedStory, refetch: refetchTasks, handlePartnerTaskStatus } = props;
  const [comment, setComment] = React.useState<string>("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [user] = useRecoilState(userAtom);
  const inputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<any>();

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollTo(0, messagesEndRef?.current?.scrollHeight);
    }, 200);
  };

  const { data, isLoading, refetch } = useQuery(
    "fetchMultiTimeline",
    () => fetchMultiTimeline(selectedStory.project_story_id),
    {
      enabled: !!selectedStory.project_story_id,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    scrollToBottom();

    if (data) {
      const work = data?.filter(
        (e: any) =>
          e?.content?.type === "work" &&
          (!e?.read_users_id || e?.read_users_id?.findIndex((e: any) => e === user?.id) === -1)
      );
      if (!work && !user) return;
      for (const item of work) {
        const messageCurrentTask = selectedStory?.tasks?.find(
          (e: any) => e.partner_task_id === item?.partner_task_id
        );
        workHasBeenSeen({
          userId: user?.id || "",
          email: user?.email || "",
          partnerTaskId: item?.partner_task_id,
          partnerId: item?.user_id,
          partnerFullName:
            messageCurrentTask?.partner_first_name + " " + messageCurrentTask?.partner_last_name,
          messageId: item?.id,
          partnerRole: messageCurrentTask?.partner_role,
          storyName: selectedStory?.story_name,
          projectStoryId: selectedStory?.project_story_id,
        });
      }
    }
  }, [data]);

  const { mutateAsync, isLoading: isLoadingPost } = useMutation(postMessageMultiTimeline, {});

  const handleSendComment = () => {
    if (!comment) {
      if (!files) {
        toast.error("Please fill comment or upload file");
        return;
      } else {
        setComment("");
        mutateAsync({
          project_story_id: selectedStory.project_story_id,
          message: comment,
          files: files,
        })
          .then(() => {
            setComment("");
            setFiles([]);
            toast.info("Comment sent successfully");
            refetch();
          })
          .catch((err) => {
            toast.error("Error sending comment: " + err.message);
          });
      }
    } else {
      setComment("");
      mutateAsync({
        project_story_id: selectedStory.project_story_id,
        message: comment,
        files: files,
      })
        .then(() => {
          setComment("");
          setFiles([]);
          toast.info("Comment sent successfully");
          refetch();
        })
        .catch((err) => {
          toast.error("Error sending comment: " + err.message);
        });
    }
  };

  return (
    <div className="h-full w-full">
      {isLoading ? (
        <div className="flex justify-center items-center h-full  w-full">
          <LoadingSpinner size="w-5 h-5" />
        </div>
      ) : (
        <div className="flex flex-col  h-full">
          <div className="text-xl font-medium my-5">{t("Comments with talents")}</div>
          <div className="overflow-auto h-[calc(100vh-350px)]" ref={messagesEndRef}>
            <div className="flex flex-col-reverse gap-3">
              {data.map((item: any) => {
                const currentTask = selectedStory?.tasks?.find(
                  (e: any) => e.partner_task_id === item?.partner_task_id
                );

                return (
                  <>
                    <div className="flow-root">
                      <ul className="-mb-8 pr-2">
                        {item?.user_id === user?.id && item?.content?.type !== "notification" ? (
                          <li key={item.id}>
                            <div className="relative  pb-8">
                              <div className="relative bg-green-50 flex items-start space-x-3">
                                <>
                                  <div className="relative">
                                    {user?.avatar ? (
                                      <img
                                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                        src={user?.avatar}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                        src="/assets/icons/placeholder.png"
                                        alt=""
                                      />
                                    )}

                                    <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                      <ChatBubbleLeftEllipsisIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span className="font-medium text-gray-900">
                                          {/*  {
                                          currentTask?.partner_first_name
                                        } */}
                                          You
                                        </span>
                                      </div>
                                      <p className="mt-0.5 text-sm text-gray-500">
                                        {t("commented")}{" "}
                                        {humanizeDuration(
                                          moment().diff(moment(item?.create_date)),
                                          {
                                            largest: 1,
                                            language: i18n.language,
                                          }
                                        )}
                                      </p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">
                                      <p>{item?.content?.user_message || item?.content?.message}</p>
                                      {item?.content?.files?.length > 0 && (
                                        <>
                                          <span className="font-bold">Files: </span>
                                          {item?.content?.files?.map((file: any, index: any) => (
                                            <>
                                              <a
                                                href={file?.file_url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-blue-500 hover:text-blue-600">
                                                {file?.file_name}
                                              </a>
                                              {item?.content?.files?.length <= index && (
                                                <span className="mx-1">|</span>
                                              )}
                                            </>
                                          ))}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>
                        ) : (
                          item?.content?.type !== "notification" &&
                          item?.content?.type !== "work" && (
                            <>
                              <li key={item.id}>
                                <div className="relative pb-8">
                                  <div className="relative flex items-start space-x-3">
                                    <>
                                      <div className="relative">
                                        {currentTask?.partner_avatar ? (
                                          <img
                                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                            src={currentTask?.partner_avatar}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                            src="/assets/icons/placeholder.png"
                                            alt=""
                                          />
                                        )}

                                        <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                          <ChatBubbleLeftEllipsisIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </div>
                                      <div className="min-w-0 flex-1">
                                        <div>
                                          <div className="text-sm">
                                            <span className="font-medium text-gray-900">
                                              {currentTask?.partner_first_name}
                                            </span>
                                          </div>
                                          <p className="mt-0.5 text-sm text-gray-500">
                                            {t("commented")}{" "}
                                            {humanizeDuration(
                                              moment().diff(moment(item?.create_date)),
                                              {
                                                largest: 1,
                                                language: i18n.language,
                                              }
                                            )}
                                          </p>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>
                                            {item?.content?.user_message || item?.content?.message}
                                          </p>
                                          {item?.content?.files?.length > 0 && (
                                            <>
                                              <span className="font-bold">Files: </span>
                                              {item?.content?.files?.map(
                                                (file: any, index: any) => (
                                                  <>
                                                    <a
                                                      href={file?.file_url}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      className="text-blue-500 hover:text-blue-600">
                                                      {file?.file_name}
                                                    </a>
                                                    {item?.content?.files?.length <= index && (
                                                      <span className="mx-1">|</span>
                                                    )}
                                                  </>
                                                )
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </li>
                            </>
                          )
                        )}
                        {item?.content?.type === "notification" && (
                          <>
                            <li key={item.id}>
                              <div className="relative pb-8 w-full flex justify-center">
                                <div className="mt-2  text-sm text-gray-700  bg-gray-100 w-fit p-2 rounded">
                                  <p>{item?.content?.user_message || item?.content?.message}</p>
                                </div>
                              </div>
                            </li>
                          </>
                        )}
                        {item?.content?.type === "work" && (
                          <li key={item.id}>
                            <div
                              className={`relative ${data
                                .reduce((acc: any, x: any) => {
                                  if (x?.content?.type === "work") {
                                    if (
                                      !acc.find(
                                        (i: any) => i?.partner_task_id === x.partner_task_id
                                      )
                                    )
                                      acc.push(x);
                                  }
                                  return acc;
                                }, [])
                                .find((i: any) => i?.id === item?.id)
                                ? "border border-gray-200 p-1 pb-3 shadow mb-8 rounded"
                                : "pb-8"
                                }`}>
                              <div className="relative flex items-start space-x-3">
                                <>
                                  <div className="relative">
                                    {currentTask?.partner_avatar ? (
                                      <img
                                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                        src={currentTask?.partner_avatar}
                                        alt=""
                                      />
                                    ) : (
                                      <img
                                        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"
                                        src="/assets/icons/placeholder.png"
                                        alt=""
                                      />
                                    )}

                                    <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                                      <ChatBubbleLeftEllipsisIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                  <div className="min-w-0 flex-1">
                                    <div>
                                      <div className="text-sm">
                                        <span className="font-medium text-gray-900">
                                          {currentTask?.partner_first_name}
                                        </span>
                                      </div>
                                      <p className="mt-0.5 text-sm text-gray-500">
                                        {t("commented")}{" "}
                                        {humanizeDuration(
                                          moment().diff(moment(item?.create_date)),
                                          {
                                            largest: 1,
                                            language: i18n.language,
                                          }
                                        )}
                                      </p>
                                    </div>
                                    <div className="mt-2 text-sm text-gray-700">
                                      <p>
                                        <span className="font-bold">Text: </span>
                                        <span>
                                          {item?.content?.user_message || item?.content?.message}
                                        </span>
                                      </p>
                                      {item?.content?.files?.length > 0 && (
                                        <>
                                          <span className="font-bold">Files: </span>
                                          {item?.content?.files?.map((file: any, index: any) => (
                                            <>
                                              <a
                                                href={file?.file_url}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="text-blue-500 hover:text-blue-600">
                                                {file?.file_name}
                                              </a>
                                              {item?.content?.files?.length <= index && (
                                                <span className="mx-1">|</span>
                                              )}
                                            </>
                                          ))}
                                        </>
                                      )}
                                    </div>

                                    {currentTask?.partner_task_status !== "finished" && (
                                      <>
                                        {data
                                          .reduce((acc: any, x: any) => {
                                            if (x?.content?.type === "work") {
                                              if (
                                                !acc.find(
                                                  (i: any) =>
                                                    i?.partner_task_id === x.partner_task_id
                                                )
                                              )
                                                acc.push(x);
                                            }
                                            return acc;
                                          }, [])
                                          .find((i: any) => i?.id === item?.id) && (
                                            <div className="flex justify-end">
                                              <DefaultButton
                                                className="w-fit"
                                                onClick={() => setOpenConfirmModal(true)}>
                                                Confirm{" "}
                                              </DefaultButton>
                                            </div>
                                          )}
                                        <ConfirmModal
                                          refetchTasks={refetchTasks}
                                          item={item}
                                          handlePartnerTaskStatus={handlePartnerTaskStatus}
                                          open={openConfirmModal}
                                          setOpen={setOpenConfirmModal}
                                        />
                                      </>
                                    )}
                                  </div>
                                </>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          {selectedStory.project_story_status !== "finished" && (
            <div className="w-full border-t -mt-1">
              <div className="flex w-full border relative">
                <textarea
                  rows={1}
                  value={comment}
                  id="website-admin"
                  className="resize-none  outline-none active:border-secondBackground rounded-lg   text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm border-gray-300 p-2.5"
                  placeholder={t("writeComment")}
                  onChange={(e) => setComment(e.target.value)}
                />

                {isLoadingPost ? (
                  <div className="flex justify-end bg-[#f7f7f7]">
                    <div className="py-1 px-5">
                      <LoadingSpinner size="w-4" />
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-end ">
                    <span className="absolute right-20 top-2 ">
                      <ArrowDownTrayIcon
                        onClick={() => inputRef.current?.click()}
                        className="h-5 text-black"
                      />
                      <input
                        onChange={(e) => {
                          if (e.target.files) {
                            setFiles(e.target.files);
                          }
                        }}
                        ref={inputRef}
                        type="file"
                        className="hidden"
                      />
                    </span>

                    <button
                      onClick={() => handleSendComment()}
                      className="w-fit rounded-[0] bg-indigo-600 border-t border-r hover:bg-indigo-700 py-2 px-6">
                      <PaperAirplaneIcon className="w-5 h-5 text-white " />
                    </button>
                  </div>
                )}
              </div>

              {files && (
                <div className="flex items-center mt-1">
                  {Array.from(files).map((file: any, index: number) => (
                    <div
                      key={index}
                      className="flex items-center justify-center bg-indigo-100 rounded-full py-0.5 -3 px-3 mr-2">
                      <span className="text-xs font-medium leading-none text-gray-800 truncate">
                        {file.name}
                      </span>
                      <span>
                        <svg
                          onClick={() => {
                            const newFiles = Array.from(files);
                            newFiles.splice(index, 1);
                            setFiles(newFiles);
                          }}
                          className="h-4 w-4 text-gray-500 ml-1 cursor-pointer"
                          fill="currentColor"
                          viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MessageTabContent;
