import client from "./client";

export const postContact = async (data: {
  name: string;
  email: string;
  phone_number: string;
  message: string;
}) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("phone_number", data.phone_number);
  formData.append("message", data.message);

  const response = await client.post("/contact", formData);

  return response.data;
};
