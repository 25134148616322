import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3CenterLeftIcon,
  CalendarDaysIcon,
  HomeIcon,
  // CalendarIcon,
  // MapIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

// import HomeIcon from "@/assets/jsx/home";
import IntegrationIcon from "@/assets/jsx/integration";
// import TasksIcon from "@/assets/jsx/tasks";
// import RuleIcon from "@/assets/jsx/rule";
import ReportIcon from "@/assets/jsx/report";

import { Link, NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { t } from "i18next";
import { handleLogout } from "@/utils/logout";
import { USER_TYPE, USER_TYPE_MVP2 } from "@/models/userType";
import { fetchProjectMainTasks } from "@/api/task";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useQuery } from "react-query";
import { unreadMessageCount } from "@/store/atoms/unreadMessageCount";
//import { tr } from "date-fns/locale";
import AutoPilotIcon from "@/assets/jsx/auto-pilot";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  children: React.ReactNode;
};

export default function Sidebar(props: Props) {
  const navigation: any = [
    //For MVP. Uncomment these menu items when mvp is done
    { key: "home", name: t("home"), href: "/", icon: HomeIcon, current: false },
    //{ key: "strategies", name: t("strategies"), href: "/roadmaps", icon: MapIcon, current: false },
    //{ key: "tasks", name: t("Tasks"), href: "/tasks", icon: TasksIcon, current: false },
    { key: "reports", name: t("reports"), href: "/reports", icon: ReportIcon, current: false },
    //{ key: "contentCalendar", name: t("contentCalendar"), href: "/content-calendar", icon: CalendarIcon, current: false },
    //{ key: "rules", name: t("rules"), href: "/rules", icon: RuleIcon, current: false },
    {
      key: "autoPilot",
      name: t("autopilot"),
      href: "/auto-pilot",
      icon: AutoPilotIcon,
      current: false,
    },
    {
      key: "integrations",
      name: t("integrations"),
      href: "/integrations",
      icon: IntegrationIcon,
      current: false,
    },
  ];

  const [user] = useRecoilState(userAtom);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [project] = useRecoilState(projectInfo);

  const [unreadMessageItems, setUnreadMessageItems] = useRecoilState(unreadMessageCount);

  const { data: tasks, isSuccess } = useQuery(
    "fetchTask",
    () => fetchProjectMainTasks({ project_id: project.id, status: "inprogress" }),
    {
      enabled: !!project.id,
      cacheTime: 0,
      refetchOnWindowFocus: true,
    }
  );

  useEffect(() => {
    if (tasks?.items) {
      const t = tasks?.items?.filter((item: any) => {
        return item?.tasks?.some((task: any) => task?.unread_message_count > 0);
      });

      setUnreadMessageItems({ items: t });
    }
  }, [isSuccess]);

  return (
    <>
      <div className="min-h-screen">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-[101] lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-[101] flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Link to="/">
                      <img
                        className="h-8 w-auto"
                        src="/assets/icons/logoPrivate.svg"
                        alt="Mikrolo"
                      />
                    </Link>
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="px-2">
                      <div className="space-y-1">
                        {navigation.map((item: any) => {
                          return (
                            <React.Fragment key={item.key}>
                              {(user?.role === USER_TYPE || user?.role === USER_TYPE_MVP2) &&
                                item.key !== "home" &&
                                item.key !== "strategies" &&
                                item.key !== "tasks" ? (
                                <>
                                  <div
                                    className={classNames(
                                      "text-gray-700 hover:text-gray-900",
                                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    )}>
                                    <>
                                      <item.icon
                                        className={classNames(
                                          "text-gray-500 group-hover:text-gray-600",
                                          "mr-3 flex-shrink-0 h-6 w-6"
                                        )}
                                        aria-hidden="true"
                                      />{" "}
                                      <div className="flex justify-between w-full text-gray-300">
                                        {item.name}
                                        {unreadMessageItems.items.length > 0 && item.key === "tasks" && (
                                          <div
                                            className="text-[10px] 
                                    bg-red-500 text-white
                                    px-1 rounded-full 
                                  ">
                                            {unreadMessageItems.items.length}
                                          </div>
                                        )}
                                        {user?.role === USER_TYPE && item.key === "strategies" && (
                                          <div
                                            className="text-xs min-w-[45px]
                            flex items-center justify-center
                      bg-red-100 text-red-500
                       px-2 py-1 rounded-md
                      hover:bg-red-200 hover:text-red-600
                      transition duration-300 ease-in-out
                      ">
                                            New
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <NavLink
                                    key={item.key}
                                    to={item.href}
                                    children={({ isActive }) => (
                                      <>
                                        <item.icon
                                          className={classNames(
                                            // isActive ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
                                            isActive
                                              ? "text-primaryColor"
                                              : "text-gray-500 group-hover:text-gray-600",

                                            "mr-3 flex-shrink-0 h-6 w-6"
                                          )}
                                          aria-hidden="true"
                                        />{" "}
                                        <div className="flex justify-between w-full">
                                          {item.name}
                                          {unreadMessageItems.items.length > 0 &&
                                            (item.key === "tasks") && (
                                              <div
                                                className="text-[10px] 
                                    bg-red-500 text-white
                                    px-1 rounded-full 
                                  ">
                                                {unreadMessageItems.items.length}
                                              </div>
                                            )}
                                          {user?.role === USER_TYPE && item.key === "strategies" && (
                                            <div
                                              className="text-xs min-w-[45px]
                            flex items-center justify-center
                      bg-red-100 text-red-500
                       px-2 py-1 rounded-md
                      hover:bg-red-200 hover:text-red-600
                      transition duration-300 ease-in-out
                      ">
                                              New
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    )}
                                    className={({ isActive }) =>
                                      classNames(
                                        isActive
                                          ? // ? "bg-gray-200 text-gray-900"
                                          "bg-secondaryColor text-primaryColor "
                                          : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                      )
                                    }
                                  />
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                      <div className="px-2 py-1">
                        <Link to="/help-center">
                          <div className="flex items-center cursor-pointer">
                            <QuestionMarkCircleIcon
                              className="text-gray-500 group-hover:text-gray-600 mr-3 flex-shrink-0 h-6 w-6"
                              aria-hidden="true"
                            />
                            <div className="group flex items-center  py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 ">
                              {t("helpCenter")}
                            </div>
                          </div>
                        </Link>
                      </div>

                      <div className="px-2">
                        <a
                          href="https://calendly.com/burak-yilmaz-earnado/15min"
                          target={"_blank"}>
                          <div className="flex items-center cursor-pointer">
                            <CalendarDaysIcon
                              className="text-gray-500 group-hover:text-gray-600 mr-3 flex-shrink-0 h-6 w-6"
                              aria-hidden="true"
                            />
                            <div className="group flex items-center  py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 ">
                              {t("bookADemo")}
                            </div>
                          </div>
                        </a>
                      </div>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-white lg:pt-5 ">
          <div className="flex flex-shrink-0 items-center px-6">
            <Link to="/">
              <img
                className="h-8 w-auto"
                src="/assets/icons/logoPrivate.svg"
                alt="Mikrolo"
              />
            </Link>
          </div>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="mt-6 flex h-0 flex-1 flex-col overflow-y-auto">
            {/* User account dropdown */}

            <nav className="mt-6 px-3">
              <div className="space-y-1">
                {navigation.map((item: any) => {
                  return (
                    <React.Fragment key={item.key}>
                      {(user?.role === USER_TYPE || user?.role === USER_TYPE_MVP2) &&
                        item.key !== "home" &&
                        item.key !== "strategies" &&
                        item.key !== "tasks" ? (
                        <>
                          <div
                            className={classNames(
                              "text-gray-700 hover:text-gray-900",
                              "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            )}>
                            <>
                              <item.icon
                                className={classNames(
                                  "text-gray-500 group-hover:text-gray-600",
                                  "mr-3 flex-shrink-0 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />{" "}
                              <div className="flex justify-between w-full text-gray-300">
                                {item.name}

                                {unreadMessageItems.items.length > 0 && item.key === "tasks" && (
                                  <div
                                    className="text-[10px] 
                                    bg-red-500 text-white
                                    px-1 rounded-full 
                                  ">
                                    {unreadMessageItems.items.length}
                                  </div>
                                )}
                                {user?.role === USER_TYPE && item.key === "strategies" && (
                                  <div
                                    className="text-xs min-w-[45px]
                            flex items-center justify-center
                      bg-red-100 text-red-500
                       px-2 py-1 rounded-md
                      hover:bg-red-200 hover:text-red-600
                      transition duration-300 ease-in-out
                      ">
                                    New
                                  </div>
                                )}
                              </div>
                            </>
                          </div>
                        </>
                      ) : (
                        <NavLink to={item.href} children={({ isActive }) => (
                          <>
                            <item.icon
                              className={classNames(
                                // isActive ? "text-gray-500" : "text-gray-400 group-hover:text-gray-500",
                                isActive
                                  ? "text-primaryColor"
                                  : "text-gray-500 group-hover:text-gray-600",
                                "mr-3 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />{" "}
                            <div className="flex justify-between w-full">
                              {item.name}
                              {unreadMessageItems.items.length > 0 && item.key === "tasks" && (
                                <div
                                  className="text-[10px] 
                                       bg-red-500 text-white
                                       px-1 rounded-full 
                                     ">
                                  {unreadMessageItems.items.length}
                                </div>
                              )}
                              {user?.role === USER_TYPE && item.key === "strategies" && (
                                <div
                                  className="text-xs min-w-[45px]
                               flex items-center justify-center
                         bg-red-100 text-red-500
                          px-2 py-1 rounded-md
                         hover:bg-red-200 hover:text-red-600
                         transition duration-300 ease-in-out
                         ">
                                  New
                                </div>
                              )}
                            </div>
                          </>
                        )}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? // ? "bg-gray-200 text-gray-900"
                                "bg-secondaryColor text-primaryColor "
                                : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
                              "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            )
                          }
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </nav>
          </div>

          <div className="px-5 py-2">
            <Link to="/help-center">
              <div className="flex items-center cursor-pointer">
                <QuestionMarkCircleIcon
                  className="text-gray-500 group-hover:text-gray-600 mr-3 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                <div className="group flex items-center  py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 ">
                  {t("helpCenter")}
                </div>
              </div>
            </Link>
          </div>

          <div className="px-5 pb-2  ">
            <a href="https://calendly.com/burak-yilmaz-earnado/15min" target={"_blank"}>
              <div className="flex items-center cursor-pointer">
                <CalendarDaysIcon
                  className="text-gray-500 group-hover:text-gray-600 mr-3 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                <div className="group flex items-center  py-2 text-sm font-medium rounded-md text-gray-700 hover:text-gray-900 ">
                  {t("bookADemo")}
                </div>
              </div>
            </a>
          </div>

          <div className="lg:flex flex-shrink-0 border-t border-gray-200 px-4 py-2 hidden">
            <div className="flex items-center">
              <Menu as="div" className="relative inline-block px-3 text-left">
                <div>
                  <Menu.Button className="group w-full rounded-md py-2 text-left text-sm font-medium text-gray-700 focus:outline-none">
                    <Link to="/profile">
                      <span className="flex w-full items-center justify-between">
                        <span className="flex min-w-0 items-center justify-between space-x-3">
                          <img
                            className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                            src={user?.avatar ?? "/assets/icons/placeholder.png"}
                            alt=""
                          />
                          <span className="flex min-w-0 flex-1 flex-col">
                            <span className="truncate text-sm font-medium text-gray-900 min-w-[100px] ">
                              {user?.first_name + " " + user?.last_name}
                            </span>
                            <span className="truncate text-sm text-gray-500 ">
                              {t("viewProfile")}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Link>
                  </Menu.Button>
                </div>
              </Menu>
            </div>
          </div>
        </div>
        {/* Main column */}
        <div className="flex flex-col lg:ml-64 ">
          {/* Search header */}
          <div className="sticky top-0 z-[30] flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:hidden ">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4 sm:px-6 lg:px-8">
              <div className="flex flex-1">
                <form className="flex w-full md:ml-0" action="#" method="GET"></form>
              </div>
              <div className="flex justify-end items-center gap-2">
                {/*      <div className="mr-2 relative">
                  <Notifications setUnreadMessageCount={setUnreadMessageCount} />
                  {unreadMessageCount > 0 && (
                    <div className="absolute -right-2 -top-[2px] w-4 h-4 text-white bg-red-400 flex justify-center items-center text-xs rounded-full z-[999]">
                      {unreadMessageCount}
                    </div>
                  )}
                </div> */}
                {/*   <div>
                  <SelectLanguage />
                </div> */}
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300"
                        src={user?.avatar ?? "/assets/icons/placeholder.png"}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/profile"
                              className={classNames(
                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}>
                              {t("viewProfile")}
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                              onClick={() => handleLogout()}
                              className={classNames(
                                active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}>
                              {t("logout")}
                            </span>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <div className="bg-gray-100 min-h-screen">
            <main className="pt-5 px-4 sm:px-6 lg:px-14">{props.children}</main>
          </div>
        </div>
      </div>
    </>
  );
}
