// src/MyForm.js
// import {  } from '@/api/eAnl';
import { postAnalyticsReport } from '@/api/eAnl';
import Input from '@/components/UI/Input';
import DefaultButton from '@/components/buttons/DefaultButton/DefaultButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { MultiValue, ActionMeta } from 'react-select'

interface FormProps {
    templateOptions: any[];
    getSelectedMetrics: (templateName?: string) => any[];
    metricOptions: any[];
    placeholder: string;
    buttonText: string;
    projectId: number;
    propertyId: number;
    setLoading: (state: boolean) => void;
    onRefresh: () => void;
}

type OptionType = { value: string; label: string };

function SetForm(props: FormProps) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState<{ SelectedMetrics: any[], ReportName: string }>({
        SelectedMetrics: [],
        ReportName: ""
    });
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedMetrics, setSelectedMetrics] = useState<any[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const handleSelectChange = (selectedOptions: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        setSelectedIds(selectedOptions.map(option => option.value));
        console.log(actionMeta);
        setSelectedMetrics(selectedOptions.map(option => {
            return {
                value: option.value,
                label: option.label
            }
        }
        ));
        setFormData(prev => ({ ...prev, SelectedMetrics: selectedIds }));
    };

    useEffect(() => {
        setFormData(prev => ({ ...prev, SelectedMetrics: selectedIds }));
    }, [selectedIds]);

    const handleTemplateSelectChange = (selectedOption: OptionType | null) => {
        if (selectedOption) {
            setSelectedIds(props.getSelectedMetrics(selectedOption.value).map((metric: any) => metric.value));
            setSelectedMetrics(props.getSelectedMetrics(selectedOption.value));
        }
    };

    const [inputValue, setInputValue] = useState("");
    const handleInputChange = (e: any) => {
        setFormData(prev => ({ ...prev, ReportName: e.target.value }));
        setInputValue(e.target.value);
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        props.setLoading(true);
        const data = {
            metrics: selectedIds.map(id => Number(id)),
            reportName: formData.ReportName,
            propertyId: props.propertyId,
            projectId: props.projectId
        };

        try {
            const result = await postAnalyticsReport(data);
            if (result.status === 201) {
                setIsSuccess(true);
                props.setLoading(false);
                props.onRefresh();
            }
        } catch (error) {
            props.setLoading(false);
            console.error("Error creating report:", error);
        }
    };

    return (
        <>
            {
                isSuccess ?
                    <div className="flex">
                        <div className='w-full'>
                            <div className='w-full'>
                                <div className="text-lg font-medium divide-y divide-gray-200">
                                    {t("createReportStarted")}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="flex">
                        <div className='w-full'>
                            <form onSubmit={handleSubmit}>
                                <div className='w-full'>
                                    <div className="text-lg font-medium divide-y divide-gray-200">
                                        {t("reportName")}:
                                    </div>
                                    <Input
                                        required
                                        value={inputValue}
                                        name={t("reportName")}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder={t("pleaseEnterReportName")}
                                        title="" />
                                    {/* <input
                                        type="search"
                                        id="default-search"
                                        className="block p-2 pl-10 w-3/5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primaryColor focus:border-primaryColor outline-none"
                                        placeholder={t("pleaseEnterReportName")}
                                        required
                                    /> */}
                                </div>
                                <div className='w-full'>
                                    <div className="text-lg font-medium divide-y divide-gray-200">
                                        {t("reportTemplates")}:
                                    </div>
                                    <Select
                                        defaultValue={[]}
                                        isMulti={false}
                                        name="selectTemplate"
                                        id="selectTemplate"
                                        required
                                        options={props.templateOptions}
                                        placeholder={t("selectTemplate")}
                                        onChange={handleTemplateSelectChange}
                                        styles={{
                                            placeholder: (provided: any) => ({
                                                ...provided,
                                                fontSize: "0.875rem",
                                            }),
                                            control: (provided: any) => ({
                                                ...provided,
                                                fontSize: "0.875rem",
                                            }),
                                        }}
                                        className='w-full' />
                                </div>
                                <div className='w-full'>
                                    <div className="text-lg font-medium divide-y divide-gray-200">
                                        {t("metrics")}:
                                    </div>
                                    <Select
                                        defaultValue={[]}
                                        value={selectedMetrics}
                                        isMulti={true}
                                        name="selectMetrics"
                                        id="selectMetrics"
                                        required
                                        options={props.metricOptions}
                                        placeholder={t("selectMetrics")}
                                        onChange={handleSelectChange}
                                        styles={{
                                            placeholder: (provided: any) => ({
                                                ...provided,
                                                fontSize: "0.875rem",
                                            }),
                                            control: (provided: any) => ({
                                                ...provided,
                                                fontSize: "0.875rem",
                                            }),
                                        }}
                                        className='w-full' />
                                </div>
                                <div className="flex justify-end w-full">
                                    <DefaultButton type="submit" className="w-1/2 mt-4 mb-4">
                                        {t("createReport")}
                                    </DefaultButton>
                                </div>
                            </form>
                        </div>
                    </div>
            }
        </>
    );
}

export default SetForm;