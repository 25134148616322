import { ExclamationCircleIcon, PlusIcon, SparklesIcon, XMarkIcon, QuestionMarkCircleIcon, CogIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";

type Props = {
    name: string;
    bgClass?: string;
    text?: string;
    fontClass?: string;
    icon?: string;
    iconClass?: string;
    iconSpanClass?: string;
    closeButtonColor?: string;
    hoverClass?: string;
    boldSentenceIndex?: number[];
    buttonWithLink?: boolean;
    buttonWithLinkText?: string;
    buttonWithLinkClass?: string;
    buttonWithLinkTarget?: string;
    closeButton?: boolean;
};

export default function InfoSection(props: Props) {
    const [show, setShow] = useState(
        localStorage.getItem(props.name) === "true" ? false : true
    );

    const handleClose = () => {
        localStorage.setItem(props.name, "true");
        setShow(false);
    };

    let modifiedText = null;
    if (props.text) {
        const sentences = props.text.split('. ');
        modifiedText = sentences.map((sentence, index) => {
            const sentenceWithPeriod = (index < sentences.length - 1) ? sentence + '. ' : sentence;
            // Check if the current index is in the boldSentenceIndex array
            return props.boldSentenceIndex && props.boldSentenceIndex.includes(index) ?
                <strong key={index}>{sentenceWithPeriod}</strong> :
                sentenceWithPeriod;
        });
    }


    return (
        <div
            className={`
        ${show ? "block" : "hidden"} mt-5 
      `}>
            <div>
                <div className={props.bgClass}>
                    <div className="flex flex-wrap items-center justify-between">
                        <div className="flex w-0 flex-1 items-center">
                            <span className={props.iconSpanClass}>
                                {
                                    props.icon === "SparklesIcon" ? <SparklesIcon className={props.iconClass} aria-hidden="true" /> :
                                        props.icon === "ExclamationCircleIcon" ? <ExclamationCircleIcon className={props.iconClass} aria-hidden="true" /> :
                                            props.icon === "QuestionMarkCircleIcon" ? <QuestionMarkCircleIcon className={props.iconClass} aria-hidden="true" /> :
                                                props.icon === "CogIcon" ? <CogIcon className={props.iconClass} aria-hidden="true" /> :
                                                    <PlusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                }
                            </span>
                            {props.text &&
                                <p className={props.fontClass + " break-words"}>
                                    {modifiedText}
                                </p>
                            }
                        </div>
                        {
                            props.buttonWithLink &&
                            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                                <Link
                                    to={props.buttonWithLinkTarget || "/"}
                                    className={props.buttonWithLinkClass}>
                                    {props.buttonWithLinkText}
                                </Link>
                            </div>
                        }
                        {
                            props.closeButton &&
                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                                <button
                                    onClick={() => handleClose()}
                                    type="button"
                                    className={"-mr-1 flex rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-white " + props.hoverClass}>
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className={props.closeButtonColor + " h-6 w-6"} aria-hidden="true" />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}