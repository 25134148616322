import { useMutation, useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { deleteGoogleAnalyticsIntegration } from "@/api/analytics/googleAnalytics";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { createGAUrl } from "@/api/credential";
import PopupModal from "@/components/PopupModal/PopupModal";
import { useState } from "react";
import WhiteButton from "@/components/buttons/WhiteButton";
import { t } from "i18next";
import { getAnalyticsEnabled } from "@/api/eAnl";

interface Props {
  title: string;
  description: string;
  imgSrc?: string;
}

const GoogleAnalyticsCard = (props: Props) => {
  const { projectId, isLoading: isLoadingIds } = useFetchingProjectIds();
  const [openModal, setOpenModal] = useState(false);

  const { title, description } = props;

  const {
    data: status
  } = useQuery("fetchStatus", () => getAnalyticsEnabled(projectId), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!projectId,
    cacheTime: 0,
  });

  const { data, isLoading } = useQuery("getGoogleAnalytics", () =>
    createGAUrl("google_analytics")
  );

  const { mutateAsync } = useMutation(
    "deleteGoogleAnalyticsIntegration",
    deleteGoogleAnalyticsIntegration,
    {
      retry: false,
    }
  );

  const handleDisconnect = () => {
    mutateAsync(projectId).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="bg-bgCardColor max-w-7xl rounded-lg shadow p-6 ">
      {isLoadingIds || isLoading ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <PopupModal
            closeAction={() => setOpenModal(false)}
            title=""
            isPopup={openModal}
            width="max-w-md">
            <>
              <div>{t("Are you sure you want to disconnect Google Analytics?")}</div>
              <div className="flex flex-col gap-2">
                <DefaultButton onClick={() => handleDisconnect()}>{t("remove")}</DefaultButton>
                <WhiteButton onClick={() => setOpenModal(false)}>{t("cancel")}</WhiteButton>
              </div>
            </>
          </PopupModal>
          <img src="/assets/icons/google_analytics.svg" alt="gas" width={34} className="mb-2" />
          <div className="text-lg font-semibold">{title}</div>
          <div className="my-3 text-base ">{description}</div>
          <div className="flex justify-end pt-3">
            <div>
              {status ? (
                // <a href={data?.authorization_url} rel="noreferrer">
                <button
                  className=" text-green-700 bg-green-100 font-medium py-2 px-4 rounded-lg border border-green-400 transition-all text-sm" disabled={status}>
                  {t("connected")}
                </button>
                // </a>
              ) : (
                <a href={data?.authorization_url} rel="noreferrer">
                  <DefaultButton className="w-fit">{t("connect")}</DefaultButton>
                </a>
              )}
            </div>
            {/* <div>
          {status?.data?.googleAnalytics && status?.data?.integration ? (
            <button
              className=" text-red-700 bg-red-100 font-medium py-2 px-4 rounded-[10px] border border-red-400
                    hover:bg-red-200 hover:border-red-500 transition-all"
              onClick={() => handleDisconnect()}>
              Disconnect
            </button>
          ) : (
            <a href={data?.authorization_url} rel="noreferrer">
              <button
                className=" text-green-700 bg-green-100 font-medium py-2 px-4 rounded-[10px] border border-green-400
                    hover:bg-green-200 hover:border-green-500 transition-all">
                Connect
              </button>
            </a>
          )}
        </div> */}
            {/* <div className="">
          <DefaultButton className="w-fit">Connect</DefaultButton>
        </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default GoogleAnalyticsCard;
