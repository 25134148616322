import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "react-query";
import { fetchIyzicoPlans, postIyzicoCheckoutForm } from "@/api/iyzico";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { Link, useNavigate } from "react-router-dom";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { authMe } from "@/api/auth";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import UpdateProfileInformationModal from "@/features/profile/components/UpdateProfileInformationModal";

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function PricingModal(props: Props) {
  const { open, setOpen } = props;
  const navigate = useNavigate();

  const [project] = useRecoilState(projectInfo);
  const [user] = useRecoilState(userAtom);
  const [promotionCode, setPromotionCode] = useState<string>("");
  const [step, setStep] = useState<number>(1);
  const [error, setError] = useState<string>("");

  const { data: authData, isLoading: isLoadingAuth } = useQuery("authmeProfile", authMe);
  const [isProfileModal, setProfileModal] = useState<boolean>(false);

  const { mutateAsync } = useMutation(postIyzicoCheckoutForm, {});

  // set local session storage to prevent modal from showing again
  const handleSetSessionStorage = () => {
    setOpen(false);
  };

  const { data, refetch, isLoading } = useQuery(
    ["iyzicoPlans"],
    () =>
      fetchIyzicoPlans({
        projectId: project?.id || "",
        currency: "USD",
        promotion_code: promotionCode,
      }),
    {
      enabled: false,
    }
  );

  const handleClick = () => {
    if (!authData?.invoice_type || !authData?.address?.country) {
      setProfileModal(true);
      return;
    }

    mutateAsync({
      locale: "tr",
      conversation_id: project.id.toString(),
      project_id: project.id,
      callback_url: `${process.env.REACT_APP_BASE_URL}iyzico/callback/${project.id}`,
      pricing_plan_reference_code: data.iyzico_pricing_plan_reference_code,
      subscription_initial_status: "ACTIVE",
      customer: {
        name: user?.first_name,
        surname: user?.last_name,
        email: user?.email,
        gsm_number: "+905555555555",
        identity_number: "11111111111",
        billing_address: {
          contact_name: user?.first_name,
          city: "İstanbul",
          country: "Turkey",
          address:
            "Maslak, Teknokent ARI, Reşitpaşa Mah. Katar Cad, 3 Binası No:4 D:B208, 34467 Sarıyer/İstanbul",
        },
      },
    }).then((res) => {
      if (res.status === "failure") {
        alert(res.error_message);
      } else {
        if (res.checkout_form_content) {
          navigate("/payment", {
            state: {
              checkoutFormContent: res.checkout_form_content,
            },
          });
          window.location.reload();
        } else if (res.url) {
          navigate("/payment-success", {});
        }
      }
    });
  };

  const handlePromotionCode = () => {
    setError("");
    refetch().then((res) => {
      if (res.isSuccess) setStep(2);
      else setError("Promotion code is not valid");
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          handleSetSessionStorage();
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                {isLoadingAuth ? (
                  <LoadingSpinner size="w-5 h-5" />
                ) : (
                  <>
                    <div>
                      <div className="mx-auto flex items-center justify-center ">
                        <SparklesIcon className="h-12 w-12 text-green-400" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900">
                          Special Offer
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Great offer for you special for today only!
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      {step === 2 && (
                        <div className="border mt-3 rounded-lg">
                          <div className="p-4 space-y-2">
                            <div className="mt-2 text-lg font-medium">Welcome the plan</div>
                            <div className="text-sm text-gray-400">
                              All the basics for starting a new business
                            </div>
                            <div className="flex items-end">
                              <div className="font-semibold text-green-600 text-4xl">
                                ${data?.iyzico_pricing_plan_price}
                              </div>
                              <div>/mo</div>
                            </div>
                          </div>
                          <div className="bg-green-100 p-4">
                            <div> What's included</div>
                            <div className="text-sm text-gray-500 space-y-2 mt-3">
                              <div>✓ All strategies</div>
                              <div>✓ Creating Task</div>
                              <div>✓ {data?.credit} credits</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {step === 1 && (
                      <>
                        <div>
                          <input
                            type="text"
                            placeholder="Enter your coupon code"
                            value={promotionCode}
                            onChange={(e) => setPromotionCode(e.target.value)}
                            className="mt-5 border border-gray-300 rounded-md shadow-sm w-full px-3 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="text-sm text-red-400">{error}</div>
                        <div className="mt-5 sm:mt-6">
                          <DefaultButton
                            type="button"
                            isLoading={isLoading}
                            isDisabled={promotionCode === ""}
                            onClick={() => handlePromotionCode()}>
                            Continue
                          </DefaultButton>
                          <span className="text-xs text-gray-400 block mt-2">
                            You can change your plan from your{" "}
                            <Link
                              className="text-indigo-600 hover:text-indigo-500"
                              to="/profile/plans">
                              plan page.
                            </Link>
                          </span>
                        </div>
                      </>
                    )}
                    {step === 2 && (
                      <>
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => handleClick()}>
                            Buy
                          </button>
                        </div>
                      </>
                    )}

                    {isProfileModal && (
                      <UpdateProfileInformationModal
                        open={isProfileModal}
                        setOpen={setProfileModal}
                      />
                    )}
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
