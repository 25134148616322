// Package
import axios from "axios";
// Util
import { getToken } from "../utils/token";

// if node env is development, use local server

const getBrowserDefaultLanguage = () => {
  if (window.location.hostname.includes("prod")) return "en";
  else {
    let currentLanguage = sessionStorage.getItem("language") || navigator?.languages?.[0];
    console.log(currentLanguage)
    currentLanguage = currentLanguage.split("-")[0];
    if (currentLanguage === "tr" || currentLanguage === "en") return currentLanguage;
    else return "en";
  }
};

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://dev.api.mikrolo.com" // "http://127.0.0.1:8000" // "http://localhost:8000"
      : process.env.REACT_APP_BASE_URL,
  headers: {
    "Accept-Language": getBrowserDefaultLanguage(),
  },
});

api.interceptors.request.use((request: any) => {
  const token = localStorage.getItem("googleAdsLogin");
  const analyticsToken = JSON.parse(localStorage.getItem("googleAnalyticsTokenHolder") || "{}").tokenString;
  request.headers.common.Authorization = `Bearer ${getToken()}`;
  request.headers.common.EAAuthorization = `Bearer ${token}`;
  request.headers.common.EAnlAuthorization = `Bearer ${analyticsToken}`;
  return request;
});

api.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      // error.response.status === 401
      localStorage.removeItem("authToken");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default api;

/* if (response.status < 300) {
      return response;
    }
    if (response.status === 401 || response.status === 403) {
      localStorage.removeItem("authToken");
      window.location.href = "/login";
    }
    throw (
      response.data?.message ||
      response.statusText ||
      "There was an error, please try again later or contact support"
    ); */
