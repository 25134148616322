import { CheckIcon } from "@heroicons/react/20/solid";
import React, { useEffect } from "react";
import { selectedGoalEvent } from "@/analytics/mixpanel";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { mvpOnboarding } from "@/store/atoms/mvpOnboarding";
import {
  ArrowPathIcon,
  FolderIcon,
  PencilIcon,
  PlayCircleIcon,
  PresentationChartLineIcon,
  ServerStackIcon,
} from "@heroicons/react/24/outline";

type Props = {
  id: number;
  text: string;
  description: string;
  setSelectedGoal: (goalId: number) => void;
  selectedGoal: number;
};

const Goal = (props: Props) => {
  const { id, text, description, selectedGoal, setSelectedGoal } = props;
  const [user] = useRecoilState(userAtom);
  const [mvponboarding, setMvpOnboarding] = useRecoilState(mvpOnboarding);

  useEffect(() => {
    if (mvponboarding.needs === id) {
      setSelectedGoal(id);
    }
  }, [mvponboarding.needs]);

  const icons = {
    0: <PlayCircleIcon className="w-7 h-7 text-white" />,
    1: <FolderIcon className="w-7 h-7 text-white" />,
    2: <ArrowPathIcon className="w-7 h-7 text-white" />,
    3: <PresentationChartLineIcon className="w-7 h-7 text-white" />,
    4: <PencilIcon className="w-7 h-7 text-white" />,
    5: <ServerStackIcon className="w-7 h-7 text-white" />,
  } as any;

  return (
    <div
      onClick={() => {
        setMvpOnboarding((prev) => ({
          ...prev,
          needs: id,
        }));

        setSelectedGoal(id);
        selectedGoalEvent({
          userId: user?.id.toString() || "",
          email: user?.email || "",
          goalName: text,
          selected: !selectedGoal,
        });
      }}
      className={`
        flex
        items-center
        justify-between
        px-8
        pb-8
        rounded-lg
        mb-4
        border
        max-w-[384px]
        relative
        hover:shadow-md
        transition-all
        cursor-pointer
        ${selectedGoal === id ? "bg-gray-100 border-primaryColor" : "bg-white"}
    `}>
      <div className="flex items-center flex-col">
        <div
          className={`absolute w-12 h-12 rounded-lg  flex items-center justify-center top-0 right-1/2 translate-x-8 -translate-y-4
        ${selectedGoal === id ? "bg-primaryColor" : "bg-indigo-500"}
        `}>
          {selectedGoal === id ? <CheckIcon className="w-6 h-6 text-white" /> : icons[5 % id]}
        </div>
        <div className="flex flex-col items-center gap-5 mt-12">
          <div className="text-lg font-medium text-gray-900 text-center">{text}</div>
          <div className=" text-center text-gray-500">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default Goal;
