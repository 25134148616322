import base from "./base";

export const createAuthUrl = async () => {
  const response = await base.get("/google-analytics");
  return response.data;
};

export const googleAnalyticsCallback = async (data: { project_id: number; code: string }) => {
  const response = await base.post(`/google-analytics/callback?project_id=${data.project_id}`, {
    code: data.code,
  });
  return response.data;
};

export const fetchGoogleAnalyticsProperties = async (project_id: number) => {
  const response = await base.get(`/google-analytics/properties?project_id=${project_id}`);
  return response.data;
};

export const fetchStatus = async (projectId: number) => {
  const response = await base.get(`/google-analytics/status?project_id=${projectId}`);
  return response.data;
};

export const createGoogleAnalyticsIntegration = async (data: {
  project_id: number;
  account_id: string;
  property_id: string;
  profile_id: string;
}) => {
  const response = await base.post(`/google-analytics?project_id=${data.project_id}`, {
    account_id: data.account_id,
    property_id: data.property_id,
    profile_id: data.profile_id,
  });
  return response.data;
};

export const deleteGoogleAnalyticsIntegration = async (projectId: number) => {
  const response = await base.delete(`/google-analytics/integration?project_id=${projectId}`);
  return response.data;
};
