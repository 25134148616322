import React, { useEffect } from "react";
import { t } from "i18next";

import { pageViewEvent } from "@/analytics/mixpanel";

import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";

import Tabs from "@/features/profile/components/Tabs";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import PartnerSelectBox from "@/features/profile/components/PartnerSelectBox";
import ProjectUpdateForm from "@/features/profile/components/ProjectUpdateForm";

const ProjectsPage = () => {
  const [selectedTab, setSelectedTab] = React.useState("projects");
  const [user] = useRecoilState(userAtom);
  const [project] = useRecoilState(projectInfo);

  const [selectedPartner, setSelectedPartner] = React.useState({});

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "projects",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  return (
    <AssessmentLayout>
      <ContentBar title={t("projects")} />
      <Tabs setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
      {user && (user?.role === "system" || user?.role === "project_leader") && project?.id && (
        <PartnerSelectBox
          projectId={project.id}
          selectedPartner={selectedPartner}
          setSelectedPartner={setSelectedPartner}
        />
      )}
      <ProjectUpdateForm />
    </AssessmentLayout>
  );
};

export default ProjectsPage;
