import { atom } from "recoil";

export const mvpOnboarding = atom<{
  needs: number;
  digitalAssets: number[];
  digitalTools: number[];
  focusArea: number | null;
}>({
  key: "mvponboarding",
  default: {
    needs: 0,
    digitalAssets: [],
    digitalTools: [],
    focusArea: null,
  },
});
