import client from "./client";

export const fetchIyzicoPlans = async (data: {
  projectId: any;
  currency: string;
  promotion_code?: string;
}) => {
  const response = await client.get("/iyzico/plans", {
    params: {
      project_id: data.projectId,
      currency: data.currency,
      promotion_code: data.promotion_code,
    },
  });

  return response.data;
};

export const postIyzicoCallback = async (data: any) => {
  const response = await client.post("/iyzico/callback", data);

  return response.data;
};

export const postIyzicoCheckoutForm = async (data: any) => {
  const response = await client.post("/iyzico/checkout_form/initialize", data);

  return response.data;
};

export const fetchTemp = async () => {
  const response = await client.get("/iyzico/temp");

  return response.data;
};

export const cancelSubscription = async (projectId: number) => {
  const response = await client.post(`/iyzico/subscription/cancel/${projectId}`);

  return response.data;
};
