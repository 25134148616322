type Props = {
  setSelectFilteredData: any;
  selectFilteredData: any;
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Filter = (props: Props) => {
  const { setSelectFilteredData, selectFilteredData } = props;
  const categories = [
    {
      id: "1",
      title: "Google Ads",
      scope: "google_ads",
    },
    {
      id: "2",
      title: "Google Analytics",
      scope: "google_analytics",
    },
    {
      id: "3",
      title: "Facebook Ads",
      scope: "facebook_ads",
    },
  ];

  const handleFilter = (filterName: string) => {
    // if the filter is already selected, remove it+

    setSelectFilteredData(filterName);
  };

  return (
    <div className="w-full">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 py-2 outline-none"
          onChange={(e) => handleFilter(e.target.value)}>
          {categories.map((tab: any) => (
            <option value={tab.scope} key={tab.id}>
              {tab.title}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:flex   justify-center">
        <nav className="flex space-x-4" aria-label="Tabs">
          <div
            className={classNames(
              selectFilteredData === ""
                ? "bg-white text-gray-800"
                : "text-gray-600 hover:text-gray-800",
              "rounded-md px-3 py-2 text-sm font-medium border cursor-pointer"
            )}
            onClick={() => handleFilter("")}>
            All
          </div>
          {categories.map((tab: any) => (
            <div
              key={tab.od}
              onClick={() => handleFilter(tab.scope)}
              className={classNames(
                selectFilteredData === tab.scope
                  ? "bg-white text-gray-800"
                  : "text-gray-600 hover:text-gray-800",
                "rounded-md px-3 py-2 text-sm font-medium border cursor-pointer"
              )}
              aria-current={tab.current ? "page" : undefined}>
              {tab.title}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Filter;
