import client from "./client";

export const sendEmailVerification = async (data: { user_id: number }) => {
  const response = await client.post("/verification/send/email_verification", data);
  return response.data;
};

export const sendPasswordRecovery = async (data: { email: string }) => {
  const formData = new FormData();
  formData.append("email", data.email);
  const response = await client.post("/verification/send/password_recovery", formData);
  return response.data;
};

export const resendVerificationEmail = async (data: {
  user_id: number;
  verification_type: "password_recovery" | "email_verification";
}) => {
  const form_data: any = new FormData();
  form_data.append("user_id", data.user_id);
  form_data.append("verification_type", data.verification_type);

  const response = await client.post("/verification/resend", form_data);
  return response.data;
};

export const verifyEmail = async (data: {
  user_id: number;
  uuid_str: string;
  new_password: string | null;
}) => {
  const form_data: any = new FormData();
  form_data.append("user_id", data.user_id);
  form_data.append("uuid_str", data.uuid_str);
  if (data.new_password) form_data.append("new_password", data.new_password);

  const response = await client.post("/verification/verify", form_data);
  return response.data;
};
