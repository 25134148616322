import client from "./client";

export const postSchedule = async (data: {
  event_type: string;
  event_resources: any[];
  event_platform: object;
  status: "pending";
  project_id: number;
  event_date: string;
}) => {
  const response = await client.post(`/schedule`, data);
  return response.data;
};

export const fetchContentCalendar = async (projectId: number) => {
  const response = await client.get(`/schedule/calendar/${projectId}`);

  return response.data;
};
