import { ButtonProps } from "@/common/ButtonProps";
import "@/styles/buttons.css";

function CancelButton(props: ButtonProps) {
    return (
        <button className="cancelButton" onClick={props.onClick}>{props.text}
        </button>
    );
}

export default CancelButton;
