import React from "react";

const Privacy = () => {
  return (
    <div className="my-5 lg:mx-24 mx-10">
      <div>
        <span className="font-medium text-xl">Privacy Policy</span>
        <br />
        <br />
        At Mikrolo, accessible from https://app.mikrolo.com, one of our main priorities is the
        privacy of our visitors. This Privacy Policy document contains types of information that is
        collected and recorded by Mikrolo and how we use it. If you have additional questions or
        require more information about our Privacy Policy, do not hesitate to contact us.
        <div className="font-medium">General Data Protection Regulation (GDPR)</div>
        We are a Data Controller of your information. Mikrolo legal basis for collecting and using
        the personal information described in this Privacy Policy depends on the Personal
        Information we collect and the specific context in which we collect the information: Mikrolo
        needs to perform a contract with you You have given Mikrolo permission to do so Processing
        your personal information is in Mikrolo legitimate interests Mikrolo needs to comply with
        the law Mikrolo will retain your personal information only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use your information to the
        extent necessary to comply with our legal obligations, resolve disputes, and enforce our
        policies. If you are a resident of the European Economic Area (EEA), you have certain data
        protection rights. If you wish to be informed what Personal Information we hold about you
        and if you want it to be removed from our systems, please contact us. In certain
        circumstances, you have the following data protection rights: The right to access, update or
        to delete the information we have on you. The right of rectification. The right to object.
        The right of restriction. The right to data portability The right to withdraw consent
        <div className="font-medium">The purposes of processing</div>
        The Data concerning the User is collected to allow the Owner to provide its Services, as
        well as for the following purposes: Handling payments, Remarketing and behavioral targeting,
        Analytics, Displaying content from external platforms, Registration and authentication, User
        database management, Traffic optimization and distribution, Hosting and backend
        infrastructure and Infrastructure monitoring. Users can find further detailed information
        about such purposes of processing and about the specific Personal Data used for each purpose
        in the respective sections of this document.
        <div className="font-medium">Detailed information on the processing of Personal Data</div>
        Personal Data is collected for the following purposes and using the following services:
        Information You Provide to Us:
        <br />
        <div>Facebook</div>
        <div>Facebook Ads conversion tracking (Facebook pixel) (Facebook, Inc.)</div>
        <div>
          Facebook Ads conversion tracking (Facebook pixel) is an analytics service provided by
          Facebook, Inc. that connects data from the Facebook advertising network with actions
          performed on this Application. The Facebook pixel tracks conversions that can be
          attributed to ads on Facebook, Instagram and Audience Network. Personal Data collected:
          Cookies; Usage Data.
        </div>
        <div>Facebook Remarketing (Facebook, Inc.)</div>
        <div>
          Facebook Remarketing is a remarketing and behavioral targeting service provided by
          Facebook, Inc. that connects the activity of this Application with the Facebook
          advertising network. Personal Data collected: Cookies; Usage Data.
        </div>
        <div>Facebook Custom Audience (Facebook, Inc.)</div>
        <div>
          Facebook Custom Audience is a remarketing and behavioral targeting service provided by
          Facebook, Inc. that connects the activity of this Application with the Facebook
          advertising network. Personal Data collected: Cookies; email address.
        </div>
        <div>
          We receive and store any information you knowingly provide to us. For example, through the
          registration process and/or through your account settings, we may collect Personal
          Information such as your name, email address, phone number, and third-party account
          credentials (for example, your Facebook account Id or other third party sites). If you
          provide your third-party account credentials to us or otherwise sign in to the Services
          through a third party site or service, you understand some content and/or information in
          those accounts (“Third Party Account Information”) may be transmitted into your account
          with us if you authorize such transmissions, and that Third Party Account Information
          transmitted to our Services is covered by this Privacy Policy; for example, your previous
          Facebook ads campaigns content. Certain information may be required to register with us or
          to take advantage of some of our features.
        </div>
        <div>
          We may communicate with you if you’ve provided us the means to do so. For example, if
          you’ve given us your email address, we may send you promotional email offers on behalf of
          other businesses, or email you about your use of the Services. Also, we may receive a
          confirmation when you open an email from us. This confirmation helps us make our
          communications with you more interesting and improve our services. If you do not want to
          receive communications from us, please indicate your preference by writing to
          privacy@/mikrolo.com or using the unsubscribe link in each email.
        </div>
        <div><b>Google</b></div>
        <div><b>Google Analytics (Google Inc.)</b></div>
        <div>
          Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google
          utilizes the Data collected to track and examine the use of this Application, to prepare
          reports on its activities and share them with other Google services.
        </div>
        <div>
          Google may use the Data collected to contextualize and personalize the ads of its own
          advertising network. Personal Data collected: Cookies; Usage Data.
        </div>
        <div>Display Advertising extension for Google Analytics (Google Inc.)</div>
        <div>
          audience data and information from the DoubleClick Cookie to extend analytics with
          demographics, interests and ads interaction data. Personal Data collected: Cookies; Usage
          Data.
        </div>
        <div><b>Data Use and Privacy Disclosure</b></div>
        <div>At Mikrolo, we are committed to upholding the highest standards of user privacy and data security. Our application, Mikrolo, is designed with a strong focus on protecting user privacy. </div>
        <div><b>No Sharing with Third Parties</b>: We do not share any user data with third-party tools, including AI models. </div>
        <div><b>Limited Use of Data</b>: Any information received from Google APIs or any other sources is used solely for the intended functionality of our app. We do not transfer this data to other apps or services.</div>
        <div><b>Data Security</b>: We employ stringent security measures to ensure that your personal and sensitive information is treated with the utmost care and confidentiality.</div>
        <div>For more details on our data use and privacy practices, please refer to the <a className="underline" href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy.</a>. By using our app, you agree to these terms.</div>
        <div>We continually update our practices to align with the latest data protection regulations and best practices to safeguard your privacy.</div>

        <div><b>Google Ads Remarketing (Google Inc.)</b></div>
        <div>
          Google Ads Remarketing is a remarketing and behavioral targeting service provided by
          Google LLC or by Google Ireland Limited, depending on the location this Application is
          accessed from that connects the activity of this Application with the Google Ads
          advertising network and the DoubleClick Cookie. Users can opt out of Google's use of
          cookies for ads personalization by visiting Google's Ads Settings. Personal Data
          collected: Cookies; Usage Data.
        </div>
        <div>Remarketing with Google Analytics (Google Inc.)</div>
        <div>
          Remarketing with Google Analytics is a remarketing and behavioral targeting service
          provided by Google LLC or by Google Ireland Limited, depending on the location this
          Application is accessed from that connects the tracking activity performed by Google
          Analytics and its Cookies with the Google Ads advertising network and the Doubleclick
          Cookie.
        </div>
        <div>Personal Data collected: Cookies; Usage Data.</div>
        <div className="font-medium">Information Collected Automatically</div>
        <div>
          Whenever you interact with our Services, we automatically receive and record information
          on our server logs from your browser or device, which may include your IP address, device
          identification, “cookie” information, the type of browser and/or device you’re using to
          access our Services, and the page or feature you requested. “Cookies” are identifiers we
          transfer to your browser or device that allow us to recognize your browser or device and
          tell us how and when pages and features in our Services are visited and by how many
          people. You may be able to change the preferences on your browser or device to prevent or
          limit your device’s acceptance of cookies, but this may prevent you from taking advantage
          of some of our features. Our advertising partners may also transmit cookies to your
          browser or device, when you click on ads that appear on the Services. Also, if you click
          on a link to a third party website or service, such third party may also transmit cookies
          to you. Again, this Privacy Policy does not cover the use of cookies by any third parties,
          and we aren’t responsible for their privacy policies and practices. We may use this data
          to customize content for you that we think you might like, based on your usage patterns.
          We may also use it to improve the Services – for example, this data can tell us how often
          users use a particular feature of the Services, and we can use that knowledge to make the
          Services interesting to as many users as possible.
        </div>
        <div className="font-medium">Online Tracking</div>
        <div>
          Through cookies we place on your browser or device, we may collect information about your
          online activity after you leave our Services. Just like any other usage information we
          collect, this information allows us to improve the Services and customize your online
          experience, and otherwise as described in this Privacy Policy. Your browser may offer you
          a “Do Not Track” option, which allows you to signal to operators of websites and online
          services (including third-party behavioral advertising services) that you do not wish such
          operators to track certain of your online activities over time and across different
          websites. Our Services do not support Do Not Track requests at this time, which means that
          we may collect information about your online activity both while you are using the
          Services and after you leave our Services.
        </div>
        <div className="font-medium">Personal Information Protection</div>
        <div>
          We neither rent nor sell your Personal Information in personally identifiable form to
          anyone. However, we may share your Personal Information with third parties as described in
          this section: Information that’s no longer personally identifiable. We may anonymize your
          Personal Information so that you are not individually identified, and provide that
          information to our partners. We may also provide aggregate usage information to our
          partners, who may use such information to understand how often and in what ways people use
          our Services, so that they, too, can provide you with an optimal online experience.
          However, we never disclose aggregate usage information to a partner in a manner that would
          identify you personally, as an individual. We use Google Analytics to help analyze how
          Users use the Service. Google Analytics uses cookies to collect information such as how
          often Users visit the Service, what pages they visit, and what other sites they used prior
          to coming to the Service. We use the information we get from Google Analytics only to
          improve our Service. Google Analytics collects the IP address assigned to you on the date
          you visit the Service, but not your name or other personally identifying information. We
          do not combine the information generated through the use of Google Analytics with your
          Personal Information. Although Google Analytics plants a persistent Cookie on your web
          browser to identify you as a unique User the next time you visit the Service, the Cookie
          cannot be used by anyone but Google. Google’s ability to use and share information
          collected by Google Analytics about your visits to the Service is restricted by the Google
          Analytics Terms of Use and the Google Privacy Policy. Advertisers: We allow advertisers
          and/or merchant partners (“Advertisers”) to choose the demographic information of users
          who will see their advertisements and/or promotional offers and you agree that we may
          provide any of the information we have collected from you in non-personally identifiable
          form to an Advertiser, in order for that Advertiser to select the appropriate audience for
          those advertisements and/or offers. For example, we might use the fact you are located in
          San Francisco to show you ads or offers for San Francisco businesses, but we will not tell
          such businesses who you are. Or, we might allow Advertisers to display their ads to users
          with similar usage patterns to yours, but we will not disclose usage information to
          Advertisers except in aggregate form, and not in a manner that would identify you
          personally. Note that if an advertiser asks us to show an ad to a certain audience or
          audience segment and you respond to that ad, the advertiser may conclude that you fit the
          description of the audience they were trying to reach. User Profiles and Submissions:
          Certain user profile information, including your name, location, and any video or image
          content that such user has uploaded to the Services, may be displayed to other users to
          facilitate user interaction within the Services or address your request for our services.
          Your account privacy settings may allow you to limit the other users who can see the
          Personal Information in your user profile and/or what information in your user profile is
          visible to others. Please remember that any content you upload to your public user
          profile, along with any Personal Information or content that you voluntarily disclose
          online in a manner other users can view (on discussion boards, in messages and chat areas,
          etc.) becomes publicly available, and can be collected and used by anyone. Your user name
          may also be displayed to other users if and when you send messages or comments or upload
          images or videos through the Services and other users can contact you through messages and
          comments.
        </div>
        <div className="font-medium">Log Files</div>
        <div>
          Mikrolo follows a standard procedure of using log files. These files log visitors when
          they visit websites. All hosting companies do this and a part of hosting services'
          analytics. The information collected by log files include internet protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the information is for
          analyzing trends, administering the site, tracking users' movement on the website, and
          gathering demographic information.
        </div>
        <div className="font-medium">Cookies and Web Beacons</div>
        <div>
          Like any other website, Mikrolo uses 'cookies'. These cookies are used to store
          information including visitors' preferences, and the pages on the website that the visitor
          accessed or visited. The information is used to optimize the users' experience by
          customizing our web page content based on visitors' browser type and/or other information.
        </div>
        <div className="font-medium">Privacy Policies</div>
        <div>
          You may consult this list to find the Privacy Policy for each of the advertising partners
          of Mikrolo. Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective advertisements and links that
          appear on Mikrolo, which are sent directly to users' browser. They automatically receive
          your IP address when this occurs. These technologies are used to measure the effectiveness
          of their advertising campaigns and/or to personalize the advertising content that you see
          on websites that you visit. Note that Mikrolo has no access to or control over these
          cookies that are used by third-party advertisers.
        </div>
        <div className="font-medium">Third Party Privacy Policies</div>
        <div>
          Mikrolo's Privacy Policy does not apply to other advertisers or websites. Thus, we are
          advising you to consult the respective Privacy Policies of these third-party ad servers
          for more detailed information. It may include their practices and instructions about how
          to opt-out of certain options. You can choose to disable cookies through your individual
          browser options. To know more detailed information about cookie management with specific
          web browsers, it can be found at the browsers' respective websites.
        </div>
        <div className="font-medium">Children's Information</div>
        <div>
          Another part of our priority is adding protection for children while using the internet.
          We encourage parents and guardians to observe, participate in, and/or monitor and guide
          their online activity. Mikrolo does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your child provided this
          kind of information on our website, we strongly encourage you to contact us immediately
          and we will do our best efforts to promptly remove such information from our records.
        </div>
        <div className="font-medium">Online Privacy Policy Only</div>
        <div>
          Our Privacy Policy applies only to our online activities and is valid for visitors to our
          website with regards to the information that they shared and/or collected in Mikrolo. This
          policy is not applicable to any information collected offline or via channels other than
          this website.
        </div>
        <div className="font-medium">Consent</div>
        <div>
          By using our website, you hereby consent to our Privacy Policy and agree to its terms.
        </div>
      </div>
    </div>
  );
};

export default Privacy;
