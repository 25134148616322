import { ReportTableProps } from "@/common/ReportTableProps";
import { TableRowProps } from '@/common/TableRowProps';
import React from "react";
import TableRow from "@/components/Table/TableRow";

const ReportTable: React.FC<ReportTableProps> = (
    {
        title,
        description,
        date,
        tableHeader,
        akey,
        rows,
        maxHeight
    }) => {
    return (
        <div className={`flex flex-col rounded-[10px] border  text-sm shadow items-center`} key={akey}>
            <div className="relative border-b py-3 px-6 bg-[#F8F8FC] w-full overflow-y-auto custom-scroll rounded-t-[10px] ">
                <div className="absolute right-5 font-medium text-gray-600 text-xs">
                    {date}
                </div>
                <div className="font-medium text-base mb-2 capitalize">
                    {title || "Report"}
                </div>
                <div className="text-gray-500 text-sm ">{description}</div>
            </div>
            <div className={`bg-white bold-custom-scroll overflow-y-auto rounded-b-[10px]  w-full ${maxHeight}`}>
                <div className="px-8 flex flex-col justify-center mt-5 mb-5 w-full">
                    <table className="rounded-[10px] reportTable min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                {tableHeader.map((header: any) => (
                                    <th
                                        scope="col"
                                        className={`py-3.5 pl-4 pr-3 ${header.align} text-sm font-semibold text-gray-900 sm:pl-3`
                                        }
                                        key={header.text}>
                                        {header.text}
                                    </th>
                                )
                                )}
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {rows?.map((tableRowProps: TableRowProps) => (
                                <TableRow {...tableRowProps}></TableRow>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div >
        </div >
    );
};

export default ReportTable;