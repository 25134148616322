import client from "./client";
import { loginGoogleAnalytics } from "./googleAnalytics";

export const getAnalyticsEnabled = async (projectId: number) => {
  try {
    await loginGoogleAnalytics();

    const response = await client.get(`/eanl/account_enabled/${projectId}`);
    if (response.status === 200 && response.data.status) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Login failed or network request failed", error);
    return false;
  }
}

export const postNewProject = async (projectId: number) => {
  await loginGoogleAnalytics();

  const response = await client.post(`/eanl/new_account?project_id=${projectId}`);

  return response;
}

export const postSelectProperty = async ({ projectId, propertyName, propertyId }: { projectId: number, propertyName: string, propertyId: number }) => {
  await loginGoogleAnalytics();

  const response = await client.post(`/eanl/select_property`, { projectId: projectId, propertyName: propertyName, propertyId: propertyId });
  if (response.status === 202)
    return true;
  return false;
}

export const getAnalyticsReports = async (projectId: number) => {
  await loginGoogleAnalytics();

  const response = await client.get(`/eanl/project_reports/${projectId}`);

  return response;
}

export const getAnalyticsReportMetrics = async (projectId: number) => {
  await loginGoogleAnalytics();

  const response = await client.get(`/eanl/report_metrics?project_id=${projectId}`);

  return response;
}

export const getAnalyticsReportTemplates = async (projectId: number) => {
  await loginGoogleAnalytics();

  const response = await client.get(`/eanl/report_templates?project_id=${projectId}`);

  return response;
}

export const getAnalyticsReport = async (projectId: number, reportId: number, start_date: string, end_date: string) => {
  await loginGoogleAnalytics();

  const response = await client.get(`/eanl/report?project_id=${projectId}&report_id=${reportId}&start_date=${start_date}&end_date=${end_date}`);

  return response;
}

export const postAnalyticsReport = async (data: { projectId: number, reportName: string, propertyId: number, metrics: number[] }) => {
  await loginGoogleAnalytics();

  const response = await client.post(`/eanl/report`, data);

  return response;
}

export const putAnalyticsReport = async (data: { projectId: number, reportName: string, propertyId: number, metrics: number[], reportId: number }) => {
  await loginGoogleAnalytics();

  const response = await client.put(`/eanl/report`, data);

  return response;
}

export const deleteAnalyticsReport = async (data: { projectId: number, reportId: number, propertyId: number }) => {
  await loginGoogleAnalytics();

  const response = await client.delete(`/eanl/report`, { data: data }); // Bunu bir check et.

  return response;
}

export const getSelectedProperties = async (projectId: number) => {
  await loginGoogleAnalytics();

  const response = await client.get(`/eanl/selected_properties?project_id=${projectId}`);

  return response;
}