import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { createGAUrl } from "@/api/credential";
import { fetchEaStatus } from "@/api/eA";
import PopupModal from "@/components/PopupModal/PopupModal";
import { useState } from "react";
import WhiteButton from "@/components/buttons/WhiteButton";
import { t } from "i18next";
import useGoogleAdsLogin from "@/hooks/use-google-login";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";

interface Props {
  title: string;
  description: string;
  imgSrc?: string;
}

const GoogleAdsCard = (props: Props) => {
  const [project] = useRecoilState(projectInfo);
  const [openModal, setOpenModal] = useState(false);

  const { title } = props;

  const { isSuccess: isSuccessGoogle, isLoadingGoogleAdsLogin } = useGoogleAdsLogin();

  const { data: status, isLoading: isLoadingStatus } = useQuery(
    "fetchPlatformStatus",
    () =>
      fetchEaStatus({
        project_id: project.id,
      }),
    {
      retry: false,
      enabled: !!project.id && isSuccessGoogle,
      cacheTime: 0,
    }
  );

  const { data, isLoading } = useQuery("getGoogleAdsUrl", () => createGAUrl("google_ads"));

  const handleDisconnect = () => { };

  return (
    <div className="bg-bgCardColor w-full md:w-[600px] rounded-lg shadow ">
      {isLoadingStatus || isLoading || isLoadingGoogleAdsLogin ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <PopupModal
            closeAction={() => setOpenModal(false)}
            title=""
            isPopup={openModal}
            width="max-w-md">
            <>
              <div>{t("Are you sure you want to disconnect Google Ads?")}</div>
              <div className="flex flex-col gap-2">
                <DefaultButton onClick={() => handleDisconnect()}>{t("remove")}</DefaultButton>
                <WhiteButton onClick={() => setOpenModal(false)}>{t("cancel")}</WhiteButton>
              </div>
            </>
          </PopupModal>
          <div className="flex items-center gap-2 justify-between">
            <div className="flex items-center gap-3 pl-6">
              <img src="/assets/icons/google_ads.svg" alt="gas" width={34} className="mb-2" />
              <div className="text-lg font-semibold">{title}</div>
            </div>
            <div className="flex items-center justify-end border-l p-6">
              <div>
                {status ? (
                  <a href={data?.authorization_url} rel="noreferrer">
                    <button
                      className=" text-green-600 font-medium rounded-lg
                    transition-all ">
                      {t("connected")}
                    </button>
                  </a>
                ) : (
                  <a href={data?.authorization_url} rel="noreferrer">
                    <button className="text-indigo-600">{t("connect")}</button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GoogleAdsCard;
