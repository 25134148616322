import { useState } from "react";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { useQuery } from "react-query";
import PreviewModal from "./PreviewModal";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import { useRecoilState } from "recoil";
import { fetchPreviewData } from "@/api/project";
import { t } from "i18next";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import WhiteButton from "@/components/buttons/WhiteButton";
import UploadYourContentModal from "./UploadYourContentModal";

interface Props {
  selectedStory: any;
}

const PreviewTabContent = (props: Props) => {
  const { selectedStory } = props;
  const [open, setOpen] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [project] = useRecoilState(projectInfo);

  const { isLoading, data, refetch, isRefetching } = useQuery(
    "fetchPreviewData",
    () =>
      fetchPreviewData({
        project_id: project.id,
        project_story_id: selectedStory.project_story_id,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    }
  );

  return (
    <div className="pt-4 flex flex-col h-full w-full ">
      {isLoading || isRefetching ? (
        <LoadingSpinner size="w-5 h-5" />
      ) : (
        <div className="flex flex-col h-full overflow-auto w-full">
          <div className="w-full h-[calc(100%-150px)]  overflow-auto">
            <div className="font-bold">{t("Preview on your task")}</div>
            <div className="flex flex-col md:flex-row gap-4 py-3 w-full ">
              {data && data[data.length - 1]?.file_url ? (
                data[data.length - 1]?.file_type.includes("image") ? (
                  <img
                    className="w-[250px] h-[250px] object-cover"
                    src={data[data.length - 1]?.file_url}
                    alt="placeholder"
                  />
                ) : (
                  <div>No image</div>
                )
              ) : (
                <div className="flex justify-center items-center h-52 w-72 bg-gray-100">
                  <div className="text-gray-400">{t("No image")}</div>
                </div>
              )}
              <div>
                <div>
                  {data && data[data.length - 1].additional_data?.message ? (
                    <div className="text-gray-800">
                      {data[data.length - 1].additional_data?.message}
                    </div>
                  ) : (
                    <div className="text-gray-400">{t("No message")}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 left-0 w-full bg-white px-5">
            <div className=" flex justify-between py-4">
              {selectedStory.tasks
                .map((task: any) => task.partner_first_name !== null)
                .includes(true) ? (
                <WhiteButton
                  onClick={() => setOpenUploadModal(true)}
                  className="w-fit"
                  type="button">
                  {t("Upload your files")}
                </WhiteButton>
              ) : (
                <div> </div>
              )}

              {(data && data[0]?.file_url && data[0]?.file_type.includes("image")) ||
                (data && data[0].additional_data?.message) ? (
                <DefaultButton onClick={() => setOpen(true)} className="w-fit" type="button">
                  {t("Share in")}
                </DefaultButton>
              ) : null}
            </div>
          </div>

          {open && <PreviewModal selectedStory={selectedStory} open={open} setOpen={setOpen} />}
          {openUploadModal && (
            <UploadYourContentModal
              defaultImage={data && data[data.length - 1]?.file_url}
              defaultImageName={data && data[data.length - 1]?.file_name}
              defaultText={data && data[data.length - 1].additional_data?.message}
              selectedStory={selectedStory}
              open={openUploadModal}
              setOpen={setOpenUploadModal}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PreviewTabContent;
