import React from "react";
import SmallCardItem from "./SmallCardItem";

type Props = {
    metrics: any[];
};

const SmallCardChart = (props: Props) => {
    return (
        <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 shadow sm:px-6 sm:pt-6">
            {/* Use 'md:grid-cols-5' to have 5 columns on medium screens and above, 
                 'grid-cols-1' for single column on small screens */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                {props.metrics?.map((metric, index) => (
                    <SmallCardItem
                        key={metric.ChartName + index}
                        metricName={metric.MetricName}
                        value={metric.Value}
                        difference={metric.Difference}
                        color={metric.Color}
                    />
                ))}
            </div>
        </div>
    );
};

export default SmallCardChart;
