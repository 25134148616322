import { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { IStories } from "@/models/assessment/stories.interface";
import { useQuery } from "react-query";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import TaskContent from "@/features/tasks/components/TaskContent";
import { fetchProjectMainTasks } from "@/api/task";
import CreateStoryPopup from "@/features/create-task/components/Popup";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { taskViewEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { useRecoilState } from "recoil";
import { ChevronRightIcon, UsersIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import FilterInput from "@/features/tasks/components/FilterInput";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";

const TaskPage = () => {
  const [project] = useRecoilState(projectInfo);
  const { i18n } = useTranslation();
  const [tabStatus] = useState<"in progress" | "finished">("in progress");
  const [open, setOpen] = useState(false);
  const [selectedStory, setSelectedStory] = useState<IStories | null>(null);
  const contentRef = useRef<any>();
  const [loadingRefetch, setLoadingRefetch] = useState(false);
  const [partnerName, setPartnerName] = useState<string | null>(null);
  const [user] = useRecoilState(userAtom);

  const [openCreateTaskModal, setOpenCreateTaskModal] = useState(false);

  const [filterSettings, setFilterSettings] = useState({
    search: "",
    sort: false,
  });

  const {
    data: tasks,
    isLoading: isLoadingTasks,
    refetch,
    isSuccess,
  } = useQuery(
    "fetchTask",
    () => fetchProjectMainTasks({ project_id: project.id, status: "inprogress" }),
    {
      retry: false,
      enabled: !!project.id,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (project.id) {
      setLoadingRefetch(true);
      refetch()
        .then(() => { })
        .finally(() => {
          setLoadingRefetch(false);
        });
    }
  }, [tabStatus]);

  const handleSelectTask = (story: any) => {
    setSelectedStory(story);

    const getPaths = JSON.parse(localStorage.getItem("paths") as any);
    taskViewEvent({
      taskId: story.id,
      taskName: story.story_name + " - " + story.title,
      taskViewDone: true,
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.prevPath || "",
    });

    setOpen(true);
    setPartnerName(story.partner_first_name);
  };

  useEffect(() => {
    localStorage.setItem("stories.items", JSON.stringify(tasks?.items));
  }, [isSuccess]);

  // if task object has task array include partner_first_name then filter it
  const filteredTasks = tasks?.items?.filter((item: any) =>
    item.tasks.find((task: any) => task.partner_first_name)
  );

  const [unreadMessageItems, setUnreadMessageItems] = useState<any>(null);

  useEffect(() => {
    if (tasks?.items) {
      // return items that has unread message
      const t = tasks?.items?.filter((item: any) => {
        return item?.tasks?.some((task: any) => task?.unread_message_count > 0);
      });

      setUnreadMessageItems(t);
    }
  }, [isSuccess]);

  return (
    <div>
      {isLoadingTasks || loadingRefetch ? (
        <div className="flex h-[calc(100vh-2rem)] justify-center items-center ">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <>
          <div className="border-b border-gray-300 text-lg py-4 flex justify-between items-center font-medium">
            {t("assigned")}
          </div>
          <div className="flex flex-col gap-2 md:flex-row justify-between py-5">
            {/* <DefaultButton
              className="md:w-80 bg-indigo-600 hover:bg-indigo-700 h-fit w-full"
              onClick={() => {
                setOpenCreateTaskModal(true);
                listenButtonClickedEvent({
                  buttonName: "Create a new task(Tasks)",
                  email: user?.email || "",
                  userId: user?.id.toString() || "",
                });
              }}>
              + {t("createNewTask")}
            </DefaultButton>
 */}
            <div></div>
            <FilterInput filterSettings={filterSettings} setFilterSettings={setFilterSettings} />
          </div>
          <div className="flex xl:gap-5 ">
            <div className=" flex flex-col gap-4 w-full ">
              <div
                className="overflow-y-auto overflow-x-visible p-2 mb-10 "
                style={{
                  height: "calc(100vh - 8rem)",
                }}>
                {filteredTasks
                  ?.filter((item: any) => {
                    if (filterSettings.search) {
                      return (
                        item?.sub_title
                          ?.toLowerCase()
                          .includes(filterSettings.search.toLowerCase()) ||
                        item?.story_name
                          ?.toLowerCase()
                          .includes(filterSettings.search.toLowerCase())
                      );
                    }
                    return true;
                  })
                  .sort((a: any, b: any) => {
                    if (filterSettings.sort) {
                      return a?.create_date > b?.create_date ? 1 : -1;
                    } else {
                      return a?.create_date < b?.create_date ? 1 : -1;
                    }
                  })
                  .map((item: any, key: any) => {
                    const unread_message_count = unreadMessageItems
                      ?.find((i: any) => i?.project_story_id === item?.project_story_id)
                      ?.tasks?.reduce((a: any, b: any) => a + b?.unread_message_count, 0);
                    return (
                      <div
                        key={key}
                        onClick={() => handleSelectTask(item)}
                        className="overflow-hidden bg-white border-b cursor-pointer ">
                        <div className="">
                          <div className="block hover:bg-gray-50 ">
                            <div className="flex items-center px-4 py-4 sm:px-6 relative">
                              {unread_message_count > 0 && (
                                <span className="w-4 h-4 bg-red-600 text-xs text-white text-center absolute top-0 left-0">
                                  {unread_message_count}
                                </span>
                              )}
                              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                                <div className="truncate">
                                  <div className="flex text-sm">
                                    <p className="truncate font-medium text-indigo-600">
                                      {(item.sub_title && item.sub_title.replace("&", "and")) ||
                                        (item.story_name && item.story_name.replace("&", "and"))}
                                    </p>
                                  </div>
                                  <div className="mt-2 flex">
                                    <div className="flex items-center text-sm text-gray-500">
                                      {item?.tasks?.find((t: any) => t.partner_first_name) && (
                                        <UsersIcon
                                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      )}

                                      <p>
                                        {item?.tasks?.map(
                                          (t: any, index: any) =>
                                            t.partner_first_name && (
                                              <>
                                                <span className="mr-2">
                                                  {t.partner_first_name}
                                                  {index !== item?.tasks?.length - 1 && ","}
                                                </span>
                                              </>
                                            )
                                        )}
                                      </p>
                                    </div>
                                    <div className="flex items-center text-sm text-gray-500">
                                      {item?.label_name && (
                                        <span className="">
                                          {item?.label_icon && (
                                            <img src={item?.label_icon} alt="" width={14} />
                                          )}
                                          <span
                                            style={{
                                              backgroundColor: item?.label_color,
                                            }}
                                            className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-gray-500">
                                            {item?.label_title}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                                  <div className="flex justify-end -space-x-1 overflow-hidden h-6 ">
                                    {item.rule_name && (
                                      <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-50 text-gray-500">
                                        {item.rule_name}
                                      </div>
                                    )}
                                    {item?.tasks?.map(
                                      (t: any) =>
                                        t.partner_avatar && (
                                          <img
                                            className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                                            src={t.partner_avatar}
                                            alt=""
                                          />
                                        )
                                    )}
                                  </div>
                                  <div className="mt-2 flex justify-end">
                                    <div className="flex items-center text-sm text-gray-500">
                                      <CalendarIcon
                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                      />
                                      <p>
                                        <time>
                                          {item.due_date === null ? (
                                            <div className="flex gap-2">
                                              <div>{t("createDate")}:</div>
                                              {new Date(item.create_date).toLocaleString(
                                                i18n.language,
                                                {
                                                  year: "numeric",
                                                  month: "long",
                                                  day: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                }
                                              )}
                                            </div>
                                          ) : (
                                            <div className="flex gap-2">
                                              <div>{t("dueDate")}:</div>
                                              {new Date(item.due_date).toLocaleString(
                                                i18n.language,
                                                {
                                                  year: "numeric",
                                                  month: "long",
                                                  day: "numeric",
                                                  hour: "numeric",
                                                  minute: "numeric",
                                                }
                                              )}
                                            </div>
                                          )}
                                        </time>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="ml-5 flex-shrink-0">
                                <ChevronRightIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="hidden" ref={contentRef}>
              {selectedStory && open && (
                <TaskContent
                  partnerName={partnerName}
                  projectId={project.id}
                  refetch={refetch}
                  open={open}
                  setOpen={setOpen}
                  selectedStory={selectedStory}
                  setSelectedStory={setSelectedStory}
                  hasAssignTask={project.hasAssignTask}
                  tasks={tasks}
                  setUnreadMessageItems={setUnreadMessageItems}
                  unreadMessageItems={unreadMessageItems}
                />
              )}
            </div>
          </div>
        </>
      )}

      {openCreateTaskModal && (
        <CreateStoryPopup
          modalState={openCreateTaskModal}
          setModalState={setOpenCreateTaskModal}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default TaskPage;
