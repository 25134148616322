import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/20/solid";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";

interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
}

export default function ConfirmPopup(props: Props) {
  const { open, setOpen } = props;
  const navigate = useNavigate();

  return (
    <div>
      {open && (
        <div className="flex justify-center items-center fixed inset-0 z-[999]">
          <button
            className="fixed inset-0 bg-black opacity-25 z-20"
            onClick={() => setOpen(false)}
            type="button"></button>
          <div
            className={`flex flex-col gap-6 bg-white p-6 rounded-lg shadow-xl transform transition-all sm:w-full relative z-30 overflow-auto max-h-[90%] ${"max-w-lg"}  `}>
            <div className="flex justify-end items-start -mb-6">
              <button onClick={() => setOpen(false)}>
                <XMarkIcon className="h-6 w-6 text-gray-400" />
              </button>
            </div>
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <div className="text-lg font-medium leading-6 text-gray-900">
                  {t("Roadmap start to work for you!")}
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {t("Let's see what tasks you need to do.")}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6 ">
              <DefaultButton
                className="w-full "
                onClick={() => {
                  setOpen(false);
                  navigate("/tasks");
                }}>
                <div className="first-letter:uppercase">{t("See my tasks")}</div>
              </DefaultButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
