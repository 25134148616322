import React, { useEffect } from "react";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { useQuery } from "react-query";
import { useRecoilState } from "recoil";
import { t } from "i18next";
import { pageViewEvent } from "@/analytics/mixpanel";
import { userAtom } from "@/store/atoms/user.atoms";
import { fetchMvpQuestions } from "@/api/mvp";
import { USER_TYPE } from "@/models/userType";
import Select from "react-select";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { mvpOnboarding } from "@/store/atoms/mvpOnboarding";

type Props = {
  setCurrentStep: (step: number) => void;
  setSteps: any;
  projectId: number;
};

const DigitalAssets = (props: Props) => {
  const { setCurrentStep, setSteps } = props;
  const [user] = useRecoilState(userAtom);
  const [mvponboarding, setMvpOnboarding] = useRecoilState(mvpOnboarding);

  const handleBusinessInfoSubmit = (e: any) => {
    e.preventDefault();

    setSteps([
      { id: "01", name: t("goals"), status: "complete" },
      { id: "02", name: t("assets"), status: "complete" },
      { id: "03", name: t("marketingTools"), status: "current" },
      { id: "04", name: t("target"), status: "upcoming" },
    ]);

    setCurrentStep(3);
  };

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "onboarding(target)",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  const { data: goals, isLoading } = useQuery(
    "goals2",
    () =>
      fetchMvpQuestions({
        form: USER_TYPE,
      }),
    {}
  );

  return (
    <div className="flex-1 h-full w-full flex md:items-center md:justify-center flex-col  ">
      {isLoading ? (
        <LoadingSpinner size="w-6 h-6" />
      ) : (
        <>
          {" "}
          <div className="flex justify-center flex-col items-center pt-4 max-w-3xl text-center">
            {/* <div className="text-indigo-600 uppercase font-medium">{t("businessInfo")}</div> */}
            <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">{goals[1]?.text}</h1>
            <div className="text-xl text-gray-500">{goals[1]?.description}</div>
          </div>
          <form className="w-full" onSubmit={handleBusinessInfoSubmit}>
            <div className="flex justify-center">
              <Select
                isMulti
                className="w-full md:w-1/2 mt-10 mb-20"
                options={goals[1]?.answers?.map((goal: any) => ({
                  value: goal.id,
                  label: goal.text,
                }))}
                onChange={(e) => {
                  setMvpOnboarding((prev) => ({
                    ...prev,
                    digitalAssets: e.map((item: any) => item.value),
                  }));
                }}
                defaultValue={
                  mvponboarding.digitalAssets.map((item) => ({
                    value: item,
                    label: goals[1]?.answers?.find((answer: any) => answer.id === item)?.text,
                  })) as any
                }
              />
            </div>

            <div className="flex justify-end my-5 md:mb-0 pr-5 md:absolute bottom-10 right-10 items-center">
              <DefaultButton
                isDisabled={mvponboarding.digitalAssets.length === 0}
                className="w-fit px-6 mr-2 md:mr-0">
                {t("next")}
              </DefaultButton>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default DigitalAssets;
