import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { fetchProjectData, postArrayProjectData, postProjectData } from "@/api/project";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import CountryCombobox from "@/components/UI/CountryCombobox";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { ProjectDataType } from "@/models/projectDataType";
import toast, { Toaster } from "react-hot-toast";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import PopupModal from "@/components/PopupModal/PopupModal";
import { Link } from "react-router-dom";

const ProjectUpdateForm = () => {
  const [project] = useRecoilState(projectInfo);
  const [editable, setEditable] = React.useState(false);
  const [formData, setFormData] = React.useState<any>({
    businessName: null,
    domainName: null,
    country: null,
    keywords: null,
    competitors: null,
    businessType: null,
    businessProvider: null,
    businessDescription: null,
    onlineSales: null,
    minBudgetAmount: null,
    maxBudgetAmount: null,
    advertiseChannel: null,
  });
  const [openModal, setOpenModal] = useState(false);

  const { data, isLoading: isLoadingForm } = useQuery(
    "fetchProjectData",
    () =>
      fetchProjectData({
        projectId: project.id,
      }),
    {
      enabled: !!project.id,
      cacheTime: 0,
      retry: false,
    }
  );

  const { mutateAsync, isLoading } = useMutation(postArrayProjectData, {
    retry: false,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const sendProjectData = [
      {
        datatype_name: ProjectDataType.BUSINESS_DATA,
        data: {
          value: {
            businessName: formData.businessName,
            businessType: formData.businessType,
            businessProvider: formData.businessProvider,
            businessDescription: formData.businessDescription,
            onlineSales: formData.onlineSales,
            minBudgetAmount: formData.minBudgetAmount,
            maxBudgetAmount: formData.maxBudgetAmount,
            advertiseChannel: formData.advertiseChannel,
          },
        },
      },
      {
        datatype_name: ProjectDataType.DOMAIN_NAME,
        data: {
          value: formData.domainName,
        },
      },
      {
        datatype_name: ProjectDataType.COUNTRIES,
        data: {
          value: formData.country,
        },
      },
      {
        datatype_name: ProjectDataType.KEYWORDS,
        data: {
          value: formData.keywords,
        },
      },
      {
        datatype_name: ProjectDataType.COMPETITOR_DOMAIN_NAMES,
        data: {
          value: formData.competitors,
        },
      },
    ];

    mutateAsync({
      projectPost: sendProjectData,
      projectId: project.id,
    })
      .catch((err) => {
        toast.error("Project information update failed" + err.message);
      })
      .finally(() => {
        handleGettingStart("assigned");
        toast.success("Project information updated successfully");
      });
  };

  useEffect(() => {
    if (data) {
      setFormData({
        businessName: data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data
          ?.value?.businessName,
        domainName: data?.find((item: any) => item?.datatype_name === "DOMAIN_NAME")?.data?.value,
        country: data?.find((item: any) => item?.datatype_name === "COUNTRIES")?.data?.value,
        keywords: data?.find((item: any) => item?.datatype_name === "KEYWORDS")?.data?.value,
        competitors: data?.find((item: any) => item?.datatype_name === "COMPETITOR_DOMAIN_NAMES")
          ?.data?.value,
        businessType: data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data
          ?.value?.businessType,
        businessProvider: data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data
          ?.value?.businessProvider,
        businessDescription: data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")
          ?.data?.value?.businessDescription,
        onlineSales: data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data?.value
          ?.onlineSales,
        minBudgetAmount: data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data
          ?.value?.minBudgetAmount,
        maxBudgetAmount: data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data
          ?.value?.maxBudgetAmount,
        advertiseChannel: data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data
          ?.value?.advertiseChannel,
      });
    }
  }, [data]);

  const { mutateAsync: mutateAsyncPost } = useMutation("postProjectDomainData", postProjectData, {
    retry: false,
  });

  const handleGettingStart = (key: string) => {
    console.log("I was here");
    fetchProjectData({ projectId: project.id, name: "GETTING_STARTED" }).then((res) => {
      const data = res.data.value;

      console.log("data", data);

      if (!data[key]) {
        setOpenModal(true);
      }

      mutateAsyncPost({
        projectId: project.id,
        project: {
          datatype_name: ProjectDataType.GETTING_STARTED,
          data: {
            value: {
              ...data,
              [key]: true,
            },
          },
        },
      });
    });
  };

  return (
    <div className="my-5">
      <Toaster />
      <PopupModal closeAction={() => setOpenModal(false)} isPopup={openModal}>
        <div className="text-center">
          <div className=" text-2xl font-semibold">Congratulations!</div>
          <div
            className="mt-2 text-gray-500
          ">
            You have successfully set up your project. You have one last step left for Mikrolo to
            offer you unique opportunities.Let's continue.
          </div>
          <Link to="/">
            <div className="flex justify-end mt-4 text-indigo-700 ml-1 items-center">
              Go to Dashboard
              <ArrowRightIcon className="h-5 w-5 ml-1" aria-hidden="true" />
            </div>
          </Link>
        </div>
      </PopupModal>

      <div className="flex gap-1 items-center">
        <div>Project Information</div>
        <PencilSquareIcon
          onClick={() => setEditable(!editable)}
          className="h-5 w-5 text-indigo-700 cursor-pointer ml-2"
          aria-hidden="true"
        />
      </div>
      {isLoadingForm ? (
        <LoadingSpinner size="w-5 h-5" />
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 ">
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("businessName")}
              </label>
              <div className="mt-1">
                <input
                  disabled={!editable}
                  type="text"
                  defaultValue={
                    data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data?.value
                      ?.businessName
                  }
                  onChange={(e) => setFormData({ ...formData, businessName: e.target.value })}
                  className={`border-gray-300 block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                />
              </div>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("domainName")}
              </label>
              <div className="mt-1">
                <input
                  disabled={!editable}
                  type="text"
                  defaultValue={
                    data?.find((item: any) => item?.datatype_name === "DOMAIN_NAME")?.data?.value
                  }
                  onChange={(e) => setFormData({ ...formData, domainName: e.target.value })}
                  className={`border-gray-300 block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                />
              </div>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("businessType")}
              </label>
              <div className="mt-1">
                <input
                  required
                  disabled={!editable}
                  type="text"
                  defaultValue={
                    data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data?.value
                      ?.businessType
                  }
                  onChange={(e) => setFormData({ ...formData, businessType: e.target.value })}
                  className={`border-gray-300 block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                />
              </div>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("What product/ services does the business provide?")}
              </label>
              <div className="mt-1">
                <input
                  required
                  disabled={!editable}
                  type="text"
                  defaultValue={
                    data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data?.value
                      ?.businessProvider
                  }
                  onChange={(e) => setFormData({ ...formData, businessProvider: e.target.value })}
                  className={`border-gray-300 block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                />
              </div>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("Do you have online sales?")}
              </label>
              <div className="mt-1 flex items-center gap-5 text-sm text-gray-500">
                <div>
                  <input
                    type="radio"
                    id="yes1"
                    name="onlineSales"
                    onChange={(e) => setFormData({ ...formData, onlineSales: e.target.value })}
                    checked={formData.onlineSales === "yes"}
                    value="yes"
                  />
                  <label className="ml-1" htmlFor="yes1">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="no1"
                    name="onlineSales"
                    value="no"
                    onChange={(e) => setFormData({ ...formData, onlineSales: e.target.value })}
                    checked={formData.onlineSales === "no" || formData.onlineSales === undefined}
                  />
                  <label className="ml-1" htmlFor="no1">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("How much is the monthly budget you set for your ads?")}
              </label>
              <div className="mt-1 flex items-center gap-5 text-sm text-gray-500">
                <input
                  id="default-range"
                  type="number"
                  placeholder="Min"
                  min={0}
                  disabled={!editable}
                  onChange={(e) => setFormData({ ...formData, minBudgetAmount: e.target.value })}
                  defaultValue={
                    data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data?.value
                      ?.minBudgetAmount
                  }
                  className="border px-2 py-1 rounded appearance-none cursor-pointer dark:bg-gray-700 w-24 shadow-sm focus:border-primaryColor focus:outline-none"
                />
                -
                <input
                  id="default-range"
                  type="number"
                  placeholder="Max"
                  min={0}
                  disabled={!editable}
                  onChange={(e) => setFormData({ ...formData, maxBudgetAmount: e.target.value })}
                  defaultValue={
                    data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data?.value
                      ?.maxBudgetAmount
                  }
                  className="border px-2 py-1 rounded appearance-none cursor-pointer dark:bg-gray-700 w-24 shadow-sm focus:border-primaryColor focus:outline-none"
                />
              </div>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("Describe your business")}
              </label>
              <div className="mt-1">
                <textarea
                  disabled={!editable}
                  rows={4}
                  defaultValue={
                    data?.find((item: any) => item?.datatype_name === "BUSINESS_DATA")?.data?.value
                      ?.businessDescription
                  }
                  onChange={(e) =>
                    setFormData({ ...formData, businessDescription: e.target.value })
                  }
                  className={`border-gray-300 block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                />
              </div>
            </div>

            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("Have you ever advertised before? If yes, in which channels?")}
              </label>
              <div className="mt-1 flex items-center gap-5 text-sm text-gray-500">
                <div>
                  <input
                    type="radio"
                    id="yes2"
                    name="advertiseChannel"
                    onChange={(e) => setFormData({ ...formData, advertiseChannel: e.target.value })}
                    checked={formData.advertiseChannel === "yes"}
                    value="yes"
                  />
                  <label className="ml-1" htmlFor="yes2">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="no2"
                    name="advertiseChannel"
                    value="no"
                    onChange={(e) => setFormData({ ...formData, advertiseChannel: e.target.value })}
                    checked={formData.advertiseChannel === "no" || formData.advertiseChannel === ""}
                  />
                  <label className="ml-1" htmlFor="no2">
                    No
                  </label>
                </div>
              </div>
            </div>

            <div>
              <span className="text-textColor text-sm">{t("selectCountry")}</span>
              <CountryCombobox
                disabled={!editable}
                selectedCountry={
                  data?.find((item: any) => item?.datatype_name === "COUNTRIES")?.data?.value
                }
                countryData={null}
              />
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("keywords")}
              </label>
              <div className="mt-1">
                <input
                  disabled={!editable}
                  type="text"
                  name="first-name"
                  id="first-name"
                  className={`border-gray-300 block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setFormData({
                        ...formData,
                        keywords: [...formData.keywords, e.target.value],
                      });
                      e.target.value = "";
                    }
                  }}
                />
                <div className="mt-3 flex items-center gap-2">
                  {formData?.keywords?.map((a: any) => (
                    <div className="flex items-center bg-blue-400/40 text-blue-800 text-sm w-fit px-2 rounded-lg">
                      <div>{a}</div>
                      <XMarkIcon
                        className="h-4 w-4 ml-1 cursor-pointer"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            keywords: formData.keywords.filter((b: any) => b !== a),
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                {t("competitors")}
              </label>
              <div className="mt-1">
                <input
                  disabled={!editable}
                  type="text"
                  name="first-name"
                  id="first-name"
                  className={`border-gray-300 block w-full appearance-none rounded-md border  px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primaryColor focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      setFormData({
                        ...formData,
                        competitors: [...formData.competitors, e.target.value],
                      });
                      e.target.value = "";
                    }
                  }}
                />
                <div className="mt-3 flex items-center gap-2">
                  {formData?.competitors?.map((a: any) => (
                    <div className="flex items-center bg-blue-400/40 text-blue-800 text-sm w-fit px-2 rounded-lg">
                      <div>{a}</div>
                      <XMarkIcon
                        className="h-4 w-4 ml-1 cursor-pointer"
                        onClick={() => {
                          setFormData({
                            ...formData,
                            competitors: formData.competitors.filter((b: any) => b !== a),
                          });
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <DefaultButton
                isLoading={isLoading}
                isDisabled={!editable}
                type="submit"
                className="w-fit">
                {t("save")}
              </DefaultButton>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default ProjectUpdateForm;
