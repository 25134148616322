import client from "./client";

import { PostProjectData } from "@/models/postProjectData.interface";
import { StepperPostData } from "../models/stepperPostData.interface";
import { QueryFunctionContext } from "react-query";

export const fetchStories = async ({ queryKey }: QueryFunctionContext) => {
  // queryKey : [key, projectId, page, limit, status]
  // const skip = (parseInt(queryKey[2] as string) - 1) * parseInt(queryKey[3] as string);
  const response = await client.get(
    `/project/${queryKey[1]}/stories?limit=${queryKey[3]}&status=${queryKey[4]}`
  );
  return response.data;
};

export const fetchProjectCountries = async (project_id: number) => {
  const response = await client.get(`/project/${project_id}/countries`);
  return response.data;
};

export const postProjectData = async (projectPost: PostProjectData) => {
  const response = await client.post(`/project/${projectPost.projectId}/data`, projectPost.project);
  return response.data;
};

export const postArrayProjectData = async (data: { projectPost: any; projectId: number }) => {
  const response = await client.post(`/project/${data.projectId}/data`, data.projectPost);
  return response.data;
};

export const fetchProjectData = async (data: { projectId: number; name?: string }) => {
  const response = await client.get(`/project/${data.projectId}/data`, {
    params: {
      name: data.name,
    },
  });
  return response.data;
};

export const postOnboardingForm = async (stepperPost: StepperPostData) => {
  const response = await client.post(`/project/${stepperPost.projectId}/data`, stepperPost.project);
  return response.data;
};

export const fetchProjectSpecialDate = async (project_id: number) => {
  const response = await client.get(`/project/${project_id}/special-dates`);
  return response.data;
};

export const fetchStoryStatusFromIdentifier = async (data: {
  project_id: number;
  identifier: string;
}) => {
  const response = await client.get(`/project/${data.project_id}/stories/${data.identifier}`);
  return response.data;
};

export const fetchProjectPartnerTasks = async (data: { project_id: number; page?: number }) => {
  const params: any = {};
  if (data && data.page && data.page > 0) {
    params["page"] = data.page;
    params["limit"] = 10;
  }

  const response = await client.get(`/project/${data.project_id}/partner-tasks`, {
    params: params,
  });
  return response.data;
};

export const postProjectWorkDemands = async (data: {
  project_id: number;
  story_id: number;
  tasks?: [{ task_id: number; amount: number }] | [];
  title: string;
  content: string;
  due_date: string;
}) => {
  if (data?.tasks?.length === 0) {
    delete data.tasks;
  }

  const response = await client.post(`/project/work-demand`, data);
  return response.data;
};

export const fetchProjectNotifications = async (project_id: number) => {
  const response = await client.get(`/project/${project_id}/notifications`);
  return response.data;
};

// roadmap
export const fetchProjectRoadmap = async (project_id: number) => {
  const response = await client.get(`/project/${project_id}/roadmap`);
  return response.data;
};

export const fetchProjectRoadmapDetail = async (data: {
  roadmap_id: number;
  project_id: number;
}) => {
  const response = await client.get(`roadmaps/${data.roadmap_id}`, {
    params: {
      project_id: data.project_id,
    },
  });
  return response.data;
};

export const postProjectRoadmap = async (data: { project_id: number; roadmap_id: number }) => {
  const response = await client.post(`/project/${data.project_id}/roadmap`, null, {
    params: {
      roadmap_id: data.roadmap_id,
    },
  });
  return response.data;
};

export const fetchRoadmapCategories = async () => {
  const response = await client.get(`/roadmaps/categories`);
  return response.data;
};

export const fetchKeywordsFromGenerator = async (data: {
  project_id: number;
  language: "TR" | "EN";
  keyword: string;
}) => {
  const response = await client.get(`/project/${data.project_id}/keywords-generator`, {
    params: {
      keyword: data.keyword,
    },
  });

  return response.data;
};

export const fetchRoadmapLogs = async (project_id: number) => {
  const response = await client.get(`/roadmaps/active-rule/${project_id}`);
  return response.data;
};

export const fetchPreviewData = async (data: { project_id: number; project_story_id: string }) => {
  const response = await client.get(`/project/project_file`, {
    params: {
      project_id: data.project_id,
      project_story_id: data.project_story_id,
    },
  });
  return response.data;
};

export const postProjectFile = async (data: {
  project_id: number;
  file_in?: File;
  file_url?: string;
  file_type?: string;
  additional_data?: string;
}) => {
  const form_data = new FormData();

  form_data.append("project_id", data.project_id as any);
  if (data.file_url) form_data.append("file_url", data.file_url);
  if (data.file_type) form_data.append("file_type", data.file_type);
  if (data.additional_data) form_data.append("additional_data", data.additional_data);
  if (data.file_in) form_data.append("file_in", data.file_in);

  const response = await client.post(`/project/project_file`, form_data);
  return response.data;
};
