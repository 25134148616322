import { useEffect, useState } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import { t } from "i18next";

import RuleCard from "@/features/rules/components/RuleCard";
import Filter from "@/features/rules/components/Filter";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { useQuery } from "react-query";
import { fetchRules } from "@/api/rules";
import ContentBar from "@/components/ContentBar";

import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";
import { pageViewEvent } from "@/analytics/mixpanel";

const RulesPage = () => {
  const [selectFilteredData, setSelectFilteredData] = useState<any>("");
  const [user] = useRecoilState(userAtom);
  const [search, setSearch] = useState<string>("");

  // const { isLoadingGoogleAdsLogin, isSuccess } = useGoogleAdsLogin();

  const { data, isLoading } = useQuery("fetchRules123", () => fetchRules(), {
    retry: false,
    // cacheTime: 0,
  });

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "rules",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  return (
    <AssessmentLayout>
      {isLoading ? (
        <div className="flex h-[calc(100vh-2rem)] justify-center items-center ">
          <LoadingSpinner size="w-8 h-8" />
        </div>
      ) : (
        <>
          <ContentBar title={t("rules")} />
          <div className="my-8">
            <div className="text-gray-500 mb-6">
              {t(
                "Rules regularly monitor and audit your digital assets. As a result, it presents you with potential threats and opportunities so you don't overlook them. It helps you strengthen your digital marketing by alerting you to anomalies in your account and ad campaigns and creating tasks for you. In this section, you can review the rules that work for you and see what's under audit."
              )}
            </div>

            <div className="flex justify-end mb-4">
              <Filter
                selectFilteredData={selectFilteredData}
                setSelectFilteredData={setSelectFilteredData}
              />
            </div>
            <div className="py-4 pb-8">
              <input
                className="w-1/2 h-9 px-3 text-base placeholder-gray-400 border rounded-lg focus:shadow-outline outline-none"
                type="search"
                placeholder="Search Rules"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div className="flex gap-[30px] flex-wrap">
              {data
                .filter((data: any) =>
                  selectFilteredData ? selectFilteredData === data.application : data
                )
                .filter((data: any) =>
                  search ? data.name.toLowerCase().includes(search.toLowerCase()) : data
                )
                .map((item: any, index: any) => (
                  <RuleCard data={item} key={index} />
                ))}
            </div>
          </div>
        </>
      )}
    </AssessmentLayout>
  );
};

export default RulesPage;
