interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  name: string;
  value?: string;
  title?: string;
  type?: React.HTMLInputTypeAttribute;
  required?: boolean;
  rows?: number;
  defaultValue?: string;
}

const TextArea = (props: Props) => {
  const { placeholder, onChange, name, value, title, required, rows, defaultValue } = props;
  return (
    <div className="w-full flex flex-col gap-2">
      <label htmlFor={name} className="text-textColor text-sm">
        {title}
      </label>
      <textarea
        rows={rows || 5}
        {...props}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        className="w-full resize-none appearance-none py-[10px] px-5 text-black text-sm leading-5 outline-none rounded-[10px] border-[1px] border-borderStroke bg-white focus:border-focusStroke"
        name={name}
        id={name}
        required={required}
      />
    </div>
  );
};

export default TextArea;
