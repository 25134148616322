import { useEffect } from "react";
import AssessmentLayout from "@/components/Layout/Assessment";
import ContentBar from "@/components/ContentBar";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchProjectReports } from "@/api/reports";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

import { reportsDetailEvent } from "@/analytics/mixpanel";
import { useRecoilState } from "recoil";
import { userAtom } from "@/store/atoms/user.atoms";

import AdsReport from "@/features/report/pages/AdsReport";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import AnalyticsReport from "@/features/report/pages/AnalyticsReport";
import InfoSection from "@/components/InfoSection";

const ReportsDetailsPage = () => {
  const { t } = useTranslation();
  const params: any = useParams();
  const [user] = useRecoilState(userAtom);

  const { projectId } = useFetchingProjectIds();

  const { data, isLoading, isSuccess } = useQuery(
    "fetchProjectReports",
    () =>
      fetchProjectReports({
        projectId: projectId,
        reportId: params.id as any,
      }),
    {
      retry: false,
      enabled: !!projectId && !!params.id,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const getPaths = JSON.parse(localStorage.getItem("paths") as any);
      reportsDetailEvent({
        reportName: data?.name,
        reportId: params.id?.toString() as any,
        userId: user?.id.toString() || "",
        email: user?.email || "",
        previousPage: getPaths?.prevPath || "",
      });
    }
  }, [isSuccess]);

  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("reports")} />
        <InfoSection
          name="ReportDetailInfoSection"
          bgClass="rounded-lg bg-yellow-100 p-2 sm:p-3 shadow-sm"
          icon="ExclamationCircleIcon"
          iconClass="h-6 w-6 text-white"
          text={t("reportInfoText")}
          fontClass="ml-3 mr-8"
          hoverClass="hover:bg-yellow-500"
          iconSpanClass="flex rounded-lg bg-yellow-500 p-2"
          boldSentenceIndex={[0]}
          closeButton={true}
        />
        <div className="text-sm text-[#707070] mt-8 mb-6">
          <div className="text-sm text-[#707070] flex gap-2 flex-row items-center w-fit pb-1">
            <div>
              <HomeIcon className="w-4 h-4" />
            </div>
            <div>
              <Link className="flex items-center gap-2" to="/reports">
                <ChevronRightIcon className="w-4 h-4" />
                {t("reports")}
              </Link>
            </div>
          </div>
        </div>

        {isLoading ? (
          <LoadingSpinner size="w-8 h-8" />
        ) : (
          <div className="overflow-auto ">
            {/* {+params.id === 8 && <AssessmentReport data={data} />}
            {+params.id === 10 && <TrafficReport data={data} />} */}
            {+params.id === 19 && <AdsReport data={data} />}
            {params.application === "google_analytics" && <AnalyticsReport reportId={params.id} />}
          </div>
        )}

        {/* {location.state.report.status.toLowerCase() === "preparing" && (
          <>
            <div className=" bg-white px-2 py-6 rounded-lg right-0 bottom-0 font-medium flex items-center mt-7 ">
              <img src="/assets/icons/spinner.svg" alt="" width={50} />
              <div className="ml-4">This report is still preparing.</div>
            </div>
          </>
        )} */}
      </AssessmentLayout>
    </div>
  );
};

export default ReportsDetailsPage;
