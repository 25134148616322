import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SimpleTabProps } from '@/common/SimpleTabProps';
import { t } from 'i18next';
import ReportTable from '@/features/report/components/cards/ReportTable';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            key={index}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SimpleTab(props: SimpleTabProps) {
    const [value, setValue] = React.useState(0);
    const simpleTabDataProps = props.simpleTabDataProps;

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {
                        simpleTabDataProps?.map((item: any, index: number) => (
                            <Tab label={item.label} {...a11yProps(index)} key={'tab2' + item.akey} />
                        ))
                    }
                </Tabs>
            </Box>
            {
                simpleTabDataProps?.map((item: any, index: number) => (
                    <CustomTabPanel value={value} index={index} key={index}>
                        <ReportTable
                            title={t("autopilotreporttitle").toUpperCase()}
                            description={t("autopilotreportdescription")}
                            tableHeader={item.tableHeaders}
                            akey={item.akey}
                            rows={item.tableRows}
                        ></ReportTable>
                    </CustomTabPanel>
                ))
            }
        </Box >
    );
}
