import { t } from "i18next";
import React from "react";

type Props = {
  children?: React.ReactNode;
  title?: string;
  description?: string;
  descriptionLink?: string;
  subDescription?: string;
  date?: string;
  size?: string;
  height?: string;
  customTitle?: string;
  reportDetail: any;
};

const ContentCard = (props: Props) => {
  const {
    children,
    description,
    title,
    descriptionLink,
    subDescription,
    date,
    customTitle,
    reportDetail,
    size = "w-[540px]",
    height = "h-[350px] max-h-[350px]"
  } = props;

  return (
    <div className={`flex flex-col rounded-[10px] border  text-sm shadow items-center ${size}`}>
      <div className="relative border-b py-3 px-6 bg-[#F8F8FC] w-full overflow-y-auto custom-scroll rounded-t-[10px] ">
        <div className="absolute right-5 font-medium text-gray-600 text-xs">
          {reportDetail.sample ? (
            <div className=" font-medium text-sm text-orange-500 bg-orange-50 w-fit rounded-lg ">
              {t("sample")}
            </div>
          ) : (
            date || ""
          )}
        </div>
        <div className="font-medium text-base mb-2 capitalize">
          {title || customTitle || "Report"}
        </div>
        {description && <div className="text-gray-500 text-sm ">{description}</div>}

        {descriptionLink && (
          <div className="text-xs mt-2">
            <a
              className="text-indigo-600"
              href={descriptionLink || "#"}
              target="_blank"
              rel="noopener noreferrer">
              {t("Learn more about this report")}
            </a>
          </div>
        )}
      </div>
      <div className={`${height} bg-white bold-custom-scroll overflow-y-auto rounded-b-[10px]  w-full`}>
        <div className="px-8 justify-center mt-5 w-full items-center">
          <div className="w-full">{children}</div>
          <div className="mt-2 text-center">{subDescription}</div>
        </div>
      </div>
    </div>
  );
};

export default ContentCard;
