import client from "./client";

export const postLinkedinAccessToken = async (data: {
  project_id: number;
  code: string;
  redirect_uri: string;
}) => {
  const new_data = new FormData();
  new_data.append("code", data.code);
  new_data.append("redirect_uri", data.redirect_uri);

  const response = await client.post(
    "/social_media/linkedin/auth/long_lived_user_access_token",
    new_data,
    {
      params: { project_id: data.project_id },
    }
  );

  return response.data;
};

export const fetchLinkedinTokenStatus = async (projectId: number) => {
  const response = await client.get("/social_media/linkedin/auth/token_status", {
    params: { project_id: projectId },
  });
  return response.data;
};

export const postLinkedinNewPost = async (data: {
  commentary: string;
  organization_urn: string;
  project_id: number;
}) => {
  const response_form = new FormData();
  response_form.append("project_id", data.project_id as any);
  response_form.append("page_id", data.organization_urn);
  response_form.append("message", data.commentary);

  const response = await client.post("/social_media/linkedin/post/create_text_post", response_form);
  return response.data;
};

export const fetchOrganizations = async (projectId: number) => {
  const response = await client.get(`/social_media/linkedin/auth/organizations`, {
    params: { project_id: projectId },
  });
  return response.data;
};

export const removeLinkedinConnection = async (projectId: number) => {
  const response = await client.delete(`/social_media/linkedin/auth/${projectId}`);

  return response.data;
};

export const fetchLinkedinPages = async (projectId: number) => {
  const response = await client.get(`/social_media/linkedin/auth/organizations`, {
    params: { project_id: projectId },
  });
  return response.data;
};