import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

type SimpleLineChartProps = {
    data: any;
    lineProps: LineProps[];
    dataKey: string;
    width?: any;
    height?: any;
    languageCode: string;
}

type LineProps = {
    dataKey: string;
    color: string;
    name: string;
}

export default function SimpleLineChart(props: SimpleLineChartProps) {
    return (
        <ResponsiveContainer width={props.width || 500} height={props.height || 300}>
            <LineChart
                width={props.width || 500}
                height={props.height || 300}
                data={props.data}
                key={props.dataKey}
            >
                {console.log("props", props)}
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={props.dataKey} />
                <YAxis tickFormatter={(value) =>
                    new Intl.NumberFormat(props.languageCode, {
                        notation: "compact",
                        compactDisplay: "short",
                    }).format(value)
                } />
                <Tooltip formatter={(value: any) => value && value.toLocaleString()} />
                <Legend />
                {props.lineProps.map((lineProp: LineProps) => (
                    <Line key={lineProp.name} name={lineProp.name} type="monotone" dataKey={lineProp.dataKey} stroke={lineProp.color} activeDot={{ r: 8 }} />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}
