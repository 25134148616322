import React from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";

type SimpleBarChartProps = {
    data: any;
    dataKey: string;
    barProps: BarProps[];
    height?: number;
}

type BarProps = {
    isKey: boolean;
    dataKey: string;
    color: string;
    name: string;
}

export default function SimpleBarChart(props: SimpleBarChartProps) {
    return (
        <ResponsiveContainer width={"100%"} height={props.height || 300}>
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={props.dataKey} />
                <YAxis />
                <Tooltip />
                <Legend />
                {
                    props.barProps.map((barProp: BarProps) => (
                        barProp.isKey !== true &&
                        <Bar key={barProp.name} dataKey={barProp.dataKey} name={barProp.name} fill={barProp.color} />
                    ))
                }
            </BarChart>
        </ResponsiveContainer>
    );
}
