import client from "./client";

export const fetchProjectMetrics = async (data: { projectId: string; metricId: string }) => {
  const response = await client.get(`/metric/project_metric/${data.metricId}`, {
    params: {
      project_id: data.projectId,
    },
  });

  return response.data;
};
