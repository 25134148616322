import { useQuery } from "react-query";
import { loginGoogleAds } from "@/api/googleAds";

const useGoogleAdsLogin = () => {
  const {
    isLoading: isLoadingGoogleAdsLogin,
    isSuccess
  } = useQuery("googleAdsLogin", loginGoogleAds);

  return { isLoadingGoogleAdsLogin, isSuccess };
};

export default useGoogleAdsLogin;
