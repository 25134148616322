import client from "./client";

export const fetchMvpQuestions = async (data: { form: string }) => {
  const response = await client.get("/mvp/questions", {
    params: {
      form: data.form,
    },
  });
  return response.data;
};

export const fetchRoadmapUrl = async (projectId: number) => {
  const response = await client.get("/mvp/roadmap_url", {
    params: {
      project_id: projectId,
    },
  });
  return response.data;
};

export const fetchMvpRoadmaps = async () => {
  const response = await client.get("/mvp/roadmaps");
  return response.data;
};
