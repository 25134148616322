import React from "react";

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  children: any;
  className?: string;
}

const BlurInput = (props: Props) => {
  const { children, className } = props;
  const [onblur, setOnblur] = React.useState<any>(false);

  return <div className={className}>{children({ setOnblur, onblur })}</div>;
};

export default BlurInput;
