import React from "react";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { t } from "i18next";
import { useMutation } from "react-query";
import { confirmPartnerJob } from "@/api/partner";
import { taskNavbar } from "@/store/atoms/taskNavbar.atoms";
import { useRecoilState } from "recoil";

type Props = {
  item: any;
  handlePartnerTaskStatus: any;
  refetchTasks: any;
  setOpen: any;
};

const ConfirmButton = (props: Props) => {
  const { item, handlePartnerTaskStatus, refetchTasks, setOpen } = props;
  const [navbarFetch, setNavbarFetch] = useRecoilState<any>(taskNavbar);

  const {
    mutateAsync: confirmJob,
    isLoading: isLoadingConfirmJob,
    isSuccess,
  } = useMutation(confirmPartnerJob, {});

  const handleConfirmJob = async () => {
    confirmJob(item.partner_task_id)
      .then((res) => {
        handlePartnerTaskStatus(item.partner_task_id, "finished");
        if (res.story_is_finished) {
          setNavbarFetch(!navbarFetch);
        }
      })
      .then(() => {
        refetchTasks();
        setOpen(false);
      });
  };

  return (
    <DefaultButton
      isLoading={isLoadingConfirmJob}
      isDisabled={isSuccess}
      onClick={handleConfirmJob}>
      {t("confirm")}
    </DefaultButton>
  );
};

export default ConfirmButton;
