import React from "react";
import { useQuery } from "react-query";
import { fetchProjectMetrics } from "@/api/metric";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import AreaChart from "@/features/report/components/charts/AreaChart";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import { Link } from "react-router-dom";
import { t } from "i18next";
import PricingTableForAssign from "@/components/PricingTableForAssign";

type Props = {
  projectId: any;
  pricingPlan: any;
};

const Graph2 = (props: Props) => {
  const { projectId, pricingPlan } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const { data: dataSecond, isLoading: isLoadingMetrics } = useQuery(
    "fetchProjectMetrics20",
    () => fetchProjectMetrics({ projectId: projectId, metricId: "20" }),
    {
      retry: false,
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    }
  );

  const handleUpgrage = () => {
    setIsOpen(true);
  };

  return (
    <div>
      {isLoadingMetrics ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <PricingTableForAssign
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            title={t("The features covered by your plan are over!")}
            description={t(
              "If you want to contune using the features’ how about choosing a plan that suits you better?"
            )}
          />

          <div className="overflow-hidden rounded-lg py-2  custom-scroll bg-white shadow overflow-x-hidden relative ">
            <div className="pl-4 pt-4 font-medium text-xl">{dataSecond?.metric_name}</div>

            {pricingPlan.id > 1 && (
              <>
                {dataSecond?.sample && (
                  <div className="absolute font-medium text-sm text-orange-500 mt-1 bg-orange-50 w-fit rounded-lg py-.5 px-2.5 right-1 top-2">
                    {t("sample")}
                  </div>
                )}
                {dataSecond?.data && <AreaChart data={dataSecond?.data} height={183} />}
              </>
            )}

            {dataSecond?.is_premium && pricingPlan.id === 1 && (
              <div className="flex justify-center">
                <button onClick={() => handleUpgrage()}>
                  <DefaultButton className="w-fit my-5 ">{t("Upgrade Your Plan")}</DefaultButton>
                </button>
              </div>
            )}

            {pricingPlan.id > 1 &&
              dataSecond?.required_applications.length > 0 &&
              dataSecond?.required_applications.map((item: any, index: any) => {
                return (
                  <div className="flex justify-center" key={index}>
                    <Link to="/integrations">
                      <DefaultButton className="w-fit my-5 capitalize">
                        {item.replace("_", " ")}
                        {t("required")}
                      </DefaultButton>
                    </Link>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default Graph2;
