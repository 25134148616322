import React, { useState } from "react";

type Props = {
  children: (props: { openCard: boolean; setOpenCard: any }) => React.ReactNode;
};

const GeneralState = (props: Props) => {
  const { children } = props;
  const [openCard, setOpenCard] = useState(false);

  return <>{children({ openCard, setOpenCard })}</>;
};

export default GeneralState;
