import { useState, useEffect } from 'react';
import { fetchAutoPilotRules, fetchEaStatus, getAutoPilotAssets, stopAutoPilot } from "src/api/eA";
import { useRecoilState } from "recoil";
import { projectInfo } from "@/store/atoms/projectInfo.atoms";
import AssessmentLayout from '@/components/Layout/Assessment';
import ContentBar from '@/components/ContentBar';
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LoadingSpinner from '@/components/UI/LoadingSpinner';
import SimpleTab from '@/components/SimpleTab';
import { TableRowProps } from '@/common/TableRowProps';
import Modal from '@/components/Modal';
import Button from '@/components/buttons/Button';
import SetForm from './popup/SetForm';
import { useQuery } from 'react-query';
import InfoSection from '@/components/InfoSection';

const AutoPilotPage = () => {
  const { t } = useTranslation();
  const [dataFromApi, setData] = useState<any>(null);
  const [project] = useRecoilState(projectInfo);
  const [isLoading, setLoadingState] = useState(true);
  const [isLoadingInPopup, setLoadingStateInPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupContent, setOpenPopupContent] = useState<any>(null);
  const [openPopupOption, setOpenPopupOption] = useState("set");

  const [refreshCount, setRefreshCount] = useState(0);

  const handleRefresh = () => {
    setRefreshCount(prevCount => prevCount + 1);  // Trigger a change in refreshCount, which will re-run the useEffect
  }

  const { data: eaStatus, isLoading: isLoadingStatus } = useQuery(
    ["fetchEaStatus", project.id],
    () => fetchEaStatus({ project_id: project.id }),
    {
      retry: true,
      enabled: !!project.id,
    }
  );

  useEffect(() => {
    if (!isLoadingStatus && eaStatus === false) {
      window.location.href = "/integrations";
    }
  }, [eaStatus, isLoadingStatus]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchAutoPilotRules({ project_id: project.id });
        setData(result.data);
        setLoadingState(false);
      } catch (error: any) {
        if (error.response.status === 401)
          return window.location.href = "/integrations";
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [refreshCount]);

  const onClickStopButton = async (auto_pilot_item_id: number) => {
    try {
      const result = await stopAutoPilot({ auto_pilot_item_id: auto_pilot_item_id });
      if (result.status === 200) {
        setLoadingState(true);
        setOpenPopup(false);
        handleRefresh();
      }
    } catch (error) {
      console.error("Error stopping the auto-pilot:", error);
    }
  }

  const getAutoPilotAssetFromApi = async (customerClientId: number, ruleIdentifier: string) => {
    try {
      const result = await getAutoPilotAssets({ customerClientId: customerClientId, ruleIdentifier: ruleIdentifier });
      if (result.status === 200) {
        return result.data;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const CreateRows = (data: any) => {
    const rowProps: TableRowProps[] = data.rules.map((rule: any) => ({
      className: "even:bg-gray-50",
      cells: [
        {
          className: "px-3 py-4 text-sm text-gray-500",
          text: rule.text,
          desc: rule.description,
          type: "textwithdesc",
        },
        {
          className: "px-3 py-4 text-sm text-gray-500",
          text: rule.severityText,
        },
        {
          className: "px-3 py-4 text-sm light-blue-500",
          text: t("seeExecutionHistory"),
          type: "link",
          link:
            "auto-pilot/execution-history?r=" + rule.ruleIdentifier + "&c=" + data.customerClientId,
        },
        {
          className: "px-3 py-4 text-sm text-gray-500",
          text: rule.scopeText,
          type: "text",
          // 2. aşama
          // className: rule.violatingAssetCount === 0 ? "whitespace-nowrap px-3 py-4 text-sm text-gray-500" : "whitespace-nowrap px-3 py-4 text-sm light-blue-500",
          // text: rule.scopeText + (rule.violatingAssetCount <= 0 ? "" :
          //   " (" + rule.violatingAssetCount + ")"
          // ),
          // type: rule.violatingAssetCount === 0 || rule.violatingAssetCount === undefined ? "text" : "link",
          // link: "auto-pilot/violating-assets?r=" + rule.ruleIdentifier + "&c=" + data.customerClientId
        },
        {
          className: "text-sm text-gray-500 px-3 py-4",
          type: "button",
          buttonProps: rule.autoPilotDefined
            ? {
              buttonType: "cancelButton",
              text: t("stopAutoPilot"),
              onClick: () => {
                setOpenPopup(true);
                setOpenPopupOption("stopAutoPilot");
                setOpenPopupContent({
                  id: rule.autoPilotItemId,
                  ruleText: rule.text,
                });
              },
            }
            : {
              buttonType: "setButton",
              text: t("setAutoPilot"),
              onClick: async () => {
                setLoadingStateInPopup(true);
                const assets = await getAutoPilotAssetFromApi(
                  data.customerClientId,
                  rule.ruleIdentifier
                );

                setOpenPopup(true);
                setOpenPopupOption("setAutoPilot");
                setOpenPopupContent({
                  customerClientId: data.customerClientId,
                  ruleText: rule.text,
                  ruleIdentifier: rule.ruleIdentifier,
                  options: assets.map((asset: any) => {
                    return {
                      label: null !== asset.displayText ? asset.displayText : "",
                      value: null !== asset.assetId ? asset.assetId : 0,
                    };
                  }),
                });
                setLoadingStateInPopup(false);
              },
            },
        },
      ],
      akey: rule.ruleIdentifier,
    }));
    return rowProps;
  }




  return (
    <div>
      <AssessmentLayout>
        <ContentBar title={t("autopilottitle")} />
        <div className="text-sm text-[#707070] mt-8 mb-6">
          <div className="text-sm text-[#707070] flex gap-2 flex-row items-center w-fit pb-1">
            <div>
              <HomeIcon className="w-4 h-4" />
            </div>
            <div>
              <Link className="flex items-center gap-2" to="/auto-pilot">
                <ChevronRightIcon className="w-4 h-4" />
                {t("autopilottitle")}
              </Link>
            </div>
          </div>
        </div>

        {isLoading ? (
          <LoadingSpinner size="w-8 h-8" />
        ) : (
          <div className="overflow-auto ">
            {
              <InfoSection
                name="AutoPilotInfoSection"
                bgClass="rounded-lg bg-blue-100 p-2 sm:p-3 shadow-sm"
                icon="CogIcon"
                iconClass="h-6 w-6 text-white"
                text={t("autoPilotInfoText")}
                fontClass="ml-3 mr-8"
                hoverClass="hover:bg-blue-500 hover:text-white"
                iconSpanClass="flex rounded-lg bg-blue-500 p-2"
                boldSentenceIndex={[0, 2]}
                closeButton={true}
                buttonWithLink={true}
                buttonWithLinkClass='flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-50 hover:text-gray-500'
                buttonWithLinkTarget='/help-center'
                buttonWithLinkText={t("contactUs")}
              />
            }
            {
              <SimpleTab
                simpleTabDataProps={dataFromApi.customerClientRules.flatMap((customerClientRule: any) => ({
                  tableHeaders: [
                    {
                      "text": t("rule"),
                      "align": "text-left",
                    },
                    {
                      "text": t("severity"),
                      "align": "text-left",
                    },
                    {
                      "text": t("execution"),
                      "align": "text-left",
                    },
                    {
                      "text": t("scope"),
                      "align": "text-left",
                    },
                    {
                      "text": "",
                      "align": "text-left",
                    },
                  ],
                  tableRows: CreateRows(customerClientRule),
                  label: customerClientRule.customerClientName + " - " + customerClientRule.customerClientId,
                  akey: customerClientRule.customerClientId,
                }))}
              />
            }
          </div>
        )
        }
      </AssessmentLayout>

      <div id="modal-root">
        <Modal
          isOpen={openPopup}
          onClose={() => setOpenPopup(false)}
          title={openPopupOption === "setAutoPilot" ? t("autopilotSetPopupTitle") : t("autopilotStopPopupTitle")}
          description={openPopupOption === "setAutoPilot" ? t("autopilotSetPopupDescription") : t("autopilotStopPopupDescription")}>
          {openPopupOption !== "setAutoPilot" ?
            <>
              <div className="text-lg font-medium divide-y divide-gray-200">
                {openPopupContent !== null ? openPopupContent.ruleText : ""}
              </div>
              <br />
              <div className="flex space-x-4">
                <Button
                  buttonType="halfBackButton"
                  onClick={() => setOpenPopup(false)}
                  text={t("cancel")} />
                <Button
                  buttonType="defaultHalfButton"
                  onClick={() => openPopupOption === "setAutoPilot" ? console.log("Tıklandı") : onClickStopButton(openPopupContent.id)}
                  text={t(openPopupOption)} />
              </div>
            </> :
            <> {
              isLoadingInPopup ? (
                <div className="flex align-center">
                  <LoadingSpinner size="w-8 h-8" />
                </div>
              ) :
                (
                  <>
                    <SetForm
                      buttonText={t("setAutoPilot")}
                      options={
                        openPopupContent.options
                      }
                      placeholder={t("atLeastSelectAnOption")}
                      ruleIdentifier={openPopupContent.ruleIdentifier}
                      customerClientId={openPopupContent.customerClientId}
                      onRefresh={() => handleRefresh()}
                    ></SetForm>
                  </>
                )
            }
            </>
          }
        </Modal>
      </div>
    </div >
  );
};

export default AutoPilotPage;
