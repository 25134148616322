export const tr = {
  autoPilotInfoText: "Otopilot özelliğimizde kullanılan kurallar sürekli olarak güncellenmekte ve geliştirilmektedir. Günden güne sisteme eklediğimiz yeni kurallar sayesinde, kullanıcı deneyiminizi daha da iyileştirmeyi hedefliyoruz. Ancak, sistemimizde yer almayan ve eklenmesini istediğiniz özel kurallar olabilir. Eğer autopilot özelliğimize eklememizi istediğiniz özel kurallar varsa, lütfen bizimle iletişime geçmekten çekinmeyin.",
  recaptchaFailed: "Recaptcha doğrulaması başarısız oldu. Lütfen destek ekibiyle iletişime geçin.",
  reportInfoText: "Google Ads ve Google Analytics'ten veri çekme işlemleri, veri hacmi ve ağ yoğunluğuna bağlı olarak zaman alabilir. Bu süreç, raporlarda yer alan verilerin doğruluk ve güvenilirliğini sağlamak için gereklidir. Lütfen raporlarınızın en güncel ve doğru verileri yansıtmasını garantilemek adına veri senkronizasyonunun tamamlanmasını bekleyiniz. İşlemin süresi, çekilen veri miktarına bağlı olarak değişkenlik gösterebilir. Anlayışınız ve sabrınız için teşekkür ederiz.",
  adsPropertiesPageSuccess: "Google Ads hesabınızın bağlantısı tamamlandı ve verileriniz toplanmaya başlandı. Bu işlem biraz zaman alabilir. Raporlarınız hazır olduğunda ve görüntülenmeye hazır olduğunda sizi e-posta yoluyla bilgilendireceğiz.",
  goBack: "Geri dön",
  analyticsPropertiesPageSuccess: "Google Analytics hesabınızın bağlantısı tamamlandı ve verileriniz toplanmaya başlandı. Bu işlem biraz zaman alabilir. Raporlarınız hazır olduğunda ve görüntülenmeye hazır olduğunda sizi e-posta yoluyla bilgilendireceğiz.",
  setUpNow: "Şimdi kur",
  refineCampaignsEffortlesslyClickSetUpToTailorRulesEnsuringCustomizedOptimizationForYourUniqueAdStrategy: "Kampanyalarınızı sorunsuz bir şekilde düzenleyin. Özelleştirilmiş reklam stratejiniz için kuralları belirlemek üzere 'Kur' butonuna tıklayın, böylece kişiye özel optimizasyon sağlanır.",
  elevateYourAds: "Reklamlarınızı yükseltin",
  setUpAutoPilot: "Otopilotu kur",
  getAllInsights: "Tüm görüşleri al",
  accessClicksImpressionsCTRConversionsAndCostsForInDepthAdAnalysisAcrossAllCampaignsAndAdGroups: "Tüm kampanyalar ve reklam grupları için detaylı reklam analizi için erişim, tıklamalar, gösterimler, CTR, dönüşümler ve maliyetler",
  unlockYourMetrics: "Metriklerini aç",
  getYourFirstReport: "İlk Raporunu Al",
  connectAccounts: "Hesapları bağla",
  dataFuelsAccuracyConnectGoogleAdsAndAnalyticsForPreciseInsightsIntoThreatsAndOpportunities: "Veri, tehdit ve fırsatlar hakkında kesin bilgiler için Google Ads ve Analytics'i bağlayın",
  letsSyncForInsights: "Görüşler için senkronize edin",
  connectYourAccounts: "Hesaplarınızı bağlayın",
  followTheseSimpleStepsOrDropUsaLineIfYouNeedaFriendlyGuide: "Bu basit adımları izleyin veya dost bir rehber ihtiyacınız varsa bize bir istek bırakın",
  letsRockDigitalMarketingSuccessWithPrecision: "Dijital pazarlama başarısını hassasiyetle yaşayın",
  toCreateReportPleaseConnectAccounts: "Rapor oluşturmak için lütfen hesaplarınızı bağlayın",
  selectMetrics: "Metrikleri seçin",
  sampleReportName: "Örnek rapor adı",
  pleaseEnterReportName: "Lütfen rapor adını giriniz",
  reportTemplates: "Rapor Şablonları",
  reportName: "Rapor Adı",
  metrics: "Metrikler",
  createReportStarted: "Rapor oluşturma işlemi başlatıldı!",
  CreateReport: "Rapor Oluştur",
  executionHistoryNotFound: "Yürütme geçmişi bulunamadı",
  noAssetsFoundForSettingException: "İstisna oluşturmak için varlık bulunamadı.",
  autoPilotItemCreated: "Otomatik pilot öğesi oluşturuldu!",
  atLeastSelectAnOption: "En az bir seçenek seçin",
  keepThisRuleOutFor: "Bu kuralı şunlar için dışarıda tut:",
  informationStopAutopilot: "Otomatik pilot hakkında bilgi",
  selectAnActionPauseRemove: "Bir eylem seçin (Durdur / Kaldır)",
  pause: "Durdur",
  autopilotSetPopupDescription: "Otomatik pilotunuzu ayarlamak için aşağıdaki bilgileri doldurarak başlayın.",
  autopilotSetPopupTitle: "Otopilot Kurulumu",
  autopilotStopPopupDescription: "Bu öğe, aşağıdakiler için yürütüldü. Otomatik pilotu durdurmak ister misiniz?",
  autopilotStopPopupTitle: "Otopilotu durdurmak istediğinize emin misiniz?",
  noExecutionsFound: "Yürütme geçmişi bulunamadı",
  adGroupKeyword: "Reklam Grubu Anahtar Kelimesi",
  adGroupName: "Reklam Grubu Adı",
  action: "Aksiyon",
  actionIkiNokta: "Aksiyon:",
  campaignName: "Kampanya Adı",
  executionDate: "Yürütme Tarihi",
  setAutoPilot: "Otopilot Kur",
  stopAutoPilot: "Otopilotu Durdur",
  seeExecutionHistory: "Yürütme Geçmişini Gör",
  executionHistoryTitle: "Yürütme Geçmişi",
  autopilot: "Otopilot",
  autopilottitle: "Google Ads Otopilot",
  autopilotreporttitle: "Kurallar",
  autopilotreportdescription: "Bu tablo, reklam kampanyalarınızın daha spesifik ve ayrıntılı bir dizi kural içinde uyumluluğunu analiz etmek ve gerektiğinde iyileştirmeleri kolaylaştırmak için tasarlanmış bir kontrol aracıdır. Bu tablo, her kampanyanın benzersiz gereksinimlerini karşılayıp karşılamadığını belirlemek ve optimizasyon için gerekli eylemleri planlamak için özelleştirilmiştir.",
  rule: "Kural",
  "Welcome to React": "React'e ve react-i18next'e hoşgeldiniz",
  english: "İngilizce",
  turkish: "Türkçe",
  dashboard: "Ana Sayfa",
  stories: "Hikayeler",
  deliverables: "Çıktılar",
  reports: "Raporlar",
  calendar: "İçerik Takvimi",
  activeStories: "Aktif hikayeler",
  kpiAnalysis: "KPI analizi",
  noCurentData: "Şu anda veri yok.",
  inProgress: "Devam ediyor",
  finished: "Tamamlanmış",
  logout: "Çıkış",
  adsManagement: "Reklam Yönetimi",
  adsAnalyses: "Reklam Analizleri",
  campaigns: "Kampanyalar",
  displayedProject: "Görüntülenen proje",
  viewAllStories: "Tüm hikayeleri gör",
  showMore: "Daha fazlasını göster",
  backToActiveStories: "Aktif hikayelere dön",
  detail: "Detaylar",
  errorDetail: "Sorun detayları",
  totalErrors: "Toplam sorun",
  critical: "Kritik",
  high: "Yüksek",
  moderate: "Orta",
  low: "Düşük",
  errorType: "Sorun özeti",
  severity: "Önem",
  execution: "Yürütme",
  SEVERITY: "ÖNEM",
  EXECUTION: "YÜRÜTME",
  count: "Toplam",
  scope: "Düzey",
  SCOPE: "KAPSAM",
  howTo: "Nasıl yapılır",
  activities: "Aktiviteler",
  dueDate: "Bitiş tarihi",
  partnerName: "Partner adı",
  status: "Durum",
  comments: "Yorumlar",
  sendComment: "Yorumu gönder",
  writeComment: "Yorum ekle",
  dragDropYourFiles: "Yüklemek için dosyaları sürükleyip bırakınız veya",
  finishTheStory: "Hikayeyi bitir",
  previous: "Önceki",
  next: "Sonraki",
  markAsDone: "Tamamlandı olarak işaretle",
  assignToMikrolod: "Partner'e ata",
  assignedToMikrolo: "Partner'e atandı",
  assignedPerson: "Atanan kişi",
  aboutMe: "Hakkımda",
  browse: "Gözat",
  hi: "Merhaba",
  criticalSeverity: "Kritik seviyede",
  highSeverity: "Yüksek seviyede",
  moderateSeverity: "Orta seviyede",
  lowSeverity: "Düşük seviyede",
  backToAdsManagement: "Reklam yönetimine geri dön",
  editCalendar: "Takvimi düzenle",
  seeAssessmentReport: "Değerlendirme raporunu gör",
  seeContentCalendar: "İçerik takvimi gör",
  backToStories: "Hikayeleri gör",
  backToFinishedStories: "Tamamlanmış hikayeleri gör",
  lookingPartner: "Partner aranıyor...",
  allTaskWereDone: "Tüm görevler tamamlandı",
  youNeedToCompleteToFinishTheStory: "Hikayeyi bitirmek için gereken görevleri tamamlamalısınız",
  all: "Tümü",
  login: "Giriş",
  email: "E-posta",
  password: "Parola",
  forgotPassword: "Parolanızı mı unuttunuz?",
  logIn: "Giriş yapın",
  dontHaveAnAccount: "Hesabınız yok mu?",
  registerNow: "Şimdi kaydolun",
  enterYourEmail: "E-posta adresinizi giriniz",
  enterYourPassword: "Parolanızı giriniz",
  signup: "Kayıt ol",
  fullName: "Ad Soyad",
  phone: "Telefon",
  bySigningUpYouAgreeToOur: "Kaydolmak için ilgli belgelerimiz olan",
  termsOfUse: "kullanım Şartları",
  and: "ve",
  privacyPolicy: "gizlilik politikası",
  aldreadyHaveAnAccount: "Hesabınız var mı?",
  signUp: "Kaydol",
  enterYourFullName: "Tam adınızı giriniz",
  enterYourPhone: "Telefon numaranızı giriniz",
  securityReason:
    "Şifrenizi sıfırlama talimatlarını almak için sisteme kayıtlı e-posta adresinizi girin. Güvenlik gerekçesiyle şifrenizi saklamıyoruz. Bu yüzden, şifrenizi e-posta yoluyla göndermeyeceğimizden emin olabilirsiniz.",
  send: "Gönder",
  save: "Kaydet",
  selectCountry: "Ülke seç",
  selectPartner: "Partner seç",
  enterYourKeywords: "Anahtar kelimeleri giriniz",
  enterYourCompetitors: "Rakipleri giriniz",
  youCanAddMaxium5Keywords: "En fazla 5 anahtar kelime ekleyebilirsiniz",
  youCanAddMaxium5Competitors: "En fazla 5 rakip ekleyebilirsiniz",
  allTasksWereDone: "Tüm görevler tamamlandı",
  okItems: "Sorunsuz çıktılar",
  new: "Yeni",
  storyDetail: "Hikaye Detayları",
  yourAssessmentReportWillBeReady: "Değerlendirme raporunuz 5 dk içinde hazır olacak.",
  domainName: "Alan adı",
  thereIsNoNotification: "Bildirim yok",
  createStory: "Hikaye oluştur",
  storyName: "Hikaye adı",
  selectTemplate: "Şablon seç",
  selectTask: "Görev seç",
  fillTheForm: "Formu doldur",
  writeYourStoryName: "Hikaye adını giriniz",
  writeYourComments: "Yorumlarınızı giriniz",
  yes: "Evet",
  no: "Hayır",
  pleaseSelectAtLeastOneTask: "En az bir görev seçiniz",
  pleaseFillAllTheFields: "Tüm alanları doldurun",
  somethingWentWrong: "Bir şeyler yanlış gitti| Lütfen tekrar deneyiniz",
  backToReports: "Raporlara dön",
  noUnreadMessages: "Okunmamış mesaj yok",
  dueDateDescription: "Bitiş tarihi, görevin tamamlanması gereken tarihtir.",
  back: "Geri",
  done: "Tamamlandı",
  pleaseSelectTemplate: "Lütfen şablon seçiniz",
  dontShowAgain: "Tekrar gösterme",
  unreadMessage: "Okunmamış mesajlar",
  current: "Şu anki",
  potential: "Potansiyel",
  conversion: "Dönüşüm",
  monthlyTraffic: "aylık trafik",
  rules: "Kurallar",
  RULES: "KURALLAR",
  allRules: "Tüm kurallar",
  filterRules: "Kural filtrele",
  phone_number_must_be_10_digits: "Telefon numarası 10 haneli olmalıdır",
  full_name_is_required: "Ad Soyad zorunludur",
  phone_number_is_required: "Telefon numarası zorunludur",
  email_is_required: "E-posta zorunludur",
  password_is_required: "Parola zorunludur",
  password_must_be_at_least_6_characters: "Parola en az 6 karakter olmalıdır",
  password_must_be_less_than_20_characters: "Parola 20 karakterden az olmalıdır",
  password_must_contain: "Parola en az bir büyük harf ve bir sayı içermelidir",
  yup_required: "This field is required",
  thereIsNoStory: "Şu anda hikaye yok",
  tasks: "Görevler",
  tasks_plural: "Görev(ler)",
  waitingDescription:
    "Öncelikle kısa bilgi hikayesini doldurun. Sonrasında rapor oluşturabilirsiniz.",
  assessmentReport: "Değerlendirme raporu",
  assessmentReportDescription: "Yetki Puanı, Sayfa yükleme süresi , Organik trafik",
  sec: "sn",
  publishDate: "Yayınlanma tarihi",
  contentDetails: "İçerik detayları",
  signInToYourAccount: "Giriş yapın",
  integrations: "Entegrasyonlar",
  home: "Ana Sayfa",
  messages: "Mesajlar",
  myTasks: "Görevlerim",
  backToTasks: "Görevlere dön",
  first_name_is_required: "İsim zorunludur",
  last_name_is_required: "Soyisim zorunludur",
  country_is_required: "Ülke zorunludur",
  street_is_required: "Sokak zorunludur",
  city_is_required: "Şehir zorunludur",
  zip_is_required: "Posta kodu zorunludur",
  state_is_required: "İlçe zorunludur",
  firstname_is_required: "İsim zorunludur",
  lastname_is_required: "Soyisim zorunludur",
  firstname: "İsim",
  lastname: "Soyisim",
  enteryourfirstname: "İsminizi giriniz",
  enteryourlastname: "Soyisminizi giriniz",
  has_premium_rules_false: "Temel Raporlar",
  has_premium_rules_true: "Temel + Seçkin Raporlar",
  premium_rules_false: "Temel Kurallar",
  premium_rules_true: "Temel + Seçkin Kurallar",
  integrations_true: "Entegrasyonlar",
  supports_true: "7/24 Destek",
  finishTask: "Görevi tamamla",
  finishAllTasks: "Tüm görevleri tamamla",
  completed: "Tamamlandı",
  selectBy: "Sırala",
  businessInfo: "İşletme bilgileri",
  targetCountry: "Hedef ülke",
  keywords: "Anahtar kelimeler",
  competitors: "Rakipler",
  addYourBusinessData: "İşletme bilgilerinizi giriniz",
  yourBusinessContactDetails: "İşletme iletişim bilgileriniz",
  businessName: "İşletme adı",
  businessWebsite: "İşletme web sitesi",
  sector: "Sektör",
  finish: "Tamamla",
  selectYourTargetCountry: "Hedef ülkenizi seçiniz",
  pleaseSelectTheCountryYourBusiness:
    "Lütfen işletmenizin bulunduğu veya ağırlıklı olarak odaklandığınız ülkeyi seçin. Seçtiğiniz ülkeye göre analizleri göreceksiniz.",
  addYourKeywords: "Anahtar kelimelerinizi giriniz",
  shareWithUsTheKeywordsYouThink:
    "İşletmenizle alakalı olduğunu düşündüğünüz anahtar kelimeleri bizimle paylaşın. Web sitenizin içeriği hakkında bilgi verir ve SEO performansınızı geliştirmenize yardımcı oluruz.",
  addYourCompetitors: "Rakiplerinizi giriniz",
  dontLetYourCompetitors:
    "Rakiplerinizin sizi geçmesine izin vermeyin! Rakip olarak gördüğünüz birkaç işletmenin web sitelerini bizimle paylaşın. Böylece rakipleriniz arasındaki konumunuzu göreceksiniz.",
  add: "Ekle",
  required: "Zorunlu",
  bookADemo: "Demo Talebi",
  buy_now: "Satın Al",
  profile: "Profil",
  change: "Değiştir",
  country: "Ülke",
  streetAddress: "Adres",
  city: "Şehir",
  stateProvince: "İlçe",
  zipCode: "Posta Kodu",
  cancel: "İptal",
  photo: "Fotoğraf",
  personalInformation: "Kişisel bilgiler",
  projects: "Projeler",
  subscriptinPlans: "Abonelik Planları",
  billing: "Fatura",
  connect: "Bağlan",
  disconnect: "Bağlantıyı Kes",
  sample: "Örnek",
  viewProfile: "Profilimi görüntüle",
  comingSoon: "Yakında",
  profilePhotoEdit: "Profil fotoğrafını düzenle",
  remove: "Kaldır",
  createNewTask: "Yeni görev oluştur",
  noTaskSelected: "Hiçbir görev seçilmedi",
  pleaseSelectOneTaskToRead: "Lütfen devam etmek için bir görev seçin",
  noAssigned: "Hiçbir görev ataması yapılmadı",
  waitingForThePartner: "Partnerin atanması bekleniyor",
  label: "Etiket",
  assign: "Atama",
  assignee: "Atanan",
  noLabel: "Etiket yok",
  createTask: "Görev oluştur",
  noMessageSelected: "Hiçbir mesaj seçilmedi",
  pleaseSelectOneMessageToRead: "Lütfen devam etmek için bir mesaj seçin",
  searchByName: "İsme göre ara",
  verifyAccount: "Hesabını doğrula",
  changePassword: "Şifre değiştir",
  newPassword: "Yeni şifre",
  confirmPassword: "Şifreyi onayla",
  passwordResetSuccess: "Email adresinize şifre sıfırlama linki gönderildi",
  emailSent: "Email gönderildi",
  emailNotSent: "Email gönderilemedi",
  taskName: "Görev adı",
  writeYourTaskName: "Görev adını yaz",
  gettingStarted: "Başlangıç",
  helpCenter: "Yardım Merkezi",
  frequentlyAskedQuestions: "Sıkça Sorulan Sorular",
  yourMessage: "Mesajınız",
  submit: "Gönder",
  discoverGettingStarted: "Ücretsiz planınızı tamamlamak için özelliklerimizi keşfedin",
  gettingStartedIntro:
    "Başlarken bölümündeki adımları takip ederek Mikrolo'nun özelliklerini ve faydalarını öğrenebilirsiniz. Ayrıca buradan hesaplarınızı bağlayabilirsiniz ki size verilere dayalı kişiselleştirilmiş bir dijital pazarlama hizmeti sunalım.",
  contactUs: "Bize Ulaşın",
  ruleDetails: "Kural Detayları",
  roadmaps: "Yol Haritaları",
  noDueDate: "Bitiş tarihi yok",
  description: "Açıklama",
  content: "İçerik",
  summary: "Özet",
  totalTasks: "Toplam Görev",
  adsSnapshotFor: "Ads anlık görüntüsü - ",
  adsMetricsFor: "Ads metrikleri - ",
  accept: "Kabul et",
  accepted: "Onaylandı",
  connected: "Bağlandı",
  freeNoCreditCard: "Kredi kartı gerekmez.",
  getStartedForFree: "Ücretsiz Başlayın",
  roadmapDetail: "Yol haritası detayı",
  loginWithGoogle: "Google ile giriş yap",
  createANewTask: "Yeni bir görev oluştur",
  upgradeYourPlan: "Planınızı yükseltin",
  writeYourDescription: "Açıklamanızı yazın",
  active: "Aktif",
  start: "Başlat",
  loading: "Yükleniyor",
  filterRoadmaps: "Roadmapları filtrele",
  goals: "İhtiyaçlar",
  recommendedKeywords: "Önerilen anahtar kelimeler",
  accounts: "Hesaplar",
  skip: "Atla",
  target: "Hedef",
  writeYourBrief: "Brief yazın",
  brief: "Brief",
  create: "Oluştur",
  selectExperts: "Uzmanları seçin",
  messageIntroTitle: "Uzmanlar aranıyor. Yakında onlarla eşleşeceksin ",
  messageIntroContent:
    "Sizin için en iyilerini arıyoruz. Bir uzman bulmak biraz zaman alabilir. Uzmanlarla eşleştiğindiğinde iletişime başlayabilirsiniz.",
  welcomeToMikrolo: "Mikrolo'ya hoşgeldin",
  strategies: "Stratejiler",
  registerWithGoogle: "Google ile kayıt ol",
  viewAll: "Hepsini görüntüle",
  assigned: "Atanan",
  preview: "Önizleme",
  confirm: "Onayla",
  "Task completed successfully, you can continue with the next task":
    "Görev başarıyla tamamlandı, bir sonraki göreve devam edebilirsiniz",
  "Share in": "Paylaş",
  sort: "Sırala",
  contentCalendar: "İçerik Takvimi",
  search: "Ara",
  "Your post has been scheduled successfully": "Gönderiniz başarıyla planlandı",
  "Please remove http or https from the website address":
    "Lütfen web sitesi adresinden http veya https kaldırın",
  "Your Plan": "Planınız",
  "What's included": "Neler dahil",
  "Upgrade Your Account": "Hesabınızı Yükseltin",
  "To create and assign more tasks to our experts, let’s upgrade your account!":
    "Uzmanlarımıza daha fazla görev oluşturmak ve atamak için hesabınızı yükseltelim!",
  "Please connect your Facebook account to schedule a post":
    "Lütfen bir gönderi planlamak için Facebook hesabınızı bağlayın",
  "Please connect your Linkedin account to schedule a post":
    "Lütfen bir gönderi planlamak için Linkedin hesabınızı bağlayın",
  "Share to Facebook": "Facebook'ta paylaş",
  "Share to Instagram": "Instagram'da paylaş",
  "Share to Linkedin": "Linkedin'de paylaş",
  "Select a Facebook page": "Bir Facebook sayfası seçin",
  "Select a page": "Bir sayfa seçin",
  "Please add a page to your account to share": "Lütfen paylaşmak için bir sayfa ekleyin",
  "Select a Linkedin page": "Bir Linkedin sayfası seçin",
  "Share now": "Şimdi paylaş",
  "If you want to schedule your post, please select a date and time":
    "Gönderinizi planlamak istiyorsanız, lütfen bir tarih ve saat seçin",
  "Select timezone": "Zaman dilimi seçin",
  "Select a date": "Bir tarih seçin",
  share: "Paylaş",
  "This roadmap has been already started": "Bu roadmap zaten başlatıldı",
  "There are rules that start working for you in line with the strategy you have started":
    "Başlattığınız stratejiye göre çalışmaya başlayan kurallar var",
  "Go to Rules": "Kurallara git",
  "Are you sure you want to disconnect Google Analytics?":
    "Google Analytics bağlantısını kesmek istediğinize emin misiniz?",
  "Are you sure you want to disconnect Google Ads?":
    "Google Ads bağlantısını kesmek istediğinize emin misiniz?",
  "Please confirm you want to disconnect your Facebook account from this project":
    "Lütfen bu projeden Facebook hesabınızın bağlantısını kesmek istediğinizi onaylayın",
  "Are you sure you want to disconnect Linkedin?":
    "Linkedin bağlantısını kesmek istediğinize emin misiniz?",
  "Coming Soon": "Yakında",
  "Start My Strategy": "Stratejimi Başlat",
  "See what's the next steps": "Bir sonraki adımları gör",
  "When you complete this stage, the strategic plan that a digital marketing manager can prepare and the task list for this plan will be created automatically":
    "Bu aşamayı tamamladığınızda, dijital pazarlama yöneticisinin hazırlayabileceği stratejik plan ve bu plan için görev listesi otomatik olarak oluşturulur",
  "View Strategy": "Stratejiyi Görüntüle",
  "Assign My Task": "Görevimi Ata",
  "Create a task based on your needs": "İhtiyaçlarınıza göre bir görev oluşturun",
  "Lets our talents all over the world works for you! Decide your need and we will assign your task to relevant talent":
    "Dünyanın her yerindeki yeteneklerimiz sizin için çalışsın! İhtiyacınızı belirleyin ve görevinizi ilgili yeteneğe atayacağız",
  "Connect My Account": "Hesabımı Bağla",
  "Let’s connect your accounts": "Hesaplarınızı bağlayalım",
  "No data, no accurate outcomes. Let us detect upcoming threats and opportunities":
    "Veri yok, doğru sonuçlar yok. Yaklaşan tehditleri ve fırsatları algılayalım",
  "Connect My Accounts": "Hesaplarımı Bağla",
  "Strategies For You": "Sizin İçin Stratejiler",
  "View All": "Hepsini Görüntüle",
  "Task List": "Görev Listesi",
  "Connecting your Google Analytics account allows us to generate reports with your data, detect some issues and create custom tasks for you.":
    "Google Analytics hesabınızı bağlamanız, verilerinizle raporlar oluşturmanıza, bazı sorunları algılayabilmenize ve özel görevler oluşturabilmenize olanak tanır.",
  "Connecting your Google Ads account allows us to provide you some reports on your ads, detect errors and create tasks about your ads.":
    "Google Ads hesabınızı bağlamanız, reklamlarınızla ilgili bazı raporlar sağlamanıza, hataları algılayabilmenize ve reklamlarınız hakkında görevler oluşturabilmenize olanak tanır.",
  "Connecting your Facebook account allows us to share your posts on your Facebook page and create tasks about your Facebook page.":
    "Facebook hesabınızı bağlamanız, gönderilerinizi Facebook sayfanızda paylaşabilmenize ve Facebook sayfanız hakkında görevler oluşturabilmenize olanak tanır.",
  "Connecting your Linkedin account allows us to share your posts on your Linkedin page and create tasks about your Linkedin page.":
    "Linkedin hesabınızı bağlamanız, gönderilerinizi Linkedin sayfanızda paylaşabilmenize ve Linkedin sayfanız hakkında görevler oluşturabilmenize olanak tanır.",
  "Connecting your Facebook Ads account allows us to provide you some reports on your ads, detect errors and create tasks about your ads.":
    "Facebook Ads hesabınızı bağlamanız, reklamlarınızla ilgili bazı raporlar sağlamanıza, hataları algılayabilmenize ve reklamlarınız hakkında görevler oluşturabilmenize olanak tanır.",
  "Continue with Facebook": "Facebook ile devam et",
  "There is no event for this project. If you want to add an event, please click the button below and go to the task page. You can schedule your tasks from there.":
    "Bu projeye ait bir etkinlik yok. Bir etkinlik eklemek istiyorsanız, lütfen aşağıdaki butona tıklayın ve görev sayfasına gidin. Görevlerinizi oradan planlayabilirsiniz.",
  "Add Event": "Etkinlik Ekle",
  "Todays Files": "Bugünün Dosyaları",
  "No content found": "İçerik bulunamadı",
  "This Month": "Bu Ay",
  "How can we help?": "Nasıl yardımcı olabiliriz?",
  "Everything you need to know about the product. Can’t find the answer you’re looking for? Please contact us.":
    "Ürün hakkında bilmeniz gereken her şey. Aradığınız cevabı bulamıyorsanız, lütfen bizimle iletişime geçin.",
  "Can’t find your answer you’re looking for? Our friendly team would love to hear from you!":
    "Aradığınız cevabı bulamıyorsanız, bizimle iletişime geçmekten çekinmeyin!",
  "Upgrade Plan": "Planı Yükselt",
  live: "Canlı",
  "This table is a control tool designed to analyze the compliance of your advertising campaigns within a more specific and detailed set of rules and to facilitate improvements when necessary. This table is tailored to determine if each campaign meets its unique requirements and to plan necessary actions for optimization.": "Bu tablo, reklam kampanyalarınızın daha spesifik ve detaylı kurallar çerçevesinde uygunluğunu analiz etmek ve gerektiğinde iyileştirmeleri kolaylaştırmak için tasarlanmış bir kontrol aracıdır. Bu tablo, her kampanyanın kendine özgü gereksinimlerini karşılayıp karşılamadığını belirlemek ve optimizasyon için gerekli eylemleri planlamak için özel olarak hazırlanmıştır.",
  preparing: "Hazırlanıyor",
  "Learn more about this report": "Bu rapor hakkında daha fazla bilgi edinin",
  "Top organic keywords": "En iyi organik anahtar kelimeler",
  "Create a new task": "Yeni bir görev oluştur",
  "Get started by filling in the information below to create your new task.":
    "Yeni görevinizi oluşturmak için aşağıdaki bilgileri doldurarak başlayın.",
  "Please select a template to see the list of experts!":
    "Lütfen uzmanların listesini görmek için bir şablon seçin!",
  "Upon creation of this task, it will be assigned to the relevant talents who will begin working on it. The task will be delivered to you within one day.":
    "Bu görevin oluşturulmasıyla, bu göreve başlayacak ilgili yeteneklere atama yapılacaktır. Görev bir gün içinde size teslim edilecektir.",
  about: "Hakkında",
  "Comments with talents": "Uzmanların yorumları",
  commented: "Yorum yapıldı",
  daily: "Günlük",
  monthly: "Aylık",
  weekly: "Haftalık",
  "Authority Score": "Yetki Skoru",
  desktop: "Masaüstü",
  mobile: "Mobil",
  "Working rules for you": "Sizin için çalışma kuralları",
  "When system finish to read audits, you will see the rules working for you!":
    "Sistem denetimleri okumayı tamamladığında, sizin için çalışan kuralları göreceksiniz!",
  "View All Rules": "Tüm Kuralları Görüntüle",
  "Which keywords best describe your business?":
    "İşletmenizi en iyi tanımlayan anahtar kelimeler hangileridir?",
  "Share with us the keywords you think are relevant to your business. We will help you to improve your SEO.":
    "İşletmenize ilişkin olduğunu düşündüğünüz anahtar kelimeleri bizimle paylaşın. SEO'nuzu geliştirmenize yardımcı olacağız.",
  "Who are your competitors?": "Rakipleriniz kimlerdir?",
  "Don’t let your competitors to get ahead of you! Share with us a few businesses' websites you see as competitors. So, you will see your position among your competitors.":
    "Rakiplerinizden öne geçmeyin! Rakip olarak gördüğünüz bazı işletmelerin web sitelerini bizimle paylaşın. Böylece rakipleriniz arasında konumunuzu göreceksiniz.",
  "What accounts do you have?": "Hangi hesaplarınız var?",
  "Connect your accounts to get the most out of your SEO and PPC campaigns.":
    "SEO ve PPC kampanyalarınızın en iyisini almak için hesaplarınızı bağlayın.",
  "Getting Started": "Başlangıç",
  "Understand how the task structure works!": "Görev yapısının nasıl çalıştığını anlayın!",
  "Connect your accounts!": "Hesaplarınızı bağlayın!",
  "See your first report!": "İlk raporunuzu görün!",
  "Complete your profile!": "Profilinizi tamamlayın!",
  "Connection your accounts!": "Hesaplarınızı bağlayın!",
  "You need to connect accounts to some rules to start working for you in line with the strategy you have started!":
    "Başlattığınız stratejiye uygun olarak sizin için çalışmaya başlamak için bazı kurallara hesap bağlamanız gerekiyor!",
  "Upgrade Your Plan": "Planınızı Yükseltin",
  "Don’t let websites you see as competitors. So, you will see your position among your competitors.":
    "Rakip olarak gördüğünüz web sitelerini engellemeyin. Böylece rakipleriniz arasında konumunuzu göreceksiniz.",
  "Did you like the work?": "İşi beğendiniz mi?",
  "If you like the work, please click the 'Accept' button.":
    "Çalışmayı beğendiyseniz, lütfen 'Kabul Et' düğmesini tıklayın.",
  "What’s your digital marketing needs?": "Dijital pazarlama ihtiyaçlarınız nedir?",
  "Select the situations and needs below that are appropriate for you.":
    "Sizin için uygun olan aşağıdaki durumları ve ihtiyaçları seçin.",
  "Which country is the destination for your business?": "İşletmenizin hedef ülkesi hangisi?",
  "Please select the country your business located in or mainly focus on. You will see the analyzes according to country you have selected.":
    "Lütfen işletmenizin bulunduğu veya ana odaklandığı ülkeyi seçin. Seçtiğiniz ülkeye göre analizleri göreceksiniz.",
  "You are one step away from feeling happy about digital marketing.":
    "Dijital pazarlamaya ilişkin mutlu olmanız için bir adım kaldı.",
  membership: "Üyelik",
  "Your current plan": "Mevcut planınız",
  "Pricing Plans": "Fiyatlandırma Planları",
  "Start building for free, then add a site plan to go live. Account plans unlock additional features.":
    "Ücretsiz olarak oluşturmaya başlayın, ardından yayınlamak için bir site planı ekleyin. Hesap planları ek özelliklerin kilidini açar.",
  "You can only add 5 competitors": "Sadece 5 rakip ekleyebilirsiniz",
  "You can only add 5 keywords": "Sadece 5 anahtar kelime ekleyebilirsiniz",
  "Please enter a valid URL. Example: https://www.example.com":
    "Lütfen geçerli bir URL girin. Örnek: https://www.example.com",
  objectives: "Hedefler",
  "Social Media Audit": "Sosyal Medya Denetimi",
  "If you don't get verification email, please again click on verify account button":
    "Doğrulama e-postası almadıysanız, lütfen hesabı doğrulamak için tekrar tıklayın",
  position: "Pozisyon",
  "Traffic Percent": "Trafik Yüzdesi",
  "Roadmap start to work for you!": "Yol haritası sizin için çalışmaya başlıyor!",
  "Let's see what tasks you need to do.": "Yapmanız gereken görevleri görelim.",
  "See my tasks": "Görevlerimi görüntüle",
  "What you need?": "Ne ihtiyacınız var?",
  "You can select any expert to do your task. Select what you need!":
    "Görevinizi yapmak için herhangi bir uzmanı seçebilirsiniz. Ne ihtiyacınız olduğunu seçin!",
  experts: "Uzmanlar",
  "Here all tasks in your plan. You will find information for each for completion.":
    "Burada planınızdaki tüm görevler. Her birini tamamlamak için bilgi bulacaksınız.",
  "Please select at least one platform": "Lütfen en az bir platform seçin",
  "Please select a page": "Lütfen bir sayfa seçin",
  "Please select a linkedin page": "Lütfen bir linkedin sayfası seçin",
  "Please fill all fields": "Lütfen tüm alanları doldurun",
  "There was an error assigning the tasks": "Görevler atamasında bir hata oluştu",
  "Please select at least one objective": "Lütfen en az bir hedef seçin",
  "You can select maximum 3 objectives": "En fazla 3 hedef seçebilirsiniz",
  "I need Digital Marketing Manager!": "Dijital Pazarlama Yöneticisi ihtiyacım var!",
  "Create my digital marketing strategy and plans.":
    "Dijital pazarlama stratejimi ve planlarımı oluştur.",
  "I need experts to do the digital marketing works!":
    "Dijital pazarlama işlerini yapmak için uzmanlara ihtiyacım var!",
  "Assign my Digital Marketing tasks to relevant experts.":
    "Dijital Pazarlama görevlerimi ilgili uzmanlara atayın.",
  "I need ad images and content!": "Reklam resimlerine ve içeriğine ihtiyacım var!",
  "Create my ad images and content.": "Reklam resimlerimi ve içeriğimi oluştur.",
  "I need to do benchmarks in my industry to be successful!":
    "Başarılı olmak için sektörümdeki karşılaştırmaları yapmak istiyorum!",
  "Give me digital marketing insights about my industry and drivers for success.":
    "Başarı için sektörüm ve sürücülerim hakkında dijital pazarlama bilgileri ver.",
  "I need daily/monthly budget suggestion for my ads!":
    "Reklamlarım için günlük / aylık bütçe önerisi ihtiyacım var!",
  "Find and scale my test budget and my sales drivers":
    "Test bütçemi ve satış sürücülerimi bul ve ölçeklendir",
  "I need to know opportunities and threats and enable me to take action.":
    "Fırsatları ve tehditleri bilmek ve eyleme geçmemi sağlamak istiyorum.",
  "Show where opportunities and threats occur automatically and regularly":
    "Fırsatları ve tehditleri otomatik ve düzenli olarak göster",
  "Maximum 5 keywords allowed": "Maksimum 5 anahtar kelimeye izin verilir",
  "Domain name is required": "Alan adı gerekli",
  "Competitors are required": "Rakipler gerekli",
  credit: "Kredi",
  "The features covered by your plan are over!": "Planınız tarafından kapsanan özellikler bitti!",
  "If you want to contune using the features’ how about choosing a plan that suits you better?":
    "Özellikleri kullanmaya devam etmek istiyorsanız, bir plan seçiniz.",
  "There was an error finishing the task. Try again later.":
    "Görevi tamamlamada bir hata oluştu. Daha sonra tekrar deneyin.",
  "Are you sure you want to complete the task without uploading any file?":
    "Herhangi bir dosya yüklemeksizin görevi tamamlamak istediğinizden emin misiniz?",
  "Go back to task": "Göreve geri dön",
  ourSuggestions: "Önerilerimiz",
  images: "Resimler",
  captions: "Başlıklar",
  "Create post now": "Şimdi gönderi oluştur",
  "Please select image or caption": "Lütfen resim veya altyazı seçin",
  "Post created successfully, you can preview": "Gönderi başarıyla oluşturuldu, önizleyebilirsiniz",
  viewSuggestions: "Önerileri görüntüle",
  "Please fill country field": "Lütfen ülke alanını doldurun",
  "Invoice Information": "Fatura Bilgileri",
  "We need your billing address for your invoice.": "Faturanız için fatura adresinizi gerekiyor",
  "Let's reach your digital marketing goals.": "Dijital pazarlama hedeflerinize ulaşalım.",
  Tasks: "Görevler",
  "Upload your content": "İçeriğinizi yükleyin",
  "Total Credit": "Toplam Kredi",
  "Everything you need to know about the how to finish a task. Can’t find the answer you’re looking for? Please contact us.":
    "Bir görevi nasıl tamamlacağınız hakkında bilmeniz gereken her şey burada. Aradığınız cevabı bulamıyorsanız, lütfen bizimle iletişime geçin.",
  "Tax Identification Number": "Vergi Kimlik Numarası",
  "Tax Office": "Vergi Dairesi",
  Channel: "Kanal",
  Count: "Sayı",
  "Page Path": "Sayfa Yolu",
  "Page Views": "Sayfa Görüntülemesi",
  "Everything you need to know about the product. Can’t find the answer you’re looking for?":
    "Ürün hakkında bilmeniz gereken her şey burada. Aradığınız cevabı bulamıyorsanız, lütfen bizimle iletişime geçin.",
  "How can I assign my task to a talent?": "Görevimi bir partnere nasıl atayabilirim?",
  "You can view the video below to learn how to assign a task to a talent.":
    "Bir görevi bir partnere nasıl atayacağınızı öğrenmek için videoyu izleyebilirsiniz.",
  "Start strategy": "Strateji Başlat",
  "You can view the video below to learn how to start a strategy and use the strategy builder.":
    "Bir strateji nasıl başlatılacağını ve strateji oluşturucuyu nasıl kullanacağınızı öğrenmek için videoyu izleyebilirsiniz.",
  "You can view the video below to learn how to create a new task.":
    "Yeni bir görev nasıl oluşturulacağını öğrenmek için videoyu izleyebilirsiniz.",
  "Upload your files": "Dosyalarınızı düzenleyin",
  "No image": "Resim yok",
  "No message": "Mesaj yok",
  "Preview on your task": "Görev önizlemesi",
  "Upload your image": "Resminizi yükleyin",
  "Your message": "Mesajınız",
  upload: "Yükle",
  "Write your thoughts here...": "Düşüncelerinizi buraya yazın...",
  createDate: "Oluşturulma Tarihi",
  "Share your work": "Çalışmanızı paylaşın",
  "Select a Instagram page": "Bir Instagram sayfası seçin",
  "Number of Assigned Tasks": "Atanan Görev Sayısı",
  "Total Credits Spent": "Toplam Harcanan Kredi",
  "Available Credit": "Kullanılabilir Kredi",
  businessType: "İşletme Türü",
  "What product/ services does the business provide?": "İşletme hangi ürün / hizmeti sunar?",
  "Describe your business": "İşletmenizi tanıtın",
  "Buy Extra Credit": "Ekstra Kredi Satın Al",
  buy: "Satın Al",
  invoiceTitle: "Firma İsmi/Adı Soyadı",
  invoiceType: "Fatura Türü",
  "Selected image": "Seçili resim",
  "Rules regularly monitor and audit your digital assets. As a result, it presents you with potential threats and opportunities so you don't overlook them. It helps you strengthen your digital marketing by alerting you to anomalies in your account and ad campaigns and creating tasks for you. In this section, you can review the rules that work for you and see what's under audit.":
    "Kurallar, dijital varlıklarınızı düzenli olarak izler ve denetler. Sonuç olarak, göz ardı etmemeniz için size olası tehditleri ve fırsatları sunar. Hesabınızdaki ve reklam kampanyalarınızdaki anormallikler konusunda sizi uyararak ve reklam kampanyaları oluşturarak dijital pazarlamanızı güçlendirmenize yardımcı olur. Bu bölümde işinize yarayan kuralları inceleyebilir ve nelerin denetlendiğini görebilirsiniz.",
  "Cancel Subscription": "Aboneliği İptal Et",
  Details: "Detaylar",
  "Are you sure you want to cancel your subscription?":
    "Aboneliğinizi iptal etmek istediğinizden emin misiniz?",
  earnadoPlans: "Earnado Planları",
  "Earnado Plans": "Earnado Planları",
  "Do you have online sales?": "Çevrimiçi satışlarınız var mı?",
  "How much is the monthly budget you set for your ads?":
    "Reklamlarınız için ayda ayarladığınız bütçe ne kadar?",
  "Have you ever advertised before? If yes, in which channels?":
    "Daha önce reklam vermiş misiniz? Eğer evet ise hangi kanallarda?",
  "Create your profile": "Profilinizi oluşturun",
  "Let's define the needed information to the talents":
    "Partnere ihtiyaç duyulan bilgileri tanımlayalım",
  "Let's set up your profile that will customize your project and better identify your needs.":
    "Projenizi özelleştirmek ve ihtiyaçlarınızı daha iyi tanımlamak için profilinizi ayarlayalım.",
  startNow: "Şimdi başla",
  createdBy: "Oluşturan kişi",
  totalCredit: "Toplam Kredi",
  assets: "Varlıklar",
  marketingTools: "Pazarlama Araçları",
  clicks: "Tıklamalar",
  impressions: "Gösterimler",
  averageCpc: "Ortalama Tıklama Maliyeti",
  cost: "Maliyet",
  "Number of users coming to your website from Social media channels.":
    "Sosyal medya kanallarından web sitenize gelen kullanıcı sayısı.",
  "Select Campaign": " Kampanya Seçin",
  accountIssues: "Hesap Sorunları",
  name: "İsim",
  view: "Görüntüle",
  searchKeywords: "Aranan Anahtar Kelimeler",
  adGroup: "Reklam Grubu",
  impression: "Gösterim",
  ctr: "CTR",
  cpc: "CPC",
  keywordPerformance: "Anahtar Kelime Performansı",
  "Keywords are the words or phrases that trigger your ads to appear.":
    "Anahtar kelimeler reklamlarınızın görünmesini tetikleyen kelimeler veya ifadelerdir.",
  allAdsCampaignMetrics: "Tüm Reklam Kampanya Metrikleri",
  "You can see finished tasks on that range by click button.":
    "Tamamlanmış görevleri butona tıklayarak o aralıkta görebilirsiniz.",
};
