import { useEffect } from "react";
import { useMutation } from "react-query";
import { postProjectData } from "@/api/project";
import { useRecoilState } from "recoil";
import { t } from "i18next";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
import GoogleAdsCard from "@/features/onboarding/components/ConnectAccounts/GoogleAdsCard";
import GoogleAnalyticsCard from "@/features/onboarding/components/ConnectAccounts/GoogleAnalyticsCard";

import { useNavigate } from "react-router-dom";
import { userAtom } from "@/store/atoms/user.atoms";
import { ProjectDataType } from "@/models/projectDataType";
import { listenButtonClickedEvent, pageViewEvent, saveOnboardingEvent } from "@/analytics/mixpanel";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

type Props = {
  projectId: number;
  setCurrentStep: (step: number) => void;
  setSteps: any;
};

const ConnectAccounts = (props: Props) => {
  const navigate = useNavigate();
  const { projectId } = props;
  const [user] = useRecoilState(userAtom);

  const { mutateAsync, isLoading } = useMutation("postProjectDomainData", postProjectData, {
    retry: false,
  });

  const handleSaveCountry = () => {
    mutateAsync({
      projectId,
      project: {
        datatype_name: ProjectDataType.ONBOARDING,
        data: {
          value: true,
        },
      },
    }).then(() => {
      saveOnboardingEvent({
        userId: user?.id.toString() || "",
        email: user?.email || "",
      });
      localStorage.removeItem("stepper");
      localStorage.removeItem("onboardingItems");
      localStorage.removeItem("onboardingCurrentStep");
      navigate("/");
    });
  };

  const handleSaveSkip = () => {
    mutateAsync({
      projectId,
      project: {
        datatype_name: ProjectDataType.ONBOARDING,
        data: {
          value: true,
        },
      },
    }).then(() => {
      saveOnboardingEvent({
        userId: user?.id.toString() || "",
        email: user?.email || "",
      });

      listenButtonClickedEvent({
        buttonName: "onboarding(account) skip",
        userId: user?.id.toString() || "",
        email: user?.email || "",
        skip: true,
      });

      localStorage.removeItem("stepper");
      localStorage.removeItem("onboardingItems");
      localStorage.removeItem("onboardingCurrentStep");
      navigate("/");
    });
  };

  useEffect(() => {
    const getPaths = JSON.parse(localStorage.getItem("paths") as any);

    pageViewEvent({
      pageName: "onboarding(accounts)",
      userId: user?.id.toString() || "",
      email: user?.email || "",
      previousPage: getPaths?.currentPath || "",
    });
  }, []);

  return (
    <div className="h-full w-full flex md:items-center md:justify-center flex-col ">
      <div className="flex justify-center flex-col items-center  p-5 ">
        {/* <div className="text-indigo-600 uppercase font-medium">{t("accounts")}</div> */}
        <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
          {t("What accounts do you have?")}
        </h1>
        <div className="text-xl text-gray-500">
          {t("Connect your accounts to get the most out of your SEO and PPC campaigns.")}
        </div>
      </div>
      <div>
        <div className="flex flex-col gap-[30px] w-full my-8">
          <GoogleAnalyticsCard
            title="Google Analytics"
            description="Connecting your Google Analytics account allows us to generate reports with your data, detect some issues and create custom tasks for you.
            "
          />
          <GoogleAdsCard
            title="Google Ads"
            description="Connecting your Google Ads account allows us to provide you some reports on your ads, detect errors and create tasks about your ads."
          />
        </div>
      </div>
      <div className="flex justify-end my-5 md:mb-0 pr-5 md:absolute bottom-10 right-10 ">
        {isLoading ? (
          <LoadingSpinner size="w-6 h-6" />
        ) : (
          <div className="flex items-center ">
            {/* <button
              onClick={() => handleSaveSkip()}
              className="text-indigo-600 font-medium mt-[22px]  px-6 cursor-pointer hover:opacity-90 transition-all w-fit mr-6 md:mr-0">
              {t("skip")}
            </button> */}
            <DefaultButton
              onClick={() => handleSaveCountry()}
              className={`text-white font-medium mt-[22px]  px-6 cursor-pointer hover:opacity-90 transition-all w-fit mr-2 md:mr-0`}>
              {t("finish")}
            </DefaultButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectAccounts;
