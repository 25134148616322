import ContentBar from "@/components/ContentBar";
import AssessmentLayout from "@/components/Layout/Assessment";
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LoadingSpinner from '@/components/UI/LoadingSpinner'
import { useEffect, useState } from "react";
import { getExecutionHistory } from "@/api/eA";
import ReportTable from "@/features/report/components/cards/ReportTable";
import { FormatDate } from "@/utils/FormatDate";

const ExecutionHistoryPage = () => {
    const { t } = useTranslation();
    const [isLoading, setLoadingState] = useState(true);
    const [dataFromApi, setData] = useState<any>(null);
    const [is404, setIs404] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const customerClientId = queryParams.get('c');
    const ruleIdentifier = queryParams.get('r');


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (ruleIdentifier === undefined || customerClientId === undefined) {
                    return
                }
                const result = await getExecutionHistory({
                    customer_client_id: customerClientId || "0",
                    rule_identifier: ruleIdentifier || ""
                });
                setData(result);
                setLoadingState(false);
            } catch (error: any) {
                if (error.response.status === 404) {
                    setIs404(true);
                    setLoadingState(false);
                }
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const CreateRows = (scope: number, data: any) => {
        switch (scope) {
            case 0:
                //ACCOUNT
                return;
            case 1:
                //CAMPAIGN
                return data.map((item: any) => (
                    {
                        className: "even:bg-gray-50",
                        cells: [
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: FormatDate(item.executionDate)
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.campaignName
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.actionText
                            }
                        ],
                        key: item.id
                    }
                ));
            case 2:
                //ADGROUP
                return data.map((item: any) => (
                    {
                        className: "even:bg-gray-50",
                        cells: [
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: FormatDate(item.executionDate)
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.campaignName
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.adGroupName
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.actionText
                            }
                        ],
                        key: item.id
                    }
                ));
            case 3:
                //AD
                return;
            case 4:
                //ADGROUPKEYWORD
                return data.map((item: any) => (
                    {
                        className: "even:bg-gray-50",
                        cells: [
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: FormatDate(item.executionDate)
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.campaignName
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.adGroupName
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.adGroupKeywordText
                            },
                            {
                                className: "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                                text: item.actionText
                            }
                        ],
                        key: item.id
                    }
                ));
            case 5:
                //ACCOUNTBUGDET
                return;
            default:
                return;
        }
    }

    const GetTableHeader = (scope: number) => {
        switch (scope) {
            case 0:
                //ACCOUNT
                return;
            case 1:
                //CAMPAIGN
                return [t("executionDate"), t("campaignName"), t("action")];
            case 2:
                //ADGROUP
                return [t("executionDate"), t("campaignName"), t("adGroupName"), t("action")];
            case 3:
                //AD
                return;
            case 4:
                //ADGROUPKEYWORD
                return [t("executionDate"), t("campaignName"), t("adGroupName"), t("adGroupKeyword"), t("action")];
            case 5:
                //ACCOUNTBUGDET
                return;
            default:
                return;
        }
    }

    return (
        <div>
            <AssessmentLayout>
                <ContentBar title={t("executionHistoryTitle")} />

                <div className="text-sm text-[#707070] mt-8 mb-6">
                    <div className="text-sm text-[#707070] flex gap-2 flex-row items-center w-fit pb-1">
                        <div>
                            <HomeIcon className="w-4 h-4" />
                        </div>
                        <div>
                            <Link className="flex items-center gap-2" to="/auto-pilot">
                                <ChevronRightIcon className="w-4 h-4" />
                                {t("autopilottitle")}
                            </Link>
                        </div>
                    </div>
                </div>

                {
                    isLoading ? (
                        <LoadingSpinner size="w-8 h-8" />
                    ) : is404 ? (
                        <div className="overflow-auto">
                            {t("executionHistoryNotFound")}
                            <a href="/auto-pilot"
                                className="flex max-w-sm justify-center mt-2.5
                            rounded-md border border-transparent
                            bg-indigo-600 disabled:bg-gray-400
                            py-2 px-4 text-sm text-white shadow-sm
                            hover:bg-indigo-700 focus:outline-none
                            focus:ring-2 focus:ring-indigo-200 transition-all">
                                {t("back")}
                            </a>
                        </div>
                    ) : (
                        <div className="overflow-auto">
                            <ReportTable
                                title={t(dataFromApi.autoPilotRuleHttpResponse.text)}
                                description={t("scope") + ": " + t(dataFromApi.autoPilotRuleHttpResponse.scopeText) + " | " + t("severity") + ": " + t(dataFromApi.autoPilotRuleHttpResponse.severityText)}
                                tableHeader={GetTableHeader(dataFromApi.autoPilotRuleHttpResponse.scope)}
                                akey={customerClientId}
                                rows={CreateRows(dataFromApi.autoPilotRuleHttpResponse.scope, dataFromApi.items)}
                            ></ReportTable>
                        </div>
                    )
                }
            </AssessmentLayout>
        </div>
    );
}

export default ExecutionHistoryPage;
