import React, { useEffect } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { t } from "i18next";
import introJs from "intro.js";

type Props = {
  setSelectUser: (value: boolean) => void;
  selectUser: any;
  userList: any;
  currentPage: number;
  setCurrentPage: (value: any) => void;
  refetch: any;
};

const UserList = (props: Props) => {
  const { setSelectUser, selectUser, userList, currentPage, setCurrentPage } = props;

  const [searchValue, setSearchValue] = React.useState<string>("");

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  // filter userListData by search value
  const filteredUserList =
    userList &&
    userList?.items?.filter((user: any) => {
      return user.first_name.toLowerCase().includes(searchValue?.toLowerCase());
    });

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev: number) => +prev - 1);
      setSelectUser(false);
    }
  };

  const handleNext = () => {
    if (+currentPage * 10 < +userList?.total_count) {
      setCurrentPage((prev: number) => +prev + 1);
      setSelectUser(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("showMessageForGettingStarted") === "true") {
      introJs().start();
      localStorage.removeItem("showMessageForGettingStarted");
    }
  }, []);

  return (
    <>
      <div
        className="bg-white rounded-lg overflow-y-auto mb-1 "
        style={{ height: "calc(100vh - 150px)" }}
        data-title={t("messageIntroTitle")}
        data-intro={t("messageIntroContent")}>
        <form className="bg-gray-100 pb-4">
          <label
            htmlFor="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only ">
            Search
          </label>
          <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
            <input
              onChange={(e) => setSearchValue(e.target.value)}
              type="search"
              id="default-search"
              className="block p-2 pl-10 w-3/5 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primaryColor focus:border-primaryColor outline-none"
              placeholder={t("searchByName")}
              required
            />
          </div>
        </form>

        {filteredUserList?.map((user: any, index: any) => (
          <div
            key={index}
            onClick={() => setSelectUser(user)}
            className={classNames(
              "flex items-center p-4 border-y border-gray-200 hover:bg-gray-100 transition-all cursor-pointer  mb-2",
              user.id === selectUser.id ? "bg-secondaryColor" : "bg-white"
            )}>
            <div
              className={classNames(
                "rounded-full border min-w-[12px] min-h-[12px] mr-2",
                user.id === selectUser.id ? "bg-primaryColor" : "bg-white"
              )}></div>
            <div className="flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full"
                src={user?.partner_avatar ?? "/assets/icons/placeholder.png"}
                alt=""
              />
            </div>
            <div className="ml-3 overflow-hidden">
              <p className="text-sm font-medium text-gray-900 flex">
                <span>{user.first_name}</span>

                {/*  {user?.last_review_timeline?.is_read && (
                  <span className="ml-.5 text-xs text-red-500">
                    <EnvelopeIcon className="h-3 w-3" />
                  </span>
                )} */}
              </p>

              <p className="text-sm text-gray-500 truncate">
                {user.last_review_timeline?.content?.type === "auto_message" &&
                  user.last_review_timeline?.content?.user_message}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-end bg-gray-100 pt-2 pr-1 gap-2 ">
        <button
          onClick={() => handlePrev()}
          className="flex items-center justify-between  px-2 py-2 text-xs font-medium text-left text-gray-700 bg-white rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryColor">
          <ChevronLeftIcon className="w-3 h-3" />
        </button>

        {/*current page as select */}
        <select
          value={currentPage}
          onChange={(e) => {
            setSelectUser(false);
            setCurrentPage(e.target.value);
          }}
          className="flex items-center justify-between  px-2 py-2 text-xs font-medium text-left text-gray-700 bg-white rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring-1 focus-visible:ring-offset-1 focus-visible:ring-primaryColor">
          {userList &&
            Array.from(Array(Math.ceil(+userList?.total_count / 10)).keys()).map((item: any) => (
              <option key={item} value={item + 1}>
                {item + 1}
              </option>
            ))}
          <option value={0}>All</option>
        </select>
        {/*  <div className="flex items-center justify-between  px-3 py-2 text-xs font-medium text-left text-gray-700 bg-white rounded-lg  ">
          {currentPage}
        </div> */}
        <button
          onClick={() => handleNext()}
          className="flex items-center justify-between  px-2 py-2 text-xs font-medium text-left text-gray-700 bg-white rounded-lg hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryColor">
          <ChevronRightIcon className="w-3 h-3" />
        </button>
      </div>
    </>
  );
};

export default UserList;
