import { atom } from "recoil";

export const projectInfo = atom<{
  id: number;
  pricingPlan: {
    id: number;
  };
  hasAssignTask: boolean;
  totalCredit: number;
  totalUsedCredit: number;
}>({
  key: "projectInfo",
  default: {
    id: 0,
    pricingPlan: {
      id: 1,
    },
    hasAssignTask: false,
    totalCredit: 0,
    totalUsedCredit: 0,
  },
});
