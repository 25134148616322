import VideoModal from "@/components/VideoModal";
import { t } from "i18next";
import React from "react";

type Props = {
  video: any;
};

const VideoContainer = (props: Props) => {
  const { video } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div
      className="bg-white rounded-md shadow flex flex-col gap-4 overflow-hidden 
    w-[400px]
    cursor-pointer
  "
      onClick={() => {
        setOpen(true);
      }}>
      <img src={video.image} className="w-full h-[200px] object-cover" alt="" />
      <div className="p-4 space-y-2 ">
        <div className="text-lg font-bold text-black">{t(video.title)}</div>
        <div className="text-sm">{t(video.description)}</div>
      </div>
      {open && (
        <VideoModal
          src={video.video}
          onClose={() => setOpen(false)}
          open={open}
          title={video.title}
        />
      )}
    </div>
  );
};

export default VideoContainer;
