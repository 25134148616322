import { ButtonProps } from "@/common/ButtonProps";
import "@/styles/style.css";

function SetButton(props: ButtonProps) {
    return (
        <button className="setButton" onClick={props.onClick}>{props.text}
        </button>
    );
}

export default SetButton;
