import { useMutation, useQuery } from "react-query";
import { fetchProjectData, fetchRoadmapLogs, postProjectData } from "@/api/project";
import LoadingSpinner from "@/components/UI/LoadingSpinner";
import { fetchWeeklyTasks } from "@/api/task";
import { Link } from "react-router-dom";
import {
  CalendarIcon,
} from "@heroicons/react/20/solid";
import Graph1 from "@/features/dashboard/components/Graph1";
import Graph2 from "@/features/dashboard/components/Graph2";
import { ProjectDataType } from "@/models/projectDataType";
import { t } from "i18next";
import { ChevronRightIcon, ShieldExclamationIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import i18n from "@/i18n";

interface Props {
  projectId: any;
  pricingPlan: any;
}

export default function MiddleSection(props: Props) {
  const { projectId, pricingPlan } = props;

  const { isLoading: isLoadingProjectData } = useQuery(
    "fetchProjectGettingStartedDataForDashboardd",
    () => fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      cacheTime: 0,
    }
  );

  const {
    data: tasks,
    isLoading: isLoadingTasks,
  } = useQuery<any>(
    "fetchWeeklyTasks",
    () => fetchWeeklyTasks({ projectId: projectId, is_done: false }),
    {
      retry: false,
      enabled: !!projectId,
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { mutateAsync } = useMutation("postProjectDomainData", postProjectData, {
    retry: false,
  });

  (key: string) => {
    fetchProjectData({ projectId: projectId, name: "GETTING_STARTED" })
      .then((res) => {
        const data = res.data.value;
        // object key count
        mutateAsync({
          projectId,
          project: {
            datatype_name: ProjectDataType.GETTING_STARTED,
            data: {
              value: {
                ...data,
                [key]: true,
              },
            },
          },
        });
      })
      .catch(() => {
        mutateAsync({
          projectId,
          project: {
            datatype_name: ProjectDataType.GETTING_STARTED,
            data: {
              value: {
                [key]: true,
              },
            },
          },
        });
      });
  };

  const { data: roadmapLogs, isLoading: isLoadingLogs } = useQuery<any>(
    "fetchRoadmapLogs",
    () => fetchRoadmapLogs(projectId),
    {
      retry: false,
      enabled: !!projectId,
    }
  );

  return (
    <div id="getting-started">
      {isLoadingProjectData || isLoadingTasks || isLoadingLogs ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <>
          <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 ">
            <div className="overflow-hidden rounded-lg bg-white px-4 py-4 shadow sm:p-5">
              <div className="mt-1 text-xl font-medium tracking-tight text-gray-900 mb-3 first-letter:uppercase">
                {t("Task List")}
              </div>
              <div className="h-48  overflow-y-hidden bg-white  sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                  {tasks?.items.slice(0, 3).map((position: any) => (
                    <li key={position.id}>
                      <Link to={`/tasks`} className="block hover:bg-gray-50">
                        <div className="flex items-center px-4 py-2 sm:px-6">
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="truncate font-medium text-indigo-600">
                                  {position.story_description}
                                </p>
                              </div>
                              <div className="mt-2 flex">
                                <div className="flex items-center text-sm text-gray-500">
                                  <CalendarIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <p className="capitalize">
                                    {t("dueDate")}
                                    {": "}
                                    {position.due_date
                                      ? moment(position.due_date)
                                        .locale(i18n.language)
                                        .format("DD MMM YYYY")
                                      : t("noDueDate")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                              <div className="flex -space-x-1 overflow-hidden"></div>
                            </div>
                          </div>
                          <div className="ml-5 flex-shrink-0">
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/*  <div className="h-48 overflow-y-auto flex flex-col divide-y">
                {tasks?.items.map((task: any) => (
                  <div
                    className="flex items-center gap-3 cursor-pointer"
                    onClick={() => navigate("/tasks")}>
                    <CalendarIcon className="w-7 h-7 text-blue-500" />
                    <div className="flex flex-col my-2">
                      <div className="text-sm ">{task.title}</div>
                      <div className="text-xs text-gray-400 ">{task.story_description}</div>
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="flex justify-end">
                <Link
                  to={`/tasks`}
                  className="text-blue-500 text-sm font-medium mt-5 cursor-pointer">
                  {t("View All")}
                </Link>
              </div>
            </div>
            <div className="overflow-hidden rounded-lg py-2 custom-scroll bg-white shadow overflow-x-auto relative ">
              <div className="mt-1 text-xl font-medium tracking-tight text-gray-900 mb-3 first-letter:uppercase px-4 pt-4">
                {t("Working rules for you")}
              </div>
              <div className="h-48 overflow-y-auto ">
                {roadmapLogs.length > 0 ? (
                  roadmapLogs?.map((log: any) => (
                    <div className="flex items-center gap-3 px-4 py-2 border-b ">
                      <div>
                        <ShieldExclamationIcon className="w-5 h-5 block text-red-500" />
                      </div>
                      <div className="flex flex-col my-2">
                        <div className="text-xs text-gray-800 ">{log?.description}</div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex items-center gap-3 px-4 py-2">
                    <div className="flex justify-center items-center my-2 p-2 bg-gray-50 font-medium">
                      {t(
                        "When system finish to read audits, you will see the rules working for you!"
                      )}
                    </div>
                  </div>
                )}
              </div>
              {roadmapLogs.length > 0 && (
                <div className="flex justify-end pr-4">
                  <Link
                    to={`/rules`}
                    className="text-blue-500 text-sm font-medium mt-5 cursor-pointer">
                    {t("View All Rules")} →
                  </Link>
                </div>
              )}
            </div>
            <Graph1 projectId={projectId} />
            <Graph2 pricingPlan={pricingPlan} projectId={projectId} />
          </dl>
        </>
      )}
    </div>
  );
}
