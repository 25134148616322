import { atom } from "recoil";

export const taskNavbar = atom<{
  refetchNav: boolean;
}>({
  key: "taskNavbar",
  default: {
    refetchNav: false,
  },
});
