import { CheckIcon } from "@heroicons/react/20/solid";
import DefaultButton from "@/components/buttons/DefaultButton/DefaultButton";
// import { useRecoilState } from "recoil";
// import { userAtom } from "@/store/atoms/user.atoms";
import useFetchingProjectIds from "@/hooks/use-fetching-projectIds";
import LoadingSpinner from "@/components/UI/LoadingSpinner";

const PaymentSuccessfullPage = () => {
  // const [user] = useRecoilState(userAtom);
  const { isLoading: isLoadingProjectId } = useFetchingProjectIds(); //, pricingPlan, isSuccess

  /*   useEffect(() => {
    if (isSuccess) {
      if (pricingPlan?.id !== 1) {
        buyingPriceEvent({
          email: user?.email || "",
          userId: user?.id.toString() || "",
          pricingPlanId: pricingPlan?.id || "",
          status: "success",
        });
      } else {
        window.location.href = "/profile";
      }
    }
  }, [isSuccess]); */

  const handleNavigateToDashboard = () => {
    const textDescription =
      "You have successfully paid for the package. You can now access your package limits.";
    localStorage.setItem(
      "notificationInfoForPayment",
      JSON.stringify({ isShow: "true", message: textDescription })
    );
    window.location.href = "/";
  };

  return (
    <>
      {isLoadingProjectId ? (
        <LoadingSpinner size="w-8 h-8" />
      ) : (
        <div className=" h-screen flex justify-center items-center text-lg flex-col gap-2">
          <div className="p-3 bg-green-200 rounded-full">
            <CheckIcon className="h-5 w-5 flex-shrink-0 text-green-600" aria-hidden="true" />
          </div>
          <div className="text-lg font-medium mt-2">Payment Successful</div>
          <div className="text-gray-500 text-sm max-w-sm text-center ">
            Your payment has been successfully processed. You can now access your package limits.
          </div>
          <DefaultButton onClick={handleNavigateToDashboard} className="w-fit">
            Go to Dashboard
          </DefaultButton>
        </div>
      )}
    </>
  );
};

export default PaymentSuccessfullPage;
